/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { CountriesService } from './services/countries.service';
import { HeadlinesService } from './services/headlines.service';
import { AnnouncementService } from './services/announcement.service';
import { BuybackService } from './services/buyback.service';
import { UserService } from './services/user.service';
import { QualityGateService } from './services/quality-gate.service';
import { SuspiciousViewService } from './services/suspicious-view.service';
import { CompanyService } from './services/company.service';
import { IntentionService } from './services/intention.service';
import { InternalReportsService } from './services/internal-reports.service';
import { IssuerService } from './services/issuer.service';
import { IssuerMappingsService } from './services/issuer-mappings.service';
import { SenatorBiographyService } from './services/senator-biography.service';
import { BiographyCongressTenureService } from './services/biography-congress-tenure.service';
import { SenatorCommitteeService } from './services/senator-committee.service';
import { SenatorService } from './services/senator.service';
import { SenatorCompanyService } from './services/senator-company.service';
import { SenatorCompanyAssetClassService } from './services/senator-company-asset-class.service';
import { StockOptionListingService } from './services/stock-option-listing.service';
import { TradesService } from './services/trades.service';
import { SenatorQualityGateService } from './services/senator-quality-gate.service';
import { SenatorNoGvKeyConfirmationService } from './services/senator-no-gv-key-confirmation.service';
import { SenatorMarketTradedSecurityConfirmationService } from './services/senator-market-traded-security-confirmation.service';
import { SenatorRecentlyMergedAssetClassesTradesService } from './services/senator-recently-merged-asset-classes-trades.service';
import { ShortSellingService } from './services/short-selling.service';
import { ShortDisclosuresExportService } from './services/short-disclosures-export.service';
import { ShareBuybackService } from './services/share-buyback.service';
import { ShortSellingDataService } from './services/short-selling-data.service';
import { ShortDisclosuresQualityGateService } from './services/short-disclosures-quality-gate.service';
import { ShortDisclosuresService } from './services/short-disclosures.service';
import { ShortDisclosureService } from './services/short-disclosure.service';
import { SdsDataService } from './services/sds-data.service';
import { ShortInterestOverviewService } from './services/short-interest-overview.service';
import { SbbBotsService } from './services/sbb-bots.service';
import { ManagerService } from './services/manager.service';
import { PositionsService } from './services/positions.service';
import { IssuerPriceService } from './services/issuer-price.service';
import { SectorService } from './services/sector.service';
import { PoliticianTweetService } from './services/politician-tweet.service';
import { IssuerMappingService } from './services/issuer-mapping.service';
import { CompanyScreeningService } from './services/company-screening.service';
import { SmallCapCompaniesService } from './services/small-cap-companies.service';
import { BotsStatusService } from './services/bots-status.service';
import { CapitolTradesService } from './services/capitol-trades.service';
import { TransactionLabelService } from './services/transaction-label.service';
import { ListedOptionsQualityGateService } from './services/listed-options-quality-gate.service';
import { QualityChecksService } from './services/quality-checks.service';
import { RepresentativeDistrictService } from './services/representative-district.service';
import { ManualExportService } from './services/manual-export.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    CountriesService,
    HeadlinesService,
    AnnouncementService,
    BuybackService,
    UserService,
    QualityGateService,
    SuspiciousViewService,
    CompanyService,
    IntentionService,
    InternalReportsService,
    IssuerService,
    IssuerMappingsService,
    SenatorBiographyService,
    BiographyCongressTenureService,
    SenatorCommitteeService,
    SenatorService,
    SenatorCompanyService,
    SenatorCompanyAssetClassService,
    StockOptionListingService,
    TradesService,
    SenatorQualityGateService,
    SenatorNoGvKeyConfirmationService,
    SenatorMarketTradedSecurityConfirmationService,
    SenatorRecentlyMergedAssetClassesTradesService,
    ShortSellingService,
    ShortDisclosuresExportService,
    ShareBuybackService,
    ShortSellingDataService,
    ShortDisclosuresQualityGateService,
    ShortDisclosuresService,
    ShortDisclosureService,
    SdsDataService,
    ShortInterestOverviewService,
    SbbBotsService,
    ManagerService,
    PositionsService,
    IssuerPriceService,
    SectorService,
    PoliticianTweetService,
    IssuerMappingService,
    CompanyScreeningService,
    SmallCapCompaniesService,
    BotsStatusService,
    CapitolTradesService,
    TransactionLabelService,
    ListedOptionsQualityGateService,
    QualityChecksService,
    RepresentativeDistrictService,
    ManualExportService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}

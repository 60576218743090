/* tslint:disable */

/**
 * a model to tell type of enum
 */
type TickerMappingTypeEnum =
  'Un-mapped' |
  'Mapped Only';
module TickerMappingTypeEnum {
  export const UN_MAPPED: TickerMappingTypeEnum = 'Un-mapped';
  export const MAPPED_ONLY: TickerMappingTypeEnum = 'Mapped Only';
  export function values(): TickerMappingTypeEnum[] {
    return [
      UN_MAPPED,
      MAPPED_ONLY
    ];
  }
}

export { TickerMappingTypeEnum }
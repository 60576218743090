/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EUDisclosure } from '../models/eudisclosure';
@Injectable({
  providedIn: 'root',
})
class ShortDisclosuresExportService extends __BaseService {
  static readonly getshortDisclosuresExportPath = '/shortDisclosuresExport/europe';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShortDisclosuresExportService.GetshortDisclosuresExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `isActive`:
   *
   * - `endDate`:
   *
   * - `countryCode`:
   *
   * @return Response will be disclosure information for region europe.
   */
  getshortDisclosuresExportResponse(params: ShortDisclosuresExportService.GetshortDisclosuresExportParams): __Observable<__StrictHttpResponse<Array<EUDisclosure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.countryCode != null) __params = __params.set('countryCode', params.countryCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shortDisclosuresExport/europe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EUDisclosure>>;
      })
    );
  }
  /**
   * @param params The `ShortDisclosuresExportService.GetshortDisclosuresExportParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `isActive`:
   *
   * - `endDate`:
   *
   * - `countryCode`:
   *
   * @return Response will be disclosure information for region europe.
   */
  getshortDisclosuresExport(params: ShortDisclosuresExportService.GetshortDisclosuresExportParams): __Observable<Array<EUDisclosure>> {
    return this.getshortDisclosuresExportResponse(params).pipe(
      __map(_r => _r.body as Array<EUDisclosure>)
    );
  }
}

module ShortDisclosuresExportService {

  /**
   * Parameters for getshortDisclosuresExport
   */
  export interface GetshortDisclosuresExportParams {
    startDate?: string;
    pageSize?: number;
    pageNumber?: number;
    isActive?: boolean;
    endDate?: string;
    countryCode?: string;
  }
}

export { ShortDisclosuresExportService }

/* tslint:disable */
type Senator2iqTransactionTypesForFlag =
  'Short Cover' |
  'Short Sell' |
  'Dividend Reinvestment' |
  'Gift Plus' |
  'Gift Minus' |
  'Donation Plus' |
  'Donation Minus' |
  'Transfer Plus' |
  'Transfer Minus' |
  'Exercise Of Call Option' |
  'Exercise Of Put Option' |
  'Subscribe' |
  'Lending Plus' |
  'Lending Minus' |
  'Contribution Plus' |
  'Contribution Minus' |
  'Award' |
  'IsCurated' |
  'IsNotReportedOnSource';
module Senator2iqTransactionTypesForFlag {
  export const SHORT_COVER: Senator2iqTransactionTypesForFlag = 'Short Cover';
  export const SHORT_SELL: Senator2iqTransactionTypesForFlag = 'Short Sell';
  export const DIVIDEND_REINVESTMENT: Senator2iqTransactionTypesForFlag = 'Dividend Reinvestment';
  export const GIFT_PLUS: Senator2iqTransactionTypesForFlag = 'Gift Plus';
  export const GIFT_MINUS: Senator2iqTransactionTypesForFlag = 'Gift Minus';
  export const DONATION_PLUS: Senator2iqTransactionTypesForFlag = 'Donation Plus';
  export const DONATION_MINUS: Senator2iqTransactionTypesForFlag = 'Donation Minus';
  export const TRANSFER_PLUS: Senator2iqTransactionTypesForFlag = 'Transfer Plus';
  export const TRANSFER_MINUS: Senator2iqTransactionTypesForFlag = 'Transfer Minus';
  export const EXERCISE_OF_CALL_OPTION: Senator2iqTransactionTypesForFlag = 'Exercise Of Call Option';
  export const EXERCISE_OF_PUT_OPTION: Senator2iqTransactionTypesForFlag = 'Exercise Of Put Option';
  export const SUBSCRIBE: Senator2iqTransactionTypesForFlag = 'Subscribe';
  export const LENDING_PLUS: Senator2iqTransactionTypesForFlag = 'Lending Plus';
  export const LENDING_MINUS: Senator2iqTransactionTypesForFlag = 'Lending Minus';
  export const CONTRIBUTION_PLUS: Senator2iqTransactionTypesForFlag = 'Contribution Plus';
  export const CONTRIBUTION_MINUS: Senator2iqTransactionTypesForFlag = 'Contribution Minus';
  export const AWARD: Senator2iqTransactionTypesForFlag = 'Award';
  export const IS_CURATED: Senator2iqTransactionTypesForFlag = 'IsCurated';
  export const IS_NOT_REPORTED_ON_SOURCE: Senator2iqTransactionTypesForFlag = 'IsNotReportedOnSource';
  export function values(): Senator2iqTransactionTypesForFlag[] {
    return [
      SHORT_COVER,
      SHORT_SELL,
      DIVIDEND_REINVESTMENT,
      GIFT_PLUS,
      GIFT_MINUS,
      DONATION_PLUS,
      DONATION_MINUS,
      TRANSFER_PLUS,
      TRANSFER_MINUS,
      EXERCISE_OF_CALL_OPTION,
      EXERCISE_OF_PUT_OPTION,
      SUBSCRIBE,
      LENDING_PLUS,
      LENDING_MINUS,
      CONTRIBUTION_PLUS,
      CONTRIBUTION_MINUS,
      AWARD,
      IS_CURATED,
      IS_NOT_REPORTED_ON_SOURCE
    ];
  }
}

export { Senator2iqTransactionTypesForFlag }
import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "./services/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'capitol_trades';
  user: string = null;
  opened = true;
  isLoggedIn = false;
  tabs: TabItem[] = [
    {
      label: 'Files',
      url: './assets/images/file.png',
      route: 'files',
    },
    {
      label: 'Biographies',
      url: './assets/images/biography.png',
      route: 'biographies',
    },
    {
      label: 'Committees',
      url: './assets/images/group.png',
      route: 'committees',
    },
    {
      label: 'Quality Gates',
      url: './assets/images/quality.png',
      route: 'qualityGates',
    },
    {
      label: 'Stats',
      url: './assets/images/stocks.png',
      route: 'stats',
    },
    {
      label: 'All Trades',
      url: './assets/images/stocks.png',
      route: 'alltrades',
    }
  ];

  constructor(private authService: AuthenticationService) {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  ngOnInit() {
    this.user = localStorage.getItem('userId');
    const isAuthenticateUser = this.isAuthToCheckUserBased();
    if (!isAuthenticateUser) {
      this.tabs = this.tabs.filter(t => t.label != 'Stats');
    }
  }

  isAuthToCheckUserBased(): boolean {
    return this.user == 'muaz.ul-haq@2iqresearch.com' ||
      this.user == 'huzaifa.waseem@2iqresearch.com' ||
      this.user == 'rahul.joshua@2iqresearch.com' ||
      this.user == 'usman.naveed@2iqresearch.com' ||
      this.user == 'talha.tufail@2iqresearch.com' ||
      this.user == 'zafar.shujat@2iqresearch.com' ||
      this.user == 'taimoor.waheed@2iqresearch.com' ||
      this.user == 'huzaifa.waseem@2iqresearch.com' ||
      this.user == 'aswad.abbas@2iqresearch.com' ||
      this.user == 'irfan.akbar@2iqresearch.com' ||
      this.user == 'zabih.ullah@2iqresearch.com' ||
      this.user == 'muhammad.waqas@2iqresearch.com' ||
      this.user == 'tayyab.anmol@2iqresearch.com';
  }

  getClass() {
    return 'active';
  }

  doLogin() {
    this.authService.doLogin();
  }

  logOut() {
    this.authService.logout();
    this.isLoggedIn = false;
  }
}

export interface TabItem {
  label: string;
  url: string;
  route: string;
}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorTrade } from '../models/senator-trade';
import { TradeSearchFilter } from '../models/trade-search-filter';
import { TypesList } from '../models/types-list';
@Injectable({
  providedIn: 'root',
})
class TradesService extends __BaseService {
  static readonly getSenatorTradesPath = '/senatorTrades';
  static readonly getTypesPath = '/types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param searchFilter This includes some filters to search by.
   * @return List of selected senator trades will be returned.
   */
  getSenatorTradesResponse(searchFilter?: TradeSearchFilter): __Observable<__StrictHttpResponse<Array<SenatorTrade>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorTrades`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrade>>;
      })
    );
  }
  /**
   * @param searchFilter This includes some filters to search by.
   * @return List of selected senator trades will be returned.
   */
  getSenatorTrades(searchFilter?: TradeSearchFilter): __Observable<Array<SenatorTrade>> {
    return this.getSenatorTradesResponse(searchFilter).pipe(
      __map(_r => _r.body as Array<SenatorTrade>)
    );
  }

  /**
   * @return Types having list to filters drop down.
   */
  getTypesResponse(): __Observable<__StrictHttpResponse<TypesList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TypesList>;
      })
    );
  }
  /**
   * @return Types having list to filters drop down.
   */
  getTypes(): __Observable<TypesList> {
    return this.getTypesResponse().pipe(
      __map(_r => _r.body as TypesList)
    );
  }
}

module TradesService {
}

export { TradesService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BotsStuckStatus } from '../models/bots-stuck-status';
@Injectable({
  providedIn: 'root',
})
class BotsStatusService extends __BaseService {
  static readonly getSbbBotsLastRunStatusPath = '/SbbBotsLastRunStatus';
  static readonly getSdsBotsLastRunStatusPath = '/SdsBotsLastRunStatus';
  static readonly getPoliticianBotsLastRunStatusPath = '/PoliticianBotsLastRunStatus';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return A List of model that will be responsible for informing status of sbb bots.
   */
  getSbbBotsLastRunStatusResponse(): __Observable<__StrictHttpResponse<Array<BotsStuckStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SbbBotsLastRunStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BotsStuckStatus>>;
      })
    );
  }
  /**
   * @return A List of model that will be responsible for informing status of sbb bots.
   */
  getSbbBotsLastRunStatus(): __Observable<Array<BotsStuckStatus>> {
    return this.getSbbBotsLastRunStatusResponse().pipe(
      __map(_r => _r.body as Array<BotsStuckStatus>)
    );
  }

  /**
   * @return A List of model that will be responsible for informing status of sds bots.
   */
  getSdsBotsLastRunStatusResponse(): __Observable<__StrictHttpResponse<Array<BotsStuckStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SdsBotsLastRunStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BotsStuckStatus>>;
      })
    );
  }
  /**
   * @return A List of model that will be responsible for informing status of sds bots.
   */
  getSdsBotsLastRunStatus(): __Observable<Array<BotsStuckStatus>> {
    return this.getSdsBotsLastRunStatusResponse().pipe(
      __map(_r => _r.body as Array<BotsStuckStatus>)
    );
  }

  /**
   * @return A List of model that will be responsible for informing status of politician bots.
   */
  getPoliticianBotsLastRunStatusResponse(): __Observable<__StrictHttpResponse<Array<BotsStuckStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/PoliticianBotsLastRunStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BotsStuckStatus>>;
      })
    );
  }
  /**
   * @return A List of model that will be responsible for informing status of politician bots.
   */
  getPoliticianBotsLastRunStatus(): __Observable<Array<BotsStuckStatus>> {
    return this.getPoliticianBotsLastRunStatusResponse().pipe(
      __map(_r => _r.body as Array<BotsStuckStatus>)
    );
  }
}

module BotsStatusService {
}

export { BotsStatusService }

import {Component, OnInit, ViewChild} from '@angular/core';
import {TradesService} from '../../../api/services/trades.service';
import {PoliticianPartyEnum, SenatorTrade, SenatorTradeFilter, TradeSearchFilter, TypesList} from '../../../api/models';
import {CongressTypeEnum} from '../../../api/models/congress-type-enum';
import {map, startWith} from 'rxjs/operators';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';


@Component({
  selector: 'app-all-trades',
  templateUrl: './all-trades.component.html',
  styleUrls: ['./all-trades.component.css']
})
export class AllTradesComponent implements OnInit {
  loading = false;
  tableDataSource: SenatorTrade[];
  politicians: string[];
  issuers: string[];
  tickers: string[];
  originalIssuerNames: string[];
  shareTypes = [] as string[];
  tradeTypes: string[];
  typeList: TypesList;
  topFilters = {pageNumber: 1, pageSize: 20} as TradeSearchFilter;


// Mat Paginator
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pagination = {pageSize: 10, pageNumber: 1};
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageEvent: PageEvent;

  congressTypes = CongressTypeEnum.values();
  politicianParties = PoliticianPartyEnum.values();
  filteredPoliticians: string[];
  filteredIssuers: string[];
  filteredOriginalIssuerNames: string[];
  filteredTickers: string[];
  filtersForm: FormGroup;
  public height: string;
  public height1: string;
  public height2: string;
  public height3: string;

  constructor(private tradesService: TradesService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initVariables();
    this.initFiltersForm();
    this.getTypes();
    this.searchTrades();
  }

  initVariables() {
  }

  initFiltersForm() {
    this.filtersForm = this.formBuilder.group({
      politicalName: [''],
      politicianParty: [PoliticianPartyEnum.BOTH],
      issuerName: [''],
      shareType: [''],
      tradeType: [''],
      ticker: [''],
      orignalIssuerName: [''],
      congressType: [CongressTypeEnum.BOTH],
      filingDateFrom: [null],
      filingDateTo: [null],
      transactionDateFrom: [null],
      transactionDateTo: [null],
    });
  }

  getTypes() {
    this.tradesService.getTypes().subscribe(
      (res: TypesList) => {
        this.typeList = res;
      },
      (err) => {
      },
      () => {
        this.initChangeListeners();
      }
    );
  }

  initChangeListeners() {
    this.tradeTypes = this.typeList.tradeTypes;
    this.shareTypes = this.typeList.shareTypes;
    this.issuers = this.typeList.issuerNames;
    this.originalIssuerNames = this.typeList.orignalIssuerNames;
    this.politicians = this.typeList.politicianNames;

    // Listen for changes to the PoliticianName input
    this.politicalName.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // Filter the options
          this.filteredPoliticians = this.politicians.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));

          // Recompute how big the viewport should be.
          if (this.filteredPoliticians.length < 4) {
            this.height = (this.filteredPoliticians.length * 50) + 'px';
          } else {
            this.height = '300px';
          }
        })
      ).subscribe();

    // Listen for changes to the issuerName input
    this.issuerName.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // Filter the options
          this.filteredIssuers = this.issuers.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));
          // Recompute how big the viewport should be.
          if (this.filteredIssuers.length < 4) {
            this.height1 = (this.filteredIssuers.length * 50) + 'px';
          } else {
            this.height1 = '300px';
          }
        })
      ).subscribe();

    // Listen for changes to the Original Issuer Name
    this.originalIssuerName.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // Filter the options
          this.filteredOriginalIssuerNames = this.originalIssuerNames.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));
          // Recompute how big the viewport should be.
          if (this.filteredOriginalIssuerNames.length < 4) {
            this.height3 = (this.filteredOriginalIssuerNames.length * 50) + 'px';
          } else {
            this.height3 = '300px';
          }
        })
      ).subscribe();

    // Listen for changes to the ticker input
    this.ticker.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // Filter the options
          this.filteredTickers = this.tickers.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));
          // Recompute how big the viewport should be.
          if (this.filteredTickers.length < 4) {
            this.height2 = (this.filteredTickers.length * 50) + 'px';
          } else {
            this.height2 = '300px';
          }
        })
      ).subscribe();

  }

  searchTrades() {
    this.topFilters = this.filtersForm.value;

    this.topFilters.pageNumber = this.pagination.pageNumber;
    this.topFilters.pageSize = this.pagination.pageSize;


    if (this.filingDateFrom.value) {
      this.topFilters.filingDateFrom = this.getRequiredFormatDate(this.filingDateFrom.value);
    }
    if (this.filingDateTo.value) {
      this.topFilters.filingDateTo = this.getRequiredFormatDate(this.filingDateTo.value);
    }

    if (this.transactionDateFrom.value) {
      this.topFilters.transactionDateFrom = this.getRequiredFormatDate(this.transactionDateFrom.value);
    }
    if (this.transactionDateTo.value) {
      this.topFilters.transactionDateTo = this.getRequiredFormatDate(this.transactionDateTo.value);
    }
    this.loadTheTrades(this.topFilters);
  }

  loadTheTrades(topFilters: TradeSearchFilter) {
    this.loading = true;
    this.tradesService.getSenatorTrades(topFilters).subscribe(
      (response: SenatorTrade[]) => {
        this.tableDataSource = response as SenatorTrade[];
        // console.log('checkTrade', this.tableDataSource);
      },
      (error) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  getRequiredFormatDate(fpDate: any) {
    let formatMonth;
    let formatDay;
    const date = fpDate.getDate();
    const month = fpDate.getMonth();
    const year = fpDate.getFullYear();
    if (month < 10) {
      formatMonth = '0' + (month + 1).toString();
    } else {
      formatMonth = month + 1;
    }
    if (date < 10) {
      formatDay = '0' + (date).toString();
    } else {
      formatDay = date;
    }
    return year + '-' + formatMonth + '-' + formatDay;
  }

  clearFilters() {
    this.initFiltersForm();
    this.searchTrades();
    this.initChangeListeners();
  }

  clearFilter(filterName: string) {
    this[filterName].setValue('');
    this.searchTrades();
    this.initChangeListeners();
  }

  onPaginateChange(event: PageEvent) {
    if (event == null) {
      return;
    }
    this.pagination.pageNumber = event.pageIndex + 1;
    this.pagination.pageSize = event.pageSize;
    if (this.paginator == null) {
      return;
    }
    this.searchTrades();
  }

  get politicalName() {
    return this.filtersForm.get('politicalName');
  }

  get issuerName() {
    return this.filtersForm.get('issuerName');
  }

  get originalIssuerName() {
    return this.filtersForm.get('orignalIssuerName');
  }

  get tradeType() {
    return this.filtersForm.get('tradeType');
  }

  get ticker() {
    return this.filtersForm.get('ticker');
  }

  get congressType() {
    return this.filtersForm.get('congressType');
  }

  get shareType() {
    return this.filtersForm.get('shareType');
  }

  get filingDateFrom() {
    return this.filtersForm.get('filingDateFrom');
  }

  get filingDateTo() {
    return this.filtersForm.get('filingDateTo');
  }

  get transactionDateFrom() {
    return this.filtersForm.get('transactionDateFrom');
  }

  get transactionDateTo() {
    return this.filtersForm.get('transactionDateTo');
  }

}

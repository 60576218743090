/* tslint:disable */
type PoliticianPartyEnum =
  'Republican' |
  'Democrat' |
  'Both' |
  'Other';
module PoliticianPartyEnum {
  export const REPUBLICAN: PoliticianPartyEnum = 'Republican';
  export const DEMOCRAT: PoliticianPartyEnum = 'Democrat';
  export const BOTH: PoliticianPartyEnum = 'Both';
  export const OTHER: PoliticianPartyEnum = 'Other';
  export function values(): PoliticianPartyEnum[] {
    return [
      REPUBLICAN,
      DEMOCRAT,
      BOTH,
      OTHER
    ];
  }
}

export { PoliticianPartyEnum }
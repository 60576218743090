/* tslint:disable */

/**
 * A enum contains checks for listed option quality gate
 */
type ListedOptionQualityGateCheck =
  'OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED' |
  'TICKER_NULL_THEN_STYLE_OPR17_OCC21_FIGI_NULL' |
  'UNDERLYING_ASSET_TYPE_IS_STOCK_EQUITIES_THEN_UNDERLYING_ASSET_TYPE_DECLARED' |
  'EXPIRATION_DATE_LESS_THAN_TRANSACTION_DATE' |
  'EXPIRATION_DATE_DIFFERENT_DATE_IN_TICKER' |
  'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_IN_COMMENTS_BOT_NAME' |
  'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_MATCH_TYPE_OF_LISTED_OPTION' |
  'UNDERLYING_ASSET_AND_STYLE_PAIR_CHECK' |
  'UNDERLYING_ASSET_AND_CORRESPONDING_ASSET_TYPE_PAIR_CHECK' |
  'OPTION_DETAIL_IS_NOT_POPULATE';
module ListedOptionQualityGateCheck {
  export const OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED: ListedOptionQualityGateCheck = 'OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED';
  export const TICKER_NULL_THEN_STYLE_OPR17_OCC21_FIGI_NULL: ListedOptionQualityGateCheck = 'TICKER_NULL_THEN_STYLE_OPR17_OCC21_FIGI_NULL';
  export const UNDERLYING_ASSET_TYPE_IS_STOCK_EQUITIES_THEN_UNDERLYING_ASSET_TYPE_DECLARED: ListedOptionQualityGateCheck = 'UNDERLYING_ASSET_TYPE_IS_STOCK_EQUITIES_THEN_UNDERLYING_ASSET_TYPE_DECLARED';
  export const EXPIRATION_DATE_LESS_THAN_TRANSACTION_DATE: ListedOptionQualityGateCheck = 'EXPIRATION_DATE_LESS_THAN_TRANSACTION_DATE';
  export const EXPIRATION_DATE_DIFFERENT_DATE_IN_TICKER: ListedOptionQualityGateCheck = 'EXPIRATION_DATE_DIFFERENT_DATE_IN_TICKER';
  export const CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_IN_COMMENTS_BOT_NAME: ListedOptionQualityGateCheck = 'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_IN_COMMENTS_BOT_NAME';
  export const CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_MATCH_TYPE_OF_LISTED_OPTION: ListedOptionQualityGateCheck = 'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_MATCH_TYPE_OF_LISTED_OPTION';
  export const UNDERLYING_ASSET_AND_STYLE_PAIR_CHECK: ListedOptionQualityGateCheck = 'UNDERLYING_ASSET_AND_STYLE_PAIR_CHECK';
  export const UNDERLYING_ASSET_AND_CORRESPONDING_ASSET_TYPE_PAIR_CHECK: ListedOptionQualityGateCheck = 'UNDERLYING_ASSET_AND_CORRESPONDING_ASSET_TYPE_PAIR_CHECK';
  export const OPTION_DETAIL_IS_NOT_POPULATE: ListedOptionQualityGateCheck = 'OPTION_DETAIL_IS_NOT_POPULATE';
  export function values(): ListedOptionQualityGateCheck[] {
    return [
      OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED,
      TICKER_NULL_THEN_STYLE_OPR17_OCC21_FIGI_NULL,
      UNDERLYING_ASSET_TYPE_IS_STOCK_EQUITIES_THEN_UNDERLYING_ASSET_TYPE_DECLARED,
      EXPIRATION_DATE_LESS_THAN_TRANSACTION_DATE,
      EXPIRATION_DATE_DIFFERENT_DATE_IN_TICKER,
      CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_IN_COMMENTS_BOT_NAME,
      CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_MATCH_TYPE_OF_LISTED_OPTION,
      UNDERLYING_ASSET_AND_STYLE_PAIR_CHECK,
      UNDERLYING_ASSET_AND_CORRESPONDING_ASSET_TYPE_PAIR_CHECK,
      OPTION_DETAIL_IS_NOT_POPULATE
    ];
  }
}

export { ListedOptionQualityGateCheck }
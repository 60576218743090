/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QualityChecksModel } from '../models/quality-checks-model';
import { QualityChecksFilter } from '../models/quality-checks-filter';
import { QualityCheckDescriptionModel } from '../models/quality-check-description-model';
@Injectable({
  providedIn: 'root',
})
class QualityChecksService extends __BaseService {
  static readonly transactionDateCheckPath = '/qualityChecks/transaction-date';
  static readonly processTransactionDateCheckPath = '/qualityChecks/transaction-date/process';
  static readonly filingDateCheckPath = '/qualityChecks/filing-date';
  static readonly processFilingDateCheckPath = '/qualityChecks/filing-date/process';
  static readonly singleQualityCheckDetailsPath = '/qualityChecks/single/details';
  static readonly processCheckPath = '/qualityChecks/single/details/process';
  static readonly allErrorDetailsPath = '/qualityChecks/all/details';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param qualityChecksFilter undefined
   * @return An array will be returned
   */
  transactionDateCheckResponse(qualityChecksFilter: QualityChecksFilter): __Observable<__StrictHttpResponse<Array<QualityChecksModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/transaction-date`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QualityChecksModel>>;
      })
    );
  }
  /**
   * @param qualityChecksFilter undefined
   * @return An array will be returned
   */
  transactionDateCheck(qualityChecksFilter: QualityChecksFilter): __Observable<Array<QualityChecksModel>> {
    return this.transactionDateCheckResponse(qualityChecksFilter).pipe(
      __map(_r => _r.body as Array<QualityChecksModel>)
    );
  }

  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processTransactionDateCheckResponse(qualityChecksModel: QualityChecksModel): __Observable<__StrictHttpResponse<QualityChecksModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/transaction-date/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QualityChecksModel>;
      })
    );
  }
  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processTransactionDateCheck(qualityChecksModel: QualityChecksModel): __Observable<QualityChecksModel> {
    return this.processTransactionDateCheckResponse(qualityChecksModel).pipe(
      __map(_r => _r.body as QualityChecksModel)
    );
  }

  /**
   * @param qualityChecksFilter undefined
   * @return An array Model will be returned
   */
  filingDateCheckResponse(qualityChecksFilter: QualityChecksFilter): __Observable<__StrictHttpResponse<Array<QualityChecksModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/filing-date`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QualityChecksModel>>;
      })
    );
  }
  /**
   * @param qualityChecksFilter undefined
   * @return An array Model will be returned
   */
  filingDateCheck(qualityChecksFilter: QualityChecksFilter): __Observable<Array<QualityChecksModel>> {
    return this.filingDateCheckResponse(qualityChecksFilter).pipe(
      __map(_r => _r.body as Array<QualityChecksModel>)
    );
  }

  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processFilingDateCheckResponse(qualityChecksModel: QualityChecksModel): __Observable<__StrictHttpResponse<QualityChecksModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/filing-date/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QualityChecksModel>;
      })
    );
  }
  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processFilingDateCheck(qualityChecksModel: QualityChecksModel): __Observable<QualityChecksModel> {
    return this.processFilingDateCheckResponse(qualityChecksModel).pipe(
      __map(_r => _r.body as QualityChecksModel)
    );
  }

  /**
   * @param qualityChecksFilter undefined
   * @return An array Model will be returned
   */
  singleQualityCheckDetailsResponse(qualityChecksFilter: QualityChecksFilter): __Observable<__StrictHttpResponse<Array<QualityChecksModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/single/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QualityChecksModel>>;
      })
    );
  }
  /**
   * @param qualityChecksFilter undefined
   * @return An array Model will be returned
   */
  singleQualityCheckDetails(qualityChecksFilter: QualityChecksFilter): __Observable<Array<QualityChecksModel>> {
    return this.singleQualityCheckDetailsResponse(qualityChecksFilter).pipe(
      __map(_r => _r.body as Array<QualityChecksModel>)
    );
  }

  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processCheckResponse(qualityChecksModel: QualityChecksModel): __Observable<__StrictHttpResponse<QualityChecksModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityChecksModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/qualityChecks/single/details/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QualityChecksModel>;
      })
    );
  }
  /**
   * @param qualityChecksModel undefined
   * @return A Model will be returned after trade Processing
   */
  processCheck(qualityChecksModel: QualityChecksModel): __Observable<QualityChecksModel> {
    return this.processCheckResponse(qualityChecksModel).pipe(
      __map(_r => _r.body as QualityChecksModel)
    );
  }

  /**
   * @param params The `QualityChecksService.AllErrorDetailsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return An array will be returned
   */
  allErrorDetailsResponse(params: QualityChecksService.AllErrorDetailsParams): __Observable<__StrictHttpResponse<Array<QualityCheckDescriptionModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/qualityChecks/all/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QualityCheckDescriptionModel>>;
      })
    );
  }
  /**
   * @param params The `QualityChecksService.AllErrorDetailsParams` containing the following parameters:
   *
   * - `startDate`:
   *
   * - `endDate`:
   *
   * @return An array will be returned
   */
  allErrorDetails(params: QualityChecksService.AllErrorDetailsParams): __Observable<Array<QualityCheckDescriptionModel>> {
    return this.allErrorDetailsResponse(params).pipe(
      __map(_r => _r.body as Array<QualityCheckDescriptionModel>)
    );
  }
}

module QualityChecksService {

  /**
   * Parameters for allErrorDetails
   */
  export interface AllErrorDetailsParams {
    startDate: string;
    endDate: string;
  }
}

export { QualityChecksService }

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {authConfig} from './auth.config';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class AuthenticationService {
  muser: any;

  constructor(private _router: Router, private httpClient: HttpClient,
              private oauthService: OAuthService) {
    this.configureWithNewConfigApi();
  }

  public obtainAccessToken() {
    this.oauthService.initImplicitFlow();
    this.getUser();
  }

  public isLoggedIn() {
    if (this.oauthService.getAccessToken() === null) {
      return false;
    }
    const result = this.oauthService.getAccessTokenExpiration() > new Date().getTime();
    if (!result) {
      this.obtainAccessToken();
      return true;
    }
    return true;
  }

  public doLogin() {
    if (this.oauthService.getAccessToken() === null) {
      this.obtainAccessToken();
    }
  }

  public isAccessTokenPresent() {
    if (this.oauthService.getAccessToken() !== null) {
      return this.oauthService.getAccessTokenExpiration() > new Date().getTime();
    }
    return false;
  }

  public logout() {
    let tokenUrl = authConfig.loginUrl;
    // @ts-ignore
    tokenUrl = tokenUrl.replace('/oauth/authorize', '/my/oauth/token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oauthService.getAccessToken());

    this.httpClient.delete(tokenUrl, {
      headers: headers
    }).subscribe({
      next: (response) => {
        console.log('Logout success!', response);
        this.oauthService.logOut();
        this._router.navigate(['login']);
      }, error: (error) => {
        this.clearSession();
      },
      complete: () => {
        this.clearSession();
      }
    });
  }

  public getUser() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oauthService.getAccessToken());
    this.httpClient
      .get(authConfig.userinfoEndpoint as string, {headers}).subscribe(
      {
        next: (response) => {
          this.muser = response;
        }, error: (error) => {
          console.log(error);
        }, complete: () => {
          localStorage.setItem('username', this.muser.username);
          localStorage.setItem('email', this.muser.email);
        }
      }
    );
  }

  public clearSession() {
    this._router.navigate(['login']).then(value => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('userId');
      localStorage.removeItem('nonce');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('access_token_stored_at');
      localStorage.removeItem('picture');
    });
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure(authConfig);
    this.oauthService.events.subscribe(e => {
      if (e.type === 'token_received') {
        const returnUrl = localStorage.getItem('/');
        localStorage.setItem('returnUrl', '/');
        this.getUser();
        // @ts-ignore
        this._router.navigateByUrl(returnUrl);
      }
    });
    this.oauthService.setStorage(localStorage);
    this.oauthService.oidc = false;
    this.oauthService.tryLogin({
      onTokenReceived: context => {
        console.log(context);
      }
    });
  }

}


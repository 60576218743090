/* tslint:disable */

/**
 * a model to tell type of enum
 */
type IssuerMappingTypeEnum =
  'Un-mapped' |
  'Auto-mapped' |
  'Re-mapped' |
  'Mapped Only' |
  'All';
module IssuerMappingTypeEnum {
  export const UN_MAPPED: IssuerMappingTypeEnum = 'Un-mapped';
  export const AUTO_MAPPED: IssuerMappingTypeEnum = 'Auto-mapped';
  export const RE_MAPPED: IssuerMappingTypeEnum = 'Re-mapped';
  export const MAPPED_ONLY: IssuerMappingTypeEnum = 'Mapped Only';
  export const ALL: IssuerMappingTypeEnum = 'All';
  export function values(): IssuerMappingTypeEnum[] {
    return [
      UN_MAPPED,
      AUTO_MAPPED,
      RE_MAPPED,
      MAPPED_ONLY,
      ALL
    ];
  }
}

export { IssuerMappingTypeEnum }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly addUserPath = '/user/addUser';
  static readonly getUsersPath = '/user/getUser';
  static readonly updateUserStatusPath = '/user/update/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param User model Add New User.
   * @return User is added.
   */
  addUserResponse(UserModel: User): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UserModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/addUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param User model Add New User.
   * @return User is added.
   */
  addUser(UserModel: User): __Observable<boolean> {
    return this.addUserResponse(UserModel).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param areUsersActive Users to be Selected.
   * @return List of Users.
   */
  getUsersResponse(areUsersActive: boolean): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (areUsersActive != null) __params = __params.set('areUsersActive', areUsersActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/getUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * @param areUsersActive Users to be Selected.
   * @return List of Users.
   */
  getUsers(areUsersActive: boolean): __Observable<Array<User>> {
    return this.getUsersResponse(areUsersActive).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * @param userId undefined
   * @return User Status Updated.
   */
  updateUserStatusResponse(userId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user/update/${encodeURIComponent(String(userId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param userId undefined
   * @return User Status Updated.
   */
  updateUserStatus(userId: number): __Observable<boolean> {
    return this.updateUserStatusResponse(userId).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module UserService {
}

export { UserService }

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  CongressTypeEnum,
  PoliticianIncomeRanges,
  PoliticianIncomeType,
  PoliticianTrade,
  PriceSource,
  Senator2iqTransactionTypes,
  Senator2iqTransactionTypesForFlag,
  SenatorAmountRanges,
  SenatorAssetSubCategory,
  SenatorAssetType,
  SenatorCompany,
  SenatorCompanyAssetClass,
  SenatorFiling,
  SenatorOwnerTypes,
  SenatorTrades,
  SenatorTransactionTypes
} from "../../../api/models";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SenatorService} from '../../../api/services/senator.service';
import {Subject} from 'rxjs';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {SnackbarMessageService} from '../services/snackbar-message.service';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {SenatorCompanyAssetClassService} from '../../../api/services/senator-company-asset-class.service';
import {EditCompanyComponent} from '../quality-gates/issuer-mapping/all-companies/edit-company/edit-company.component';
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import 'rxjs-compat/add/observable/forkJoin';
//import {TwitterImageDialogComponent} from '../home/twitter-image-dialog/twitter-image-dialog.component';
import {DataService} from '../services/data.service';
import {MessageService} from 'primeng/api';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {
  ListedOptionsComponent
} from '../quality-gates/issuer-mapping/all-companies/edit-company/listed-options/listed-options.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import DeleteSenatorTradesByTradeIdParams = SenatorService.DeleteSenatorTradesByTradeIdParams;
import GetSenatorTradesByFilingIdParams = SenatorService.GetSenatorTradesByFilingIdParams;
import GetSenatorTradesBySenatorCompanyIdParams = SenatorService.GetSenatorTradesBySenatorCompanyIdParams;
import GetPoliticianAssetTradesByFilingIdParams = SenatorService.GetPoliticianAssetTradesByFilingIdParams;
import DeletePoliticianAssetTradesByTradeIdParams = SenatorService.DeletePoliticianAssetTradesByTradeIdParams;
import GetSenatorTradesByStockOptionListingIdParams = SenatorService.GetSenatorTradesByStockOptionListingIdParams;

interface CheckboxModel {
  label: string;
  value: boolean | string;
}

export interface TradeSearchFilter {
  tradeType?: Array<SenatorTransactionTypes>;
  x2iqTradeType?: Array<Senator2iqTransactionTypes>;
  owner?: Array<SenatorOwnerTypes>;
  noOfShares: Array<string>;
  assetType?: Array<SenatorAssetType>;
  assetSubCategory?: Array<SenatorAssetType>;
  amount?: Array<SenatorAmountRanges>;
  status: Array<String>;
  issuerName: string;
  assetName: string;
  gvkey: string;
  ticker: string;
  currencyCode: Array<String>;
  price: Array<string>;
  isMarketTraded: Array<boolean>;
  capGain: Array<boolean>;
  isTaxAvailable: Array<boolean>;
  transactionDateStart: string;
  transactionDateEnd: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css']
})
export class TradesComponent implements OnInit, AfterViewChecked, OnDestroy {
  politicianTrade: PoliticianTrade = new class implements PoliticianTrade {
    assetSubCategory: string;
    assetType: string;
    comment: string;
    congressType: string;
    filingDate: string;
    isAmended: boolean;
    issuerName: string;
    owner: string;
    party: string;
    politicianName: string;
    refBiographyId: number;
    shortName: string;
    shareType: string;
    ticker: string;
    tradeType: string;
    tradeValueRange: string;
    transactionDate: string;
    publicationDate: string;
  };
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  // readonly RANGE_OR_INT_PATTERN = '(^\\d+$)|(^\\d+\\s-\\s\\d+)';
  readonly RANGE_OR_INT_PATTERN = '^\\s*(\\d+(?:\\.\\d+)?)(?:\\s*\\-\\s*(\\d+(?:\\.\\d+)?))?\\s*$';
  congressType: string;
  selectedAssetType = 'Stock';
  tradeSearchFilters: TradeSearchFilter;
  multipleReplacementForm: FormGroup;
  tradesLoading = false;
  tradesSaving = false;
  assetNamesLoading: boolean;
  assetClassesLoading: boolean;
  twoIQAssetsList: SenatorCompany[] = [];
  selectedTrades: SenatorTrades[];
  selectedTradesToProcess: SenatorTrades[] = [];
  totalTradesProcessedNum: number;
  isAllTradesProcessed: boolean;
  newAddedTradesCount: number;
  deletedTradesSuccessCount: number;
  deletedTradesUnSuccessCount: number;
  publishedTradesCount: number;
  unpublishedTradesCount: number;
  savedTradesCount: number;
  actionToPerform: string;
  activeRow: SenatorTrades;
  activeRowOfAssetClass: SenatorTrades;
  isIssuerNameSearched: boolean;
  tradeTransactionMinDate: Date;
  tradeTransactionMaxDate: Date;
  issuerSelectionCount: number;
  maxTransactionDate: Date;
  isFormValid: boolean;
  issuerIndex: number;
  selectedRowForMergeIssuer: any;
  reportType: string;
  // service subscriptions
  expandTradePanel: boolean;
  // material table configurations
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  // component inputs
  @Input() file: SenatorFiling;
  @Input() report: any;
  @Input() inputMode: string;
  issuerParam = {} as GetSenatorTradesBySenatorCompanyIdParams;
  paramOptionListingId: number;

  @Output() expandPanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  dataSource: SenatorTrades[] = [];
  // @Input() public dataSource$: Observable<SenatorTrades[]>;


  tradesTableDisplayedColumns: string[] = ['select', 'tradeIndex', 'status', 'transactionDate',
    'issuerName', 'ticker', 'owner', 'assetType', 'amount', 'isMarketTraded', 'comments', 'remarks'];
  multipleSelectionHeader = ['msCheck', 'msNo', 'msStatus', 'msTransactionDate', 'msIssuer', 'msTickerAndGvkey',
    'msOwner', 'msSecurityType', 'msAmount', 'msIsMarketTraded', 'msComments', 'msRemarks'];

  matTableDataSource: MatTableDataSource<SenatorTrades>;
  selection = new SelectionModel<SenatorTrades>(true, []);
  pageSize: number;
  pageNumber: number;
  pageSizeOptions: number[];
  totalEntries: number;
  pageEvent: PageEvent;
  readonly maxPageSize = 100;
  allSelectedOfCurrentPage: boolean;
  securityTypes: SenatorAssetType [];
  subSecurityTypesOfStock: SenatorAssetSubCategory[];
  assetClasses = [] as SenatorCompanyAssetClass[];
  transactionTypes: SenatorTransactionTypes [];
  x2iqTransactionTypes: Senator2iqTransactionTypes [];
  amounts: SenatorAmountRanges[];
  incomes: PoliticianIncomeRanges[];
  incomeTypes: PoliticianIncomeType[];
  x2iqTransactionTypeForFlags: Senator2iqTransactionTypesForFlag[];
  capitalGains: CheckboxModel [];
  isMarketTrades: CheckboxModel [];
  owners: SenatorOwnerTypes [];
  noOfShares: string[];
  currencyCodes: string[];
  statuses: string[];
  allTrades = [] as SenatorTrades[];
  // excel file import vars
  spinnerEnabled = false;
  keys: string[];
  dataSheet = new Subject();
  excelTrades: any;
  @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;
  // router params
  showTradeSearchFilter: boolean;
  amendmentFilingId: number;
  readonly FILE = 'FILE';
  readonly QUALITY_GATE = 'QUALITY-GATE';
  readonly REPORT_HOLDINGS = 'REPORT-HOLDINGS';
  readonly LISTING_OPTIONS = 'LISTING-OPTIONS';
  isTransDisable = false;
  istransactionDateNotDisclosed;
  readonly priceSources: string[] = PriceSource.values().map(t => t.toString());
  private tradeTableRows = this.fb.array([]);
  tradeForm = this.fb.group({
    tableRowArray: this.tradeTableRows
  });
  // constants
  private readonly DATE_FORMAT = 'MM/dd/yyyy';

  constructor(
    private matDialog: MatDialog, private cdr: ChangeDetectorRef,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _senatorService: SenatorService,
    private _senatorAssetClassService: SenatorCompanyAssetClassService,
    public snackBar: MatSnackBar,
    private snackbarMessageService: SnackbarMessageService,
    private companyDialog: MatDialog,
    private dataService: DataService,
    private messageService: MessageService
  ) {

    this.issuerParam.senatorCompanyId = this._activatedRoute.snapshot.params.senatorCompanyId;
    this.issuerParam.assetId = this._activatedRoute.snapshot.params.assetId;
    this.issuerParam.byDateFilter = this._activatedRoute.snapshot.params.date;
    this.paramOptionListingId = this._activatedRoute.snapshot.params.optionListingId;

  }

  get tradeTableRowArray(): FormArray {
    return this.tradeTableRows;
  }

  get _multiSelectX2iqTransactionTypeForFlag() {
    return this.multipleReplacementForm.get('x2iqTransactionTypeForFlag');
  }

  ngOnInit() {
    this.initVariables();

    if (this.inputMode == this.FILE) {
      this.getTradesByFiling();
    } else if (this.inputMode == this.QUALITY_GATE) {
      this.getTradesByIssuer();
    } else if (this.inputMode == this.REPORT_HOLDINGS) {
      this.getHoldingsByReport();
    } else if (this.inputMode == this.LISTING_OPTIONS) {
      this.getListingOptionTrades();
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // Reset the local storage and other variable before close the file.
    localStorage.removeItem('tradesPageSize');
    localStorage.removeItem('tradesPageNumber');
    localStorage.removeItem('tradeSearchFilters');
  }

  initVariables() {

    this.transactionTypes = SenatorTransactionTypes.values();
    this.x2iqTransactionTypes = Senator2iqTransactionTypes.values();
    this.owners = SenatorOwnerTypes.values();
    this.noOfShares = ['Empty', 'Non Empty'];
    this.securityTypes = SenatorAssetType.values().sort();
    this.subSecurityTypesOfStock = SenatorAssetSubCategory.values();
    this.amounts = SenatorAmountRanges.values();
    this.incomes = PoliticianIncomeRanges.values();
    this.incomeTypes = PoliticianIncomeType.values();
    this.x2iqTransactionTypeForFlags = Senator2iqTransactionTypesForFlag.values();
    this.capitalGains = [{label: 'checked', value: true}, {label: 'unChecked', value: false}, {
      label: 'invalid',
      value: null
    }];
    this.isMarketTrades = [{label: 'checked', value: true}, {label: 'unChecked', value: false}];

    this.currencyCodes = [
      'USD',
      'CAD',
      'EUR',
      'AED',
      'AFN',
      'ALL',
      'AMD',
      'ARS',
      'AUD',
      'AZN',
      'BAM',
      'BDT',
      'BGN',
      'BHD',
      'BIF',
      'BND',
      'BOB',
      'BRL',
      'BWP',
      'BYN',
      'BZD',
      'CDF',
      'CHF',
      'CLP',
      'CNY',
      'COP',
      'CRC',
      'CVE',
      'CZK',
      'DJF',
      'DKK',
      'DOP',
      'DZD',
      'EEK',
      'EGP',
      'ERN',
      'ETB',
      'GBP',
      'GEL',
      'GHS',
      'GNF',
      'GTQ',
      'HKD',
      'HNL',
      'HRK',
      'HUF',
      'IDR',
      'ILS',
      'INR',
      'IQD',
      'IRR',
      'ISK',
      'JMD',
      'JOD',
      'JPY',
      'KES',
      'KHR',
      'KMF',
      'KRW',
      'KWD',
      'KZT',
      'LBP',
      'LKR',
      'LTL',
      'LVL',
      'LYD',
      'MAD',
      'MDL',
      'MGA',
      'MKD',
      'MMK',
      'MOP',
      'MUR',
      'MXN',
      'MYR',
      'MZN',
      'NAD',
      'NGN',
      'NIO',
      'NOK',
      'NPR',
      'NZD',
      'OMR',
      'PAB',
      'PEN',
      'PHP',
      'PKR',
      'PLN',
      'PYG',
      'QAR',
      'RON',
      'RSD',
      'RUB',
      'RWF',
      'SAR',
      'SDG',
      'SEK',
      'SGD',
      'SOS',
      'SYP',
      'THB',
      'TND',
      'TOP',
      'TRY',
      'TTD',
      'TWD',
      'TZS',
      'UAH',
      'UGX',
      'UYU',
      'UZS',
      'VEF',
      'VND',
      'XAF',
      'XOF',
      'YER',
      'ZAR',
      'ZMK',
      'ZWL'];
    this.statuses = ['Published', 'Unpublished', 'Saved', 'Amended', 'New'];


    this.showTradeSearchFilter = false;

    if (localStorage.getItem('tradesPageSize')) {
      this.pageSize = Number(localStorage.getItem('tradesPageSize'));
      this.pageNumber = Number(localStorage.getItem('tradesPageNumber'));

    } else {
      this.pageSize = 10;
      this.pageNumber = 1;
    }
    if (localStorage.getItem('tradeSearchFilters')) {

      this.tradeSearchFilters = JSON.parse(localStorage.getItem('tradeSearchFilters'));
    } else {
      this.tradeSearchFilters = {
        tradeType: [],
        x2iqTradeType: [],
        owner: [],
        noOfShares: [],
        assetType: [],
        assetSubCategory: [],
        amount: [],
        status: [],
        issuerName: null,
        assetName: null,
        gvkey: null,
        ticker: null,
        isMarketTraded: [],
        currencyCode: [],
        price: [],
        capGain: [],
        isTaxAvailable: [],
        transactionDateEnd: null,
        transactionDateStart: null,
      };
    }

    this.pageSizeOptions = [10, 20, 500, 1000];
    this.allSelectedOfCurrentPage = false;

    this.totalTradesProcessedNum = 0;

    this.deletedTradesSuccessCount = 0;
    this.deletedTradesUnSuccessCount = 0;
    this.isAllTradesProcessed = false;
    this.newAddedTradesCount = 0;

    this.publishedTradesCount = 0;
    this.unpublishedTradesCount = 0;
    this.savedTradesCount = 0;

    this.assetNamesLoading = false;
    this.assetClassesLoading = false;
    this.isIssuerNameSearched = false;
    this.issuerSelectionCount = 1;

    this.tradeTransactionMinDate = new Date(2010, 0, 1);
    this.tradeTransactionMaxDate = new Date();


    this.isFormValid = false;
    this.expandTradePanel = false;
    if (this.inputMode === this.FILE) {

      if (this.file.congressType === 'Representative') {
        if (this.tradesTableDisplayedColumns.indexOf('isCapGain') === -1) {
          this.tradesTableDisplayedColumns.push('isCapGain');
        }

        if (this.multipleSelectionHeader.indexOf('msIsCapGain') === -1) {
          this.multipleSelectionHeader.push('msIsCapGain');
        }
      }
    }
    if (this.inputMode === this.QUALITY_GATE || this.inputMode === this.LISTING_OPTIONS) {
      if (this.tradesTableDisplayedColumns.indexOf('isCapGain') === -1) {
        this.tradesTableDisplayedColumns.push('isCapGain');
      }
      if (this.multipleSelectionHeader.indexOf('msIsCapGain') === -1) {
        this.multipleSelectionHeader.push('msIsCapGain');
      }

      if (this.tradesTableDisplayedColumns.indexOf('filingId') === -1) {
        this.tradesTableDisplayedColumns.push('filingId');
      }
      if (this.multipleSelectionHeader.indexOf('msFilingId') === -1) {
        this.multipleSelectionHeader.push('msFilingId');
      }

      if (this.tradesTableDisplayedColumns.indexOf('house') === -1) {
        this.tradesTableDisplayedColumns.push('house');
      }
      if (this.multipleSelectionHeader.indexOf('msHouse') === -1) {
        this.multipleSelectionHeader.push('msHouse');
      }

    }

    if (this.inputMode === this.REPORT_HOLDINGS) {

      if (this.tradesTableDisplayedColumns.indexOf('income') === -1) {
        this.tradesTableDisplayedColumns.push('income');
      }
      if (this.multipleSelectionHeader.indexOf('msIncome') === -1) {
        this.multipleSelectionHeader.push('msIncome');
      }

      if (this.tradesTableDisplayedColumns.indexOf('isTaxAvailable') === -1) {
        this.tradesTableDisplayedColumns.push('isTaxAvailable');
      }
      if (this.multipleSelectionHeader.indexOf('msIsTaxAvailable') === -1) {
        this.multipleSelectionHeader.push('msIsTaxAvailable');
      }

    }
    if (this.inputMode === this.FILE || this.inputMode === this.QUALITY_GATE) {
      if (this.tradesTableDisplayedColumns.indexOf('transactionType') === -1) {
        this.tradesTableDisplayedColumns.splice(4, 0, 'transactionType');
      }

      if (this.multipleSelectionHeader.indexOf('msTransactionType') === -1) {
        this.multipleSelectionHeader.splice(4, 0, 'msTransactionType');
      }
    }
  }

  initTradesData(trades: SenatorTrades[]) {
    this.totalEntries = trades.length;
    this.allTrades = trades;
    this.matTableDataSource = new MatTableDataSource<SenatorTrades>(this.allTrades);
    this.matTableDataSource.paginator = this.paginator;
    this.matTableDataSource.sort = this.sort;
    this.initTradeFrom();
    this.initMultipleReplacementForm();
    this.onChangesForm();
    if (localStorage.getItem('tradeSearchFilters')) {
      this.filterTrades();
    }
    this.updateX2iqTransactionTypeForFlag();

  }

  getTradesByIssuer() {
    this.tradesLoading = true;
    let response: SenatorTrades[] = [];
    this._senatorService.getSenatorTradesBySenatorCompanyId(this.issuerParam).subscribe((res: SenatorTrades[]) => {
      response = res;

    }, (err) => {
      console.log(err);
      // this.openSnackBar(err.error.message, 'Close');
      // this.tradesLoading = false;
    }, () => {

      this.initTradesData(response);
      this.tradesLoading = false;
    });
  }

  getTradesByFiling() {
    this.tradesLoading = true;
    let response: SenatorTrades[] = [];

    const params: GetSenatorTradesByFilingIdParams = {
      filingId: this.file.filingId,
      congressType: this.file.congressType
    };

    this._senatorService.getSenatorTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      response = res;
    }, (err) => {
      console.log(err);
      this.openSnackBar(err.error.message, 'Close');
      this.tradesLoading = false;
    }, () => {
      this.tradesLoading = false;
      this.initTradesData(response);
    });
  }

  getHoldingsByReport() {
    let response;
    const params: GetPoliticianAssetTradesByFilingIdParams = {
      filingId: this.report.filingId,
      congressType: this.report.congressType
    };
    this._senatorService.getPoliticianAssetTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      response = res;
    }, (err) => {

    }, () => {
      this.initTradesData(response);
      this.tradesLoading = false;
    });
  }

  getListingOptionTrades() {
    let response;
    const params: GetSenatorTradesByStockOptionListingIdParams = {
      stockOptionListingId: this.paramOptionListingId
    };
    this._senatorService.getSenatorTradesByStockOptionListingId(params).subscribe((res: SenatorTrades[]) => {
      response = res;
    }, (err) => {

    }, () => {
      this.initTradesData(response);
      this.tradesLoading = false;
    });
  }

  getActualIndex(index: number) {
    return index + this.paginator.pageSize * this.paginator.pageIndex;
  }

  initTradeFrom() {
    this.matTableDataSource.data.forEach((trade) => {
      this.tradeTableRowArray.push(this.createTradeRow(trade));
    });
    this.tradesLoading = false;
  }

  onChkChange(ob: MatCheckboxChange) {
    this.isTransDisable = ob.checked;
    console.log(this.isTransDisable);
  }

  initMultipleReplacementForm() {
    this.multipleReplacementForm = this.fb.group({
      transactionDate: [''],
      tradeType: [''],
      x2iqTransactionType: [''],
      issuerName: [''],
      botGrabbedIssuerName: [''],
      owner: [''],
      shares: [''],
      assetType: [''],
      assetSubCategory: [''],
      ref_senator_company_id: [''],
      amount: [''],
      price: [''],
      currencyCode: [''],
      comments: [''],
      isCapitalGain: [''],
      income: [''],
      incomeType: [''],
      remarks: '',
      is_manual_shares: [''],
      istransactionDateNotDisclosed: [''],
      x2iqTransactionTypeForFlag: [''],
      isTaxAvailable: ['']
    });
  }

  createTradeRow(trade: SenatorTrades): FormGroup {

    return this.fb.group({
      filingId: [trade.filingId],
      tradeId: [trade.tradeId],
      tradeIndex: [trade.tradeIndex || ''],
      transactionDate: [new Date(trade.transactionDate) || '', [Validators.required, this.transactionDateValidator.bind(this)]],
      transactionType: [trade.transactionType, Validators.required],
      x2iqTransactionType: [trade.x2iqTransactionType, Validators.required],
      assetName: [trade.assetName || ''],
      x2iqAssetName: [trade.x2iqAssetName || '', Validators.required],
      ref_senator_company_id: [trade.ref_senator_company_id, Validators.required],
      ref_senator_company_asset_class_id: [trade.ref_senator_company_asset_class_id],
      ref_stock_option_listing_id: [trade.ref_stock_option_listing_id],
      gvkey: [trade.gvkey, [Validators.required]],
      permId: [trade.permId || null,],
      isMarketTraded: [trade.isMarketTraded || false],
      pricingSource: [trade.pricingSource || 'Compustat'],
      assetType: [(trade.assetType !== null) ? trade.assetType : null, Validators.required],
      assetSubCategory: [trade.assetSubCategory || '', Validators.required],
      owner: [trade.owner || '', Validators.required],
      noOfShares: [trade.noOfShares || '', Validators.pattern(this.RANGE_OR_INT_PATTERN)],
      isFractionalShares: [trade.is_manual_shares || false],
      ticker: [trade.ticker || ''],
      amount: [trade.amount || '',
        [Validators.required, Validators.pattern('^((\\$[0-9,]+\\s-\\s\\$[0-9,]+)?|(\\$[0-9.]+)?|' +
          '(.*?Over \\$[0-9,]+)?)$')]],
      price: [trade.price || ''],
      currencyCode: [trade.currencyCode || ''],
      comment: [trade.comment || ''],
      remarks: [trade.remarks || ''],
      sourceUrl: [trade.sourceUrl],
      isApproved: [trade.isApproved],
      isAmended: [trade.isAmended || false],
      is_manual_shares: [trade.is_manual_shares],
      istransactionDateNotDisclosed: [trade.istransactionDateNotDisclosed],
      ammendmentFilingId: [trade.ammendmentFilingId],
      isNewTrade: [trade.isNewTrade],
      congressType: [trade.congressType],
      income: [trade.income || ''],
      incomeType: [trade.incomeType || []],
      x2iqTransactionTypeForFlag: [trade.x2iqTransactionTypeForFlag || []],
      isTaxAvailable: [trade.isTaxAvailable || false],
      isCapitalGain: [(trade.congressType === 'Senator') ? false :
        (String(trade.isCapitalGain) === 'No' || String(trade.isCapitalGain) === 'no') ? false :
          (String(trade.isCapitalGain) === 'Yes' || String(trade.isCapitalGain) === 'yes') ? true : trade.isCapitalGain, Validators.required]
    });
  }

  transactionDateValidator(control: FormControl) {
    const transactionDate = new Date(control.value);
    const minDate = new Date('01/01/2000');

    /* if (transactionDate > this.maxTransactionDate || transactionDate < minDate) {
       return {
         dateError: {
           value: 'invalid'
         }
       };
     }*/
    return null;
  }

  onPaginateChange(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    localStorage.setItem('tradesPageSize', String(this.pageSize));
    localStorage.setItem('tradesPageNumber', String(this.pageNumber));

    // configure selection variables
    if (this.isAllSelectedOfCurrentPage()) {
      this.allSelectedOfCurrentPage = true;
      this.validateSelectedForm();
    } else {
      this.allSelectedOfCurrentPage = false;
    }
  }

  getPageSizeOptions(): number[] {
    const arr = [10, 20, this.maxPageSize];
    if (this.matTableDataSource == null || this.matTableDataSource.paginator == null) {
      return arr;
    }
    if (this.matTableDataSource.paginator.length > this.maxPageSize) {
      return [10, 20, 100, this.matTableDataSource.paginator.length];
    }
    return arr;
  }

  searchAssetNames(companyName: string, trade: SenatorTrades, selection: string) {
    this.activeRow = trade;
    this.assetNamesLoading = true;
    this._senatorService.getSenatorAssetNames(companyName).subscribe((res: SenatorCompany[]) => {
      this.twoIQAssetsList = res;
    }, (err) => {
      this.assetNamesLoading = false;
      if (err.status === 400 || err.status === 500) {
        this.openSnackBar('Something went wrong! please enter a valid company name.', 'Close');
      } else {
        this.openSnackBar(err.error.message, 'Close');
      }

    }, () => {
      this.assetNamesLoading = false;
      if (this.twoIQAssetsList.length === 0) {
        const snackBarRef = this.snackBar.openFromComponent(SnackBar1Component, {
          data: {
            message: 'No Company found,Populate this text and assign dummy gvkey',
            selection: selection,
            text: companyName,
            index: this.issuerIndex
          },
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom'
        });

        snackBarRef.onAction().subscribe(() => {
          this.openCreateCompanyDialog();
        });

      }
    });
  }

  openCreateCompanyDialog() {
    const selectedCompany = {ID: 0} as SenatorCompany;
    const dialogRef = this.companyDialog.open(EditCompanyComponent, {
      disableClose: true,
      width: '900px',
      data: selectedCompany
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openEditCompanyDialog(row: any, index: number) {
    const formArray = this.tradeTableRows.at(index);
    const companyId = formArray.get('ref_senator_company_id').value;
    const selectedCompany = {ID: companyId} as SenatorCompany;
    const dialogRef = this.companyDialog.open(EditCompanyComponent, {
      disableClose: true,
      width: '900px',
      data: selectedCompany
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'merged') {
        this.refreshTheDialog();
      }
    });
  }

  openStockOptionListingsDialog(row: any, index: number) {
    const formArray = this.tradeTableRows.at(index);
    const senatorCompanyAssetClassId = formArray.get('ref_senator_company_asset_class_id').value;
    const refSenatorCompanyId = formArray.get('ref_senator_company_id').value;
    const refStockOptionListingId = formArray.get('ref_stock_option_listing_id').value;

    const dialogRef = this.matDialog.open(ListedOptionsComponent, {
      disableClose: true,
      width: '900px',
      data: {
        senatorCompanyAssetClassId: senatorCompanyAssetClassId,
        assetClasses: this.assetClasses,
        refSenatorCompanyId: refSenatorCompanyId,
        refStockOptionListingId: refStockOptionListingId
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      switch (res.data.action) {
        case 'select':
          this.tradeTableRows.at(index).get('ref_stock_option_listing_id').patchValue(res.data.optionListingId);
          break;
        case 'clear':
          this.tradeTableRows.at(index).get('ref_stock_option_listing_id').patchValue(null);
          break;
      }
    });
  }

  mapCompanyWith2IQ(event, company: SenatorCompany, row: any, index: number) {
    if (event.source.selected) {
      const formArray = this.tradeTableRows.at(index);
      formArray.get('gvkey').setValue(company.gvkey);
      if (company.ticker !== null) {
        formArray.get('ticker').setValue(company.ticker);
      } else {
        formArray.get('ticker').setValue('--');
      }
      formArray.get('permId').setValue(company.permId);
      formArray.get('ref_senator_company_id').setValue(company.ID);
      formArray.get('assetSubCategory').setValue('');
      formArray.get('ref_senator_company_asset_class_id').setValue(null);
      formArray.get('ref_stock_option_listing_id').setValue(null);
      this.assetClasses = [];
    }
  }

  onPublishOrSaveTheTrades(action: string) {
    this.resetVars();
    this.actionToPerform = action;
    this.selectedTradesToProcess = this.mapSelectedTrades();
    // sending trades to the server

    switch (this.inputMode) {
      case this.FILE :
        this.saveTheTrades(this.selectedTradesToProcess);
        break;
      case this.QUALITY_GATE :
        this.saveTheTrades(this.selectedTradesToProcess);
        break;
      case this.REPORT_HOLDINGS:
        this.saveReportHoldings(this.selectedTradesToProcess);
        break;

    }

  }

  mapSelectedTrades(): SenatorTrades[] {
    return this.selectedTradesToProcess.map(
      trade => {
        if (trade.transactionDate == null) {
          trade.transactionDate = this.datePipe.transform('01/01/1970', this.DATE_FORMAT);
        } else {
          trade.transactionDate = this.datePipe.transform(new Date(trade.transactionDate), this.DATE_FORMAT);
        }
        if (trade.transactionType == null) {
          trade.transactionType = 'Sale';
        }
        switch (this.actionToPerform) {
          case 'Published' :
            trade.isApproved = true;
            break;
          case 'Saved':
            trade.isApproved = false;
            break;
          case 'Amended':
            trade.isApproved = false;
            trade.isAmended = true;
            trade.ammendmentFilingId = this.amendmentFilingId;
            break;
          case 'Unamended':
            trade.isAmended = false;
            trade.ammendmentFilingId = null;
            break;
        }

        if (trade.isMarketTraded == null) {
          trade.isMarketTraded = false;
        }

        if (trade.permId === '') {
          trade.permId = null;
        }
        return trade;
      }
    );
  }

  saveTheTrades(list: SenatorTrades[]) {

    let res = [] as SenatorTrades[];
    this.tradesSaving = true;
    this._senatorService.saveSenatorTrade(list).subscribe((value: SenatorTrades[]) => {
      res = value;
    }, (err) => {
      this.tradesSaving = false;
      this.openSnackBar(err.error.message, 'Close');
    }, () => {
      this.tradesSaving = false;
      this.openSnackBarByTimeOut(`${list.length}  trade${list.length > 1 ? 's are' : ' is'} ${this.actionToPerform} successfully.`, 'Close', 4000);
      this.refreshTheDialog();
    });
  }

  saveReportHoldings(list: SenatorTrades[]) {

    let response = [] as SenatorTrades[];
    this.tradesSaving = true;

    this._senatorService.savePoliticianAssetTrade(list).subscribe((res: SenatorTrades[]) => {
      response = res;
    }, (err) => {
      this.tradesSaving = false;
      this.openSnackBar(err.error.message, 'Close');
    }, () => {
      this.tradesSaving = false;
      this.openSnackBarByTimeOut(`${list.length}  trade${list.length > 1 ? 's are' : ' is'} ${this.actionToPerform} successfully.`, 'Close', 4000);
      this.refreshTheDialog();
    });
  }

  expandTradesPanel(expandFlag: boolean) {
    this.expandTradePanel = expandFlag;
    this.expandPanel.emit(expandFlag);
  }

  onMarkAsAmendedClick(action: string) {

    switch (action) {
      case 'Amended':
        const fileAmendmentDialogRef = this.matDialog.open(TradeAmendmentDialog1Component, {
          width: '450px',
          height: '200px',
          data: {
            filingId: this.selection.selected[0].ammendmentFilingId
          }
        });
        fileAmendmentDialogRef.afterClosed().subscribe((res) => {
          const event = res['event'];

          switch (event) {
            case 'Amended' :
              this.resetVars();
              this.amendmentFilingId = res.data['filingId'];
              this.actionToPerform = 'Amended';
              this.selectedTradesToProcess = this.mapSelectedTrades();
              this.saveTheTrades(this.selectedTradesToProcess);
              break;
            case 'Cancel':
              break;
          }
        });
        break;
      case 'Unamended':
        const fileUnAmendmentDialogRef = this.matDialog.open(TradeUnAmendmentDialog1Component, {
          width: '450px',
          height: '200px',
          data: {
            filingId: this.selection.selected[0].ammendmentFilingId
          }
        });
        fileUnAmendmentDialogRef.afterClosed().subscribe((res) => {
          const event = res['event'];
          switch (event) {
            case 'Unamended' :
              this.resetVars();
              this.actionToPerform = 'Unamended';
              this.selectedTradesToProcess = this.mapSelectedTrades();
              this.saveTheTrades(this.selectedTradesToProcess);
              break;
            case 'Cancel':
              break;
          }
        });
    }
  }

  showUnamendedOption() {
    return this.selection.selected.every(trade => (trade.isAmended && trade.ammendmentFilingId !== null));
  }

  showAmendedOption() {
    return this.selection.selected.every(trade => (!trade.isAmended && trade.ammendmentFilingId === null));
  }

  onDeleteClick() {
    const fileDeleteDialogRef = this.matDialog.open(TradeDeleteDialog1Component, {
      width: '450px',
      height: '200px',
    });
    fileDeleteDialogRef.afterClosed().subscribe((res) => {
      switch (res) {
        case 'delete':
          this.deleteSelectedTrades();
          break;
        case 'close':
          break;
      }
    });
  }

  deleteSelectedTrades() {
    this.resetVars();
    this.actionToPerform = 'delete';
    // sending trades to the server for processing
    this.selectedTrades.forEach((trade, index) => {
        switch (this.inputMode) {
          case this.FILE:
            this.deleteTrade(trade);
            break;
          case this.REPORT_HOLDINGS:
            this.deleteReportHoldings(trade);
            break;
        }
        if (index + 1 === this.selection.selected.length) {
          this.isAllTradesProcessed = true;
        }
      }
    );
  }

  deleteTrade(trade: any) {
    this.tradesLoading = true;

    const params: DeleteSenatorTradesByTradeIdParams = {
      tradeId: trade.tradeId,
      congressType: this.file.congressType
    };

    this._senatorService.deleteSenatorTradesByTradeId(params).subscribe((res: boolean) => {
      this.deletedTradesSuccessCount += 1;
      this.totalTradesProcessedNum += 1;
    }, (err) => {
      if (err.status === 404) {
        this.snackBar.open('Error!, Action Not Available yet', 'Close');
        this.tradesLoading = false;
      } else {
        this.snackBar.open(err.error.message, 'Close');
        this.deletedTradesUnSuccessCount += 1;
        this.totalTradesProcessedNum += 1;
      }

    }, () => {
      this.tradesLoading = false;
      this.afterAllTradesProcessing();
    });

  }

  deleteReportHoldings(trade: any) {
    this.tradesLoading = true;

    const params: DeletePoliticianAssetTradesByTradeIdParams = {
      tradeId: trade.tradeId,
      congressType: this.report.congressType
    };

    this._senatorService.deletePoliticianAssetTradesByTradeId(params).subscribe((res: boolean) => {
      this.deletedTradesSuccessCount += 1;
      this.totalTradesProcessedNum += 1;
    }, (err) => {
      if (err.status === 404) {
        this.snackBar.open('Error!, Action Not Available yet', 'Close');
        this.tradesLoading = false;
      } else {
        this.snackBar.open(err.error.message, 'Close');
        this.deletedTradesUnSuccessCount += 1;
        this.totalTradesProcessedNum += 1;
      }

    }, () => {
      this.tradesLoading = false;
      this.afterAllTradesProcessing();
    });

  }

  resetVars() {
    this.selectedTrades = this.selection.selected;
    this.selectedTradesToProcess = [];
    this.actionToPerform = '';

    this.totalTradesProcessedNum = 0;
    this.isAllTradesProcessed = false;

    this.deletedTradesSuccessCount = 0;
    this.deletedTradesUnSuccessCount = 0;
    switch (this.inputMode) {
      case this.FILE :
        this.selectedTradesToProcess = this.tradeTableRowArray.controls
          .filter(
            trade => this.selectedTrades.find(indexing => indexing.tradeIndex === trade.value.tradeIndex) != null
          ).map(t => {
            return t.value as SenatorTrades;
          });
        break;
      case this.REPORT_HOLDINGS :
        this.selectedTradesToProcess = this.tradeTableRowArray.controls
          .filter(
            trade => this.selectedTrades.find(indexing => indexing.tradeIndex === trade.value.tradeIndex) != null
          ).map(t => {
            return t.value as SenatorTrades;
          });
        break;
      case this.QUALITY_GATE:
        this.selectedTradesToProcess = this.tradeTableRowArray.controls
          .filter(
            trade => this.selectedTrades.find(indexing => indexing.tradeId === trade.value.tradeId) != null
          ).map(t => {
            return t.value as SenatorTrades;
          });
        break;
    }
  }

  resetGvkey(row: SenatorTrades) {
    this.issuerIndex = row.tradeIndex;
    const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(this.issuerIndex);
    this.tradeTableRows.at(tradeIndex).get('gvkey').setValue(null);
    this.tradeTableRows.at(tradeIndex).get('ticker').setValue('--');

    // this.selectedRowForMergeIssuer = (<FormGroup>this.tradeTableRows.at(tradeIndex)).controls;
  }

  searchAndReplaceMultipleIssuers(event, company: SenatorCompany) {
    if (event.source.selected) {
      let tradeIndex;

      this.selection.selected.forEach(trade => {
        switch (this.inputMode) {
          case this.FILE || this.REPORT_HOLDINGS:
            tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
            break;
          case this.QUALITY_GATE :
            tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeId).indexOf(trade.tradeId);
            break;
        }

        this.tradeTableRows.at(tradeIndex).get('x2iqAssetName').setValue(company.name);
        this.tradeTableRows.at(tradeIndex).get('assetSubCategory').setValue(null);

        this.tradeTableRows.at(tradeIndex).get('gvkey').setValue(company.gvkey);
        if (company.ticker !== null) {
          this.tradeTableRows.at(tradeIndex).get('ticker').setValue(company.ticker);
        } else {
          this.tradeTableRows.at(tradeIndex).get('ticker').setValue('--');
        }
        if (company.permId !== null) {
          this.tradeTableRows.at(tradeIndex).get('permId').setValue(company.permId);
        }
        this.tradeTableRows.at(tradeIndex).get('ref_senator_company_id').setValue(company.ID);
      });
      this.multipleReplacementForm.controls['ref_senator_company_id'].setValue(company.ID);
    }
  }

  refreshTheAssetClasses(index: number, selection: string) {
    this.assetClasses = [];
    let value;
    switch (selection) {
      case 'single':
        const formControls = this.tradeTableRows.at(index);
        value = formControls.get('ref_senator_company_id').value;
        break;
      case 'multiple':
        value = this.multipleReplacementForm.controls['ref_senator_company_id'].value;
        break;
    }
    if (value == null) {
      this.assetClasses = [];
      return;
    }

    this.assetClassesLoading = true;
    this._senatorAssetClassService.getAssetClassesOfTheCompany({gvkey: '', name: '', ID: value})
      .subscribe(value1 => this.assetClasses = value1, error => {
        this.assetClassesLoading = false;
      }, () => {
        this.assetClassesLoading = false;
      });
  }

  setAssetClassForTheRow(event: any, assetClass: SenatorCompanyAssetClass, index: number) {
    if (event.source.selected) {
      const controls = this.tradeTableRows.at(index);
      controls.get('ref_senator_company_asset_class_id').setValue(assetClass.ID);
      controls.get('isMarketTraded').setValue(assetClass.is_market_traded);
      controls.get('ref_stock_option_listing_id').setValue(null);
      controls.get('pricingSource').setValue(assetClass.is_market_traded == true ? 'Compustat' : 'Manual');
    }
  }

  searchAndReplaceMultipleAssetClass(event: any, assetClass: SenatorCompanyAssetClass) {
    if (event.source.selected) {
      this.selection.selected.forEach(trade => {
        // const controls = this.tradeTableRows.at(index);
        let tradeIndex;
        switch (this.inputMode) {
          case this.FILE || this.REPORT_HOLDINGS :
            tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
            break;
          case this.QUALITY_GATE :
            tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeId).indexOf(trade.tradeId);
            break;
        }

        this.tradeTableRows.at(tradeIndex).get('ref_senator_company_asset_class_id').setValue(assetClass.ID);
        this.tradeTableRows.at(tradeIndex).get('isMarketTraded').setValue(assetClass.is_market_traded);
        this.tradeTableRows.at(tradeIndex).get('assetSubCategory').setValue(`${assetClass.x2iqAssetClass} : ${assetClass.x2iqFinInst}`);
      });
    }
  }

  replaceAtMultipleRows(value, selectedFiled: string) {

    switch (this.inputMode) {
      case this.FILE || this.REPORT_HOLDINGS :
        this.selection.selected.forEach(trade => {
          // tslint:disable-next-line:no-shadowed-variable
          const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
          this.tradeTableRows.at(tradeIndex).get(selectedFiled).setValue(value);
        });
        break;
      case this.QUALITY_GATE:
        this.selection.selected.forEach(trade => {
          // tslint:disable-next-line:no-shadowed-variable
          const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeId).indexOf(trade.tradeId);
          this.tradeTableRows.at(tradeIndex).get(selectedFiled).setValue(value);
        });
        break;
    }
    this.table.renderRows();
    this.validateSelectedForm();

  }

  replaceMultipleX2iqTransactionTypeForFlag() {
    this.selection.selected.forEach(trade => {
      // tslint:disable-next-line:no-shadowed-variable
      const index = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
      this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').patchValue([...this._multiSelectX2iqTransactionTypeForFlag.value]);
    });
  }

  addNewTradesInForm(numOfTrades: number) {
    let count = 0;
    let tradeIndex: number;

    while (count < numOfTrades) {
      if (this.matTableDataSource.data.length < 1) {
        tradeIndex = 0;
      } else {
        tradeIndex = Math.max(...this.matTableDataSource.data.map(trade => trade.tradeIndex));
      }
      const newTrade: SenatorTrades = {
        filingId: (this.inputMode === this.FILE ? this.file.filingId : this.report.filingId),
        tradeId: -1,
        tradeIndex: tradeIndex + 1,
        transactionDate: null,
        transactionType: null,
        assetName: null,
        x2iqAssetName: null,
        gvkey: '',
        permId: null,
        isMarketTraded: null,
        assetType: null,
        assetSubCategory: null,
        owner: null,
        ticker: null,
        amount: null,
        price: null,
        noOfShares: null,
        is_manual_shares: false,
        istransactionDateNotDisclosed: false,
        isNewTrade: true,
        currencyCode: null,
        comment: null,
        remarks: null,
        sourceUrl: (this.inputMode === this.FILE ? this.file.sourceUrl : this.report.sourceUrl),
        isApproved: false,
        isAmended: false,
        ammendmentFilingId: null,
        ref_stock_option_listing_id: null,
        isCapitalGain: null,
        lastUpdatedBy: null,
        lastUpdatedOn: null,
        income: null,
        incomeType: [],
        x2iqTransactionTypeForFlag: [],
        isTaxAvailable: false,
        congressType: (this.inputMode === this.FILE ? <CongressTypeEnum>this.file.congressType : <CongressTypeEnum>this.report.congressType)
      };

      this.matTableDataSource.data = [...this.matTableDataSource.data, newTrade];
      this.allTrades.push(newTrade);
      this.tradeTableRowArray.push(this.createTradeRow(newTrade));
      this.matTableDataSource._updateChangeSubscription();
      count++;
    }
  }

  afterAllTradesProcessing() {
    if (this.totalTradesProcessedNum === this.selection.selected.length) {

      let successMessage;
      let unSuccessMessage;
      switch (this.actionToPerform) {

        case 'delete' :
          if (this.totalTradesProcessedNum === 1) {
            successMessage = `1 entry has been deleted successfully!`;
          } else {
            successMessage = `${this.deletedTradesSuccessCount} ${(this.deletedTradesSuccessCount > 1) ? 'entries' : 'entry'} has been deleted successfully!`;
            unSuccessMessage = `${(this.deletedTradesSuccessCount > 1) ? 'entries' : 'entry'} has been Deleted successfully!
                  ${(this.deletedTradesSuccessCount > 1) ? 'entries' : 'entry'} has not yet Deleted!`;
          }
          break;
        case 'mapIssuer' :
          successMessage = `${this.totalTradesProcessedNum} Issuer has been mapped successfully!`;
          unSuccessMessage = `Error! ,Issuer has not yet mapped!`;
          break;
      }

      switch (this.actionToPerform) {
        case 'delete':
          if (this.deletedTradesUnSuccessCount > 1) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });

          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          }
          break;
        case 'mapIssuer':
          if (this.totalTradesProcessedNum === 0) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          }
          break;
      }
      this.refreshTheDialog();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  compareInputAndUpdateFlags(index: number, event?: any) {
    if (this.areTranTypeAnd2iqTranTypeSame(index) && this.areAssetClassAndSecurityTypeSame(index, event)) {
      this.uncheckIsCuratedFlag(index);
    } else {
      this.checkIsCuratedFlag(index);
    }
  }

  compareInputUpdateIsCuratedFlagAtMultipleRows(event?: any) {

    this.selection.selected.forEach(trade => {
      const index = this.tradeTableRowArray.value
        .map((value) => value.tradeIndex)
        .indexOf(trade.tradeIndex);

      if (!this.areTranTypeAnd2iqTranTypeSame(index) || !this.areAssetClassAndSecurityTypeSame(index, event)) {

        this.checkIsCuratedFlag(index);
      } else if (this.areTranTypeAnd2iqTranTypeSame(index) && this.areAssetClassAndSecurityTypeSame(index, event)) {
        this.uncheckIsCuratedFlag(index);
      }
    });
  }

  areAssetClassAndSecurityTypeSame(index: number, event?: any): boolean {
    const assetType = this._getFormArrayItemControl(index, 'assetType').value;
    if (event && event.isUserInput) {
      const assetSubcategory = event.source.value.split(':')[0].trim();
      if (!assetSubcategory) {
        return true;
      }
      return assetType === assetSubcategory;
    }
    const assetSubCategory = this._getFormArrayItemControl(index, 'assetSubCategory').value;
    if (assetSubCategory != null) {
      const assetSubcategory = assetSubCategory.split(':')[0].trim();
      if (!assetSubcategory) {
        return true;
      }
      return assetType === assetSubcategory;
    }
    return false;
  }

  update2iqTranType(index: number) {
    this._getFormArrayItemControl(index, 'x2iqTransactionType').patchValue(this._getFormArrayItemControl(index, 'transactionType').value);
  }

  update2iqTranTypeAtMultipleSelection() {
    this.selection.selected.forEach(trade => {
      // tslint:disable-next-line:no-shadowed-variable
      const index = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
      this.update2iqTranType(index);
    });
  }


  areTranTypeAnd2iqTranTypeSame(index: number): boolean {
    return this._getFormArrayItemControl(index, 'transactionType').value === this._getFormArrayItemControl(index, 'x2iqTransactionType').value;
  }

  updateX2iqTransactionTypeForFlag() {
    this.allTrades.forEach((trade: SenatorTrades, index) => {
      if (trade.isShortCover && !this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value.some(el => el === 'Short Cover')) {
        this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').patchValue([...this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value, 'Short Cover']);
      }
      if (trade.isShortSell && !this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value.some(el => el === 'Short Sell')) {
        this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').patchValue([...this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value, 'Short Sell']);
      }
      if (trade.isDividendReinvestment && !this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value.some(el => el === 'Dividend Reinvestment')) {
        this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').patchValue([...this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value, 'Dividend Reinvestment']);
      }
    });
  }

  checkIsCuratedFlag(index: number) {
    if (!this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value.some(element => element === 'IsCurated')) {
      this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').setValue([...this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value, 'IsCurated']);

    }
  }

  uncheckIsCuratedFlag(index: number) {
    if (this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').value.indexOf('IsCurated') !== -1) {
      this._getFormArrayItemControl(index, 'x2iqTransactionTypeForFlag').patchValue(this._getFormArrayItemControl(index,
        'x2iqTransactionTypeForFlag').value.filter(e => e !== 'IsCurated'));
    }
  }

  validateSelectedForm() {
    this.isFormValid = this.tradeTableRowArray.controls
      .filter(a => this.selection.selected.findIndex(trade => trade.tradeIndex == a.value.tradeIndex) != -1)
      .findIndex(
        control => ((control.status == 'INVALID') || (control.value.isAmended && control.value.ammendmentFilingId !== null))
      ) == -1;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    if (this.matTableDataSource == null || this.matTableDataSource.data === null) {
      return false;
    }
    return this.selection.selected.length === this.matTableDataSource.data.length;
  }

  isAllSelectedOfCurrentPage(): boolean {
    if (this.matTableDataSource == null || this.matTableDataSource.data === null) {
      return false;
    }
    const filteredRows: SenatorTrades[] = this.matTableDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    return filteredRows.every(row => this.selection.isSelected(row));
  }

  isSomeSelectedOfCurrentPage(): boolean {
    if (this.matTableDataSource == null || this.matTableDataSource.data === null) {
      return false;
    }
    const filteredRows: SenatorTrades[] = this.matTableDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    return filteredRows.filter(row => this.selection.isSelected(row) === true).length > 0
      && !this.allSelectedOfCurrentPage;
  }

  updateAllComplete() {
    const filteredRows: SenatorTrades[] = this.matTableDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    this.allSelectedOfCurrentPage = this.matTableDataSource.data !== null &&
      filteredRows.filter(row => this.selection.isSelected(row) === true).length === this.pageSize;
  }

  /** Selects all the rows of current page if not selected, otherwise clear selection. */
  setAllOfCurrentPage(completed: boolean) {
    this.allSelectedOfCurrentPage = completed;
    if (this.matTableDataSource.data === null) {
      return;
    }
    // if any single entry is selected at any page, do validate the selected entries.

    if (!completed) {
      const filteredRows: SenatorTrades[] = this.matTableDataSource.data.filter((row, index) => {
        return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
          && index < (this.pageSize * this.pageNumber));
      });
      filteredRows.forEach((row: SenatorTrades) => {
        this.selection.deselect(row);
      });
    } else {
      const filteredRows: SenatorTrades[] = this.matTableDataSource.data.filter((row, index) => {
        return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
          && index < (this.pageSize * this.pageNumber));
      });
      filteredRows.forEach((row: SenatorTrades) => {
        this.selection.select(row);
      });
      this.validateSelectedForm();
    }
  }

  setAll(completed: boolean) {
    if (this.matTableDataSource.data === null) {
      return;
    }
    if (completed) {
      this.matTableDataSource.data.forEach((row, index) => {
        this.selection.select(row);
        this.validateSelectedForm();
      });
    } else {
      this.allSelectedOfCurrentPage = false;
      this.selection.clear();
    }

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row ?: SenatorTrades)
    :
    string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }

  onChangesForm() {
    this.tradeTableRowArray.valueChanges.subscribe(val => {
      if (this.selection.selected.length > 0) {
        this.validateSelectedForm();
      }
    });
  }

  isRowSelected(row: SenatorTrades) {
    let result: boolean;
    switch (this.inputMode) {
      case this.FILE :
        result = this.selection.selected.some(element => element.tradeIndex === row.tradeIndex);
        break;
      case this.REPORT_HOLDINGS :
        result = this.selection.selected.some(element => element.tradeIndex === row.tradeIndex);
        break;
      case this.QUALITY_GATE:
        result = this.selection.selected.some(element => element.tradeId === row.tradeId);
        break;
    }
    return result;

  }

  _getFormArrayItemControl(i: number, controlName: string):
    FormControl {
    return this.tradeTableRows.at(i).get(controlName) as FormControl;
  }

  isTranDateGreaterFilingDate(index: number) {
    if (this.inputMode === this.FILE) {
      return new Date(this._getFormArrayItemControl(index, 'transactionDate').value) > new Date(this.file.filingDate);
    } else if (this.inputMode === this.REPORT_HOLDINGS) {
      return new Date(this._getFormArrayItemControl(index, 'transactionDate').value) > new Date(this.report.filingDate);

    }
  }

  unpublishedOnlyCount(): number {
    return this.allTrades.filter(t => !t.isApproved && (t.lastUpdatedOn == null || t.lastUpdatedBy == null)).length;
  }

  publishedOnlyCount(): number {
    return this.allTrades.filter(t => t.isApproved == true).length;
  }

  savedOnlyCount(): number {
    return this.allTrades.filter(t => !t.isApproved && t.lastUpdatedOn !== null && t.lastUpdatedBy !== null && !t.isNewTrade && !t.isAmended).length;
  }

  amendedOnlyCount(): number {
    return this.allTrades.filter(t => t.isAmended && t.ammendmentFilingId !== null).length;
  }

  onChangeFileImport(evt) {
    let data;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      this.spinnerEnabled = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws, {raw: false});
      };

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.spinnerEnabled = false;
        this.keys = Object.keys(data[0]);
        this.dataSheet.next(data);
        this.excelTrades = data;
        this.addNewTradesFromExcelFile();

      };
    } else {
      this.inputFile.nativeElement.value = '';
      this.snackBar.open('This is not an Excel file. Please upload an Excel file only.', 'Close', {
        duration: 10000,
        verticalPosition: 'bottom',
        horizontalPosition: 'left',
      });
    }
  }

  addNewTradesFromExcelFile() {
    let count = 0;
    let tradeIndex: number;

    while (this.excelTrades.length > count) {
      if (this.matTableDataSource.data.length < 1) {
        tradeIndex = 0;
      } else {
        tradeIndex = Math.max(...this.matTableDataSource.data.map(trade => trade.tradeIndex));
      }
      const newTrade: SenatorTrades = {
        filingId: this.file.filingId,
        tradeId: -2,
        tradeIndex: tradeIndex + 1,
        transactionDate: this.excelTrades[count]['Transaction Date'],
        transactionType: this.excelTrades[count]['Transaction Type'],
        x2iqTransactionType: this.excelTrades[count]['x2iq Transaction Type'],
        assetName: this.excelTrades[count]['Issuer'],
        x2iqAssetName: this.excelTrades[count]['2iQ Issuer Name'],
        ref_senator_company_id: this.excelTrades[count]['ref_senator_company_id'],
        gvkey: '',
        assetType: this.excelTrades[count]['Security Type'],
        owner: this.excelTrades[count]['Owner'],
        noOfShares: this.excelTrades[count]['noOfShares'],
        is_manual_shares: this.excelTrades['isManualShares'],
        istransactionDateNotDisclosed: this.excelTrades['istransactionDateNotDisclosed'],
        ticker: null,
        amount: this.excelTrades[count]['Amount'],
        price: null,
        currencyCode: null,
        comment: this.excelTrades[count]['Comments'],
        remarks: this.excelTrades[count]['remarks'],
        sourceUrl: this.file.sourceUrl,
        isApproved: false,
        isAmended: false,
        ammendmentFilingId: null,
        isCapitalGain: this.excelTrades[count]['Cap Gains>$200'],
        isNewTrade: true,
        congressType: <CongressTypeEnum>this.file.congressType
      };

      this.matTableDataSource.data = [...this.matTableDataSource.data, newTrade];
      this.allTrades.push(newTrade);
      this.tradeTableRowArray.push(this.createTradeRow(newTrade));
      this.matTableDataSource._updateChangeSubscription();
      count++;
    }
  }

  changeSecurityTye(event) {
    this.selectedAssetType = event;
  }

  filterTrades() {
    const startTransactionDate = this.range.get('start').value;
    const endTransactionDate = this.range.get('end').value;

    let filteredTrades: SenatorTrades[] = this.allTrades;

    if (this.range.get('start').value && this.range.get('end').value) {
      filteredTrades = this.allTrades.filter((trade: SenatorTrades) => {
        return (new Date(trade.transactionDate) >= startTransactionDate && new Date(trade.transactionDate) <= endTransactionDate);
      });
    }
    if (this.tradeSearchFilters.tradeType.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.tradeType.includes(trade.transactionType) === true);
    }
    if (this.tradeSearchFilters.x2iqTradeType.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.x2iqTradeType.includes(trade.x2iqTransactionType) === true);
    }
    if (this.tradeSearchFilters.capGain.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.capGain.includes(trade.isCapitalGain) === true);
    }
    if (this.tradeSearchFilters.isMarketTraded.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.isMarketTraded.includes(trade.isMarketTraded) === true);
    }
    if (this.tradeSearchFilters.currencyCode.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.currencyCode.includes(trade.currencyCode) === true);
    }
    if (this.tradeSearchFilters.owner.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.owner.includes(trade.owner) === true);
    }
    if (this.tradeSearchFilters.assetType.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.assetType.includes(trade.assetType) === true);
    }
    if (this.tradeSearchFilters.assetSubCategory.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        (trade.assetSubCategory !== null || '') &&
        this.tradeSearchFilters.assetSubCategory.includes(trade.assetSubCategory.split('-')[0].trim()) === true);
    }
    if (this.tradeSearchFilters.amount.length > 0) {
      filteredTrades = filteredTrades.filter((trade: any) =>
        this.tradeSearchFilters.amount.includes(trade.amount) === true);
    }
    if (this.tradeSearchFilters.issuerName !== null &&
      this.tradeSearchFilters.issuerName.trim().length > 0) {
      filteredTrades = filteredTrades.filter(
        (trade: any) =>
          trade.x2iqAssetName !== null &&
          trade.x2iqAssetName !== '' &&
          trade.x2iqAssetName.toLowerCase().search(this.tradeSearchFilters.issuerName.toLowerCase()) !== -1
      );
    }
    if (this.tradeSearchFilters.assetName !== null &&
      this.tradeSearchFilters.assetName.trim().length > 0) {
      filteredTrades = filteredTrades.filter(
        (trade: any) =>
          trade.assetName !== null &&
          trade.assetName !== '' &&
          trade.assetName.toLowerCase().search(this.tradeSearchFilters.assetName.toLowerCase()) !== -1
      );
    }
    if (this.tradeSearchFilters.ticker !== null &&
      this.tradeSearchFilters.ticker.trim().length > 0) {
      filteredTrades = filteredTrades.filter(
        (trade: any) =>
          trade.ticker !== null &&
          trade.ticker !== '' &&
          trade.ticker.toLowerCase().search(this.tradeSearchFilters.ticker.toLowerCase()) !== -1
      );
    }
    if (this.tradeSearchFilters.gvkey !== null &&
      this.tradeSearchFilters.gvkey.trim().length > 0) {
      filteredTrades = filteredTrades.filter(
        (trade: any) =>
          trade.gvkey !== null &&
          trade.gvkey !== '' &&
          trade.gvkey.search(this.tradeSearchFilters.gvkey) !== -1
      );
    }
    if (this.tradeSearchFilters.noOfShares.length > 0) {
      let tempFilteredTrades: any[] = [];
      if (this.tradeSearchFilters.noOfShares.includes('Empty')) {
        tempFilteredTrades = filteredTrades.filter((trade: any) => (trade.noOfShares === null || ''));
      }
      if (this.tradeSearchFilters.noOfShares.includes('Non Empty')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter((trade: any) => (trade.noOfShares !== null || ''))];
      }
      filteredTrades = tempFilteredTrades;
    }
    if (this.tradeSearchFilters.price.length > 0) {
      let tempFilteredTrades: any[] = [];
      if (this.tradeSearchFilters.price.includes('Empty')) {
        tempFilteredTrades = filteredTrades.filter((trade: any) => (trade.price === null || ''));
      }
      if (this.tradeSearchFilters.price.includes('Non Empty')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter((trade: any) => (trade.price !== null || ''))];
      }
      filteredTrades = tempFilteredTrades;
    }
    if (this.tradeSearchFilters.status.length > 0) {
      let tempFilteredTrades: any[] = [];
      if (this.tradeSearchFilters.status.includes('Published')) {
        tempFilteredTrades = filteredTrades.filter((trade: any) => (trade.isApproved === true));
      }
      if (this.tradeSearchFilters.status.includes('Unpublished')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter((trade: any) => (!trade.isApproved &&
          (trade.lastUpdatedOn == null || trade.lastUpdatedBy == null)))];
      }
      if (this.tradeSearchFilters.status.includes('Saved')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter(trade => !trade.isApproved && trade.lastUpdatedOn !== null &&
          trade.lastUpdatedBy !== null && !trade.isNewTrade && (!trade.isAmended && trade.ammendmentFilingId === null))];
      }
      if (this.tradeSearchFilters.status.includes('New')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter(trade => trade.isNewTrade === true)];
      }
      if (this.tradeSearchFilters.status.includes('Amended')) {
        tempFilteredTrades = [...tempFilteredTrades, ...filteredTrades.filter(trade => trade.isAmended && trade.ammendmentFilingId !== null)];
      }
      filteredTrades = tempFilteredTrades;
    }

    this.matTableDataSource.data = filteredTrades;
    this.matTableDataSource.paginator = this.paginator;
    this.matTableDataSource.sort = this.sort;
    this.emptyTheTradeRows();
    this.initTradeFrom();
  }

  startChange(event: MatDatepickerInputEvent<any>) {
    if (event.value !== null) {
      this.filterTrades();
    }
  }

  endChange(event: MatDatepickerInputEvent<any>) {
    if (event.value !== null) {
      this.filterTrades();
    }
  }

  showFilters() {
    this.showTradeSearchFilter = !this.showTradeSearchFilter;
  }

  showResetFilterButton() {
    if (this.tradeSearchFilters) {
      return (this.tradeSearchFilters.tradeType.length > 0 ||
        this.tradeSearchFilters.owner.length > 0 ||
        this.tradeSearchFilters.assetType.length > 0 ||
        this.tradeSearchFilters.amount.length > 0 ||
        this.tradeSearchFilters.status.length > 0) ? true : this.tradeSearchFilters.issuerName !== null;
    }
  }

  clearFilters() {
    this.tradeSearchFilters = {
      tradeType: [],
      owner: [],
      noOfShares: [],
      assetType: [],
      assetSubCategory: [],
      amount: [],
      status: [],
      issuerName: null,
      assetName: null,
      gvkey: null,
      ticker: null,
      isMarketTraded: [],
      capGain: [],
      isTaxAvailable: [],
      price: [],
      currencyCode: [],
      transactionDateStart: null,
      transactionDateEnd: null
    };
    this.filterTrades();
  }

  totalTradesCount() {
    return this.allTrades.length;
  }

  newTradesCount(): number {
    return this.allTrades.filter(t => t.isNewTrade == true).length;
  }

  getFormGroupByIndex(index: number): FormGroup {
    return <FormGroup>this.tradeTableRowArray.controls[index];
  }

  isEditAssetDialogToShow(row: any, actualIndex: number): boolean {
    const companyIdControl = this._getFormArrayItemControl(actualIndex, 'ref_senator_company_id');
    return !this._getFormArrayItemControl(actualIndex, 'x2iqAssetName').invalid && companyIdControl?.value != null;
  }

  marketTradedChanged(event: boolean, index: number) {

    const tradeRow = this.tradeTableRows.at(index);

    tradeRow.get('isFractionalShares').setValue(false);
    tradeRow.get('noOfShares').setValidators([Validators.pattern(this.RANGE_OR_INT_PATTERN)]);
    tradeRow.get('noOfShares').updateValueAndValidity();
    if (event == false) {
      tradeRow.get('price').setValue(null);
      tradeRow.get('currencyCode').setValue(null);
      tradeRow.get('price').enable();
      tradeRow.get('currencyCode').enable();
    } else {
      tradeRow.get('price').setValue(null);
      tradeRow.get('currencyCode').setValue(null);
      tradeRow.get('price').disable();
      tradeRow.get('currencyCode').disable();
    }
  }

  openSnackBarByTimeOut(message: string, action: string, closeDuration: number) {
    this.snackBar.open(message, action, {
      duration: closeDuration,
      panelClass: ['green-snackbar']
    });
  }

  refreshTheDialog() {
    this.selection.clear();
    this.resetMultiSelectForm();

    this.multipleReplacementForm.get('tradeType').setValue('');
    this.matTableDataSource = new MatTableDataSource<SenatorTrades>();
    this.emptyTheTradeRows();
    this.ngOnInit();
  }

  emptyTheTradeRows() {
    while (this.tradeTableRows.at(0) != null) {
      this.tradeTableRows.removeAt(0);
    }
  }

  resetMultiSelectForm() {
    this.multipleReplacementForm.get('transactionDate').setValue('');
    this.multipleReplacementForm.get('tradeType').setValue('');
    this.multipleReplacementForm.get('x2iqTransactionType').setValue('');
    this.multipleReplacementForm.get('issuerName').setValue('');
    this.multipleReplacementForm.get('botGrabbedIssuerName').setValue('');
    this.multipleReplacementForm.get('owner').setValue('');
    this.multipleReplacementForm.get('shares').setValue('');
    this.multipleReplacementForm.get('assetType').setValue('');
    this.multipleReplacementForm.get('assetSubCategory').setValue('');
    this.multipleReplacementForm.get('ref_senator_company_id').setValue('');
    this.multipleReplacementForm.get('amount').setValue('');
    this.multipleReplacementForm.get('price').setValue('');
    this.multipleReplacementForm.get('currencyCode').setValue('');
    this.multipleReplacementForm.get('comments').setValue('');
    this.multipleReplacementForm.get('x2iqTransactionTypeForFlag').patchValue([]);
    this.multipleReplacementForm.get('isCapitalGain').setValue('');
    this.multipleReplacementForm.get('income').patchValue([]);
    this.multipleReplacementForm.get('incomeType').patchValue([]);
    this.multipleReplacementForm.get('isTaxAvailable').setValue('');
  }

  openDialog(selectedValue, biographyId, name, shortName, congressType, party, sourceUrl, tweeterHandler, publicationDate, filingDate) {

    if (party === 'Democratic') {
      party = 'Democrat';
    }
    const issuerName = selectedValue[0].x2iqAssetName;
    const politicianName = name;
    const politicianShortName = shortName;
    const tradeValue = selectedValue[0].amount;
    const transactionDate = selectedValue[0].transactionDate;
    const politicianType = congressType;
    const polParty = party;
    const fileSourceUrl = sourceUrl;
    const polTweeterHandler = tweeterHandler;
    if (issuerName != null && politicianName != null && tradeValue != null &&
      transactionDate != null && politicianType != null && politicianShortName && filingDate != null &&
      selectedValue[0].transactionType != null && selectedValue[0].assetType != null && this.selectedAssetType != null && selectedValue[0].assetSubCategory != null &&
      selectedValue[0].transactionId != null && selectedValue[0].reportingIndividualId != null) {
      this.politicianTrade.issuerName = issuerName;
      this.politicianTrade.politicianName = politicianName;
      this.politicianTrade.tradeValueRange = tradeValue;
      this.politicianTrade.transactionDate = transactionDate;
      this.politicianTrade.publicationDate = publicationDate;
      this.politicianTrade.filingDate = filingDate;
      this.politicianTrade.party = party;
      this.politicianTrade.congressType = congressType;
      this.politicianTrade.tradeType = selectedValue[0].transactionType;
      this.politicianTrade.assetType = this.selectedAssetType;
      this.politicianTrade.assetSubCategory = selectedValue[0].assetSubCategory.slice(0, selectedValue[0].assetSubCategory.indexOf('-')).trim();
      this.politicianTrade.sourceUrl = fileSourceUrl;
      this.politicianTrade.tweeterHandler = polTweeterHandler;
      this.politicianTrade.ticker = selectedValue[0].ticker;
      this.politicianTrade.shortName = politicianShortName;
      this.politicianTrade.refBiographyId = biographyId;
      this.politicianTrade.transactionId = selectedValue[0].transactionId;
      this.politicianTrade.reportingIndividualId = selectedValue[0].reportingIndividualId;
      this.dataService.setPoliticianTrade(this.politicianTrade);
     /* const dialogRef = this.matDialog.open(TwitterImageDialogComponent, {
        width: '630px',
        height: '620px',
      });

      dialogRef.afterClosed().subscribe(result => {
      });*/
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Please Assign Profile Name firstly'
      });
    }
  }

  doCheckReadOnly(actualIndex: number) {
    if (this.tradeTableRows.at(actualIndex).get('pricingSource').value == 'Compustat') {
      this.tradeTableRows.at(actualIndex).get('price').disable();
      this.tradeTableRows.at(actualIndex).get('currencyCode').disable();
    }
  }

  changedPriceSource(event, actualIndex: number) {
    const value = event.value;
    const tradeRow = this.tradeTableRows.at(actualIndex);

    tradeRow.get('isFractionalShares').setValue(false);
    tradeRow.get('noOfShares').setValidators([Validators.pattern(this.RANGE_OR_INT_PATTERN)]);
    tradeRow.get('noOfShares').updateValueAndValidity();
    if (value != 'Compustat') {
      tradeRow.get('price').setValue(null);
      tradeRow.get('currencyCode').setValue(null);
      tradeRow.get('price').enable();
      tradeRow.get('currencyCode').enable();
    } else {
      tradeRow.get('price').setValue(null);
      tradeRow.get('currencyCode').setValue(null);
      tradeRow.get('price').disable();
      tradeRow.get('currencyCode').disable();
    }
  }
}

@Component({
  selector: 'app-snack-bar1',
  template: `<span class="example-pizza-party">
  {{data?.message}}
</span>
  <div style="margin-top: 5px" fxLayout fxLayoutAlign="flex-end" fxLayoutGap="10px">
    <button mat-raised-button
            color="primary"
            (click)="snackBarRef.dismissWithAction();">yes
    </button>
    <button mat-raised-button
            color="accent"
            (click)="snackBarRef.dismiss()">close
    </button>
  </div>`
})
export class SnackBar1Component {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBar1Component>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
}

@Component({
  selector: 'app-delete-trade-dialog',
  template: `<h1 mat-dialog-title>Delete Entries</h1>
  <div mat-dialog-content>
    <p>Are you sure, you want to delete the selected entries?</p>
  </div>

  <div mat-dialog-actions align="end">
    <button [mat-dialog-close]="'close'" mat-button (click)="onNoClick()">Cancel</button>
    <button [mat-dialog-close]="'delete'" mat-raised-button color="warn">Delete</button>
  </div>`,
})
export class TradeDeleteDialog1Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TradeDeleteDialog1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-merge-issuer-dialog',
  template: `
    <mat-card-header fxLayout fxLayoutAlign="space-between">
      <mat-card-subtitle>Merge Issuer</mat-card-subtitle>
    </mat-card-header>
    <div mat-dialog-content>
      <p>Are you sure, you want to replace <b>{{data.trade?.assetName?.value}}</b> with
        <b>{{data.trade?.x2iqAssetName?.value}}</b> ?
      </p>
      <mat-divider></mat-divider>
      <h5 style="color:red">Warning!</h5>
      <p>This will Map <b>{{data.trade?.assetName?.value }}</b> with <b>{{data?.trade?.x2iqAssetName?.value}}</b> in all
        unpublished files.</p>
      <p>Any unsaved changes will be lost in this file.</p>
    </div>

    <div mat-dialog-actions align="end">
      <button [mat-dialog-close]="'close'" mat-button (click)="onNoClick()">Cancel</button>
      <button [mat-dialog-close]="'merge'" mat-raised-button color="primary">Merge</button>
    </div>`,
})
export class MergeIssuerDialog1Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MergeIssuerDialog1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-trade-amendment-dialog-component',
  template: `<h1 mat-dialog-title>Trade Mark As Amended</h1>
  <div mat-dialog-content>
    <p>Please enter the filing id of this amendment.</p>
    <input matInput type="number" maxlength="20" placeholder="Amendment Filing id"
           [(ngModel)]="filingId">
  </div>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onMarkAmendedClick()"
            [disabled]="filingId === null">
      Mark Amended
    </button>
  </div>`,
})
export class TradeAmendmentDialog1Component implements OnInit {

  filingId: SenatorFiling;

  constructor(
    public dialogRef: MatDialogRef<TradeAmendmentDialog1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.filingId = this.data['filingId'];
  }

  onCancelClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onMarkAmendedClick() {
    this.dialogRef.close({event: 'Amended', data: {filingId: this.filingId}});
  }
}

@Component({
  selector: 'app-trade-unamendment-dialog-component',
  template: `<h1 mat-dialog-title>Trade Mark As Unamended</h1>
  <div mat-dialog-content>
    <p>Trade is amended with filling id: {{this.filingId}}</p>
  </div>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onMarkUnAmendedClick()"
            [disabled]="filingId === null">
      Mark Unamended
    </button>
  </div>`,
})
export class TradeUnAmendmentDialog1Component implements OnInit {

  filingId: SenatorFiling;

  constructor(
    public dialogRef: MatDialogRef<TradeUnAmendmentDialog1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.filingId = this.data['filingId'];
  }

  onCancelClick(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  onMarkUnAmendedClick() {
    this.dialogRef.close({event: 'Unamended', data: {filingId: this.filingId}});
  }
}

/* tslint:disable */
type CongressTypeEnum =
  'Senator' |
  'Representative' |
  'Both';
module CongressTypeEnum {
  export const SENATOR: CongressTypeEnum = 'Senator';
  export const REPRESENTATIVE: CongressTypeEnum = 'Representative';
  export const BOTH: CongressTypeEnum = 'Both';
  export function values(): CongressTypeEnum[] {
    return [
      SENATOR,
      REPRESENTATIVE,
      BOTH
    ];
  }
}

export { CongressTypeEnum }
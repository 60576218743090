import {Component, OnInit, ViewChild} from '@angular/core';
import {SenatorQualityGateType} from '../../../api/models/senator-quality-gate-type';

@Component({
  selector: 'app-quality-gates',
  templateUrl: './quality-gates.component.html',
  styleUrls: ['./quality-gates.component.scss']
})
export class QualityGatesComponent implements OnInit {

  gateTypes = SenatorQualityGateType.values();
  selectedGateType = 'ALL_COMPANIES';

  constructor() {
  }

  ngOnInit() {
  }

  gateSelected() {
    //  console.log('selected gate type : ', this.selectedGateType);
  }

}

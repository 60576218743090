/* tslint:disable */
type PoliticianIncomeRanges =
  'None (or less than $201)' |
  '$201 - $1,000' |
  '$1,001 - $2,500' |
  '$2,501 - $5,000' |
  '$5,001 - $15,000' |
  '$15,001 - $50,000' |
  '$50,001 - $100,000' |
  '$100,001 - $1,000,000' |
  'Over $1,000,000' |
  '$1,000,001 - $5,000,000' |
  'Over $5,000,000' |
  'NOT DISCLOSED' |
  'Other';
module PoliticianIncomeRanges {
  export const NONE_OR_LESS_THAN_201_: PoliticianIncomeRanges = 'None (or less than $201)';
  export const _201_1_000: PoliticianIncomeRanges = '$201 - $1,000';
  export const _1_001_2_500: PoliticianIncomeRanges = '$1,001 - $2,500';
  export const _2_501_5_000: PoliticianIncomeRanges = '$2,501 - $5,000';
  export const _5_001_15_000: PoliticianIncomeRanges = '$5,001 - $15,000';
  export const _15_001_50_000: PoliticianIncomeRanges = '$15,001 - $50,000';
  export const _50_001_100_000: PoliticianIncomeRanges = '$50,001 - $100,000';
  export const _100_001_1_000_000: PoliticianIncomeRanges = '$100,001 - $1,000,000';
  export const OVER_1_000_000: PoliticianIncomeRanges = 'Over $1,000,000';
  export const _1_000_001_5_000_000: PoliticianIncomeRanges = '$1,000,001 - $5,000,000';
  export const OVER_5_000_000: PoliticianIncomeRanges = 'Over $5,000,000';
  export const NOT_DISCLOSED: PoliticianIncomeRanges = 'NOT DISCLOSED';
  export const OTHER: PoliticianIncomeRanges = 'Other';
  export function values(): PoliticianIncomeRanges[] {
    return [
      NONE_OR_LESS_THAN_201_,
      _201_1_000,
      _1_001_2_500,
      _2_501_5_000,
      _5_001_15_000,
      _15_001_50_000,
      _50_001_100_000,
      _100_001_1_000_000,
      OVER_1_000_000,
      _1_000_001_5_000_000,
      OVER_5_000_000,
      NOT_DISCLOSED,
      OTHER
    ];
  }
}

export { PoliticianIncomeRanges }
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, NativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatFormFieldModule} from '@angular/material/form-field';

import {MatChipsModule} from '@angular/material/chips';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
//import {FlexModule} from '@angular/flex-layout';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const material = [MatButtonModule, MatTableModule, MatProgressSpinnerModule,
  MatCardModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSortModule,
  MatDialogModule, MatDatepickerModule, NativeDateModule, MatNativeDateModule,
  MatRadioModule, MatDividerModule, MatCheckboxModule, MatAutocompleteModule,
  MatSnackBarModule, MatSelectModule, MatMenuModule, MatTooltipModule, MatExpansionModule,
  MatFormFieldModule,
  MatSidenavModule, MatChipsModule, MatSlideToggleModule
];


@NgModule({
  imports: [material, CommonModule, FormsModule],
  exports: [material],
  declarations: []

})

export class MaterialModule {
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PoliticianTrade} from '../../../api/models/politician-trade';

@Injectable()
export class DataService {
  private politicianTrade = new BehaviorSubject<PoliticianTrade>(null);
  politicianTradeForImage = this.politicianTrade.asObservable();

  constructor() {
  }

  setPoliticianTrade(trades: PoliticianTrade) {
    this.politicianTrade.next(trades);
  }
}


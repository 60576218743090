/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorFiling } from '../models/senator-filing';
import { Politician } from '../models/politician';
import { SenatorInfo } from '../models/senator-info';
import { SenatorTradeFilter } from '../models/senator-trade-filter';
import { SenatorTradesPlan } from '../models/senator-trades-plan';
import { SenatorCompany } from '../models/senator-company';
import { SenatorNoGvKeyConfirmation } from '../models/senator-no-gv-key-confirmation';
import { SenatorTrades } from '../models/senator-trades';
import { SenatorAnalystReport } from '../models/senator-analyst-report';
import { TradeAmendmentModel } from '../models/trade-amendment-model';
import { TradeOperationModel } from '../models/trade-operation-model';
import { ReverseAmendedTrade } from '../models/reverse-amended-trade';
import { AmendedTradesHistory } from '../models/amended-trades-history';
import { SenatorFilingDetailForTweet } from '../models/senator-filing-detail-for-tweet';
import { SenatorFilingForTweet } from '../models/senator-filing-for-tweet';
@Injectable({
  providedIn: 'root',
})
class SenatorService extends __BaseService {
  static readonly verifySenatorUrlPath = '/senator/verifyUrl';
  static readonly getSenatorBiographyNamesPath = '/senator/get/biography/names/{congressType}/';
  static readonly getSenatorBiographyByNamePath = '/senator/get/biography/info/{senatorName}/{congressType}';
  static readonly getSenatorFilingsPath = '/senator/get/filing';
  static readonly getSenatorAssetsPath = '/politician/get/asset';
  static readonly addSenatorAssetFilingPath = '/politician/save/asset/filing/{congressType}/';
  static readonly saveSenatorAssetFilingPath = '/politician/save/asset/filing/{congressType}/';
  static readonly addSenatorFilingPath = '/senator/save/filing/{congressType}/';
  static readonly saveSenatorFilingPath = '/senator/save/filing/{congressType}/';
  static readonly splitFilePath = '/senator/split/{congressType}/{filingId}/{parts}';
  static readonly combineFilePath = '/senator/combine/{congressType}/{filingId}';
  static readonly splitFileRetainTradesPath = '/senator/split/retain-trades/{congressType}/{filingId}/{parts}/{tradesPerFile}/{totalTrades}';
  static readonly combineFileRetainTradesPath = '/senator/combine/retain-trades/{congressType}/{filingId}';
  static readonly deleteSenatorTradesByTradeIdPath = '/senator/delete/trade/{tradeId}/{congressType}/';
  static readonly addSenatorTradesPlanPath = '/senator/trades/plan';
  static readonly saveSenatorTradesPlanPath = '/senator/trades/plan';
  static readonly getSenatorTradesPlanByFilingIdPath = '/senator/get/plan/by/{filingId}/';
  static readonly deleteIssuerSecurityPath = '/senatorCompany/delete/issuer/security/{securityId}';
  static readonly deletePoliticianAssetTradesByTradeIdPath = '/politician/delete/asset/trade/{tradeId}/{congressType}/';
  static readonly deleteSenatorFilingByFilingIdPath = '/senator/delete/filing/{filingId}/{congressType}/';
  static readonly getSenatorAssetNamesPath = '/senator/get/assetNames/';
  static readonly doReplaceTheNamePath = '/senator/assetName/replacement/{doApplyOnApproved}/as2iqName';
  static readonly getSenatorTradesByFilingIdPath = '/senator/get/trade/{filingId}/{congressType}/';
  static readonly getPoliticianAssetTradesByFilingIdPath = '/politician/get/asset/trade/{filingId}/{congressType}/';
  static readonly getSenatorTradesBySenatorCompanyIdPath = '/senator/get/trade/{senatorCompanyId}';
  static readonly getSenatorTradesByStockOptionListingIdPath = '/senator/get/trade/stock-option-listing/{stockOptionListingId}';
  static readonly getSenatorTradeByTradeIdPath = '/senator/get/tradeBy/{tradeId}/{congressType}/';
  static readonly unlockFilingByIdPath = '/senator/unlock/filing/{filingId}/{congressType}/';
  static readonly unlockAssetFilingByIdPath = '/politician/unlock/asset/filing/{filingId}/{congressType}/';
  static readonly getAnalystEmailsPath = '/senator/analyst/emails';
  static readonly getAnalystReportsPath = '/senator/analyst/reports/{date}/{congressType}/';
  static readonly saveSenatorTradePath = '/senator/save/trade';
  static readonly updateTradeStatusPath = '/senator/trade/update/status';
  static readonly updateMultipleTradeStatusPath = '/senator/trade/update/status/multiple';
  static readonly reverseTradesAmendmentPath = '/senator/trade/update/status/multiple';
  static readonly reverseAmendedTradePath = '/senator/trade/amended/reverse';
  static readonly getAmendedTradeHistoryPath = '/senator/trade/amended/history';
  static readonly savePoliticianAssetTradePath = '/politician/save/asset/trade';
  static readonly getUnProcessedFileDetailPath = '/senator/get/unprocessed/file/detail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SenatorService.VerifySenatorUrlParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `congressType`:
   *
   * @return return SenatorFiling that needs to be checked
   */
  verifySenatorUrlResponse(params: SenatorService.VerifySenatorUrlParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.url != null) __params = __params.set('url', params.url.toString());
    if (params.congressType != null) __params = __params.set('congressType', params.congressType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/verifyUrl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.VerifySenatorUrlParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `congressType`:
   *
   * @return return SenatorFiling that needs to be checked
   */
  verifySenatorUrl(params: SenatorService.VerifySenatorUrlParams): __Observable<SenatorFiling> {
    return this.verifySenatorUrlResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param congressType undefined
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorBiographyNamesResponse(congressType: string): __Observable<__StrictHttpResponse<Array<Politician>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/biography/names/${encodeURIComponent(String(congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Politician>>;
      })
    );
  }
  /**
   * @param congressType undefined
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorBiographyNames(congressType: string): __Observable<Array<Politician>> {
    return this.getSenatorBiographyNamesResponse(congressType).pipe(
      __map(_r => _r.body as Array<Politician>)
    );
  }

  /**
   * @param params The `SenatorService.GetSenatorBiographyByNameParams` containing the following parameters:
   *
   * - `senatorName`:
   *
   * - `congressType`:
   *
   * @return Returns the matching Senator that is selected.
   */
  getSenatorBiographyByNameResponse(params: SenatorService.GetSenatorBiographyByNameParams): __Observable<__StrictHttpResponse<SenatorInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/biography/info/${encodeURIComponent(String(params.senatorName))}/${encodeURIComponent(String(params.congressType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorInfo>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetSenatorBiographyByNameParams` containing the following parameters:
   *
   * - `senatorName`:
   *
   * - `congressType`:
   *
   * @return Returns the matching Senator that is selected.
   */
  getSenatorBiographyByName(params: SenatorService.GetSenatorBiographyByNameParams): __Observable<SenatorInfo> {
    return this.getSenatorBiographyByNameResponse(params).pipe(
      __map(_r => _r.body as SenatorInfo)
    );
  }

  /**
   * @param tradeFilter undefined
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorFilingsResponse(tradeFilter: SenatorTradeFilter): __Observable<__StrictHttpResponse<Array<SenatorFiling>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/get/filing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorFiling>>;
      })
    );
  }
  /**
   * @param tradeFilter undefined
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorFilings(tradeFilter: SenatorTradeFilter): __Observable<Array<SenatorFiling>> {
    return this.getSenatorFilingsResponse(tradeFilter).pipe(
      __map(_r => _r.body as Array<SenatorFiling>)
    );
  }

  /**
   * @param tradeFilter undefined
   * @return Returns list of SenatorAsset that needs to be checked.
   */
  getSenatorAssetsResponse(tradeFilter: SenatorTradeFilter): __Observable<__StrictHttpResponse<Array<SenatorFiling>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/politician/get/asset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorFiling>>;
      })
    );
  }
  /**
   * @param tradeFilter undefined
   * @return Returns list of SenatorAsset that needs to be checked.
   */
  getSenatorAssets(tradeFilter: SenatorTradeFilter): __Observable<Array<SenatorFiling>> {
    return this.getSenatorAssetsResponse(tradeFilter).pipe(
      __map(_r => _r.body as Array<SenatorFiling>)
    );
  }

  /**
   * @param params The `SenatorService.AddSenatorAssetFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  addSenatorAssetFilingResponse(params: SenatorService.AddSenatorAssetFilingParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorFiling;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/politician/save/asset/filing/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.AddSenatorAssetFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  addSenatorAssetFiling(params: SenatorService.AddSenatorAssetFilingParams): __Observable<SenatorFiling> {
    return this.addSenatorAssetFilingResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.SaveSenatorAssetFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  saveSenatorAssetFilingResponse(params: SenatorService.SaveSenatorAssetFilingParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorFiling;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/politician/save/asset/filing/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.SaveSenatorAssetFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  saveSenatorAssetFiling(params: SenatorService.SaveSenatorAssetFilingParams): __Observable<SenatorFiling> {
    return this.saveSenatorAssetFilingResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.AddSenatorFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  addSenatorFilingResponse(params: SenatorService.AddSenatorFilingParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorFiling;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/save/filing/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.AddSenatorFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  addSenatorFiling(params: SenatorService.AddSenatorFilingParams): __Observable<SenatorFiling> {
    return this.addSenatorFilingResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.SaveSenatorFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  saveSenatorFilingResponse(params: SenatorService.SaveSenatorFilingParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorFiling;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senator/save/filing/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.SaveSenatorFilingParams` containing the following parameters:
   *
   * - `senatorFiling`:
   *
   * - `congressType`:
   *
   * @return Returns the item saved.
   */
  saveSenatorFiling(params: SenatorService.SaveSenatorFilingParams): __Observable<SenatorFiling> {
    return this.saveSenatorFilingResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.SplitFileParams` containing the following parameters:
   *
   * - `parts`:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the parts of the file.
   */
  splitFileResponse(params: SenatorService.SplitFileParams): __Observable<__StrictHttpResponse<Array<SenatorFiling>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/split/${encodeURIComponent(String(params.congressType))}/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.parts))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorFiling>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.SplitFileParams` containing the following parameters:
   *
   * - `parts`:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the parts of the file.
   */
  splitFile(params: SenatorService.SplitFileParams): __Observable<Array<SenatorFiling>> {
    return this.splitFileResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorFiling>)
    );
  }

  /**
   * @param params The `SenatorService.CombineFileParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the combined file.
   */
  combineFileResponse(params: SenatorService.CombineFileParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/combine/${encodeURIComponent(String(params.congressType))}/${encodeURIComponent(String(params.filingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.CombineFileParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the combined file.
   */
  combineFile(params: SenatorService.CombineFileParams): __Observable<SenatorFiling> {
    return this.combineFileResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.SplitFileRetainTradesParams` containing the following parameters:
   *
   * - `tradesPerFile`:
   *
   * - `totalTrades`:
   *
   * - `parts`:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the parts of the file.
   */
  splitFileRetainTradesResponse(params: SenatorService.SplitFileRetainTradesParams): __Observable<__StrictHttpResponse<Array<SenatorFiling>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/split/retain-trades/${encodeURIComponent(String(params.congressType))}/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.parts))}/${encodeURIComponent(String(params.tradesPerFile))}/${encodeURIComponent(String(params.totalTrades))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorFiling>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.SplitFileRetainTradesParams` containing the following parameters:
   *
   * - `tradesPerFile`:
   *
   * - `totalTrades`:
   *
   * - `parts`:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the parts of the file.
   */
  splitFileRetainTrades(params: SenatorService.SplitFileRetainTradesParams): __Observable<Array<SenatorFiling>> {
    return this.splitFileRetainTradesResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorFiling>)
    );
  }

  /**
   * @param params The `SenatorService.CombineFileRetainTradesParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the combined file.
   */
  combineFileRetainTradesResponse(params: SenatorService.CombineFileRetainTradesParams): __Observable<__StrictHttpResponse<SenatorFiling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/combine/retain-trades/${encodeURIComponent(String(params.congressType))}/${encodeURIComponent(String(params.filingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFiling>;
      })
    );
  }
  /**
   * @param params The `SenatorService.CombineFileRetainTradesParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return when completed successful, it will return the combined file.
   */
  combineFileRetainTrades(params: SenatorService.CombineFileRetainTradesParams): __Observable<SenatorFiling> {
    return this.combineFileRetainTradesResponse(params).pipe(
      __map(_r => _r.body as SenatorFiling)
    );
  }

  /**
   * @param params The `SenatorService.DeleteSenatorTradesByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteSenatorTradesByTradeIdResponse(params: SenatorService.DeleteSenatorTradesByTradeIdParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/senator/delete/trade/${encodeURIComponent(String(params.tradeId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorService.DeleteSenatorTradesByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteSenatorTradesByTradeId(params: SenatorService.DeleteSenatorTradesByTradeIdParams): __Observable<boolean> {
    return this.deleteSenatorTradesByTradeIdResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param senatorTradesPlan undefined
   * @return Returns boolean rather inserted successfully or not.
   */
  addSenatorTradesPlanResponse(senatorTradesPlan: Array<SenatorTradesPlan>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorTradesPlan;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/trades/plan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorTradesPlan undefined
   * @return Returns boolean rather inserted successfully or not.
   */
  addSenatorTradesPlan(senatorTradesPlan: Array<SenatorTradesPlan>): __Observable<boolean> {
    return this.addSenatorTradesPlanResponse(senatorTradesPlan).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param senatorTradesPlan undefined
   * @return Returns boolean rather updated successfully or not.
   */
  saveSenatorTradesPlanResponse(senatorTradesPlan: Array<SenatorTradesPlan>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorTradesPlan;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senator/trades/plan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorTradesPlan undefined
   * @return Returns boolean rather updated successfully or not.
   */
  saveSenatorTradesPlan(senatorTradesPlan: Array<SenatorTradesPlan>): __Observable<boolean> {
    return this.saveSenatorTradesPlanResponse(senatorTradesPlan).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param filingId undefined
   * @return Returns list of senatorTrades by plan that needs to be checked.
   */
  getSenatorTradesPlanByFilingIdResponse(filingId: number): __Observable<__StrictHttpResponse<Array<SenatorTradesPlan>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/plan/by/${encodeURIComponent(String(filingId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTradesPlan>>;
      })
    );
  }
  /**
   * @param filingId undefined
   * @return Returns list of senatorTrades by plan that needs to be checked.
   */
  getSenatorTradesPlanByFilingId(filingId: number): __Observable<Array<SenatorTradesPlan>> {
    return this.getSenatorTradesPlanByFilingIdResponse(filingId).pipe(
      __map(_r => _r.body as Array<SenatorTradesPlan>)
    );
  }

  /**
   * @param securityId undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteIssuerSecurityResponse(securityId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/senatorCompany/delete/issuer/security/${encodeURIComponent(String(securityId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param securityId undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteIssuerSecurity(securityId: number): __Observable<boolean> {
    return this.deleteIssuerSecurityResponse(securityId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `SenatorService.DeletePoliticianAssetTradesByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deletePoliticianAssetTradesByTradeIdResponse(params: SenatorService.DeletePoliticianAssetTradesByTradeIdParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/politician/delete/asset/trade/${encodeURIComponent(String(params.tradeId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorService.DeletePoliticianAssetTradesByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deletePoliticianAssetTradesByTradeId(params: SenatorService.DeletePoliticianAssetTradesByTradeIdParams): __Observable<boolean> {
    return this.deletePoliticianAssetTradesByTradeIdResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `SenatorService.DeleteSenatorFilingByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteSenatorFilingByFilingIdResponse(params: SenatorService.DeleteSenatorFilingByFilingIdParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/senator/delete/filing/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorService.DeleteSenatorFilingByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns boolean rather deleted successfully or not.
   */
  deleteSenatorFilingByFilingId(params: SenatorService.DeleteSenatorFilingByFilingIdParams): __Observable<boolean> {
    return this.deleteSenatorFilingByFilingIdResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param partialName undefined
   * @return It will return already used names similar to given partial name.
   */
  getSenatorAssetNamesResponse(partialName: string): __Observable<__StrictHttpResponse<Array<SenatorCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = partialName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/get/assetNames/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorCompany>>;
      })
    );
  }
  /**
   * @param partialName undefined
   * @return It will return already used names similar to given partial name.
   */
  getSenatorAssetNames(partialName: string): __Observable<Array<SenatorCompany>> {
    return this.getSenatorAssetNamesResponse(partialName).pipe(
      __map(_r => _r.body as Array<SenatorCompany>)
    );
  }

  /**
   * @param params The `SenatorService.DoReplaceTheNameParams` containing the following parameters:
   *
   * - `replacement`:
   *
   * - `doApplyOnApproved`:
   *
   * @return reponse will be number of records that were replaced.
   */
  doReplaceTheNameResponse(params: SenatorService.DoReplaceTheNameParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.replacement;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/assetName/replacement/${encodeURIComponent(String(params.doApplyOnApproved))}/as2iqName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `SenatorService.DoReplaceTheNameParams` containing the following parameters:
   *
   * - `replacement`:
   *
   * - `doApplyOnApproved`:
   *
   * @return reponse will be number of records that were replaced.
   */
  doReplaceTheName(params: SenatorService.DoReplaceTheNameParams): __Observable<number> {
    return this.doReplaceTheNameResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `SenatorService.GetSenatorTradesByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesByFilingIdResponse(params: SenatorService.GetSenatorTradesByFilingIdParams): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/trade/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetSenatorTradesByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesByFilingId(params: SenatorService.GetSenatorTradesByFilingIdParams): __Observable<Array<SenatorTrades>> {
    return this.getSenatorTradesByFilingIdResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param params The `SenatorService.GetPoliticianAssetTradesByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getPoliticianAssetTradesByFilingIdResponse(params: SenatorService.GetPoliticianAssetTradesByFilingIdParams): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/politician/get/asset/trade/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetPoliticianAssetTradesByFilingIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getPoliticianAssetTradesByFilingId(params: SenatorService.GetPoliticianAssetTradesByFilingIdParams): __Observable<Array<SenatorTrades>> {
    return this.getPoliticianAssetTradesByFilingIdResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param params The `SenatorService.GetSenatorTradesBySenatorCompanyIdParams` containing the following parameters:
   *
   * - `senatorCompanyId`:
   *
   * - `byDateFilter`:
   *
   * - `assetId`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesBySenatorCompanyIdResponse(params: SenatorService.GetSenatorTradesBySenatorCompanyIdParams): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.byDateFilter != null) __params = __params.set('byDateFilter', params.byDateFilter.toString());
    if (params.assetId != null) __params = __params.set('assetId', params.assetId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/trade/${encodeURIComponent(String(params.senatorCompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetSenatorTradesBySenatorCompanyIdParams` containing the following parameters:
   *
   * - `senatorCompanyId`:
   *
   * - `byDateFilter`:
   *
   * - `assetId`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesBySenatorCompanyId(params: SenatorService.GetSenatorTradesBySenatorCompanyIdParams): __Observable<Array<SenatorTrades>> {
    return this.getSenatorTradesBySenatorCompanyIdResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param params The `SenatorService.GetSenatorTradesByStockOptionListingIdParams` containing the following parameters:
   *
   * - `stockOptionListingId`:
   *
   * - `byDateFilter`:
   *
   * - `assetId`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesByStockOptionListingIdResponse(params: SenatorService.GetSenatorTradesByStockOptionListingIdParams): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.byDateFilter != null) __params = __params.set('byDateFilter', params.byDateFilter.toString());
    if (params.assetId != null) __params = __params.set('assetId', params.assetId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/trade/stock-option-listing/${encodeURIComponent(String(params.stockOptionListingId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetSenatorTradesByStockOptionListingIdParams` containing the following parameters:
   *
   * - `stockOptionListingId`:
   *
   * - `byDateFilter`:
   *
   * - `assetId`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradesByStockOptionListingId(params: SenatorService.GetSenatorTradesByStockOptionListingIdParams): __Observable<Array<SenatorTrades>> {
    return this.getSenatorTradesByStockOptionListingIdResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param params The `SenatorService.GetSenatorTradeByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradeByTradeIdResponse(params: SenatorService.GetSenatorTradeByTradeIdParams): __Observable<__StrictHttpResponse<SenatorTrades>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/get/tradeBy/${encodeURIComponent(String(params.tradeId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorTrades>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetSenatorTradeByTradeIdParams` containing the following parameters:
   *
   * - `tradeId`:
   *
   * - `congressType`:
   *
   * @return Returns list of SenatorFiling that needs to be checked.
   */
  getSenatorTradeByTradeId(params: SenatorService.GetSenatorTradeByTradeIdParams): __Observable<SenatorTrades> {
    return this.getSenatorTradeByTradeIdResponse(params).pipe(
      __map(_r => _r.body as SenatorTrades)
    );
  }

  /**
   * @param params The `SenatorService.UnlockFilingByIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return True if unlocked, false if is was already unlocked.
   */
  unlockFilingByIdResponse(params: SenatorService.UnlockFilingByIdParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/unlock/filing/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorService.UnlockFilingByIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return True if unlocked, false if is was already unlocked.
   */
  unlockFilingById(params: SenatorService.UnlockFilingByIdParams): __Observable<boolean> {
    return this.unlockFilingByIdResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `SenatorService.UnlockAssetFilingByIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return True if unlocked, false if is was already unlocked.
   */
  unlockAssetFilingByIdResponse(params: SenatorService.UnlockAssetFilingByIdParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/politician/unlock/asset/filing/${encodeURIComponent(String(params.filingId))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorService.UnlockAssetFilingByIdParams` containing the following parameters:
   *
   * - `filingId`:
   *
   * - `congressType`:
   *
   * @return True if unlocked, false if is was already unlocked.
   */
  unlockAssetFilingById(params: SenatorService.UnlockAssetFilingByIdParams): __Observable<boolean> {
    return this.unlockAssetFilingByIdResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return return list of string having email
   */
  getAnalystEmailsResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/analyst/emails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return return list of string having email
   */
  getAnalystEmails(): __Observable<Array<string>> {
    return this.getAnalystEmailsResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `SenatorService.GetAnalystReportsParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `congressType`:
   *
   * @return return list of string having email
   */
  getAnalystReportsResponse(params: SenatorService.GetAnalystReportsParams): __Observable<__StrictHttpResponse<Array<SenatorAnalystReport>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/analyst/reports/${encodeURIComponent(String(params.date))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorAnalystReport>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.GetAnalystReportsParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `congressType`:
   *
   * @return return list of string having email
   */
  getAnalystReports(params: SenatorService.GetAnalystReportsParams): __Observable<Array<SenatorAnalystReport>> {
    return this.getAnalystReportsResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorAnalystReport>)
    );
  }

  /**
   * @param senatorTrade undefined
   * @return Returns the item saved.
   */
  saveSenatorTradeResponse(senatorTrade: Array<SenatorTrades>): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorTrade;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senator/save/trade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param senatorTrade undefined
   * @return Returns the item saved.
   */
  saveSenatorTrade(senatorTrade: Array<SenatorTrades>): __Observable<Array<SenatorTrades>> {
    return this.saveSenatorTradeResponse(senatorTrade).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param tradeOperation undefined
   * @return Returns the Updated Trades
   */
  updateTradeStatusResponse(tradeOperation: TradeOperationModel): __Observable<__StrictHttpResponse<TradeAmendmentModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tradeOperation;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/trade/update/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TradeAmendmentModel>;
      })
    );
  }
  /**
   * @param tradeOperation undefined
   * @return Returns the Updated Trades
   */
  updateTradeStatus(tradeOperation: TradeOperationModel): __Observable<TradeAmendmentModel> {
    return this.updateTradeStatusResponse(tradeOperation).pipe(
      __map(_r => _r.body as TradeAmendmentModel)
    );
  }

  /**
   * @param params The `SenatorService.UpdateMultipleTradeStatusParams` containing the following parameters:
   *
   * - `tradeOperation`:
   *
   * - `amendmentAction`:
   *
   * @return Returns the Updated Trades
   */
  updateMultipleTradeStatusResponse(params: SenatorService.UpdateMultipleTradeStatusParams): __Observable<__StrictHttpResponse<Array<TradeAmendmentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tradeOperation;
    if (params.amendmentAction != null) __params = __params.set('amendmentAction', params.amendmentAction.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/trade/update/status/multiple`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TradeAmendmentModel>>;
      })
    );
  }
  /**
   * @param params The `SenatorService.UpdateMultipleTradeStatusParams` containing the following parameters:
   *
   * - `tradeOperation`:
   *
   * - `amendmentAction`:
   *
   * @return Returns the Updated Trades
   */
  updateMultipleTradeStatus(params: SenatorService.UpdateMultipleTradeStatusParams): __Observable<Array<TradeAmendmentModel>> {
    return this.updateMultipleTradeStatusResponse(params).pipe(
      __map(_r => _r.body as Array<TradeAmendmentModel>)
    );
  }

  /**
   * @param amdSessionId undefined
   * @return A message will be shown after successfull reverse amendment session.
   */
  reverseTradesAmendmentResponse(amdSessionId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (amdSessionId != null) __params = __params.set('amdSessionId', amdSessionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/senator/trade/update/status/multiple`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param amdSessionId undefined
   * @return A message will be shown after successfull reverse amendment session.
   */
  reverseTradesAmendment(amdSessionId: number): __Observable<string> {
    return this.reverseTradesAmendmentResponse(amdSessionId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param reverseAmendedTrade undefined
   * @return Returns the Updated Reversed Trades
   */
  reverseAmendedTradeResponse(reverseAmendedTrade: ReverseAmendedTrade): __Observable<__StrictHttpResponse<Array<TradeAmendmentModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = reverseAmendedTrade;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/trade/amended/reverse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TradeAmendmentModel>>;
      })
    );
  }
  /**
   * @param reverseAmendedTrade undefined
   * @return Returns the Updated Reversed Trades
   */
  reverseAmendedTrade(reverseAmendedTrade: ReverseAmendedTrade): __Observable<Array<TradeAmendmentModel>> {
    return this.reverseAmendedTradeResponse(reverseAmendedTrade).pipe(
      __map(_r => _r.body as Array<TradeAmendmentModel>)
    );
  }

  /**
   * @param tradeId undefined
   * @return Returns Amended Trade History
   */
  getAmendedTradeHistoryResponse(tradeId: number): __Observable<__StrictHttpResponse<Array<AmendedTradesHistory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (tradeId != null) __params = __params.set('tradeId', tradeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senator/trade/amended/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AmendedTradesHistory>>;
      })
    );
  }
  /**
   * @param tradeId undefined
   * @return Returns Amended Trade History
   */
  getAmendedTradeHistory(tradeId: number): __Observable<Array<AmendedTradesHistory>> {
    return this.getAmendedTradeHistoryResponse(tradeId).pipe(
      __map(_r => _r.body as Array<AmendedTradesHistory>)
    );
  }

  /**
   * @param senatorTrade undefined
   * @return Returns the item saved.
   */
  savePoliticianAssetTradeResponse(senatorTrade: Array<SenatorTrades>): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorTrade;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/politician/save/asset/trade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param senatorTrade undefined
   * @return Returns the item saved.
   */
  savePoliticianAssetTrade(senatorTrade: Array<SenatorTrades>): __Observable<Array<SenatorTrades>> {
    return this.savePoliticianAssetTradeResponse(senatorTrade).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param UnProcessedFile UnProcesssed File Info
   * @return senatorUnProcessedFileDetails
   */
  getUnProcessedFileDetailResponse(UnProcessedFile?: SenatorFilingForTweet): __Observable<__StrictHttpResponse<SenatorFilingDetailForTweet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UnProcessedFile;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senator/get/unprocessed/file/detail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorFilingDetailForTweet>;
      })
    );
  }
  /**
   * @param UnProcessedFile UnProcesssed File Info
   * @return senatorUnProcessedFileDetails
   */
  getUnProcessedFileDetail(UnProcessedFile?: SenatorFilingForTweet): __Observable<SenatorFilingDetailForTweet> {
    return this.getUnProcessedFileDetailResponse(UnProcessedFile).pipe(
      __map(_r => _r.body as SenatorFilingDetailForTweet)
    );
  }
}

module SenatorService {

  /**
   * Parameters for verifySenatorUrl
   */
  export interface VerifySenatorUrlParams {
    url: string;
    congressType: string;
  }

  /**
   * Parameters for getSenatorBiographyByName
   */
  export interface GetSenatorBiographyByNameParams {
    senatorName: string;
    congressType: string;
  }

  /**
   * Parameters for addSenatorAssetFiling
   */
  export interface AddSenatorAssetFilingParams {
    senatorFiling: SenatorFiling;
    congressType: string;
  }

  /**
   * Parameters for saveSenatorAssetFiling
   */
  export interface SaveSenatorAssetFilingParams {
    senatorFiling: SenatorFiling;
    congressType: string;
  }

  /**
   * Parameters for addSenatorFiling
   */
  export interface AddSenatorFilingParams {
    senatorFiling: SenatorFiling;
    congressType: string;
  }

  /**
   * Parameters for saveSenatorFiling
   */
  export interface SaveSenatorFilingParams {
    senatorFiling: SenatorFiling;
    congressType: string;
  }

  /**
   * Parameters for splitFile
   */
  export interface SplitFileParams {
    parts: number;
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for combineFile
   */
  export interface CombineFileParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for splitFileRetainTrades
   */
  export interface SplitFileRetainTradesParams {
    tradesPerFile: number;
    totalTrades: number;
    parts: number;
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for combineFileRetainTrades
   */
  export interface CombineFileRetainTradesParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for deleteSenatorTradesByTradeId
   */
  export interface DeleteSenatorTradesByTradeIdParams {
    tradeId: number;
    congressType: string;
  }

  /**
   * Parameters for deletePoliticianAssetTradesByTradeId
   */
  export interface DeletePoliticianAssetTradesByTradeIdParams {
    tradeId: number;
    congressType: string;
  }

  /**
   * Parameters for deleteSenatorFilingByFilingId
   */
  export interface DeleteSenatorFilingByFilingIdParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for doReplaceTheName
   */
  export interface DoReplaceTheNameParams {
    replacement: SenatorNoGvKeyConfirmation;
    doApplyOnApproved: boolean;
  }

  /**
   * Parameters for getSenatorTradesByFilingId
   */
  export interface GetSenatorTradesByFilingIdParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for getPoliticianAssetTradesByFilingId
   */
  export interface GetPoliticianAssetTradesByFilingIdParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for getSenatorTradesBySenatorCompanyId
   */
  export interface GetSenatorTradesBySenatorCompanyIdParams {
    senatorCompanyId: number;
    byDateFilter?: string;
    assetId?: number;
  }

  /**
   * Parameters for getSenatorTradesByStockOptionListingId
   */
  export interface GetSenatorTradesByStockOptionListingIdParams {
    stockOptionListingId: number;
    byDateFilter?: string;
    assetId?: number;
  }

  /**
   * Parameters for getSenatorTradeByTradeId
   */
  export interface GetSenatorTradeByTradeIdParams {
    tradeId: number;
    congressType: string;
  }

  /**
   * Parameters for unlockFilingById
   */
  export interface UnlockFilingByIdParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for unlockAssetFilingById
   */
  export interface UnlockAssetFilingByIdParams {
    filingId: number;
    congressType: string;
  }

  /**
   * Parameters for getAnalystReports
   */
  export interface GetAnalystReportsParams {
    date: string;
    congressType: string;
  }

  /**
   * Parameters for updateMultipleTradeStatus
   */
  export interface UpdateMultipleTradeStatusParams {
    tradeOperation: Array<TradeOperationModel>;
    amendmentAction: string;
  }
}

export { SenatorService }

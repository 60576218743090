/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TransactionLabel } from '../models/transaction-label';
import { TransactionLabelTimerange } from '../models/transaction-label-timerange';
@Injectable({
  providedIn: 'root',
})
class TransactionLabelService extends __BaseService {
  static readonly getTransactionLabelPath = '/transactionLabel/{category}';
  static readonly updateTransactionLabelPath = '/transactionLabel';
  static readonly createTransactionLabelPath = '/transactionLabel';
  static readonly getTransactionLabelTimerangePath = '/transactionLabel/issuerOrIndividual/{category}';
  static readonly updateTransactionLabelIssuerOrIndividualPath = '/transactionLabel/issuerOrIndividual';
  static readonly createTransactionLabelIssuerOrIndividualPath = '/transactionLabel/issuerOrIndividual';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param category undefined
   * @return A list of Transaction Label.
   */
  getTransactionLabelResponse(category: string): __Observable<__StrictHttpResponse<Array<TransactionLabel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/transactionLabel/${encodeURIComponent(String(category))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TransactionLabel>>;
      })
    );
  }
  /**
   * @param category undefined
   * @return A list of Transaction Label.
   */
  getTransactionLabel(category: string): __Observable<Array<TransactionLabel>> {
    return this.getTransactionLabelResponse(category).pipe(
      __map(_r => _r.body as Array<TransactionLabel>)
    );
  }

  /**
   * @param transactionLabel undefined
   * @return Updated Transaction label will be returned.
   */
  updateTransactionLabelResponse(transactionLabel: TransactionLabel): __Observable<__StrictHttpResponse<TransactionLabel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transactionLabel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/transactionLabel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionLabel>;
      })
    );
  }
  /**
   * @param transactionLabel undefined
   * @return Updated Transaction label will be returned.
   */
  updateTransactionLabel(transactionLabel: TransactionLabel): __Observable<TransactionLabel> {
    return this.updateTransactionLabelResponse(transactionLabel).pipe(
      __map(_r => _r.body as TransactionLabel)
    );
  }

  /**
   * @param transactionLabel undefined
   * @return New Added Transaction label will be returned.
   */
  createTransactionLabelResponse(transactionLabel: TransactionLabel): __Observable<__StrictHttpResponse<TransactionLabel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transactionLabel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/transactionLabel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionLabel>;
      })
    );
  }
  /**
   * @param transactionLabel undefined
   * @return New Added Transaction label will be returned.
   */
  createTransactionLabel(transactionLabel: TransactionLabel): __Observable<TransactionLabel> {
    return this.createTransactionLabelResponse(transactionLabel).pipe(
      __map(_r => _r.body as TransactionLabel)
    );
  }

  /**
   * @param category undefined
   * @return A list of Transaction Label Timerange.
   */
  getTransactionLabelTimerangeResponse(category: string): __Observable<__StrictHttpResponse<Array<TransactionLabelTimerange>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/transactionLabel/issuerOrIndividual/${encodeURIComponent(String(category))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TransactionLabelTimerange>>;
      })
    );
  }
  /**
   * @param category undefined
   * @return A list of Transaction Label Timerange.
   */
  getTransactionLabelTimerange(category: string): __Observable<Array<TransactionLabelTimerange>> {
    return this.getTransactionLabelTimerangeResponse(category).pipe(
      __map(_r => _r.body as Array<TransactionLabelTimerange>)
    );
  }

  /**
   * @param transactionLabelTimeRange undefined
   * @return Updated Transaction label timerange will be returned.
   */
  updateTransactionLabelIssuerOrIndividualResponse(transactionLabelTimeRange: TransactionLabelTimerange): __Observable<__StrictHttpResponse<TransactionLabelTimerange>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transactionLabelTimeRange;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/transactionLabel/issuerOrIndividual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionLabelTimerange>;
      })
    );
  }
  /**
   * @param transactionLabelTimeRange undefined
   * @return Updated Transaction label timerange will be returned.
   */
  updateTransactionLabelIssuerOrIndividual(transactionLabelTimeRange: TransactionLabelTimerange): __Observable<TransactionLabelTimerange> {
    return this.updateTransactionLabelIssuerOrIndividualResponse(transactionLabelTimeRange).pipe(
      __map(_r => _r.body as TransactionLabelTimerange)
    );
  }

  /**
   * @param transactionLabelTimeRange undefined
   * @return New Added Transaction label timerange will be returned.
   */
  createTransactionLabelIssuerOrIndividualResponse(transactionLabelTimeRange: TransactionLabelTimerange): __Observable<__StrictHttpResponse<TransactionLabelTimerange>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = transactionLabelTimeRange;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/transactionLabel/issuerOrIndividual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionLabelTimerange>;
      })
    );
  }
  /**
   * @param transactionLabelTimeRange undefined
   * @return New Added Transaction label timerange will be returned.
   */
  createTransactionLabelIssuerOrIndividual(transactionLabelTimeRange: TransactionLabelTimerange): __Observable<TransactionLabelTimerange> {
    return this.createTransactionLabelIssuerOrIndividualResponse(transactionLabelTimeRange).pipe(
      __map(_r => _r.body as TransactionLabelTimerange)
    );
  }
}

module TransactionLabelService {
}

export { TransactionLabelService }

/* tslint:disable */
type ShortDisclosureRegion =
  'Europe' |
  'Asia' |
  'America' |
  'Oceania';
module ShortDisclosureRegion {
  export const EUROPE: ShortDisclosureRegion = 'Europe';
  export const ASIA: ShortDisclosureRegion = 'Asia';
  export const AMERICA: ShortDisclosureRegion = 'America';
  export const OCEANIA: ShortDisclosureRegion = 'Oceania';
  export function values(): ShortDisclosureRegion[] {
    return [
      EUROPE,
      ASIA,
      AMERICA,
      OCEANIA
    ];
  }
}

export { ShortDisclosureRegion }
/* tslint:disable */
type PriceSource =
  'Source' |
  'Manual' |
  'Compustat';
module PriceSource {
  export const SOURCE: PriceSource = 'Source';
  export const MANUAL: PriceSource = 'Manual';
  export const COMPUSTAT: PriceSource = 'Compustat';
  export function values(): PriceSource[] {
    return [
      SOURCE,
      MANUAL,
      COMPUSTAT
    ];
  }
}

export { PriceSource }
/* tslint:disable */

/**
 * This enum contains status of users
 */
type Status =
  'Active' |
  'Inactive';
module Status {
  export const ACTIVE: Status = 'Active';
  export const INACTIVE: Status = 'Inactive';
  export function values(): Status[] {
    return [
      ACTIVE,
      INACTIVE
    ];
  }
}

export { Status }
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  CongressType,
  Politician,
  SenatorAmountRanges,
  SenatorAssetType,
  SenatorCompany,
  SenatorFiling,
  SenatorNoGvKeyConfirmation,
  SenatorOwnerTypes,
  SenatorTrades,
  SenatorTransactionTypes
} from '../../../api/models';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SenatorService} from '../../../api/services/senator.service';
import {Subscription} from 'rxjs';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {SnackbarMessageService} from '../services/snackbar-message.service';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import GetSenatorTradesByFilingIdParams = SenatorService.GetSenatorTradesByFilingIdParams;
import DeleteSenatorTradesByTradeIdParams = SenatorService.DeleteSenatorTradesByTradeIdParams;
/*import AddSenatorTradeParams = SenatorService.AddSenatorTradeParams;
import SaveSenatorTradeParams = SenatorService.SaveSenatorTradeParams;*/
import DoReplaceTheNameParams = SenatorService.DoReplaceTheNameParams;
import UnlockFilingByIdParams = SenatorService.UnlockFilingByIdParams;


interface SenatorTradesExtended extends SenatorTrades {
  isNew: boolean;
}

export interface TradeSearchFilter {
  tradeType?: Array<SenatorTransactionTypes>;
  owner?: Array<SenatorOwnerTypes>;
  assetType?: Array<SenatorAssetType>;
  amount?: Array<SenatorAmountRanges>;
}


@Component({
  selector: 'app-file-processing',
  templateUrl: './focused-file-issuer-mapping.component.html',
  styleUrls: ['./focused-file-issuer-mapping.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FocusedFileIssuerMappingComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {

  file: SenatorFiling;
  fileForm: FormGroup;
  tradeForm: FormGroup;
  congressType: string;
  allPoliticians: Politician[];
  politiciansNames: string [];

  tradeSearchFilters: TradeSearchFilter;
  isFileUpdating = false;
  tradesLoading = false;
  assetNamesLoading: boolean;
  twoIQAssetsList: SenatorCompany[] = [];

  selectedTrades: SenatorTrades[];
  selectedTradesToProcess: SenatorTrades[] = [];
  totalTradesProcessedNum: number;

  isAllTradesProcessed: boolean;

  newAddedTradesCount: number;
  deletedTradesSuccessCount: number;
  deletedTradesUnSuccessCount: number;
  publishedTradesUnSuccessCount: number;
  publishedTradesSuccessCount: number;
  mappedIssuersSuccessCount: number;
  mappedIssuersUnSuccessCount: number;
  addedTradesSuccessCount: number;
  addedTradesUnSuccessCount: number;
  publishedTradesCount: number;
  unpublishedTradesCount: number;
  savedTradesCount: number;

  actionToPerform: string;
  activeRow: SenatorTrades;
  isIssuerNameSearched: boolean;

  tradeTransactionMinDate: Date;
  tradeTransactionMaxDate: Date;
  issuerSelectionCount: number;
  maxTransactionDate: Date;
  maxFilingDate: Date;
  isFormValid: boolean;
  formOperation: string;
  issuerIndex: number;
  selectedRowForMergeIssuer: any;
  reportType: string;

  // service subscriptions
  messageSubscription: Subscription;

  // material table configurations
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  tradesTableDisplayedColumns: string[] = ['select',
    'issuerName', 'ticker',];
  multipleSelectionHeader = ['msCheck', 'msIssuer', 'msTickerAndGvkey'];
  tradesDataSource: MatTableDataSource<SenatorTrades>;
  selection = new SelectionModel<SenatorTrades>(true, []);
  pageSize: number;

  pageNumber: number;
  pageSizeOptions: number[];
  totalEntries: number;
  pageEvent: PageEvent;

  isFileTradesProcessedFirstTime: boolean;
  readonly maxPageSize = 100;
  allSelectedOfCurrentPage: boolean;

  securityTypes: SenatorAssetType [];
  transactionTypes: SenatorTransactionTypes [];
  amounts: SenatorAmountRanges[];
  owners: SenatorOwnerTypes [];

  // router params
  paramCongressType: CongressType;
  paramFilingId: number;


  private readonly DATE_FORMAT = 'MM/dd/yyyy';
  allTrades = [] as SenatorTradesExtended[];

  constructor(
    private matDialog: MatDialog, private cdr: ChangeDetectorRef,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _senatorService: SenatorService,
    public snackBar: MatSnackBar,
    private snackbarMessageService: SnackbarMessageService,
  ) {
    // console.log("File processing constructor");
    this.isFileTradesProcessedFirstTime = true;

    this.messageSubscription = this.snackbarMessageService.getSnackBarMessage().subscribe(res => {
      // console.log('Snack bar message', res);
      this.assignDummyGvkey(res);
    });
  }

  get tradeTableRowArray(): FormArray {
    return this.tradeForm.get('tableRowArray') as FormArray;
  }

  ngOnInit() {
    this._activatedRoute.paramMap
      .subscribe(params => {
          this.paramCongressType = params['params']['congressType'];
          this.paramFilingId = params['params']['filingId'];
        }
      );

    this.initVariables();
    this.createTradeForm();
    this.getTradesByFilingId();
    this.onChangesForm();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();

  }

  ngAfterViewInit() {
    if (this.tradesDataSource) {
      // this.tradesDataSource.paginator = this.paginator;
      // this.tradesDataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
    this.unlockFile();
  }

  unlockFile() {
    const params: UnlockFilingByIdParams = {
      congressType: this.paramCongressType,
      filingId: this.paramFilingId
    };
    this._senatorService.unlockFilingById(params).subscribe((res) => {

      // console.log('res', res);
    }, (err) => {
      console.log('file Unlock Error:');
      console.log('err', err);
    }, () => {
      // this.openSnackBar(`${filingId} File has been Unlocked Successfully!`, 'Close');
    });

  }

  initVariables() {

    this.transactionTypes = SenatorTransactionTypes.values();
    this.owners = SenatorOwnerTypes.values();
    this.securityTypes = SenatorAssetType.values();
    this.amounts = SenatorAmountRanges.values();


    this.tradeSearchFilters = {
      tradeType: [],
      owner: [],
      assetType: [],
      amount: []
    };

    if (this.isFileTradesProcessedFirstTime) {
      this.pageSize = 10;
      this.pageNumber = 1;
      localStorage.setItem('tradesPageSize', this.pageSize.toString());
      localStorage.setItem('tradesPageNumber', this.pageNumber.toString());
    } else {

      this.pageSize = Number(localStorage.getItem('tradesPageSize'));
      this.pageNumber = Number(localStorage.getItem('tradesPageNumber'));

    }
    this.pageSizeOptions = [10, 20, 500, 1000];
    this.allSelectedOfCurrentPage = false;

    this.totalTradesProcessedNum = 0;

    this.deletedTradesSuccessCount = 0;
    this.deletedTradesUnSuccessCount = 0;
    this.isAllTradesProcessed = false;
    this.newAddedTradesCount = 0;

    this.publishedTradesUnSuccessCount = 0;
    this.publishedTradesSuccessCount = 0;
    this.addedTradesSuccessCount = 0;
    this.addedTradesUnSuccessCount = 0;
    this.publishedTradesCount = 0;
    this.unpublishedTradesCount = 0;
    this.savedTradesCount = 0;

    this.assetNamesLoading = false;
    this.isIssuerNameSearched = false;
    this.issuerSelectionCount = 1;

    this.tradeTransactionMinDate = new Date(2010, 0, 1);
    this.tradeTransactionMaxDate = new Date();

    this.maxTransactionDate = new Date();
    this.maxFilingDate = new Date();

    this.isFormValid = false;
    this.formOperation = 'init';

  }

  getActualIndex(index: number) {
    return index + this.paginator.pageSize * this.paginator.pageIndex;
  }

  createTradeForm() {
    this.tradeForm = this.fb.group({
      tableRowArray: this.fb.array([])
    });
  }

  initTradeFrom() {
    this.tradesDataSource.data.forEach((trade, index) => {
      this.tradeTableRowArray.push(this.createTradeRow(trade));
    });
  }

  createTradeRow(trade: SenatorTrades):
    FormGroup {
    return this.fb.group({
      filingId: [trade.filingId],
      tradeId: [trade.tradeId],
      tradeIndex: [trade.tradeIndex || ''],
      transactionDate: [new Date(trade.transactionDate) || '', [Validators.required, this.transactionDateValidator.bind(this)]],
      transactionType: [trade.transactionType, Validators.required],
      assetName: [trade.assetName || ''],
      x2iqAssetName: [trade.x2iqAssetName || '', Validators.required],
      gvkey: [trade.gvkey, [Validators.required]],
      assetType: [(trade.tradeId === -1) ? 'Stock' :
        (trade.assetType !== null) ? trade.assetType :
          (trade.assetType === null && trade.gvkey === null) ? 'Other Securities' :
            (trade.assetType === null && trade.gvkey !== null) ? 'Stock' : null, Validators.required],
      owner: [trade.owner || 'Not Disclosed', Validators.required],
      ticker: [trade.ticker || ''],
      amount: [trade.amount || '',
        [Validators.required, Validators.pattern('^((\\$[0-9,]+\\s-\\s\\$[0-9,]+)?|(\\$[0-9.]+)?|(.*?Over \\$[0-9,]+)?)$')]],
      comment: [trade.comment || ''],
      sourceUrl: [trade.sourceUrl],
      isApproved: [trade.isApproved],
      isCapitalGain: [(this.congressType === 'Senator') ? false :
        (String(trade.isCapitalGain) === 'No' || String(trade.isCapitalGain) === 'no') ? false :
          (String(trade.isCapitalGain) === 'Yes' || String(trade.isCapitalGain) === 'yes') ? true : trade.isCapitalGain, Validators.required]
    });
  }

  transactionDateValidator(control: FormControl) {
    const transactionDate = new Date(control.value);
    const minDate = new Date('01/01/2000');

    if (transactionDate > this.maxTransactionDate || transactionDate < minDate) {
      return {
        dateError: {
          value: 'invalid'
        }
      };
    }
    return null;
  }

  filingDateValidator(control: FormControl) {
    const currentValue = new Date(control.value);

    if (currentValue > this.maxFilingDate) {
      return {
        dateError: {
          value: 'invalid'
        }
      };
    }
    return null;
  }

  getTradesByFilingId() {
    this.tradesLoading = true;

    const params: GetSenatorTradesByFilingIdParams = {
      filingId: this.paramFilingId,
      congressType: this.paramCongressType
    };

    let trades = [] as SenatorTrades[];
    this._senatorService.getSenatorTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      trades = res;
    }, (err) => {
      console.log(err);
      this.openSnackBar(err.error.message, 'Close');
      this.tradesLoading = false;
      // this.matDialogRef.close(false);

    }, () => {
      this.tradesLoading = false;
      this.totalEntries = trades.length;
      this.allTrades = trades.map(t => {
        const trade = t as SenatorTradesExtended;
        trade.isNew = false;
        return trade;
      });
      this.tradesDataSource = new MatTableDataSource<SenatorTradesExtended>(this.allTrades);
      this.tradesDataSource.paginator = this.paginator;
      this.tradesDataSource.sort = this.sort;
      this.initTradeFrom();
    });
  }

  onPaginateChange(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;

    localStorage.setItem('tradesPageSize', String(this.pageSize));
    localStorage.setItem('tradesPageNumber', String(this.pageNumber));

    // configure selection variables
    if (this.isAllSelectedOfCurrentPage()) {
      this.allSelectedOfCurrentPage = true;
      this.validateSelectedForm();
    } else {
      this.allSelectedOfCurrentPage = false;
    }
  }

  getPageSizeOptions(): number[] {
    const arr = [10, 20, this.maxPageSize];
    if (this.tradesDataSource == null || this.tradesDataSource.paginator == null) {
      return arr;
    }
    if (this.tradesDataSource.paginator.length > this.maxPageSize) {
      return [10, 20, 100, this.tradesDataSource.paginator.length];
    }
    return arr;
  }


  searchAssetNames(companyName: string, trade: SenatorTrades, selection: string) {
    this.activeRow = trade;
    this.assetNamesLoading = true;
    this._senatorService.getSenatorAssetNames(companyName).subscribe((res: SenatorCompany[]) => {
      this.twoIQAssetsList = res;
    }, (err) => {
      this.assetNamesLoading = false;
      if (err.status === 400 || err.status === 500) {
        this.openSnackBar('Something went wrong! please enter a valid company name.', 'Close');
      } else {
        this.openSnackBar(err.error.message, 'Close');
      }

    }, () => {
      this.assetNamesLoading = false;
      if (this.twoIQAssetsList.length === 0) {
        this.snackBar.openFromComponent(SnackBar2Component, {
          data: {
            message: 'No Company found,Populate this text and assign dummy gvkey',
            selection: selection,
            text: companyName,
            index: this.issuerIndex
          },
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom'
        });
      }
    });
  }

  mapCompanyWith2IQ(event, company: SenatorCompany, row: any, index: number
  ) {

    if (event.source.selected) {

      (<FormArray>this.tradeForm.controls['tableRowArray']).at(index).get('gvkey').setValue(company.gvkey);
      if (company.ticker !== null) {
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(index).get('ticker').setValue(company.ticker);
      } else {
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(index).get('ticker').setValue('--');
      }
    }

  }

  onMergeClick() {
    const mergeConfirmDialog = this.matDialog.open(MergeIssuerDialog2Component, {
      width: '550px',
      height: '300px',
      data: {
        trade: this.selectedRowForMergeIssuer
      }
    });
    mergeConfirmDialog.afterClosed().subscribe((res) => {
      switch (res) {
        case 'merge':
          this.mergeIssuer();
          break;
        case 'close':
          break;
      }
    });


  }

  mergeIssuer() {
    this.resetVars();

    this.actionToPerform = 'mapIssuer';
    this.selectedTradesToProcess.forEach((trade, index) => {
      const currTrade = trade[0];
      // this.mapIssuer(currTrade);
      if (index + 1 === this.selection.selected.length) {
        this.isAllTradesProcessed = true;
      }
    });
  }

  // mapIssuer(trade: SenatorTrades) {
  //
  //   const replacement: SenatorNoGvKeyConfirmation = {
  //     assetName: trade.assetName,
  //     x2iqAssetName: trade.x2iqAssetName,
  //     gvkey: trade.gvkey
  //   };
  //
  //   const params: DoReplaceTheNameParams = {
  //     replacement: replacement,
  //     doApplyOnApproved: false
  //   };
  //
  //   this._senatorService.doReplaceTheName(params).subscribe((res: number) => {
  //
  //     this.mappedIssuersSuccessCount += 1;
  //     this.totalTradesProcessedNum += 1;
  //
  //   }, (err) => {
  //     console.log('Trade Publish Error', err);
  //     this.openSnackBar(err.error.message, 'Close');
  //     this.mappedIssuersUnSuccessCount += 1;
  //     this.totalTradesProcessedNum += 1;
  //
  //   }, () => {
  //     this.afterAllTradesProcessing();
  //
  //   });
  // }

  onSaveClick() {

    this.resetVars();
    console.log(' this.selectedTradesToProcess.', this.selectedTradesToProcess);
    console.log(' this.selectedTradesToProcess.', this.selectedTradesToProcess);

    this.actionToPerform = 'save';

    this.selectedTradesToProcess = this.selectedTradesToProcess.map(
      trade => {
        trade.filingId = this.paramFilingId;
        trade.sourceUrl = this.selectedTradesToProcess[0].sourceUrl;
        if (trade.transactionDate == null) {
          trade.transactionDate = this.datePipe.transform('01/01/1970', this.DATE_FORMAT);
        } else {
          trade.transactionDate = this.datePipe.transform(new Date(trade.transactionDate), this.DATE_FORMAT);
        }
        if (trade.transactionType == null) {
          trade.transactionType = 'Sale';
        }
        trade.isApproved = false;
        return trade;
      }
    );

    this.addAndUpdateTrades(this.selectedTradesToProcess);
  }

  deleteSelectedTrades() {
    this.resetVars();
    this.actionToPerform = 'delete';
    // sending trades to the server for processing
    this.selectedTrades.forEach((trade, index) => {
      // console.log(' this.selectedTradesToPublish trade: ', trade);
      this.deleteTrade(trade);
      if (index + 1 === this.selection.selected.length) {
        this.isAllTradesProcessed = true;
      }
    });

  }

  deleteTrade(trade: any
  ) {
    this.tradesLoading = true;

    const params: DeleteSenatorTradesByTradeIdParams = {
      tradeId: trade.tradeId,
      congressType: this.congressType
    };

    this._senatorService.deleteSenatorTradesByTradeId(params).subscribe((res: boolean) => {
      // console.log('File update response', res);
      this.deletedTradesSuccessCount += 1;
      this.totalTradesProcessedNum += 1;
    }, (err) => {
      if (err.status === 404) {
        this.snackBar.open('Error!, Action Not Available yet', 'Close');
        this.tradesLoading = false;
      } else {
        this.snackBar.open(err.error.message, 'Close');
        this.deletedTradesUnSuccessCount += 1;
        this.totalTradesProcessedNum += 1;
      }
      console.log('Trades Delete Error', err);

    }, () => {
      this.tradesLoading = false;
      this.afterAllTradesProcessing();
    });

  }

  resetVars() {
    this.selectedTrades = this.selection.selected;
    this.selectedTradesToProcess = [];
    this.actionToPerform = '';

    this.totalTradesProcessedNum = 0;
    this.isAllTradesProcessed = false;

    this.publishedTradesSuccessCount = 0;
    this.publishedTradesUnSuccessCount = 0;
    this.addedTradesUnSuccessCount = 0;
    this.addedTradesSuccessCount = 0;
    this.deletedTradesSuccessCount = 0;
    this.deletedTradesUnSuccessCount = 0;
    this.mappedIssuersSuccessCount = 0;
    this.mappedIssuersUnSuccessCount = 0;

    // console.log('selected trades: ', this.selectedTrades);


    this.selectedTradesToProcess = this.tradeTableRowArray.controls
      .filter(
        trade => this.selectedTrades.find(indexing => indexing.tradeIndex == trade.value.tradeIndex) != null
      ).map(t => {
        return t.value as SenatorTrades;
      });
    // console.log('selected trades to process: ', this.selectedTradesToProcess);
  }

  assignDummyGvkey(data: any) {
    // console.log('assignDummyGvkey==> data:', data.data.text);
    switch (data.data.selection) {
      case 'single' :
        const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(this.issuerIndex);
        // console.log(tradeIndex);
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('x2iqAssetName').setValue(data.data.text);
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('gvkey').setValue('000000');
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('ticker').setValue('--');
        break;
      case 'multiple' :
        this.selection.selected.forEach(trade => {
          // tslint:disable-next-line:no-shadowed-variable
          const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
          // console.log(tradeIndex);
          (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('x2iqAssetName').setValue(data.data.text);
          (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('gvkey').setValue('000000');
          (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('ticker').setValue('--');
        });
        break;
    }
  }

  resetGvkey(row: SenatorTrades) {
    this.issuerIndex = row.tradeIndex;
    const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(this.issuerIndex);
    (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('gvkey').setValue(null);
    (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('ticker').setValue('--');

    this.selectedRowForMergeIssuer = (<FormGroup>(<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex)).controls;

  }

  setIndexForDummyGvkey(row: SenatorTrades) {
    // console.log('Enter row: ', row);

    this.issuerIndex = row.tradeIndex;
  }

  searchAndReplaceMultipleIssuers(event, company) {
    if (event.source.selected) {
      // console.log('event :', event);
      // console.log('company :', company);
      this.selection.selected.forEach(trade => {
        const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
        // console.log(tradeIndex);
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('x2iqAssetName').setValue(company.name);
        (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('gvkey').setValue(company.gvkey);
        if (company.ticker !== null) {
          (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('ticker').setValue(company.ticker);
        } else {
          (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get('ticker').setValue('--');
        }
      });
    }
  }

  replaceAtMultipleRows(value, action: string
  ) {
    // console.log('Replace multiple rows:', value, action)
    let selectedFiled;
    switch (action) {
      case 'transactionType':
        selectedFiled = 'transactionType';
        break;
      case 'owner':
        selectedFiled = 'owner';
        break;
      case 'securityType':
        selectedFiled = 'assetType';
        break;
      case 'amount':
        selectedFiled = 'amount';
        break;
      case 'capitalGain':
        selectedFiled = 'isCapitalGain';
        break;
      case 'comment':
        selectedFiled = 'comment';
        break;
      case 'transactionDate':
        selectedFiled = 'transactionDate';
        break;
    }
    // debugger;
    // console.log('selection:', this.selection.selected);
    /* while (this.tradeTableRowArray.length !== 0) {
       this.tradeTableRowArray.removeAt(0)
     }
     this.createTradeForm();
     this.initTradesFrom();*/
    this.selection.selected.forEach(trade => {
      // tslint:disable-next-line:no-shadowed-variable
      const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(trade.tradeIndex);
      (<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex).get(selectedFiled).setValue(value);
      // console.log(this.tradeTableRowArray);
    });
    this.table.renderRows();
    this.validateSelectedForm();

  }

  afterAllTradesProcessing() {
    if (this.totalTradesProcessedNum === this.selection.selected.length) {
      // console.log('publishTrade()--> this.isAllTradesProcessed', this.isAllTradesProcessed);
      // console.log('publishTrade()--> this.successfullyPublishedTradesNum', this.successfullyPublishedTradesNum);

      // console.log('afterAllTradesProcessing Action Name', this.actionToPerform);

      let successMessage;
      let unSuccessMessage;
      switch (this.actionToPerform) {
        case 'publish' :
          if (this.totalTradesProcessedNum === 1) {
            successMessage = `1  trade has been published successfully!`;
          } else {
            successMessage = `${this.publishedTradesSuccessCount} trades has been published successfully!`;
            unSuccessMessage = `${this.publishedTradesSuccessCount} Trades has been published successfully!
                  ${this.publishedTradesUnSuccessCount} trades has not yet published!`;
          }
          break;

        case 'save' :
          if (this.totalTradesProcessedNum === 1) {
            successMessage = `1 trade has been saved successfully!`;
          } else {
            successMessage = `${this.addedTradesSuccessCount} trades has been saved successfully!`;
            unSuccessMessage = `${this.addedTradesSuccessCount} trades has been saved successfully!
                  ${this.addedTradesUnSuccessCount} trades has not yet saved!`;
          }
          break;

        case 'delete' :
          if (this.totalTradesProcessedNum === 1) {
            successMessage = `1 trade has been deleted successfully!`;
          } else {
            successMessage = `${this.deletedTradesSuccessCount} trades has been deleted successfully!`;
            unSuccessMessage = `${this.deletedTradesSuccessCount} trades has been Deleted successfully!
                  ${this.deletedTradesUnSuccessCount} trades has not yet Deleted!`;
          }
          break;

        case 'mapIssuer' :
          if (this.totalTradesProcessedNum === 1) {
            successMessage = `1 issuer has been mapped successfully!`;
          } else {
            successMessage = `${this.mappedIssuersSuccessCount} Issuers has been mapped successfully!`;
            unSuccessMessage = `${this.mappedIssuersSuccessCount} Issuers has been mapped successfully!
                  ${this.mappedIssuersUnSuccessCount} issuers has not yet mapped!`;
          }
          break;
      }

      switch (this.actionToPerform) {

        case 'publish':
          if ((this.publishedTradesUnSuccessCount) > 1) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            });
          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            });
          }

          break;

        case 'save':

          if (this.addedTradesUnSuccessCount > 1) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center'
            });
          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center'
            });
          }
          break;

        case 'delete':

          if (this.deletedTradesUnSuccessCount > 1) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });

          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          }
          break;

        case 'mapIssuer':
          if (this.mappedIssuersUnSuccessCount > 1) {
            this.snackBar.open(unSuccessMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          } else {
            this.snackBar.open(successMessage, 'Close', {
              duration: 10000,
              verticalPosition: 'bottom',
              horizontalPosition: 'right'
            });
          }
          break;
      }
      this.formOperation = 'update';
      //this.getTradesByFilingId();
      this.selection.clear();
      this.isFileTradesProcessedFirstTime = false;
      this.ngOnInit();
    }
  }


  enableFileForm() {
    this.isFileUpdating = false;
    this.fileForm.enable();
  }

  disableFileForm() {
    this.isFileUpdating = true;
    this.fileForm.disable();
  }

  openSnackBar(message: string, action: string
  ) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  setRowDataForMergeIssuer() {
    const index = this.selection.selected[0].tradeIndex;
    const tradeIndex = this.tradeTableRowArray.value.map(value => value.tradeIndex).indexOf(index);
    this.selectedRowForMergeIssuer = (<FormGroup>(<FormArray>this.tradeForm.controls['tableRowArray']).at(tradeIndex)).controls;
  }

  onCloseFileClicked() {
    // Reset the local storage and other variable before close the file.
    this.isFileTradesProcessedFirstTime = true;
    localStorage.removeItem('tradesPageSize');
    localStorage.removeItem('tradesPageNumber');

    // navigate the home page.
    this.router.navigate(['/politicians/files',]);
  }

  validateSelectedForm() {
    // console.log('On Change detect')
    this.isFormValid = this.tradeTableRowArray.controls
      .filter(a => this.selection.selected.findIndex(trade => trade.tradeIndex == a.value.tradeIndex) != -1)
      .findIndex(
        control => control.status == 'INVALID'
      ) == -1;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    if (this.tradesDataSource == null || this.tradesDataSource.data === null) {
      return false;
    }
    return this.selection.selected.length === this.tradesDataSource.data.length;
  }

  isAllSelectedOfCurrentPage(): boolean {
    if (this.tradesDataSource == null || this.tradesDataSource.data === null) {
      return false;
    }
    const filteredRows: SenatorTrades[] = this.tradesDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    return filteredRows.every(row => this.selection.isSelected(row));
  }

  isSomeSelectedOfCurrentPage(): boolean {
    if (this.tradesDataSource == null || this.tradesDataSource.data === null) {
      return false;
    }
    const filteredRows: SenatorTrades[] = this.tradesDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    return filteredRows.filter(row => this.selection.isSelected(row) === true).length > 0
      && !this.allSelectedOfCurrentPage;
  }

  updateAllComplete() {
    const filteredRows: SenatorTrades[] = this.tradesDataSource.data.filter((row, index) => {
      return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
        && index < (this.pageSize * this.pageNumber));
    });
    this.allSelectedOfCurrentPage = this.tradesDataSource.data !== null &&
      filteredRows.filter(row => this.selection.isSelected(row) === true).length === this.pageSize;
  }

  /** Selects all the rows of current page if not selected, otherwise clear selection. */
  setAllOfCurrentPage(completed: boolean) {
    this.allSelectedOfCurrentPage = completed;
    if (this.tradesDataSource.data === null) {
      return;
    }
    // if any single entry is selected at any page, do validate the selected entries.

    if (!completed) {
      const filteredRows: SenatorTrades[] = this.tradesDataSource.data.filter((row, index) => {
        return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
          && index < (this.pageSize * this.pageNumber));
      });
      filteredRows.forEach((row: SenatorTrades) => {
        this.selection.deselect(row);
      });
    } else {
      const filteredRows: SenatorTrades[] = this.tradesDataSource.data.filter((row, index) => {
        return (index >= ((this.pageSize * this.pageNumber) - this.pageSize)
          && index < (this.pageSize * this.pageNumber));
      });
      filteredRows.forEach((row: SenatorTrades) => {
        this.selection.select(row);
      });
      this.validateSelectedForm();
    }
  }

  setAll(completed: boolean) {
    if (this.tradesDataSource.data === null) {
      return;
    }
    if (completed) {
      this.tradesDataSource.data.forEach((row, index) => {
        this.selection.select(row);
        this.validateSelectedForm();
      });
    } else {
      this.allSelectedOfCurrentPage = false;
      this.selection.clear();
    }

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row ?: SenatorTrades)
    :
    string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }

  onChangesForm() {
    this.tradeTableRowArray.valueChanges.subscribe(val => {
      if (this.selection.selected.length > 0) {
        this.validateSelectedForm();
      }
    });
  }

  isRowSelected(row: SenatorTrades) {
    return this.selection.selected.find(element => element.tradeIndex === row.tradeIndex);
  }

  _filter(value: string):
    string[] {
    const filterValue = value.toLowerCase();
    return this.politiciansNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  _getFormArrayItemControl(i: number, controlName: string) {
    return ((<FormGroup>(<FormArray>this.tradeForm.get('tableRowArray')).controls[i]).controls[controlName]);
  }

  _getFormArrayControl(i: number) {
    return ((<FormArray>this.tradeForm.get('tableRowArray')).controls[i]);
  }

  validateFullName(officeName: string): boolean {
    if (this.allPoliticians == null && officeName != null)
      return true;

    return this.allPoliticians.map(politician => politician.name).filter(t => t == officeName).length != 1;
  }

  publishedOnly() {
    // this.tradesDataSource.data = this.allTrades.filter(t => t.isApproved == true);
  }

  savedOnly() {
    // this.tradesDataSource.data = this.allTrades.filter(t => t.isApproved == false && t.lastUpdatedOn != null);
  }


  savedOnlyCount(): number {
    return this.allTrades.filter(t => !t.isApproved && t.lastUpdatedOn !== null && t.lastUpdatedBy !== null && !t.isNew).length;
  }

  totalTrades(refresh = false) {
    // this.tradesDataSource.data = this.allTrades;
    /*  if (refresh) {
        this.tradesLoading = true;
        let res = [] as SenatorTrades[];
        this._senatorService.getSenatorTradesByFilingId({
            filingId: this.file.filingId,
            congressType: this.congressType
          }
        ).subscribe(value => {
          res = value;
        }, error => {
          this.tradesLoading = false;

        }, () => {
          this.tradesLoading = false;
          this.allTrades = res.map(trade => {
            const a = trade as SenatorTradesExtended;
            a.isNew = false;
            return a;
          });
          this.tradesDataSource.data = this.allTrades;
        });
      }*/
  }


  totalTradesCount() {
    return this.allTrades.length;
  }

  getFormGroupByIndex(index: number): FormGroup {
    return <FormGroup>this.tradeTableRowArray.controls[index];
  }

  private addAndUpdateTrades(list: SenatorTrades[]) {
   /* let saved_count = 0;
    const addParams: AddSenatorTradeParams = {
      senatorTrade: list.filter(
        trade => trade.tradeId == -1
      ),
      congressType: this.paramCongressType
    };

    let res = [] as SenatorTrades[];
    this._senatorService.addSenatorTrade(addParams).subscribe((value: SenatorTrades[]) => {
      res = value;
    }, (err) => {
      this.openSnackBar(err.error.message, 'Close');

    }, () => {
      saved_count = saved_count + res.length;
      if (this.actionToPerform === 'publish') {
        this.publishedTradesSuccessCount = res.length;
      } else if (this.actionToPerform === 'save') {
        this.addedTradesSuccessCount = res.length;
      }
      this.totalTradesProcessedNum = res.length;
      const updateParams: SaveSenatorTradeParams = {
        senatorTrade: list.filter(trade => trade.tradeId != -1),
        congressType: this.paramCongressType
      };

      // console.log('Update trade: params: ', params);
      this._senatorService.saveSenatorTrade(updateParams).subscribe((value: SenatorTrades[]) => {
        res = value;
      }, (err) => {
        this.openSnackBar(err.error.message, 'Close');
      }, () => {
        // console.log('Update trade: params: ');
        saved_count = saved_count + res.length;
        if (this.actionToPerform === 'publish') {
          this.publishedTradesSuccessCount = res.length;
        } else if (this.actionToPerform === 'save') {
          this.addedTradesSuccessCount = res.length;
        }
        this.totalTradesProcessedNum = res.length;
        if (saved_count > 0) {
          this.openSnackBarByTimeOut(saved_count + ' trades are ' + this.actionToPerform + 'd successfully.', 'Close', 5000);
        } else {
          this.openSnackBarByTimeOut('No trade is saved. Check validity.', 'Close', 5000);
        }
        this.refreshTheDialog();
      });
    });*/
  }

  private openSnackBarByTimeOut(message: string, action: string, closeDuration: number) {
    this.snackBar.open(message, action, {
      duration: closeDuration,
      panelClass: ['green-snackbar']
    });
  }

  private refreshTheDialog() {
    this.selection.clear();
    this.createTradeForm();
    this.getTradesByFilingId();
    this.onChangesForm();
  }
}

@Component({
  selector: 'app-snack-bar-2-component',
  template: `<span class="example-pizza-party">
  {{data?.message}}
</span>
  <div style="margin-top: 5px" fxLayout fxLayoutAlign="flex-end" fxLayoutGap="10px">
    <button mat-raised-button
            color="primary"
            (click)="snackBarRef.dismiss();assignDummyGvkey()">yes
    </button>
    <button mat-raised-button
            color="accent"
            (click)="snackBarRef.dismiss()">close
    </button>
  </div>
  `
})
export class SnackBar2Component {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBar2Component>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private messageService: SnackbarMessageService) {
  }

  assignDummyGvkey() {

    this.messageService.assignDummyGvkey(this.data);

  }
}

@Component({
  selector: 'app-merge-issuer-2-dialog',
  template: `
    <mat-card-header fxLayout fxLayoutAlign="space-between">
      <mat-card-subtitle>Merge Issuer</mat-card-subtitle>
    </mat-card-header>
    <div mat-dialog-content>
      <p>Are you sure, you want to replace <b>{{data.trade?.assetName?.value}}</b> with
        <b>{{data.trade?.x2iqAssetName?.value}}</b> ?
      </p>
      <mat-divider></mat-divider>
      <h5 style="color:red">Warning!</h5>
      <p>This will Map <b>{{data.trade?.assetName?.value }}</b> with <b>{{data?.trade?.x2iqAssetName?.value}}</b> in all
        unpublished files.</p>
      <p>Any unsaved changes will be lost in this file.</p>
    </div>

    <!--  <mat-progress-spinner class="progressSpinner" *ngIf="fileDeleting"
                            [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>-->


    <div mat-dialog-actions align="end">
      <button [mat-dialog-close]="'close'" mat-button (click)="onNoClick()">Cancel</button>
      <button [mat-dialog-close]="'merge'" mat-raised-button color="primary">Merge</button>
    </div>`,
})
export class MergeIssuerDialog2Component implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MergeIssuerDialog2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}








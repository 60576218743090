/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorQualityGateModel } from '../models/senator-quality-gate-model';
import { SenatorIssuerMappingsFilterModel } from '../models/senator-issuer-mappings-filter-model';
import { SenatorTrades } from '../models/senator-trades';
import { TradesUpdateModel } from '../models/trades-update-model';
import { SenatorAllCompanyMatchingMatchedRow } from '../models/senator-all-company-matching-matched-row';
import { SenatorAssignOldName } from '../models/senator-assign-old-name';
import { SenatorIssuerSecurityTypeUpdate } from '../models/senator-issuer-security-type-update';
import { SenatorSuspiciousIssuerRecordUpdate } from '../models/senator-suspicious-issuer-record-update';
import { SenatorOldCompanyName } from '../models/senator-old-company-name';
import { SenatorNoGvKeyConfirmation } from '../models/senator-no-gv-key-confirmation';
@Injectable({
  providedIn: 'root',
})
class SenatorQualityGateService extends __BaseService {
  static readonly getReplaceableAssetNamesPath = '/senatorQualityGate/';
  static readonly updateSenatorQualityGateForExcludeCompanyPath = '/senatorQualityGateForExcludeCompany/{senatorCompanyId}';
  static readonly updateSenatorTradesStatusPath = '/senatorQualityGateForChangeStatusOfTrades/';
  static readonly updateAllCompanyMatchingRowPath = '/senatorQualityGate/updateAllCompanyMatchingRow/{newGvkey}/{newIssuerName}/';
  static readonly updateAssignOldNameRowPath = '/senatorQualityGate/updateAssignOldNameRow/';
  static readonly updateAllIssuerSecurityTypeReviewRowPath = '/senatorQualityGate/updateAllIssuerSecurityTypeReviewRow/';
  static readonly updateAllSuspiciousIssuerRecordPath = '/senatorQualityGate/updateAllSuspiciousIssuerRecord/';
  static readonly addOldCompanyNamePath = '/senatorQualityGate/add/oldCompanyName';
  static readonly searchCompanyNamesInNoGvkeyPath = '/senatorQualityGate/searchCompanyNamesInNoGvkey';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SenatorQualityGateService.GetReplaceableAssetNamesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `issuerMappingsFilterModel`:
   *
   * @return list of values need to be in order to make it correctable
   */
  getReplaceableAssetNamesResponse(params: SenatorQualityGateService.GetReplaceableAssetNamesParams): __Observable<__StrictHttpResponse<SenatorQualityGateModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    __body = params.issuerMappingsFilterModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorQualityGateModel>;
      })
    );
  }
  /**
   * @param params The `SenatorQualityGateService.GetReplaceableAssetNamesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `issuerMappingsFilterModel`:
   *
   * @return list of values need to be in order to make it correctable
   */
  getReplaceableAssetNames(params: SenatorQualityGateService.GetReplaceableAssetNamesParams): __Observable<SenatorQualityGateModel> {
    return this.getReplaceableAssetNamesResponse(params).pipe(
      __map(_r => _r.body as SenatorQualityGateModel)
    );
  }

  /**
   * @param senatorCompanyId undefined
   * @return Return true or false for indication to update
   */
  updateSenatorQualityGateForExcludeCompanyResponse(senatorCompanyId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGateForExcludeCompany/${encodeURIComponent(String(senatorCompanyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorCompanyId undefined
   * @return Return true or false for indication to update
   */
  updateSenatorQualityGateForExcludeCompany(senatorCompanyId: number): __Observable<boolean> {
    return this.updateSenatorQualityGateForExcludeCompanyResponse(senatorCompanyId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param tradesStatusChangeModel undefined
   * @return it will return the updated trades
   */
  updateSenatorTradesStatusResponse(tradesStatusChangeModel: TradesUpdateModel): __Observable<__StrictHttpResponse<Array<SenatorTrades>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tradesStatusChangeModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGateForChangeStatusOfTrades/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorTrades>>;
      })
    );
  }
  /**
   * @param tradesStatusChangeModel undefined
   * @return it will return the updated trades
   */
  updateSenatorTradesStatus(tradesStatusChangeModel: TradesUpdateModel): __Observable<Array<SenatorTrades>> {
    return this.updateSenatorTradesStatusResponse(tradesStatusChangeModel).pipe(
      __map(_r => _r.body as Array<SenatorTrades>)
    );
  }

  /**
   * @param params The `SenatorQualityGateService.UpdateAllCompanyMatchingRowParams` containing the following parameters:
   *
   * - `senatorAllCompanyMatchingMatchedRow`:
   *
   * - `newIssuerName`:
   *
   * - `newGvkey`:
   *
   * @return it will return the result of the request as boolean
   */
  updateAllCompanyMatchingRowResponse(params: SenatorQualityGateService.UpdateAllCompanyMatchingRowParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorAllCompanyMatchingMatchedRow;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/updateAllCompanyMatchingRow/${encodeURIComponent(String(params.newGvkey))}/${encodeURIComponent(String(params.newIssuerName))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorQualityGateService.UpdateAllCompanyMatchingRowParams` containing the following parameters:
   *
   * - `senatorAllCompanyMatchingMatchedRow`:
   *
   * - `newIssuerName`:
   *
   * - `newGvkey`:
   *
   * @return it will return the result of the request as boolean
   */
  updateAllCompanyMatchingRow(params: SenatorQualityGateService.UpdateAllCompanyMatchingRowParams): __Observable<boolean> {
    return this.updateAllCompanyMatchingRowResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param senatorAssignOldName undefined
   * @return it will return the result of the request as boolean
   */
  updateAssignOldNameRowResponse(senatorAssignOldName: SenatorAssignOldName): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorAssignOldName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/updateAssignOldNameRow/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorAssignOldName undefined
   * @return it will return the result of the request as boolean
   */
  updateAssignOldNameRow(senatorAssignOldName: SenatorAssignOldName): __Observable<boolean> {
    return this.updateAssignOldNameRowResponse(senatorAssignOldName).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param senatorIssuerSecurityTypeUpdate undefined
   * @return it will return the result of the request as boolean
   */
  updateAllIssuerSecurityTypeReviewRowResponse(senatorIssuerSecurityTypeUpdate: SenatorIssuerSecurityTypeUpdate): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorIssuerSecurityTypeUpdate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/updateAllIssuerSecurityTypeReviewRow/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorIssuerSecurityTypeUpdate undefined
   * @return it will return the result of the request as boolean
   */
  updateAllIssuerSecurityTypeReviewRow(senatorIssuerSecurityTypeUpdate: SenatorIssuerSecurityTypeUpdate): __Observable<boolean> {
    return this.updateAllIssuerSecurityTypeReviewRowResponse(senatorIssuerSecurityTypeUpdate).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param senatorSuspiciousIssuerRecordUpdate undefined
   * @return it will return the result of the request as boolean
   */
  updateAllSuspiciousIssuerRecordResponse(senatorSuspiciousIssuerRecordUpdate: SenatorSuspiciousIssuerRecordUpdate): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorSuspiciousIssuerRecordUpdate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/updateAllSuspiciousIssuerRecord/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param senatorSuspiciousIssuerRecordUpdate undefined
   * @return it will return the result of the request as boolean
   */
  updateAllSuspiciousIssuerRecord(senatorSuspiciousIssuerRecordUpdate: SenatorSuspiciousIssuerRecordUpdate): __Observable<boolean> {
    return this.updateAllSuspiciousIssuerRecordResponse(senatorSuspiciousIssuerRecordUpdate).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param oldCompanyName undefined
   * @return it will return the result of the request as boolean
   */
  addOldCompanyNameResponse(oldCompanyName: SenatorOldCompanyName): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = oldCompanyName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/add/oldCompanyName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param oldCompanyName undefined
   * @return it will return the result of the request as boolean
   */
  addOldCompanyName(oldCompanyName: SenatorOldCompanyName): __Observable<boolean> {
    return this.addOldCompanyNameResponse(oldCompanyName).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `SenatorQualityGateService.SearchCompanyNamesInNoGvkeyParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `companyName`:
   *
   * @return list of values matching by the name.
   */
  searchCompanyNamesInNoGvkeyResponse(params: SenatorQualityGateService.SearchCompanyNamesInNoGvkeyParams): __Observable<__StrictHttpResponse<Array<SenatorNoGvKeyConfirmation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    __body = params.companyName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorQualityGate/searchCompanyNamesInNoGvkey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorNoGvKeyConfirmation>>;
      })
    );
  }
  /**
   * @param params The `SenatorQualityGateService.SearchCompanyNamesInNoGvkeyParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `companyName`:
   *
   * @return list of values matching by the name.
   */
  searchCompanyNamesInNoGvkey(params: SenatorQualityGateService.SearchCompanyNamesInNoGvkeyParams): __Observable<Array<SenatorNoGvKeyConfirmation>> {
    return this.searchCompanyNamesInNoGvkeyResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorNoGvKeyConfirmation>)
    );
  }
}

module SenatorQualityGateService {

  /**
   * Parameters for getReplaceableAssetNames
   */
  export interface GetReplaceableAssetNamesParams {
    pageSize?: number;
    pageNumber?: number;
    issuerMappingsFilterModel?: SenatorIssuerMappingsFilterModel;
  }

  /**
   * Parameters for updateAllCompanyMatchingRow
   */
  export interface UpdateAllCompanyMatchingRowParams {
    senatorAllCompanyMatchingMatchedRow: Array<SenatorAllCompanyMatchingMatchedRow>;
    newIssuerName: string;
    newGvkey: string;
  }

  /**
   * Parameters for searchCompanyNamesInNoGvkey
   */
  export interface SearchCompanyNamesInNoGvkeyParams {
    pageSize: number;
    pageNumber: number;
    companyName: string;
  }
}

export { SenatorQualityGateService }

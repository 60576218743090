/* tslint:disable */
type TradeUpdateStatusEnum =
  'Amendment' |
  'Duplicate' |
  'Delete';
module TradeUpdateStatusEnum {
  export const AMENDMENT: TradeUpdateStatusEnum = 'Amendment';
  export const DUPLICATE: TradeUpdateStatusEnum = 'Duplicate';
  export const DELETE: TradeUpdateStatusEnum = 'Delete';
  export function values(): TradeUpdateStatusEnum[] {
    return [
      AMENDMENT,
      DUPLICATE,
      DELETE
    ];
  }
}

export { TradeUpdateStatusEnum }
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SenatorCompany} from '../../../../../../../../api/models/senator-company';
import {SenatorCompanyAssetClass} from '../../../../../../../../api/models/senator-company-asset-class';
import {CompanyService} from '../../../../../../../../api/services/company.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StockOptionListingService} from '../../../../../../../../api/services/stock-option-listing.service';
import {StockOptionListing} from '../../../../../../../../api/models/stock-option-listing';
import {StockOptionStyle, StockOptionType, UnderlyingAssetType} from '../../../../../../../../api/models';
import {SenatorService} from '../../../../../../../../api/services/senator.service';
import {SenatorCompanyAssetClassService} from '../../../../../../../../api/services/senator-company-asset-class.service';
import {Clipboard} from '@angular/cdk/clipboard';
import AddNewStockOptionListingParams = StockOptionListingService.AddNewStockOptionListingParams;

@Component({
  selector: 'app-add-edit-listed-options',
  templateUrl: './add-edit-listed-options.component.html',
  styleUrls: ['./add-edit-listed-options.component.css']
})
export class AddEditListedOptionsComponent implements OnInit {

  stockOptionListingForm: FormGroup;
  selectedCompany: SenatorCompany = {} as SenatorCompany;
  selectedAssetClass: SenatorCompanyAssetClass = {} as SenatorCompanyAssetClass;

  types: StockOptionType[];
  underlyingAssetTypes: UnderlyingAssetType[];
  styles: StockOptionStyle[];
  senatorCompanyAssetClassId: number;
  stockOptionListing: StockOptionListing;
  operation: string;
  assetClasses = [] as SenatorCompanyAssetClass[];
  twoIQAssetsList: SenatorCompany[] = [];
  assetNamesLoading = false;
  /*
    dissimilarValueValidator(underLayingAssetType1: string, underLayingAssetType2: string) {
      return (formGroup: FormGroup) => {

        const underLayingAssetType1Control = formGroup.controls[underLayingAssetType1];
        const underLayingAssetType2Control = formGroup.controls[underLayingAssetType2];

        if (underLayingAssetType1Control.errors && !underLayingAssetType1Control.errors.dissimilarValueValidator) {
          return;
        }
        if (underLayingAssetType2Control.errors && !underLayingAssetType2Control.errors.dissimilarValueValidator) {
          return;
        }

        if (underLayingAssetType1Control.value || underLayingAssetType2Control.value) {
          let assetClassName = null;
          if (underLayingAssetType2Control.value) {
            assetClassName = this.assetClasses.find(el => el.ID === underLayingAssetType2Control.value).x2iqAssetClass;
          }

          if ((underLayingAssetType1Control.value === 'Stock-Equities' && assetClassName !== 'Stock') ||
            (underLayingAssetType1Control.value === 'Baskets' && assetClassName !== 'ETF') ||
            (underLayingAssetType1Control.value === 'Indices' && assetClassName !== null)) {

            underLayingAssetType1Control.setErrors({dissimilarValueValidator: true});
            underLayingAssetType2Control.setErrors({dissimilarValueValidator: true});
          } else {
            underLayingAssetType1Control.setErrors(null);
            underLayingAssetType2Control.setErrors(null);
          }
        }

      };
    }*/
  pasteEnabled = false;

  constructor(private companyService: CompanyService,
              private _senatorAssetClassService: SenatorCompanyAssetClassService,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private _senatorService: SenatorService,
              private dialogRef: MatDialogRef<AddEditListedOptionsComponent>,
              private stockOptionListingService: StockOptionListingService,
              private clipboard: Clipboard,
              private snackBar: MatSnackBar) {
    this.senatorCompanyAssetClassId = this.data.senatorCompanyAssetClassId;
    this.stockOptionListing = this.data.stockOptionListing;
    this.operation = this.data.operation;
    if (this.operation == 'Add') {
      this.stockOptionListing.stockOptionType = this.getStockOptionType(this.data.financialInstrument);
    }
  }

  get underLayingAssetType() {
    return this.stockOptionListingForm.get('underlyingAssetType');

  }

  get underLayingAssetId() {
    return this.stockOptionListingForm.get('underLyingAssetId');

  }

  get underlyingAssetIssureId() {
    return this.stockOptionListingForm.get('underlyingAssetIssureId');

  }

  private getStockOptionType(financialInstrument: string) {
    console.log(financialInstrument);
    if (financialInstrument.toLowerCase().includes('call'))
      return 'Call Options';
    if (financialInstrument.toLowerCase().includes('put'))
      return 'Put Options';
    return 'Others';
  }

  ngOnInit() {
    this.underlyingAssetTypes = UnderlyingAssetType.values();
    this.types = StockOptionType.values();
    this.styles = StockOptionStyle.values();
    this.initStockOptionListingForm();
    if (this.stockOptionListing.underlyingAssetIssureId) {
      this.getAssetClassesList(this.stockOptionListing.underlyingAssetIssureId);
    } else {
      this.assetClasses = this.data.assetClasses;

    }
  }

  initStockOptionListingForm() {
    const regexp = /[+-]?(\d*\.\d+|\d+\.\d*|\d+)/;

    this.stockOptionListingForm = this.fb.group({
      stockOptionType: [this.stockOptionListing.stockOptionType, Validators.required],
      underlyingAssetType: [this.stockOptionListing.underlyingAssetType, Validators.required],
      style: [this.stockOptionListing.style || null],
      securityTicker: [this.stockOptionListing.securityTicker || null],
      strikePrice: [this.stockOptionListing.strikePrice, Validators.pattern(regexp)],
      expirationDate: [new Date(this.stockOptionListing.expirationDate) || null],
      underlyingAssetIssureName: [this.stockOptionListing.underlyingAssetIssureName],
      underlyingAssetIssureId: [this.stockOptionListing.underlyingAssetIssureId],
      underLyingAssetId: [this.stockOptionListing.underLyingAssetId],
      optionListingId: [this.stockOptionListing.optionListingId],
      opr17: [this.stockOptionListing.opr17 || null],
      occ21: [this.stockOptionListing.occ21 || null],
      figi: [this.stockOptionListing.figi || null],
      sourceUrl: [this.stockOptionListing.sourceUrl || null],
      notes: [this.stockOptionListing.notes || null]
    }, {
      validators: [this.similarValueValidator('securityTicker', 'opr17', 'occ21', 'figi')]
    });
  }

  onAddListingOptions() {

    this.formatDate();
    this.removeExtraSpaces();
    const params: AddNewStockOptionListingParams = {
      senatorCompanyAssetClassId: this.senatorCompanyAssetClassId,
      stockOptionListing: this.stockOptionListingForm.value
    };
    this.stockOptionListingService.addNewStockOptionListing(params)
      .subscribe(
        value => {
          this.dialogRef.close(true);
          this.openSnackBar('Stock Option has been added successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');
        }, () => {
        }
      );
  }

  onUpdateListingOptions() {
    this.formatDate();

    this.removeExtraSpaces();
    const params: StockOptionListing = this.stockOptionListingForm.value;

    this.stockOptionListingService.updateStockOptionListing(params)
      .subscribe(
        value => {
          this.dialogRef.close(true);
          this.openSnackBar('Stock Option has been updated successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {
        }
      );
  }

  searchAssetNames(companyName: string) {
    this.assetNamesLoading = true;
    let companiesList = [] as SenatorCompany[];
    this.twoIQAssetsList = [];
    this._senatorService.getSenatorAssetNames(companyName).subscribe((res: SenatorCompany[]) => {
      companiesList = res;
    }, (err) => {
      this.assetNamesLoading = false;

      if (err.status === 400 || err.status === 500) {
        this.openSnackBar('Something went wrong! please enter a valid company name.', 'Close');
      } else {
        this.openSnackBar(err.error.message, 'Close');
      }

    }, () => {
      this.assetNamesLoading = false;
      if (companiesList.length === 0) {
        this.openSnackBar('No Company found with this name', 'Close');
      }
      this.twoIQAssetsList = companiesList;
    });
  }

  setCompanyIdAndAssetClasses(event, company: SenatorCompany) {
    if (event.source.selected) {
      this.assetClasses = [];
      this.underLayingAssetId.setValue(null);
      this.underlyingAssetIssureId.setValue(company.ID);
      this.getAssetClassesList(company.ID);
    }
  }

  getAssetClassesList(companyId) {

    this._senatorAssetClassService.getAssetClassesOfTheCompany({gvkey: '', name: '', ID: companyId})
      .subscribe((value1) => {
        this.assetClasses = value1;
      }, error => {

      }, () => {

      });
  }

  similarValueValidator(securityTicker: string, opr17: string, occ21: string, figi: string) {
    return (formGroup: FormGroup) => {
      const securityTickerControl = formGroup.controls[securityTicker];
      const opr17Control = formGroup.controls[opr17];
      const occ21Control = formGroup.controls[occ21];
      const figiControl = formGroup.controls[figi];

      if (securityTickerControl.errors && !securityTickerControl.errors.sameValueValidator) {
        return;
      }
      if (opr17Control.errors && !opr17Control.errors.sameValueValidator) {
        return;
      }
      if (occ21Control.errors && !occ21Control.errors.sameValueValidator) {
        return;
      }
      if (figiControl.errors && !figiControl.errors.sameValueValidator) {
        return;
      }

      if (securityTickerControl.value) {

        if (securityTickerControl.value === opr17Control.value || securityTickerControl.value === occ21Control.value || securityTickerControl.value === figiControl.value) {
          securityTickerControl.setErrors({sameValueValidator: true});
        } else {
          securityTickerControl.setErrors(null);
        }
      }
      if (opr17Control.value) {

        if (opr17Control.value === securityTickerControl.value || opr17Control.value === occ21Control.value || opr17Control.value === figiControl.value) {
          opr17Control.setErrors({sameValueValidator: true});
        } else {
          opr17Control.setErrors(null);
        }
      }
      if (occ21Control.value) {
        if (occ21Control.value === securityTickerControl.value || occ21Control.value === opr17Control.value || occ21Control.value === figiControl.value) {
          occ21Control.setErrors({sameValueValidator: true});
        } else {
          occ21Control.setErrors(null);
        }
      }
      if (figiControl.value) {
        if (figiControl.value === securityTickerControl.value || figiControl.value === opr17Control.value || figiControl.value === occ21Control.value) {
          figiControl.setErrors({sameValueValidator: true});
        } else {
          figiControl.setErrors(null);
        }
      }

    };
  }

  dissimilarValueWarnings() {
    const underLayingAssetTypeValue = this.underLayingAssetType.value;
    const underLayingAssetId = this.underLayingAssetId.value;

    if (underLayingAssetTypeValue || underLayingAssetId) {
      let assetClassName = null;

      if (underLayingAssetId && this.assetClasses.length >= 1) {
        assetClassName = this.assetClasses.filter(el => el.ID === underLayingAssetId)[0].x2iqAssetClass;
      }

      return (underLayingAssetTypeValue === 'Stock-Equities' && assetClassName !== 'Stock') ||
        (underLayingAssetTypeValue === 'Baskets' && assetClassName !== 'ETF') ||
        (underLayingAssetTypeValue === 'Indices' && assetClassName === null);
    }
  }

  formatDate() {
    const expirationDateStandard = new Date(this.stockOptionListingForm.get('expirationDate').value);
    let expirationDateFormat = '';
    if (this.stockOptionListingForm.get('expirationDate').value !== '') {
      expirationDateFormat = `${expirationDateStandard.getFullYear()}-${expirationDateStandard.getMonth() + 1}-${expirationDateStandard.getDate()}`;
      this.stockOptionListingForm.get('expirationDate').setValue(expirationDateFormat);
    }
  }

  removeExtraSpaces() {
    for (const key in this.stockOptionListingForm.value) {
      if (this.stockOptionListingForm.value[key] !== null) {
        if (key == 'occ21' || key == 'opr17') {
          this.stockOptionListingForm.value[key] = this.stockOptionListingForm.value[key].trim();
        } else if (typeof this.stockOptionListingForm.value[key] === 'string') {
          this.stockOptionListingForm.value[key] = this.stockOptionListingForm.value[key].trim().replace(/\s\s+/g, ' ');
        }
      }

    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  copyTheForm() {
    const text = JSON.stringify(this.stockOptionListing);
    this.clipboard.copy(text);
  }

  pasteTheForm(event: ClipboardEvent) {
    const text = event.clipboardData.getData('text/plain');
    try {
      this.stockOptionListing = JSON.parse(text);
      this.ngOnInit();
    } catch (e) {
      this.openSnackBar('Copied content is not recognized', 'Close');
    } finally {
      this.pasteEnabled = false;
    }
  }

  enabledPasting() {
    this.pasteEnabled = true;
  }

  keyDownPaste(event: KeyboardEvent) {
    if (!(event.ctrlKey && event.key == 'v')) {
      event.preventDefault();
    }
  }
}

/* tslint:disable */

/**
 * This enum contains all roles of users
 */
type Roles =
  'Admin' |
  'Trainee';
module Roles {
  export const ADMIN: Roles = 'Admin';
  export const TRAINEE: Roles = 'Trainee';
  export function values(): Roles[] {
    return [
      ADMIN,
      TRAINEE
    ];
  }
}

export { Roles }
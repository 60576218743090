/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Country } from '../models/country';
import { SDSIDs } from '../models/sdsids';
import { ManagerName } from '../models/manager-name';
@Injectable({
  providedIn: 'root',
})
class SdsDataService extends __BaseService {
  static readonly getSdsCountryListPath = '/SdsData/countryList/{regionName}/';
  static readonly getAllSdsDataInCSVPath = '/SdsData/InCSV/{countryId}';
  static readonly updatedReleasedDataPath = '/updateReleasedSdsData';
  static readonly saveMatchedManagerPath = '/manager/matching/names';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param regionName undefined
   * @return Return Sds data according to paramters
   */
  getSdsCountryListResponse(regionName: string): __Observable<__StrictHttpResponse<Array<Country>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/SdsData/countryList/${encodeURIComponent(String(regionName))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Country>>;
      })
    );
  }
  /**
   * @param regionName undefined
   * @return Return Sds data according to paramters
   */
  getSdsCountryList(regionName: string): __Observable<Array<Country>> {
    return this.getSdsCountryListResponse(regionName).pipe(
      __map(_r => _r.body as Array<Country>)
    );
  }

  /**
   * @param params The `SdsDataService.GetAllSdsDataInCSVParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero for all
   *
   * - `startPositionDate`: Return data according this date
   *
   * - `positionHolder`: Return all data of position holder
   *
   * - `pageSize`: Return data according to page size,By default page size is 20
   *
   * - `pageNumber`: Return data according to page number,By default page number is 1
   *
   * - `issuerName`: Return all data of issuer
   *
   * - `isHistoric`: Return all data according current or historic
   *
   * - `endPositionDate`: Return data according this date
   *
   * @return Return Sds data according to paramters
   */
  getAllSdsDataInCSVResponse(params: SdsDataService.GetAllSdsDataInCSVParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startPositionDate != null) __params = __params.set('startPositionDate', params.startPositionDate.toString());
    if (params.positionHolder != null) __params = __params.set('positionHolder', params.positionHolder.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.issuerName != null) __params = __params.set('issuerName', params.issuerName.toString());
    if (params.isHistoric != null) __params = __params.set('isHistoric', params.isHistoric.toString());
    if (params.endPositionDate != null) __params = __params.set('endPositionDate', params.endPositionDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/SdsData/InCSV/${encodeURIComponent(String(params.countryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `SdsDataService.GetAllSdsDataInCSVParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero for all
   *
   * - `startPositionDate`: Return data according this date
   *
   * - `positionHolder`: Return all data of position holder
   *
   * - `pageSize`: Return data according to page size,By default page size is 20
   *
   * - `pageNumber`: Return data according to page number,By default page number is 1
   *
   * - `issuerName`: Return all data of issuer
   *
   * - `isHistoric`: Return all data according current or historic
   *
   * - `endPositionDate`: Return data according this date
   *
   * @return Return Sds data according to paramters
   */
  getAllSdsDataInCSV(params: SdsDataService.GetAllSdsDataInCSVParams): __Observable<Blob> {
    return this.getAllSdsDataInCSVResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param listOfIds Post updatedReleasedSdsData for updated released data
   * @return Return boolean if updated.
   */
  updatedReleasedDataResponse(listOfIds: Array<SDSIDs>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = listOfIds;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/updateReleasedSdsData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param listOfIds Post updatedReleasedSdsData for updated released data
   * @return Return boolean if updated.
   */
  updatedReleasedData(listOfIds: Array<SDSIDs>): __Observable<boolean> {
    return this.updatedReleasedDataResponse(listOfIds).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param manager undefined
   * @return List of Managers matching the managerName.
   */
  saveMatchedManagerResponse(manager: ManagerName): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = manager;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/matching/names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param manager undefined
   * @return List of Managers matching the managerName.
   */
  saveMatchedManager(manager: ManagerName): __Observable<boolean> {
    return this.saveMatchedManagerResponse(manager).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module SdsDataService {

  /**
   * Parameters for getAllSdsDataInCSV
   */
  export interface GetAllSdsDataInCSVParams {

    /**
     * Return data according to this countryId, zero for all
     */
    countryId: number;

    /**
     * Return data according this date
     */
    startPositionDate?: string;

    /**
     * Return all data of position holder
     */
    positionHolder?: string;

    /**
     * Return data according to page size,By default page size is 20
     */
    pageSize?: number;

    /**
     * Return data according to page number,By default page number is 1
     */
    pageNumber?: number;

    /**
     * Return all data of issuer
     */
    issuerName?: string;

    /**
     * Return all data according current or historic
     */
    isHistoric?: boolean;

    /**
     * Return data according this date
     */
    endPositionDate?: string;
  }
}

export { SdsDataService }

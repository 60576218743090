import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {SenatorService} from '../../../api/services/senator.service';
import UnlockAssetFilingByIdParams = SenatorService.UnlockAssetFilingByIdParams;

@Component({
  selector: 'app-annual-report-processing',
  templateUrl: './annual-report-processing.component.html',
  styleUrls: ['./annual-report-processing.component.css']
})
export class AnnualReportProcessingComponent implements OnInit, OnDestroy {
  report: { congressType: string, filingId: number, sourceUrl: null } = {
    congressType: null,
    filingId: null,
    sourceUrl: null
  };

  constructor(private router: Router, private _senatorService: SenatorService,
              private _activatedRoute: ActivatedRoute) {
    this._activatedRoute.paramMap
      .subscribe(params => {
          this.report.congressType = params['params']['congressType'];
          this.report.filingId = params['params']['filingId'];
          this.report.sourceUrl = params['params']['sourceUrl'];

        }
      );
  }

  ngOnInit(): void {
  }

  onCloseFileClicked() {

    this.router.navigate(['/annual-reports']);
  }

  unlockFile() {
    const params: UnlockAssetFilingByIdParams = {
      congressType: this.report.congressType,
      filingId: this.report.filingId
    };

    this._senatorService.unlockAssetFilingById(params).subscribe((res) => {
    }, (err) => {
      console.log('err', err);
    }, () => {
    });

  }

  ngOnDestroy(): void {
    this.unlockFile();
  }
}

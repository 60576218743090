/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QualityGateTrade } from '../models/quality-gate-trade';
import { QualityCheckFilter } from '../models/quality-check-filter';
@Injectable({
  providedIn: 'root',
})
class QualityGateService extends __BaseService {
  static readonly getUserEmailsPath = '/quality/userEmails';
  static readonly getQualityGateTradesPath = '/quality/gate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return List of email addresses
   */
  getUserEmailsResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/quality/userEmails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return List of email addresses
   */
  getUserEmails(): __Observable<Array<string>> {
    return this.getUserEmailsResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param QualityCheckFilter Contains filter to select announcements that need a review.
   * @return List of Announcement with their buyback details.
   */
  getQualityGateTradesResponse(QualityCheckFilter: QualityCheckFilter): __Observable<__StrictHttpResponse<Array<QualityGateTrade>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = QualityCheckFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/quality/gate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QualityGateTrade>>;
      })
    );
  }
  /**
   * @param QualityCheckFilter Contains filter to select announcements that need a review.
   * @return List of Announcement with their buyback details.
   */
  getQualityGateTrades(QualityCheckFilter: QualityCheckFilter): __Observable<Array<QualityGateTrade>> {
    return this.getQualityGateTradesResponse(QualityCheckFilter).pipe(
      __map(_r => _r.body as Array<QualityGateTrade>)
    );
  }
}

module QualityGateService {
}

export { QualityGateService }

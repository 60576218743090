/* tslint:disable */
type SenatorAmountRanges =
  '$1 - $1,000' |
  '$1,001 - $15,000' |
  '$15,001 - $50,000' |
  '$50,001 - $100,000' |
  '$100,001 - $250,000' |
  '$250,001 - $500,000' |
  '$500,001 - $1,000,000' |
  '$1,000,001 - $5,000,000' |
  '$5,000,001 - $25,000,000' |
  '$25,000,001 - $50,000,000' |
  'Over $1,000,000' |
  'Over $50,000,000';
module SenatorAmountRanges {
  export const _1_1_000: SenatorAmountRanges = '$1 - $1,000';
  export const _1_001_15_000: SenatorAmountRanges = '$1,001 - $15,000';
  export const _15_001_50_000: SenatorAmountRanges = '$15,001 - $50,000';
  export const _50_001_100_000: SenatorAmountRanges = '$50,001 - $100,000';
  export const _100_001_250_000: SenatorAmountRanges = '$100,001 - $250,000';
  export const _250_001_500_000: SenatorAmountRanges = '$250,001 - $500,000';
  export const _500_001_1_000_000: SenatorAmountRanges = '$500,001 - $1,000,000';
  export const _1_000_001_5_000_000: SenatorAmountRanges = '$1,000,001 - $5,000,000';
  export const _5_000_001_25_000_000: SenatorAmountRanges = '$5,000,001 - $25,000,000';
  export const _25_000_001_50_000_000: SenatorAmountRanges = '$25,000,001 - $50,000,000';
  export const OVER_1_000_000: SenatorAmountRanges = 'Over $1,000,000';
  export const OVER_50_000_000: SenatorAmountRanges = 'Over $50,000,000';
  export function values(): SenatorAmountRanges[] {
    return [
      _1_1_000,
      _1_001_15_000,
      _15_001_50_000,
      _50_001_100_000,
      _100_001_250_000,
      _250_001_500_000,
      _500_001_1_000_000,
      _1_000_001_5_000_000,
      _5_000_001_25_000_000,
      _25_000_001_50_000_000,
      OVER_1_000_000,
      OVER_50_000_000
    ];
  }
}

export { SenatorAmountRanges }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Currency } from '../models/currency';
import { Company } from '../models/company';
import { Security } from '../models/security';
import { Treasury } from '../models/treasury';
import { CorporateAction } from '../models/corporate-action';
import { CountryCode } from '../models/country-code';
import { ProcessDate } from '../models/process-date';
@Injectable({
  providedIn: 'root',
})
class CompanyService extends __BaseService {
  static readonly getCurrenciesPath = '/currencies';
  static readonly getCompanyDetailPath = '/companyDetail/{companyID}';
  static readonly getSecurityDetailPath = '/securityDetail/{companyID}';
  static readonly companySearchPath = '/company/{searchBy}';
  static readonly mapSuggestionsSearchPath = '/company/search-map-suggestions';
  static readonly getCurrencyCodePath = '/currencyCode/{companyID}/{iid}';
  static readonly getTreasuryHoldingPath = '/treasury/{companyID}/{securityID}';
  static readonly getPricePath = '/price/{companyID}/{currencyCode}/{iid}';
  static readonly getLastTreasuryUpdatePath = '/lastTreasuryUpdate/{securityID}';
  static readonly getFXRatePath = '/fxRate/{currencyCode}/{date}/{customDaysDifference}';
  static readonly getCountryIDPath = '/countryID/{countryCode}/{codeType}';
  static readonly getCountryIDForCompanyPath = '/countryIDForCompany/{companyID}';
  static readonly getCorporateActionPath = '/corporateAction/{securityID}/{dateOfChange}';
  static readonly findCountryCodePath = '/workoutCountryCode/{announcementID}';
  static readonly findProcessDatePath = '/workoutProcessDate/{buybackID}/{countryLetter}';
  static readonly findReportPeriodIDPath = '/workoutReportPeriodID/{processDate}';
  static readonly findProcessDateForIntentionPath = '/workoutProcessDateForIntention/{intentionID}/{countryLetter}';
  static readonly getMaxReportPeriodIDPath = '/reportPeriodID';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Returns list of currencies.
   */
  getCurrenciesResponse(): __Observable<__StrictHttpResponse<Array<Currency>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/currencies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Currency>>;
      })
    );
  }
  /**
   * @return Returns list of currencies.
   */
  getCurrencies(): __Observable<Array<Currency>> {
    return this.getCurrenciesResponse().pipe(
      __map(_r => _r.body as Array<Currency>)
    );
  }

  /**
   * @param companyID Company ID
   * @return Company Detail.
   */
  getCompanyDetailResponse(companyID: number): __Observable<__StrictHttpResponse<Company>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companyDetail/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Company>;
      })
    );
  }
  /**
   * @param companyID Company ID
   * @return Company Detail.
   */
  getCompanyDetail(companyID: number): __Observable<Company> {
    return this.getCompanyDetailResponse(companyID).pipe(
      __map(_r => _r.body as Company)
    );
  }

  /**
   * @param companyID Company ID
   * @return Security Detail.
   */
  getSecurityDetailResponse(companyID: number): __Observable<__StrictHttpResponse<Array<Security>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/securityDetail/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Security>>;
      })
    );
  }
  /**
   * @param companyID Company ID
   * @return Security Detail.
   */
  getSecurityDetail(companyID: number): __Observable<Array<Security>> {
    return this.getSecurityDetailResponse(companyID).pipe(
      __map(_r => _r.body as Array<Security>)
    );
  }

  /**
   * @param params The `CompanyService.CompanySearchParams` containing the following parameters:
   *
   * - `searchValue`: Search Value
   *
   * - `searchBy`: Search By
   *
   * @return Companies.
   */
  companySearchResponse(params: CompanyService.CompanySearchParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchValue != null) __params = __params.set('searchValue', params.searchValue.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/company/${encodeURIComponent(String(params.searchBy))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `CompanyService.CompanySearchParams` containing the following parameters:
   *
   * - `searchValue`: Search Value
   *
   * - `searchBy`: Search By
   *
   * @return Companies.
   */
  companySearch(params: CompanyService.CompanySearchParams): __Observable<Array<Company>> {
    return this.companySearchResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param searchValue Search Value
   * @return Companies.
   */
  mapSuggestionsSearchResponse(searchValue: string): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchValue;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/company/search-map-suggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param searchValue Search Value
   * @return Companies.
   */
  mapSuggestionsSearch(searchValue: string): __Observable<Array<Company>> {
    return this.mapSuggestionsSearchResponse(searchValue).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param params The `CompanyService.GetCurrencyCodeParams` containing the following parameters:
   *
   * - `iid`: IID
   *
   * - `companyID`: Company Id
   *
   * @return CurrencyCode.
   */
  getCurrencyCodeResponse(params: CompanyService.GetCurrencyCodeParams): __Observable<__StrictHttpResponse<Currency>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/currencyCode/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.iid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Currency>;
      })
    );
  }
  /**
   * @param params The `CompanyService.GetCurrencyCodeParams` containing the following parameters:
   *
   * - `iid`: IID
   *
   * - `companyID`: Company Id
   *
   * @return CurrencyCode.
   */
  getCurrencyCode(params: CompanyService.GetCurrencyCodeParams): __Observable<Currency> {
    return this.getCurrencyCodeResponse(params).pipe(
      __map(_r => _r.body as Currency)
    );
  }

  /**
   * @param params The `CompanyService.GetTreasuryHoldingParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company Id
   *
   * @return Treasury Holding
   */
  getTreasuryHoldingResponse(params: CompanyService.GetTreasuryHoldingParams): __Observable<__StrictHttpResponse<Treasury>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/treasury/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Treasury>;
      })
    );
  }
  /**
   * @param params The `CompanyService.GetTreasuryHoldingParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company Id
   *
   * @return Treasury Holding
   */
  getTreasuryHolding(params: CompanyService.GetTreasuryHoldingParams): __Observable<Treasury> {
    return this.getTreasuryHoldingResponse(params).pipe(
      __map(_r => _r.body as Treasury)
    );
  }

  /**
   * @param params The `CompanyService.GetPriceParams` containing the following parameters:
   *
   * - `iid`: IID
   *
   * - `currencyCode`: Currency code
   *
   * - `companyID`: Company Id
   *
   * @return Price.
   */
  getPriceResponse(params: CompanyService.GetPriceParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/price/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.currencyCode))}/${encodeURIComponent(String(params.iid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `CompanyService.GetPriceParams` containing the following parameters:
   *
   * - `iid`: IID
   *
   * - `currencyCode`: Currency code
   *
   * - `companyID`: Company Id
   *
   * @return Price.
   */
  getPrice(params: CompanyService.GetPriceParams): __Observable<string> {
    return this.getPriceResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param securityID Security ID
   * @return Treasury Holding Update
   */
  getLastTreasuryUpdateResponse(securityID: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/lastTreasuryUpdate/${encodeURIComponent(String(securityID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param securityID Security ID
   * @return Treasury Holding Update
   */
  getLastTreasuryUpdate(securityID: number): __Observable<string> {
    return this.getLastTreasuryUpdateResponse(securityID).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `CompanyService.GetFXRateParams` containing the following parameters:
   *
   * - `date`: Date
   *
   * - `customDaysDifference`: Days difference
   *
   * - `currencyCode`: Currency Code
   *
   * @return FX Rate
   */
  getFXRateResponse(params: CompanyService.GetFXRateParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/fxRate/${encodeURIComponent(String(params.currencyCode))}/${encodeURIComponent(String(params.date))}/${encodeURIComponent(String(params.customDaysDifference))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `CompanyService.GetFXRateParams` containing the following parameters:
   *
   * - `date`: Date
   *
   * - `customDaysDifference`: Days difference
   *
   * - `currencyCode`: Currency Code
   *
   * @return FX Rate
   */
  getFXRate(params: CompanyService.GetFXRateParams): __Observable<number> {
    return this.getFXRateResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `CompanyService.GetCountryIDParams` containing the following parameters:
   *
   * - `countryCode`: Country Code
   *
   * - `codeType`: Code Type
   *
   * @return Get Country ID for a specific Country Code
   */
  getCountryIDResponse(params: CompanyService.GetCountryIDParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/countryID/${encodeURIComponent(String(params.countryCode))}/${encodeURIComponent(String(params.codeType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `CompanyService.GetCountryIDParams` containing the following parameters:
   *
   * - `countryCode`: Country Code
   *
   * - `codeType`: Code Type
   *
   * @return Get Country ID for a specific Country Code
   */
  getCountryID(params: CompanyService.GetCountryIDParams): __Observable<number> {
    return this.getCountryIDResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param companyID Country ID
   * @return Get Country ID for a specific Company ID
   */
  getCountryIDForCompanyResponse(companyID: string): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/countryIDForCompany/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param companyID Country ID
   * @return Get Country ID for a specific Company ID
   */
  getCountryIDForCompany(companyID: string): __Observable<number> {
    return this.getCountryIDForCompanyResponse(companyID).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `CompanyService.GetCorporateActionParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `dateOfChange`: Date of change
   *
   * @return Get amount factor and price factor values
   */
  getCorporateActionResponse(params: CompanyService.GetCorporateActionParams): __Observable<__StrictHttpResponse<CorporateAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/corporateAction/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.dateOfChange))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CorporateAction>;
      })
    );
  }
  /**
   * @param params The `CompanyService.GetCorporateActionParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `dateOfChange`: Date of change
   *
   * @return Get amount factor and price factor values
   */
  getCorporateAction(params: CompanyService.GetCorporateActionParams): __Observable<CorporateAction> {
    return this.getCorporateActionResponse(params).pipe(
      __map(_r => _r.body as CorporateAction)
    );
  }

  /**
   * @param announcementID Announcement Id
   * @return Get Country Code
   */
  findCountryCodeResponse(announcementID: number): __Observable<__StrictHttpResponse<CountryCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workoutCountryCode/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CountryCode>;
      })
    );
  }
  /**
   * @param announcementID Announcement Id
   * @return Get Country Code
   */
  findCountryCode(announcementID: number): __Observable<CountryCode> {
    return this.findCountryCodeResponse(announcementID).pipe(
      __map(_r => _r.body as CountryCode)
    );
  }

  /**
   * @param params The `CompanyService.FindProcessDateParams` containing the following parameters:
   *
   * - `countryLetter`: Country Letter
   *
   * - `buybackID`: Buyback Id
   *
   * @return Get Process Date
   */
  findProcessDateResponse(params: CompanyService.FindProcessDateParams): __Observable<__StrictHttpResponse<ProcessDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workoutProcessDate/${encodeURIComponent(String(params.buybackID))}/${encodeURIComponent(String(params.countryLetter))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcessDate>;
      })
    );
  }
  /**
   * @param params The `CompanyService.FindProcessDateParams` containing the following parameters:
   *
   * - `countryLetter`: Country Letter
   *
   * - `buybackID`: Buyback Id
   *
   * @return Get Process Date
   */
  findProcessDate(params: CompanyService.FindProcessDateParams): __Observable<ProcessDate> {
    return this.findProcessDateResponse(params).pipe(
      __map(_r => _r.body as ProcessDate)
    );
  }

  /**
   * @param processDate Process Date
   * @return Get Report Period ID
   */
  findReportPeriodIDResponse(processDate: string): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workoutReportPeriodID/${encodeURIComponent(String(processDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param processDate Process Date
   * @return Get Report Period ID
   */
  findReportPeriodID(processDate: string): __Observable<number> {
    return this.findReportPeriodIDResponse(processDate).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `CompanyService.FindProcessDateForIntentionParams` containing the following parameters:
   *
   * - `intentionID`: intention Id
   *
   * - `countryLetter`: Country Letter
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Get Process Date
   */
  findProcessDateForIntentionResponse(params: CompanyService.FindProcessDateForIntentionParams): __Observable<__StrictHttpResponse<ProcessDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.buybackIntentionGroupByID != null) __params = __params.set('buybackIntentionGroupByID', params.buybackIntentionGroupByID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/workoutProcessDateForIntention/${encodeURIComponent(String(params.intentionID))}/${encodeURIComponent(String(params.countryLetter))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProcessDate>;
      })
    );
  }
  /**
   * @param params The `CompanyService.FindProcessDateForIntentionParams` containing the following parameters:
   *
   * - `intentionID`: intention Id
   *
   * - `countryLetter`: Country Letter
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Get Process Date
   */
  findProcessDateForIntention(params: CompanyService.FindProcessDateForIntentionParams): __Observable<ProcessDate> {
    return this.findProcessDateForIntentionResponse(params).pipe(
      __map(_r => _r.body as ProcessDate)
    );
  }

  /**
   * @return Get Max Report Period ID
   */
  getMaxReportPeriodIDResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reportPeriodID`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @return Get Max Report Period ID
   */
  getMaxReportPeriodID(): __Observable<number> {
    return this.getMaxReportPeriodIDResponse().pipe(
      __map(_r => _r.body as number)
    );
  }
}

module CompanyService {

  /**
   * Parameters for companySearch
   */
  export interface CompanySearchParams {

    /**
     * Search Value
     */
    searchValue: string;

    /**
     * Search By
     */
    searchBy: string;
  }

  /**
   * Parameters for getCurrencyCode
   */
  export interface GetCurrencyCodeParams {

    /**
     * IID
     */
    iid: string;

    /**
     * Company Id
     */
    companyID: number;
  }

  /**
   * Parameters for getTreasuryHolding
   */
  export interface GetTreasuryHoldingParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company Id
     */
    companyID: number;
  }

  /**
   * Parameters for getPrice
   */
  export interface GetPriceParams {

    /**
     * IID
     */
    iid: string;

    /**
     * Currency code
     */
    currencyCode: string;

    /**
     * Company Id
     */
    companyID: number;
  }

  /**
   * Parameters for getFXRate
   */
  export interface GetFXRateParams {

    /**
     * Date
     */
    date: string;

    /**
     * Days difference
     */
    customDaysDifference: number;

    /**
     * Currency Code
     */
    currencyCode: string;
  }

  /**
   * Parameters for getCountryID
   */
  export interface GetCountryIDParams {

    /**
     * Country Code
     */
    countryCode: string;

    /**
     * Code Type
     */
    codeType: string;
  }

  /**
   * Parameters for getCorporateAction
   */
  export interface GetCorporateActionParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Date of change
     */
    dateOfChange: string;
  }

  /**
   * Parameters for findProcessDate
   */
  export interface FindProcessDateParams {

    /**
     * Country Letter
     */
    countryLetter: string;

    /**
     * Buyback Id
     */
    buybackID: number;
  }

  /**
   * Parameters for findProcessDateForIntention
   */
  export interface FindProcessDateForIntentionParams {

    /**
     * intention Id
     */
    intentionID: number;

    /**
     * Country Letter
     */
    countryLetter: string;

    /**
     * Buyback Intention Group Id
     */
    buybackIntentionGroupByID?: number;
  }
}

export { CompanyService }

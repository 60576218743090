/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyScreening } from '../models/company-screening';
import { CompanyScreeningFilters } from '../models/company-screening-filters';
@Injectable({
  providedIn: 'root',
})
class CompanyScreeningService extends __BaseService {
  static readonly getCompanyListForCountryPath = '/CompanyScreening';
  static readonly excludeCompanyfromCountyListPath = '/CompanyScreening/exclude/{companyID}/{countryID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param companyFilters filters for Company Screening
   * @return List of companies in a given country
   */
  getCompanyListForCountryResponse(companyFilters: CompanyScreeningFilters): __Observable<__StrictHttpResponse<Array<CompanyScreening>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = companyFilters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CompanyScreening`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyScreening>>;
      })
    );
  }
  /**
   * @param companyFilters filters for Company Screening
   * @return List of companies in a given country
   */
  getCompanyListForCountry(companyFilters: CompanyScreeningFilters): __Observable<Array<CompanyScreening>> {
    return this.getCompanyListForCountryResponse(companyFilters).pipe(
      __map(_r => _r.body as Array<CompanyScreening>)
    );
  }

  /**
   * @param params The `CompanyScreeningService.ExcludeCompanyfromCountyListParams` containing the following parameters:
   *
   * - `countryID`: ID of the country to map the company
   *
   * - `companyID`: ID of the company to be mapped
   *
   * @return Return boolean if updated.
   */
  excludeCompanyfromCountyListResponse(params: CompanyScreeningService.ExcludeCompanyfromCountyListParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/CompanyScreening/exclude/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.countryID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `CompanyScreeningService.ExcludeCompanyfromCountyListParams` containing the following parameters:
   *
   * - `countryID`: ID of the country to map the company
   *
   * - `companyID`: ID of the company to be mapped
   *
   * @return Return boolean if updated.
   */
  excludeCompanyfromCountyList(params: CompanyScreeningService.ExcludeCompanyfromCountyListParams): __Observable<boolean> {
    return this.excludeCompanyfromCountyListResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module CompanyScreeningService {

  /**
   * Parameters for excludeCompanyfromCountyList
   */
  export interface ExcludeCompanyfromCountyListParams {

    /**
     * ID of the country to map the company
     */
    countryID: number;

    /**
     * ID of the company to be mapped
     */
    companyID: number;
  }
}

export { CompanyScreeningService }

/* tslint:disable */
type Gender =
  'Male' |
  'Female';
module Gender {
  export const MALE: Gender = 'Male';
  export const FEMALE: Gender = 'Female';
  export function values(): Gender[] {
    return [
      MALE,
      FEMALE
    ];
  }
}

export { Gender }
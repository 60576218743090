import {Component, Inject, OnInit} from '@angular/core';
import {CompanyService} from '../../../../../../../api/services/company.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SenatorCompany} from '../../../../../../../api/models/senator-company';
import {Security} from '../../../../../../../api/models/security';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SenatorCompanyAssetClass} from '../../../../../../../api/models/senator-company-asset-class';
import {SenatorCompanyAssetClassService} from '../../../../../../../api/services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MergeAssetClassComponent} from './merge-asset-class/merge-asset-class.component';
import {SenatorAssetClass} from '../../../../../../../api/models/senator-asset-class';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FinancialInstruments} from '../../../../../../../api/models';

@Component({
  selector: 'app-add-edit-asset-class',
  templateUrl: './add-edit-asset-class.component.html',
  styleUrls: ['./add-edit-asset-class.component.scss']
})
export class AddEditAssetClassComponent implements OnInit {
  // x2iqFinInst = new FormControl();
  financialInstruments = FinancialInstruments.values();
  filteredFinancialInstruments: Observable<string[]>;
  assetClassForm: FormGroup;
  selectedCompany: SenatorCompany = {} as SenatorCompany;
  selectedAssetClass: SenatorCompanyAssetClass = {} as SenatorCompanyAssetClass;
  compustatSecuritiesLoading = false;
  hideCompustatSearch = false;
  isCompustatTicDisabled = true;
  securities = [] as Security[];
  assetClasses = SenatorAssetClass.values().sort();
  dialogResult = 'done';

  readonly MERGE_WITH_ASSET_CLASS = 'Asset Class';
  readonly MERGE_WITH_COMPANY = 'Company';

  constructor(private companyService: CompanyService,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<AddEditAssetClassComponent>,
              private service: SenatorCompanyAssetClassService,
              private snackBar: MatSnackBar) {
    this.selectedCompany = data.selectedCompany;
    this.selectedAssetClass = data.selectedAssetClass;

  }

  ngOnInit() {
    this.hideCompustatSearch = false;
    this.isCompustatTicDisabled = true;
    this.createAssetClassForm();
    if (this.selectedCompany.gvkey !== '000000')
      this.retrieveCompustatSuggestion();
    else {
      this.hideCompustatSearch = true;
      this.isCompustatTicDisabled = false;
    }
    this.filteredFinancialInstruments = this.assetClassForm.get('x2iqFinInst').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.financialInstruments.filter(option => option.toString().toLowerCase().startsWith(filterValue) || option.toString().toLowerCase().includes(filterValue));
  }

  clearFilter(financialInstruments) {
    this[financialInstruments].setValue(null);
  }

  get x2iqFinInst() {
    return this.assetClassForm.get('x2iqFinInst');
  }

  createAssetClassForm() {
    this.assetClassForm = this.fb.group({
      x2iqAssetClass: [this.selectedAssetClass.x2iqAssetClass || null, Validators.required],
      bbg_ticker: [this.selectedAssetClass.bbg_ticker || null],
      x2iqFinInst: [this.selectedAssetClass.x2iqFinInst || null],
      compustat: [this.selectedAssetClass.compustat_iid],
      compustat_tic: [this.selectedAssetClass.compustat_tic],
      isPrimary: [this.selectedAssetClass.isPrimary],
      is_market_traded: [this.selectedAssetClass.is_market_traded, [Validators.required]],
      help: [this.selectedAssetClass.help],
      isprimary_url: [this.selectedAssetClass.isprimary_url],
      market_traded_url: [this.selectedAssetClass.market_traded_url],
      name_change_url: [this.selectedAssetClass.name_change_url],
      cusip: [this.selectedAssetClass.cusip]
    });
  }

  retrieveCompustatSuggestion() {
    this.compustatSecuritiesLoading = true;
    this.companyService.getSecurityDetail(+this.selectedCompany.gvkey)
      .subscribe(
        result => {
          // console.log('compustat suggestions ', result);
          this.securities = result;
        }, error => {
        }, () => {
          this.compustatSecuritiesLoading = false;
        }
      );
  }

  onAddUpdateAssetClass() {

    if (this.selectedAssetClass.ID == 0)
      this.selectedAssetClass.ID = null;
    this.selectedAssetClass.company_id = this.selectedCompany.ID;
    this.selectedAssetClass.x2iqAssetClass = this.assetClassForm.get('x2iqAssetClass').value;
    this.selectedAssetClass.bbg_ticker = this.assetClassForm.get('bbg_ticker').value;
    this.selectedAssetClass.x2iqFinInst = this.assetClassForm.get('x2iqFinInst').value;
    this.selectedAssetClass.isPrimary = this.assetClassForm.get('isPrimary').value;
    this.selectedAssetClass.is_market_traded = this.assetClassForm.get('is_market_traded').value;
    this.selectedAssetClass.help = this.assetClassForm.get('help').value;
    this.selectedAssetClass.market_traded_url = this.assetClassForm.get('market_traded_url').value;
    this.selectedAssetClass.isprimary_url = this.assetClassForm.get('isprimary_url').value;
    this.selectedAssetClass.compustat_tic = this.assetClassForm.get('compustat_tic').value;
    this.selectedAssetClass.name_change_url = this.assetClassForm.get('name_change_url').value;
    this.selectedAssetClass.cusip = this.assetClassForm.get('cusip').value;
    this.service.addUpdateSenatorCompanyAssetClass(this.selectedAssetClass)
      .subscribe(
        value => {
          this.dialogRef.close(this.dialogResult);
          this.openSnackBar('Asset Class has been added/updated successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  mapCompustatSecurity(event) {
    const security = this.securities.find(t => t.iid == event.value);
    if (security == null) {
      this.selectedAssetClass.compustatSec = null;
      this.selectedAssetClass.compustat_tic = null;
      this.selectedAssetClass.compustat_iid = null;

      this.assetClassForm.get('compustat_tic').patchValue(null);
      this.assetClassForm.get('bbg_ticker').patchValue(null);
      return null;
    }
    this.selectedAssetClass.compustatSec = security.dsci;
    this.selectedAssetClass.compustat_tic = security.tic;
    this.selectedAssetClass.compustat_iid = security.iid;

    this.assetClassForm.get('compustat_tic').patchValue(security.tic);
    this.assetClassForm.get('bbg_ticker').patchValue(security.bbg_ticker);
    this.assetClassForm.get('cusip').patchValue(security.cusip);
  }

  /*  resetCompustat() {
      this.selectedAssetClass.compustatSec = null;
      this.selectedAssetClass.compustat_tic = null;
      this.selectedAssetClass.compustat_iid = null;

      this.assetClassForm.get('compustat_tic').patchValue(null);
      this.assetClassForm.get('bbg_ticker').patchValue(null);
    }*/

  mergeAsset(mergeType: string) {
    const dialogRef = this.dialog.open(MergeAssetClassComponent, {
      disableClose: true,
      width: '700px',
      data: {
        selectedCompany: this.selectedCompany,
        selectedAssetClass: this.selectedAssetClass,
        mergeType: mergeType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'merged') {
        this.dialogResult = 'merged';
      }
      this.dialogRef.close(this.dialogResult);
    });
  }


}

import {Component, OnInit, ViewChild} from '@angular/core';
import {SenatorBiographyService} from '../../../api/services';
import {SenatorBiography} from '../../../api/models/senator-biography';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {
  EditAndCreateBiographyDialogComponent
} from './edit-and-create-biography-dialog/edit-and-create-biography-dialog.component';
import GetSenatorBiographiesParams = SenatorBiographyService.GetSenatorBiographiesParams;


export enum Mode {
  create = 'Create',
  edit = 'Edit'
}

@Component({
  selector: 'app-biography',
  templateUrl: './biography.component.html',
  styleUrls: ['./biography.component.scss']
})
export class BiographyComponent implements OnInit {


  showBiographyDialog = false;
  congressType = ['Senator', 'Representative'];
  selectedCongressType = 'Representative';
  selectedBiography = {} as SenatorBiography;

  // mat table vars
  displayedColumns: string[] = ['picture', 'firstName', 'lastName',
    'fullName', 'gender', 'party', 'state', 'netWorth', 'twitterHandler', 'actions'];
  biographyTableDataSource: MatTableDataSource<SenatorBiography>;
  pageSize = 5;
  pageSizeOptions: number[] = [10, 20, 50, 100, 1000];
  pageEvent: PageEvent;
  filters = {} as GetSenatorBiographiesParams;


  congressTypes = [
    {value: 'Representative', viewValue: 'Representatives'},
    {value: 'Senator', viewValue: 'Senators'}
  ];

  profileStatus = [
    {value: true, viewValue: 'Complete'},
    {value: false, viewValue: 'Incomplete'}
  ];

  loadingData: boolean;

  create = Mode.create;
  edit = Mode.edit;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private senatorBiographyService: SenatorBiographyService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.initVars();
    this.getBiographies();

  }

  initVars() {
    this.filters = {
      congressType: 'Representative',
      pageSize: 10,
      pageNumber: 1,
      isApproved: false
    };
    this.loadingData = false;
  }

  resetPageNumberAndSearchFiles() {
    this.paginator.firstPage();
    this.getBiographies();
  }

  private getBiographies() {
    this.loadingData = true;
    const params: GetSenatorBiographiesParams = this.filters;
    this.senatorBiographyService.getSenatorBiographies(params).subscribe(
      (res) => {
        this.biographyTableDataSource = new MatTableDataSource<SenatorBiography>(res);
      },
      error => {
        this.loadingData = false;

      },
      () => {
        this.loadingData = false;

      }
    );
  }

  openEditCreateBiographyDialog(mode: Mode, row: SenatorBiography = {}) {

    const dialogRef = this.dialog.open(EditAndCreateBiographyDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {data: row, mode: mode, congressType: this.filters.congressType}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getBiographies();
    });
  }

  unlockTheBiography(selectedBiography: SenatorBiography
  ) {
    this.senatorBiographyService.doSenatorBiographyLockAction(
      {action: 'UNLOCK', id: selectedBiography.id, congressType: this.selectedCongressType}
    ).subscribe();
  }

  lockTheBiography(senatorBiography: SenatorBiography) {
    let res;
    this.senatorBiographyService.doSenatorBiographyLockAction(
      {action: 'LOCK', id: senatorBiography.id, congressType: this.selectedCongressType}
    ).subscribe(next => {
      res = next;
    }, error => {
      if (error.error.status == 409) {
        console.log(error.error.message);
      }
    }, () => {
      if (res != null && res == true) {
        this.showBiographyDialog = true;
        this.selectedBiography = senatorBiography;
      }
    });
  }


  onPaginateChange(event: PageEvent) {
    this.filters.pageNumber = event.pageIndex + 1;
    this.filters.pageSize = event.pageSize;
    this.getBiographies();
  }


  visibilityChanged(event) {
    if (event == false) {
      this.unlockTheBiography(this.selectedBiography);
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {SenatorAnalystReport} from '../../../api/models/senator-analyst-report';
import {SenatorService} from '../../../api/services/senator.service';
import {MatTableDataSource} from '@angular/material/table';
import {formatDate} from '@angular/common';
import GetAnalystReportsParams = SenatorService.GetAnalystReportsParams;

interface PoliticianType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  analystStats: MatTableDataSource<SenatorAnalystReport>;
  displayedColumnForFiling: string[] = ['lastUpdatedByForFilings', 'processedFilingsCount', 'date'];
  displayedColumnForTrade: string[] = ['lastUpdatedByForTrades', 'processedTradesCount', 'date'];
  statsFilter: GetAnalystReportsParams = {
    date: formatDate(new Date(), 'yyyy-MM-dd', 'en_US'),
    congressType: 'Senator'
  };
  politicianTypes: PoliticianType[] = [
    {value: 'Senator', viewValue: 'Senator'},
    {value: 'Representative', viewValue: 'Representative'}
  ];

  isFilingLoading: boolean;

  constructor(private _senatorMappingService: SenatorService) {
  }

  ngOnInit() {
    this.showFilingsStats();

  }

  showFilingsStats() {
    this.isFilingLoading = true;
    const params: GetAnalystReportsParams = {
      date: this.statsFilter.date,
      congressType: this.statsFilter.congressType
    };
    // console.log('statsFilter', params);
    this._senatorMappingService.getAnalystReports(params).subscribe((data) => {
        // console.log('data', data);
        this.analystStats = new MatTableDataSource<SenatorAnalystReport>(data);
        // console.log('stats', this.analystStats);
        if (this.analystStats == null) {
          // console.log('no data');
        }
      },
      error => console.log(error),
      () => {
        this.isFilingLoading = false;
      }
    );
  }


}

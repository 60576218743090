/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BiographyCongressTenure } from '../models/biography-congress-tenure';
@Injectable({
  providedIn: 'root',
})
class BiographyCongressTenureService extends __BaseService {
  static readonly getCongressTenureByBiographyIdPath = '/senatorBiography/biography-congress-tenure';
  static readonly createBiographyCongressTenurePath = '/senatorBiography/biography-congress-tenure';
  static readonly updateBiographyCongressTenurePath = '/senatorBiography/biography-congress-tenure';
  static readonly deleteBiographyCongressTenurePath = '/senatorBiography/biography-congress-tenure';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param biographyId undefined
   * @return a model which is updated will be returned.
   */
  getCongressTenureByBiographyIdResponse(biographyId: number): __Observable<__StrictHttpResponse<Array<BiographyCongressTenure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (biographyId != null) __params = __params.set('biographyId', biographyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorBiography/biography-congress-tenure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BiographyCongressTenure>>;
      })
    );
  }
  /**
   * @param biographyId undefined
   * @return a model which is updated will be returned.
   */
  getCongressTenureByBiographyId(biographyId: number): __Observable<Array<BiographyCongressTenure>> {
    return this.getCongressTenureByBiographyIdResponse(biographyId).pipe(
      __map(_r => _r.body as Array<BiographyCongressTenure>)
    );
  }

  /**
   * @param params The `BiographyCongressTenureService.CreateBiographyCongressTenureParams` containing the following parameters:
   *
   * - `biographyId`:
   *
   * - `biographyCongressTenure`:
   *
   * @return a model which is updated will be returned.
   */
  createBiographyCongressTenureResponse(params: BiographyCongressTenureService.CreateBiographyCongressTenureParams): __Observable<__StrictHttpResponse<BiographyCongressTenure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.biographyId != null) __params = __params.set('biographyId', params.biographyId.toString());
    __body = params.biographyCongressTenure;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorBiography/biography-congress-tenure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BiographyCongressTenure>;
      })
    );
  }
  /**
   * @param params The `BiographyCongressTenureService.CreateBiographyCongressTenureParams` containing the following parameters:
   *
   * - `biographyId`:
   *
   * - `biographyCongressTenure`:
   *
   * @return a model which is updated will be returned.
   */
  createBiographyCongressTenure(params: BiographyCongressTenureService.CreateBiographyCongressTenureParams): __Observable<BiographyCongressTenure> {
    return this.createBiographyCongressTenureResponse(params).pipe(
      __map(_r => _r.body as BiographyCongressTenure)
    );
  }

  /**
   * @param biographyCongressTenure undefined
   * @return a model which is updated will be returned.
   */
  updateBiographyCongressTenureResponse(biographyCongressTenure: BiographyCongressTenure): __Observable<__StrictHttpResponse<BiographyCongressTenure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = biographyCongressTenure;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorBiography/biography-congress-tenure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BiographyCongressTenure>;
      })
    );
  }
  /**
   * @param biographyCongressTenure undefined
   * @return a model which is updated will be returned.
   */
  updateBiographyCongressTenure(biographyCongressTenure: BiographyCongressTenure): __Observable<BiographyCongressTenure> {
    return this.updateBiographyCongressTenureResponse(biographyCongressTenure).pipe(
      __map(_r => _r.body as BiographyCongressTenure)
    );
  }

  /**
   * @param biographytenureId undefined
   * @return A message will be sent on Successfull delete
   */
  deleteBiographyCongressTenureResponse(biographytenureId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (biographytenureId != null) __params = __params.set('biographytenureId', biographytenureId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/senatorBiography/biography-congress-tenure`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param biographytenureId undefined
   * @return A message will be sent on Successfull delete
   */
  deleteBiographyCongressTenure(biographytenureId: number): __Observable<string> {
    return this.deleteBiographyCongressTenureResponse(biographytenureId).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BiographyCongressTenureService {

  /**
   * Parameters for createBiographyCongressTenure
   */
  export interface CreateBiographyCongressTenureParams {
    biographyId: number;
    biographyCongressTenure: BiographyCongressTenure;
  }
}

export { BiographyCongressTenureService }

import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {SenatorQualityGateService, SenatorService} from '../../../../../api/services';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SenatorCompany} from '../../../../../api/models/senator-company';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SenatorIssuerMappingsFilterModel} from '../../../../../api/models/senator-issuer-mappings-filter-model';
import {SenatorQualityGateType} from '../../../../../api/models/senator-quality-gate-type';
import {SenatorQualityGateModel} from '../../../../../api/models/senator-quality-gate-model';
import {EditCompanyComponent} from './edit-company/edit-company.component';

import {SenatorAllCompaniesSearchFilter} from '../../../../../api/models/senator-all-companies-search-filter';
import {MatDialog} from '@angular/material/dialog';
import GetReplaceableAssetNamesParams = SenatorQualityGateService.GetReplaceableAssetNamesParams;
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-all-companies',
  templateUrl: './all-companies.component.html',
  styleUrls: ['./all-companies.component.scss']
})
export class AllCompaniesComponent implements OnInit, AfterViewInit {

  twoIQAssetsList: SenatorCompany[] = [];

  // mat table vars
  displayedColumns: string[] = ['name', 'gvkey', 'permId', 'ticker', 'isPublic', 'sentForCreation',
    'tradeCount', 'action'];
  issuerTableDataSource = new MatTableDataSource<SenatorCompany>();

  pageSize: number;
  pageNumber: number;
  pageSizeOptions: number[] = [10, 20, 50];
  pageEvent: PageEvent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loadingData: boolean;
  assetNamesLoading: boolean;
  typedSearchCriteria = '';
  today = new Date();
  date: string;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  startDateFilter = null;
  endDateFilter = null;
  searchAllCompanyFilter = {} as SenatorAllCompaniesSearchFilter;
  sortByList = ['Date Last Reported', 'Number of Trades'];
  private selectedCompany: SenatorCompany;
  isRecentlyAdded = false;


  constructor(private qualityGateService: SenatorQualityGateService,
              private _senatorService: SenatorService, private senatorService: SenatorService,
              public snackBar: MatSnackBar, private changeDetectorRefs: ChangeDetectorRef,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.initVars();
    this.getAllSenatorCompanies();
  }

  ngAfterViewInit() {
    if (this.issuerTableDataSource) {
      this.issuerTableDataSource.paginator = this.paginator;
      this.issuerTableDataSource.sort = this.sort;
    }
  }

  initVars() {
    this.date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();
    this.pageSize = 10;
    this.pageNumber = 1;
  }

  getAllSenatorCompanies() {
    this.loadingData = true;
    if (this.typedSearchCriteria != null && this.typedSearchCriteria.length < 3) {
      this.typedSearchCriteria = null;
    }
    const filterModel: SenatorIssuerMappingsFilterModel = {
      qualityGateType: SenatorQualityGateType.ALL_COMPANIES,
      onUpdateAndProcessingNewTrade: this.isRecentlyAdded,
      processedType: 'ALL',
      searchByAssetName: this.typedSearchCriteria,
      searchAllCompaniesFilter: this.searchAllCompanyFilter,
      fromDate: this.startDateFilter,
      toDate: this.endDateFilter
    };

    const params: GetReplaceableAssetNamesParams = {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      issuerMappingsFilterModel: filterModel
    };
    let response: SenatorQualityGateModel;
    this.qualityGateService.getReplaceableAssetNames(params).subscribe(
      (res: SenatorQualityGateModel) => {
        response = res;
      },
      error => {
        this.loadingData = false;
      },
      () => {
        this.loadingData = false;
        if (response == null) {
          return;
        }
        this.issuerTableDataSource = new MatTableDataSource<SenatorCompany>(response.rowsForAllCompanies);
        if (this.paginator != null) {
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.pageSize = this.pageSize;
        }
      }
    );
  }

  addCompnay() {
    this.selectedCompany = {ID: 0} as SenatorCompany;
    this.callTheDialog();
  }

  updateCompany(rowId: number) {
    this.selectedCompany = this.issuerTableDataSource.data.find(value => value.ID == rowId);
    this.callTheDialog();
  }

  openSnackBar(message: string, action: string
  ) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  onPaginateChange(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getAllSenatorCompanies();
  }

  applyFilter() {
    this.typedSearchCriteria = this.typedSearchCriteria.trim();
    this.typedSearchCriteria = this.typedSearchCriteria.toLowerCase();
    if (this.typedSearchCriteria.length < 1) {
      this.pageNumber = 1;
      this.getAllSenatorCompanies();
    }
    if (this.typedSearchCriteria.length < 3) {
      this.openSnackBar('Too short to search! At-least 3 characters are required.', 'Close');
      return;
    }
    this.pageNumber = 1;
    this.getAllSenatorCompanies();
  }

  searchByParams() {
    this.pageNumber = 1;
    this.convertStringFilterEmptyToNull();
    this.getAllSenatorCompanies();
  }

  convertBooleanToSimpleEnglish(value: boolean) {
    if (value == null || value == false) {
      return 'No';
    }
    return 'Yes';
  }

  sortByChanged(value: string) {
    this.searchAllCompanyFilter.sortByDateLastReported = (value == 'Date Last Reported');
    this.searchAllCompanyFilter.sortByNumberOfTrades = (value == 'Number of Trades');
    this.searchByParams();
  }

  private convertStringFilterEmptyToNull() {
    if (this.searchAllCompanyFilter.searchByGvkey == '') {
      this.searchAllCompanyFilter.searchByGvkey = null;
    }
    if (this.searchAllCompanyFilter.searchByTicker == '') {
      this.searchAllCompanyFilter.searchByTicker = null;
    }
    if (this.searchAllCompanyFilter.permId == '') {
      this.searchAllCompanyFilter.permId = null;
    }
  }

  onDateChange() {
    if (this.range.controls['start'].value !== null) {
      this.startDateFilter = this.getRequiredFormatDate(this.range.controls['start'].value);
    }
    if (this.range.controls['end'].value !== null) {
      this.endDateFilter = this.getRequiredFormatDate(this.range.controls['end'].value);
    }
    this.getAllSenatorCompanies();
  }

  getRequiredFormatDate(fpDate: any) {
    let formatMonth;
    let formatDay;
    const date = fpDate.getDate();
    const month = fpDate.getMonth();
    const year = fpDate.getFullYear();
    if (month < 10) {
      formatMonth = '0' + (month + 1).toString();
    } else {
      formatMonth = month + 1;
    }
    if (date < 10) {
      formatDay = '0' + (date).toString();
    } else {
      formatDay = date;
    }
    return year + '-' + formatMonth + '-' + formatDay;
  }

  private callTheDialog() {
    const dialogRef = this.dialog.open(EditCompanyComponent, {
      disableClose: true,
      width: '1100px',
      data: this.selectedCompany
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result)
      this.getAllSenatorCompanies();
    });
  }
}

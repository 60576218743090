/* tslint:disable */
type PoliticianIncomeType =
  'None' |
  'Dividends' |
  'Rent/Royalties' |
  'Interest' |
  'Capital Gains' |
  'Excepted Investment Fund' |
  'Excepted Trust' |
  'Qualified Blind Trust' |
  'NOT DISCLOSED';
module PoliticianIncomeType {
  export const NONE: PoliticianIncomeType = 'None';
  export const DIVIDENDS: PoliticianIncomeType = 'Dividends';
  export const RENT_ROYALTIES: PoliticianIncomeType = 'Rent/Royalties';
  export const INTEREST: PoliticianIncomeType = 'Interest';
  export const CAPITAL_GAINS: PoliticianIncomeType = 'Capital Gains';
  export const EXCEPTED_INVESTMENT_FUND: PoliticianIncomeType = 'Excepted Investment Fund';
  export const EXCEPTED_TRUST: PoliticianIncomeType = 'Excepted Trust';
  export const QUALIFIED_BLIND_TRUST: PoliticianIncomeType = 'Qualified Blind Trust';
  export const NOT_DISCLOSED: PoliticianIncomeType = 'NOT DISCLOSED';
  export function values(): PoliticianIncomeType[] {
    return [
      NONE,
      DIVIDENDS,
      RENT_ROYALTIES,
      INTEREST,
      CAPITAL_GAINS,
      EXCEPTED_INVESTMENT_FUND,
      EXCEPTED_TRUST,
      QUALIFIED_BLIND_TRUST,
      NOT_DISCLOSED
    ];
  }
}

export { PoliticianIncomeType }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Issuer } from '../models/issuer';
import { ShortDataSelling } from '../models/short-data-selling';
import { IssuerMappingModel } from '../models/issuer-mapping-model';
import { TopIssuers } from '../models/top-issuers';
import { Company } from '../models/company';
import { IssuerSuggestion } from '../models/issuer-suggestion';
import { RegionalIssuersManagersFilter } from '../models/regional-issuers-managers-filter';
@Injectable({
  providedIn: 'root',
})
class IssuerService extends __BaseService {
  static readonly getIssuerInfoPath = '/issuer/info/{issuerName}';
  static readonly getIssuerToMapPath = '/issuer/toMap/{isSkippedToo}';
  static readonly lockIssuerPath = '/issuer/lock';
  static readonly getIssuersToMapPath = '/issuer/toMap';
  static readonly getAllMappedIssuersToMapPath = '/issuer/remap/toMap';
  static readonly getAutoIsinMappedIssuersToMapPath = '/issuer/remap-auto-isin/toMap';
  static readonly getJapansIssuersToMapPath = '/issuer/japan/toMap';
  static readonly getCanadianIssuersToMapPath = '/issuer/canada/toMap';
  static readonly getAustralianIssuersToMapPath = '/issuer/australia/toMap';
  static readonly getUSAIssuersToMapPath = '/issuer/usa/toMap';
  static readonly approveAutoMappedAsCorrectPath = '/issuer/approve-auto-mapped';
  static readonly getTop5IssuersByManagersPath = '/issuer/top5/{managerName}';
  static readonly getTop5IssuersPath = '/issuer/top5';
  static readonly unlockIssuerPath = '/issuer/unlock';
  static readonly mapIssuerPath = '/issuer/doMap';
  static readonly mapIssuerKoreaPath = '/issuer/korea/doMap';
  static readonly mapIssuerCanadaPath = '/issuer/canada/doMap';
  static readonly mapIssuerAustraliaPath = '/issuer/australia/doMap';
  static readonly mapIssuerUSAPath = '/issuer/usa/doMap';
  static readonly getIssuersByNamePath = '/issuer/search/name/{issuerName}/{activeOnly}/';
  static readonly getUniqueIssuersPath = '/issuer/search/name';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param issuerName Issuer Name
   * @return IssuerInformation.
   */
  getIssuerInfoResponse(issuerName: string): __Observable<__StrictHttpResponse<Issuer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/info/${encodeURIComponent(String(issuerName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Issuer>;
      })
    );
  }
  /**
   * @param issuerName Issuer Name
   * @return IssuerInformation.
   */
  getIssuerInfo(issuerName: string): __Observable<Issuer> {
    return this.getIssuerInfoResponse(issuerName).pipe(
      __map(_r => _r.body as Issuer)
    );
  }

  /**
   * @param isSkippedToo undefined
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getIssuerToMapResponse(isSkippedToo: boolean): __Observable<__StrictHttpResponse<ShortDataSelling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/toMap/${encodeURIComponent(String(isSkippedToo))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShortDataSelling>;
      })
    );
  }
  /**
   * @param isSkippedToo undefined
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getIssuerToMap(isSkippedToo: boolean): __Observable<ShortDataSelling> {
    return this.getIssuerToMapResponse(isSkippedToo).pipe(
      __map(_r => _r.body as ShortDataSelling)
    );
  }

  /**
   * @param issuerName Issuer Name
   * @return Returns true or false about is locked.
   */
  lockIssuerResponse(issuerName: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuerName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuer/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuerName Issuer Name
   * @return Returns true or false about is locked.
   */
  lockIssuer(issuerName: string): __Observable<boolean> {
    return this.lockIssuerResponse(issuerName).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAllMappedIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/remap/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAllMappedIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getAllMappedIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAutoIsinMappedIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/remap-auto-isin/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAutoIsinMappedIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getAutoIsinMappedIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getJapansIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/japan/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getJapansIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getJapansIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getCanadianIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/canada/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getCanadianIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getCanadianIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAustralianIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/australia/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getAustralianIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getAustralianIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getUSAIssuersToMapResponse(): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/usa/toMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getUSAIssuersToMap(): __Observable<Array<IssuerMappingModel>> {
    return this.getUSAIssuersToMapResponse().pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @param issuer Auto isin/name matched ssuer to be marked as approved by admin
   * @return Returns approval status
   */
  approveAutoMappedAsCorrectResponse(issuer?: IssuerMappingModel): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/approve-auto-mapped`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Auto isin/name matched ssuer to be marked as approved by admin
   * @return Returns approval status
   */
  approveAutoMappedAsCorrect(issuer?: IssuerMappingModel): __Observable<boolean> {
    return this.approveAutoMappedAsCorrectResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param managerName Manager Name
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getTop5IssuersByManagersResponse(managerName: string): __Observable<__StrictHttpResponse<Array<TopIssuers>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/top5/${encodeURIComponent(String(managerName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TopIssuers>>;
      })
    );
  }
  /**
   * @param managerName Manager Name
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getTop5IssuersByManagers(managerName: string): __Observable<Array<TopIssuers>> {
    return this.getTop5IssuersByManagersResponse(managerName).pipe(
      __map(_r => _r.body as Array<TopIssuers>)
    );
  }

  /**
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getTop5IssuersResponse(): __Observable<__StrictHttpResponse<Array<Issuer>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/top5`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Issuer>>;
      })
    );
  }
  /**
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  getTop5Issuers(): __Observable<Array<Issuer>> {
    return this.getTop5IssuersResponse().pipe(
      __map(_r => _r.body as Array<Issuer>)
    );
  }

  /**
   * @return Returns true or false about isUnlocked.
   */
  unlockIssuerResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/unlock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Returns true or false about isUnlocked.
   */
  unlockIssuer(): __Observable<boolean> {
    return this.unlockIssuerResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerResponse(issuer?: ShortDataSelling): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/doMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuer(issuer?: ShortDataSelling): __Observable<boolean> {
    return this.mapIssuerResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerKoreaResponse(issuer?: IssuerMappingModel): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/korea/doMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerKorea(issuer?: IssuerMappingModel): __Observable<boolean> {
    return this.mapIssuerKoreaResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerCanadaResponse(issuer?: IssuerMappingModel): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/canada/doMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerCanada(issuer?: IssuerMappingModel): __Observable<boolean> {
    return this.mapIssuerCanadaResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerAustraliaResponse(issuer?: IssuerMappingModel): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/australia/doMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerAustralia(issuer?: IssuerMappingModel): __Observable<boolean> {
    return this.mapIssuerAustraliaResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerUSAResponse(issuer?: IssuerMappingModel): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = issuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuer/usa/doMap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param issuer Array of Companioes which needs to be updated
   * @return Returns Array of objects of ShortDataSelling modal.
   */
  mapIssuerUSA(issuer?: IssuerMappingModel): __Observable<boolean> {
    return this.mapIssuerUSAResponse(issuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `IssuerService.GetIssuersByNameParams` containing the following parameters:
   *
   * - `issuerName`: Send approx name we will suggest company
   *
   * - `activeOnly`: Search among active or not.
   *
   * @return Returns Array of company modal.
   */
  getIssuersByNameResponse(params: IssuerService.GetIssuersByNameParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/search/name/${encodeURIComponent(String(params.issuerName))}/${encodeURIComponent(String(params.activeOnly))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `IssuerService.GetIssuersByNameParams` containing the following parameters:
   *
   * - `issuerName`: Send approx name we will suggest company
   *
   * - `activeOnly`: Search among active or not.
   *
   * @return Returns Array of company modal.
   */
  getIssuersByName(params: IssuerService.GetIssuersByNameParams): __Observable<Array<Company>> {
    return this.getIssuersByNameResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param regionalIssuersManagersFilter undefined
   * @return List of Issuers.
   */
  getUniqueIssuersResponse(regionalIssuersManagersFilter: RegionalIssuersManagersFilter): __Observable<__StrictHttpResponse<Array<IssuerSuggestion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = regionalIssuersManagersFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuer/search/name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerSuggestion>>;
      })
    );
  }
  /**
   * @param regionalIssuersManagersFilter undefined
   * @return List of Issuers.
   */
  getUniqueIssuers(regionalIssuersManagersFilter: RegionalIssuersManagersFilter): __Observable<Array<IssuerSuggestion>> {
    return this.getUniqueIssuersResponse(regionalIssuersManagersFilter).pipe(
      __map(_r => _r.body as Array<IssuerSuggestion>)
    );
  }
}

module IssuerService {

  /**
   * Parameters for getIssuersByName
   */
  export interface GetIssuersByNameParams {

    /**
     * Send approx name we will suggest company
     */
    issuerName: string;

    /**
     * Search among active or not.
     */
    activeOnly: boolean;
  }
}

export { IssuerService }

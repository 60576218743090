/* tslint:disable */
type TradeAmendmentStatusEnum =
  'Retain' |
  'Block';
module TradeAmendmentStatusEnum {
  export const RETAIN: TradeAmendmentStatusEnum = 'Retain';
  export const BLOCK: TradeAmendmentStatusEnum = 'Block';
  export function values(): TradeAmendmentStatusEnum[] {
    return [
      RETAIN,
      BLOCK
    ];
  }
}

export { TradeAmendmentStatusEnum }
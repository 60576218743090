/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BuybackIntention } from '../models/buyback-intention';
import { OnMarketTest } from '../models/on-market-test';
import { BuybackNote } from '../models/buyback-note';
import { BuybackNullValueIntention } from '../models/buyback-null-value-intention';
import { NullValueIntentionsFilter } from '../models/null-value-intentions-filter';
@Injectable({
  providedIn: 'root',
})
class IntentionService extends __BaseService {
  static readonly getBuybackIntentionsPath = '/intention/{companyID}/{securityID}';
  static readonly getBuybackIntentionsWithMandatePath = '/intention/Mandate/{companyID}/{securityID}';
  static readonly getSubBuybackIntentionsPath = '/subIntention/{buybackIntentionID}';
  static readonly deleteBuyBackIntentionPath = '/intention/delete/{buybackIntentionID}/{noteID}';
  static readonly checkDuplicateIntentionPath = '/intention/duplicate/{companyID}/{securityID}/{announcementDate}/{amountBasis}/{isNewBuyback}';
  static readonly checkIntentionByAnnsDatePath = '/intention/checkByAnnsDate/{companyID}/{securityID}/{announcementDate}/';
  static readonly getBuybackCompanyNotesPath = '/intention/notes/{companyID}';
  static readonly processBuybackIntentionPath = '/intention/process';
  static readonly getIntentionTypesPath = '/intention/type';
  static readonly getNullValueBuybackIntentionsPath = '/intention/NotNull/';
  static readonly updateNewValuesToNullValueIntentionsPath = '/intention/NotNull/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `IntentionService.GetBuybackIntentionsParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Buyback Intentions List
   */
  getBuybackIntentionsResponse(params: IntentionService.GetBuybackIntentionsParams): __Observable<__StrictHttpResponse<Array<BuybackIntention>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackIntention>>;
      })
    );
  }
  /**
   * @param params The `IntentionService.GetBuybackIntentionsParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Buyback Intentions List
   */
  getBuybackIntentions(params: IntentionService.GetBuybackIntentionsParams): __Observable<Array<BuybackIntention>> {
    return this.getBuybackIntentionsResponse(params).pipe(
      __map(_r => _r.body as Array<BuybackIntention>)
    );
  }

  /**
   * @param params The `IntentionService.GetBuybackIntentionsWithMandateParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Buyback Intentions List
   */
  getBuybackIntentionsWithMandateResponse(params: IntentionService.GetBuybackIntentionsWithMandateParams): __Observable<__StrictHttpResponse<Array<BuybackIntention>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/Mandate/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackIntention>>;
      })
    );
  }
  /**
   * @param params The `IntentionService.GetBuybackIntentionsWithMandateParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Buyback Intentions List
   */
  getBuybackIntentionsWithMandate(params: IntentionService.GetBuybackIntentionsWithMandateParams): __Observable<Array<BuybackIntention>> {
    return this.getBuybackIntentionsWithMandateResponse(params).pipe(
      __map(_r => _r.body as Array<BuybackIntention>)
    );
  }

  /**
   * @param buybackIntentionID buybackIntention ID
   * @return Buyback Sub Intentions List
   */
  getSubBuybackIntentionsResponse(buybackIntentionID: number): __Observable<__StrictHttpResponse<Array<BuybackIntention>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/subIntention/${encodeURIComponent(String(buybackIntentionID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackIntention>>;
      })
    );
  }
  /**
   * @param buybackIntentionID buybackIntention ID
   * @return Buyback Sub Intentions List
   */
  getSubBuybackIntentions(buybackIntentionID: number): __Observable<Array<BuybackIntention>> {
    return this.getSubBuybackIntentionsResponse(buybackIntentionID).pipe(
      __map(_r => _r.body as Array<BuybackIntention>)
    );
  }

  /**
   * @param params The `IntentionService.DeleteBuyBackIntentionParams` containing the following parameters:
   *
   * - `noteID`: Note ID
   *
   * - `buybackIntentionID`: Buyback Intention Id
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Buyback Intention Delete Status
   */
  deleteBuyBackIntentionResponse(params: IntentionService.DeleteBuyBackIntentionParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.buybackIntentionGroupByID != null) __params = __params.set('buybackIntentionGroupByID', params.buybackIntentionGroupByID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/delete/${encodeURIComponent(String(params.buybackIntentionID))}/${encodeURIComponent(String(params.noteID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `IntentionService.DeleteBuyBackIntentionParams` containing the following parameters:
   *
   * - `noteID`: Note ID
   *
   * - `buybackIntentionID`: Buyback Intention Id
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Buyback Intention Delete Status
   */
  deleteBuyBackIntention(params: IntentionService.DeleteBuyBackIntentionParams): __Observable<boolean> {
    return this.deleteBuyBackIntentionResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `IntentionService.CheckDuplicateIntentionParams` containing the following parameters:
   *
   * - `securityID`: security ID
   *
   * - `isNewBuyback`: Amount Basis
   *
   * - `companyID`: Company ID
   *
   * - `announcementDate`: Announcement Date
   *
   * - `amountBasis`: Amount Basis
   *
   * - `intentionValue`: Intention Value
   *
   * - `intentionPercentage`: Intention Percentage
   *
   * - `intentionAmount`: Intention Amount
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateIntentionResponse(params: IntentionService.CheckDuplicateIntentionParams): __Observable<__StrictHttpResponse<OnMarketTest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.intentionValue != null) __params = __params.set('intentionValue', params.intentionValue.toString());
    if (params.intentionPercentage != null) __params = __params.set('intentionPercentage', params.intentionPercentage.toString());
    if (params.intentionAmount != null) __params = __params.set('intentionAmount', params.intentionAmount.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/duplicate/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.announcementDate))}/${encodeURIComponent(String(params.amountBasis))}/${encodeURIComponent(String(params.isNewBuyback))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnMarketTest>;
      })
    );
  }
  /**
   * @param params The `IntentionService.CheckDuplicateIntentionParams` containing the following parameters:
   *
   * - `securityID`: security ID
   *
   * - `isNewBuyback`: Amount Basis
   *
   * - `companyID`: Company ID
   *
   * - `announcementDate`: Announcement Date
   *
   * - `amountBasis`: Amount Basis
   *
   * - `intentionValue`: Intention Value
   *
   * - `intentionPercentage`: Intention Percentage
   *
   * - `intentionAmount`: Intention Amount
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateIntention(params: IntentionService.CheckDuplicateIntentionParams): __Observable<OnMarketTest> {
    return this.checkDuplicateIntentionResponse(params).pipe(
      __map(_r => _r.body as OnMarketTest)
    );
  }

  /**
   * @param params The `IntentionService.CheckIntentionByAnnsDateParams` containing the following parameters:
   *
   * - `securityID`: security ID
   *
   * - `companyID`: Company ID
   *
   * - `announcementDate`: Announcement Date
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkIntentionByAnnsDateResponse(params: IntentionService.CheckIntentionByAnnsDateParams): __Observable<__StrictHttpResponse<OnMarketTest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/checkByAnnsDate/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.announcementDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnMarketTest>;
      })
    );
  }
  /**
   * @param params The `IntentionService.CheckIntentionByAnnsDateParams` containing the following parameters:
   *
   * - `securityID`: security ID
   *
   * - `companyID`: Company ID
   *
   * - `announcementDate`: Announcement Date
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkIntentionByAnnsDate(params: IntentionService.CheckIntentionByAnnsDateParams): __Observable<OnMarketTest> {
    return this.checkIntentionByAnnsDateResponse(params).pipe(
      __map(_r => _r.body as OnMarketTest)
    );
  }

  /**
   * @param companyID Company ID
   * @return Buyback Intention Notes
   */
  getBuybackCompanyNotesResponse(companyID: number): __Observable<__StrictHttpResponse<Array<BuybackNote>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/notes/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackNote>>;
      })
    );
  }
  /**
   * @param companyID Company ID
   * @return Buyback Intention Notes
   */
  getBuybackCompanyNotes(companyID: number): __Observable<Array<BuybackNote>> {
    return this.getBuybackCompanyNotesResponse(companyID).pipe(
      __map(_r => _r.body as Array<BuybackNote>)
    );
  }

  /**
   * @param Buyback Intention Post intention detail.
   * @return Announcement Detail.
   */
  processBuybackIntentionResponse(BuybackIntention: BuybackIntention): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = BuybackIntention;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/intention/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param Buyback Intention Post intention detail.
   * @return Announcement Detail.
   */
  processBuybackIntention(BuybackIntention: BuybackIntention): __Observable<number> {
    return this.processBuybackIntentionResponse(BuybackIntention).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @return Intention Types.
   */
  getIntentionTypesResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intention/type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Intention Types.
   */
  getIntentionTypes(): __Observable<Array<string>> {
    return this.getIntentionTypesResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param NullValuesIntentionsFilter Null Values Intentions Filters
   * @return Buyback Intentions List
   */
  getNullValueBuybackIntentionsResponse(NullValuesIntentionsFilter: NullValueIntentionsFilter): __Observable<__StrictHttpResponse<Array<BuybackNullValueIntention>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = NullValuesIntentionsFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/intention/NotNull/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackNullValueIntention>>;
      })
    );
  }
  /**
   * @param NullValuesIntentionsFilter Null Values Intentions Filters
   * @return Buyback Intentions List
   */
  getNullValueBuybackIntentions(NullValuesIntentionsFilter: NullValueIntentionsFilter): __Observable<Array<BuybackNullValueIntention>> {
    return this.getNullValueBuybackIntentionsResponse(NullValuesIntentionsFilter).pipe(
      __map(_r => _r.body as Array<BuybackNullValueIntention>)
    );
  }

  /**
   * @param buybackNullValueIntention Intention To be Updated
   * @return Return boolean if updated.
   */
  updateNewValuesToNullValueIntentionsResponse(buybackNullValueIntention: BuybackNullValueIntention): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = buybackNullValueIntention;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/intention/NotNull/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param buybackNullValueIntention Intention To be Updated
   * @return Return boolean if updated.
   */
  updateNewValuesToNullValueIntentions(buybackNullValueIntention: BuybackNullValueIntention): __Observable<boolean> {
    return this.updateNewValuesToNullValueIntentionsResponse(buybackNullValueIntention).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module IntentionService {

  /**
   * Parameters for getBuybackIntentions
   */
  export interface GetBuybackIntentionsParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company ID
     */
    companyID: number;
  }

  /**
   * Parameters for getBuybackIntentionsWithMandate
   */
  export interface GetBuybackIntentionsWithMandateParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company ID
     */
    companyID: number;
  }

  /**
   * Parameters for deleteBuyBackIntention
   */
  export interface DeleteBuyBackIntentionParams {

    /**
     * Note ID
     */
    noteID: number;

    /**
     * Buyback Intention Id
     */
    buybackIntentionID: number;

    /**
     * Buyback Intention Group Id
     */
    buybackIntentionGroupByID?: number;
  }

  /**
   * Parameters for checkDuplicateIntention
   */
  export interface CheckDuplicateIntentionParams {

    /**
     * security ID
     */
    securityID: number;

    /**
     * Amount Basis
     */
    isNewBuyback: boolean;

    /**
     * Company ID
     */
    companyID: number;

    /**
     * Announcement Date
     */
    announcementDate: string;

    /**
     * Amount Basis
     */
    amountBasis: number;

    /**
     * Intention Value
     */
    intentionValue?: string;

    /**
     * Intention Percentage
     */
    intentionPercentage?: string;

    /**
     * Intention Amount
     */
    intentionAmount?: string;
  }

  /**
   * Parameters for checkIntentionByAnnsDate
   */
  export interface CheckIntentionByAnnsDateParams {

    /**
     * security ID
     */
    securityID: number;

    /**
     * Company ID
     */
    companyID: number;

    /**
     * Announcement Date
     */
    announcementDate: string;
  }
}

export { IntentionService }

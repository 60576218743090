import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SenatorFiling} from '../../../api/models/senator-filing';
import {SenatorTradeFilter} from '../../../api/models/senator-trade-filter';
import {SenatorService} from '../../../api/services/senator.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {SenatorTrades} from '../../../api/models/senator-trades';
import GetPoliticianAssetTradesByFilingIdParams = SenatorService.GetPoliticianAssetTradesByFilingIdParams;

@Component({
  selector: 'app-anual-reports',
  templateUrl: './annual-reports.component.html',
  styleUrls: ['./annual-reports.component.scss']
})
export class AnnualReportsComponent implements OnInit, AfterViewInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['filingId', 'filingDate', 'fileStatus', 'processedBy', 'firstName', 'lastName', 'officeName',
    'reportType', 'sourceLink', 'actions'];

  tableDataSource: MatTableDataSource<SenatorFiling>;
  reportSearchFilters = {pageSize: 10, pageNumber: 1} as SenatorTradeFilter;
  reportSearchMinDate: Date;
  reportSearchMaxDate: Date;
  // MatPaginator Inputs
  length = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  user: string = null;
  isFilingLoading = false;
  loginUser: string;
  parties = [
    {value: 'Representative', viewValue: 'Representative'},
    {value: 'Senator', viewValue: 'Senator'}
  ];

  readonly DATE_FORMAT = 'yyyy-MM-dd';

  constructor(private _senatorService: SenatorService, private router: Router,
              private datePipe: DatePipe,
              private matDialog: MatDialog, public snackBar: MatSnackBar,) {
  }

  ngOnInit() {

    this.user = localStorage.getItem('userId');
    this.initVars();
    this.getReports();
  }

  ngAfterViewInit() {
    if (this.tableDataSource) {
      this.tableDataSource.paginator = this.paginator;
    }
  }

  initVars() {
    // getting current login user info    this.loginUser = localStorage.getItem('userId');
    this.reportSearchMinDate = new Date(2010, 0, 1);
    this.reportSearchMaxDate = new Date();

    const savedReportSearchFilters = JSON.parse(localStorage.getItem('reportSearchFilters'));
    const timestamp = new Date(localStorage.getItem('reportSearchFiltersStoredAt'));
    let timeSuitable = false;
    if (timestamp !== null) {
      timeSuitable = timestamp.getDate() == new Date().getDate();
    }
    if (!savedReportSearchFilters || !timeSuitable) {

      const toDateFormat = this.datePipe.transform(new Date(), this.DATE_FORMAT);
      this.reportSearchFilters = {
        fromDate: '2010-01-01',
        toDate: toDateFormat,
        pageNumber: 1,
        pageSize: 10,
        congressType: 'Senator',
        politicianName: '',
        companyName: null,
        filingId: null,
        isPaperFilesOnly: false,
        isApproved: false,
        isMissingGvkey: false,
        isMissingFilingName: false,
        isSplittedFiles: false,
        tradeType: [],
        url: null
      };
      this.setFiltersInLocalStorage();
    } else {
      this.reportSearchFilters = this.getFilterFromLocalStorage();

    }
  }

  applyFilterAndSearch() {
    this.paginator.firstPage();
    this.setFiltersInLocalStorage();
    this.getReports();
  }

  getReports() {
    this.isFilingLoading = true;
    // setting date formats
    const filters = this.getFilterFromLocalStorage();

    this._senatorService.getSenatorAssets(filters).subscribe((res: SenatorFiling[]) => {
      this.tableDataSource = new MatTableDataSource<SenatorFiling>(res);
      this.tableDataSource.sort = this.sort;

    }, (err) => {
      console.log('err', err);
    }, () => {
      // console.log('this.senatorFilling', this.senatorFilling);
      if (this.paginator != null) {
        this.paginator.pageIndex = this.reportSearchFilters.pageNumber - 1;
        this.paginator.pageSize = this.reportSearchFilters.pageSize;
      }
      this.isFilingLoading = false;
    });
  }

  reportSearchDateToggle() {
    if (this.reportSearchFilters.fromDate === null) {
      this.reportSearchFilters.fromDate = '2010-01-01';
    } else if (this.reportSearchFilters.toDate == null) {
      const todayDate = new Date();
      this.reportSearchFilters.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
    }
    this.paginator.firstPage();
    this.setFiltersInLocalStorage();
    this.getReports();
  }

  private setFiltersInLocalStorage() {
    const reportSearchFromDate = new Date(this.reportSearchFilters.fromDate);
    const reportSearchToDate = new Date(this.reportSearchFilters.toDate);
    const reportSearchFromDateFormat = this.datePipe.transform(reportSearchFromDate, this.DATE_FORMAT);
    const reportSearchToDateFormat = this.datePipe.transform(reportSearchToDate, this.DATE_FORMAT);


    const filters: SenatorTradeFilter = {
      fromDate: reportSearchFromDateFormat,
      toDate: reportSearchToDateFormat,
      pageNumber: this.reportSearchFilters.pageNumber,
      pageSize: this.reportSearchFilters.pageSize,
      congressType: this.reportSearchFilters.congressType,
      isPaperFilesOnly: this.reportSearchFilters.isPaperFilesOnly,
      isApproved: this.reportSearchFilters.isApproved,
      politicianName: this.reportSearchFilters.politicianName,
      companyName: this.reportSearchFilters.companyName,
      filingId: this.reportSearchFilters.filingId,
      isMissingGvkey: this.reportSearchFilters.isMissingGvkey,
      isMissingFilingName: this.reportSearchFilters.isMissingFilingName,
      tradeType: this.reportSearchFilters.tradeType,
      isSplittedFiles: this.reportSearchFilters.isSplittedFiles,
      url: this.reportSearchFilters.url
    };

    localStorage.setItem('reportSearchFilters', JSON.stringify(filters));
    localStorage.setItem('reportSearchFiltersStoredAt', this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
  }

  private getFilterFromLocalStorage(): SenatorTradeFilter {
    return JSON.parse(localStorage.getItem('reportSearchFilters'));
  }

  onPaginateChange(event: PageEvent) {
    if (event == null) {
      return;
    }
    this.reportSearchFilters.pageNumber = event.pageIndex + 1;
    this.reportSearchFilters.pageSize = event.pageSize;
    if (this.paginator == null) {
      return;
    }

    this.setFiltersInLocalStorage();
    this.getReports();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'left'
    });
  }

  isReportLocked(file: SenatorFiling): Observable<boolean> {
    const subject = new Subject<boolean>();
    const params: GetPoliticianAssetTradesByFilingIdParams = {
      filingId: file.filingId,
      congressType: file.congressType
    };
    this._senatorService.getPoliticianAssetTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      subject.next(false);
    }, (err) => {
      this.openSnackBar(err.error.message, 'Close');
      subject.next(true);
    }, () => {
    });
    return subject.asObservable();
  }

  onReportEditClick(file: SenatorFiling) {
    this.isReportLocked(file).subscribe(lock => {
      if (!lock) {
        this.router.navigate(['/annual-report', file.congressType, file.filingId, file.sourceUrl]);
      }
    });
  }

}

/* tslint:disable */
type CongressType =
  'Senator' |
  'Representative';
module CongressType {
  export const SENATOR: CongressType = 'Senator';
  export const REPRESENTATIVE: CongressType = 'Representative';
  export function values(): CongressType[] {
    return [
      SENATOR,
      REPRESENTATIVE
    ];
  }
}

export { CongressType }
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StockOptionListing} from '../../../../../../../api/models/stock-option-listing';
import {AddEditListedOptionsComponent} from './add-edit-listed-options/add-edit-listed-options.component';
import {StockOptionListingService} from '../../../../../../../api/services/stock-option-listing.service';
import {MatTableDataSource} from '@angular/material/table';
import {SenatorCompanyAssetClass} from '../../../../../../../api/models/senator-company-asset-class';
import {SenatorCompanyAssetClassService} from '../../../../../../../api/services/senator-company-asset-class.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-listed-options',
  templateUrl: './listed-options.component.html',
  styleUrls: ['./listed-options.component.css']
})
export class ListedOptionsComponent implements OnInit {

  loadingData = false;
  displayedColumns = ['securityTicker', 'tradeCount', 'stockOptionType', 'underlyingAssetType', 'style', 'strikePrice',
    'expirationDate', 'action'];
  tableDataSource: MatTableDataSource<StockOptionListing>;
  pageSize: number;
  pageNumber: number;
  pageSizeOptions: number[];
  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  senatorCompanyAssetClassId: number;
  assetClasses = [] as SenatorCompanyAssetClass[];
  private financialInstrument = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ListedOptionsComponent>,
              private stockOptionListingService: StockOptionListingService,
              private matDialog: MatDialog,
              private _senatorAssetClassService: SenatorCompanyAssetClassService,
              public snackBar: MatSnackBar) {
    this.senatorCompanyAssetClassId = this.data.senatorCompanyAssetClassId;

  }

  ngOnInit() {
    this.pageSize = 10;
    this.pageNumber = 1;
    this.pageSizeOptions = [10, 20, 50, 100];

    this.getAssetClassesList();
    this.getStockOptionListings();

  }

  getStockOptionListings() {
    this.stockOptionListingService.getStockOptionListings(this.senatorCompanyAssetClassId).subscribe(
      (res: StockOptionListing[]) => {
        this.tableDataSource = new MatTableDataSource<StockOptionListing>(res);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
      },
      error => {
      },
      () => {
        this.tableDataSource.data = this.tableDataSource.data.sort(function (a, b) {
          // @ts-ignore
          return new Date(b.expirationDate) - new Date(a.expirationDate);
        });
      }
    );
  }

  getAssetClassesList() {
    this.assetClasses = [];
    this._senatorAssetClassService.getAssetClassesOfTheCompany({gvkey: '', name: '', ID: this.data.refSenatorCompanyId})
      .subscribe(value1 => this.assetClasses = value1, error => {

      }, () => {
        this.financialInstrument = this.assetClasses.find(t => t.ID == this.senatorCompanyAssetClassId).x2iqFinInst;
      });
  }

  onAddUpdateStockOptionListings(operation: string, stockOptionListing?: StockOptionListing) {

    const dialogRef = this.matDialog.open(AddEditListedOptionsComponent, {
      disableClose: true,
      width: '700px',
      data: {
        operation: operation,
        stockOptionListing: stockOptionListing,
        senatorCompanyAssetClassId: this.data.senatorCompanyAssetClassId,
        assetClasses: this.assetClasses,
        financialInstrument: this.financialInstrument
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getStockOptionListings();
    });
  }

  onDeleteStockOptionListings(stockOptionListing: StockOptionListing) {
    const fileDeleteDialogRef = this.matDialog.open(StockOptionListingDeleteDialogComponent, {
      width: '550px',
      height: '250px',
      data: stockOptionListing
    });
    fileDeleteDialogRef.afterClosed().subscribe((res) => {
      this.getStockOptionListings();
    });
  }

  selectOption(action: string, stockOptionListing: StockOptionListing) {
    this.dialogRef.close({data: {action: action, optionListingId: stockOptionListing.optionListingId}});
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }


  onPaginateChange(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  }
}

@Component({
  selector: 'app-stock-option-listing-delete-dialog-Component',
  template: `
    <h1 mat-dialog-title>Delete Stock Option</h1>
    <div mat-dialog-content>
      <h4>Are you sure, you want to delete stock option listing ?</h4><br>
      <h5>Stock option type : {{data?.stockOptionType}}</h5>
      <h5>Stock Option Listing Id : {{data?.optionListingId}}</h5>
    </div>
    <mat-progress-spinner class="progressSpinner" *ngIf="isProcessing"
                          [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


    <div mat-dialog-actions align="end">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="warn" (click)="onDeleteFile()">Delete</button>
    </div>`,
})
export class StockOptionListingDeleteDialogComponent implements OnInit {
  isProcessing = false;
  filingId: number;
  congressType: string;

  constructor(
    public dialogRef: MatDialogRef<StockOptionListingDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stockOptionListingService: StockOptionListingService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDeleteFile() {

    this.isProcessing = true;
    this.stockOptionListingService.deleteStockOptionListing(this.data.optionListingId).subscribe((res) => {
    }, (err) => {

      this.isProcessing = false;
      this.openSnackBar(`Error!,  ${JSON.parse(err.error).message}`, 'Close');
    }, () => {
      this.isProcessing = false;
      this.dialogRef.close();
      this.openSnackBar('Stock Option has been deleted successfully!', 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}


/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorCompanyAssetClass } from '../models/senator-company-asset-class';
import { SenatorCompany } from '../models/senator-company';
@Injectable({
  providedIn: 'root',
})
class SenatorCompanyAssetClassService extends __BaseService {
  static readonly getAssetClassesOfTheCompanyPath = '/senatorCompanyAssetClass';
  static readonly addUpdateSenatorCompanyAssetClassPath = '/senatorCompanyAssetClass/add_update';
  static readonly mergeSenatorCompanyAssetClassPath = '/senatorCompanyAssetClass/{assetId}/merge_to/{toAssetId}';
  static readonly deMergerSenatorCompanyAssetClassPath = '/senatorCompanyAssetClass/demerger';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param company undefined
   * @return it will return the saved company asset class
   */
  getAssetClassesOfTheCompanyResponse(company: SenatorCompany): __Observable<__StrictHttpResponse<Array<SenatorCompanyAssetClass>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = company;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorCompanyAssetClass`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorCompanyAssetClass>>;
      })
    );
  }
  /**
   * @param company undefined
   * @return it will return the saved company asset class
   */
  getAssetClassesOfTheCompany(company: SenatorCompany): __Observable<Array<SenatorCompanyAssetClass>> {
    return this.getAssetClassesOfTheCompanyResponse(company).pipe(
      __map(_r => _r.body as Array<SenatorCompanyAssetClass>)
    );
  }

  /**
   * @param assetClass undefined
   * @return it will return the saved or updated asset class, or null object if not successful
   */
  addUpdateSenatorCompanyAssetClassResponse(assetClass: SenatorCompanyAssetClass): __Observable<__StrictHttpResponse<SenatorCompanyAssetClass>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = assetClass;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorCompanyAssetClass/add_update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompanyAssetClass>;
      })
    );
  }
  /**
   * @param assetClass undefined
   * @return it will return the saved or updated asset class, or null object if not successful
   */
  addUpdateSenatorCompanyAssetClass(assetClass: SenatorCompanyAssetClass): __Observable<SenatorCompanyAssetClass> {
    return this.addUpdateSenatorCompanyAssetClassResponse(assetClass).pipe(
      __map(_r => _r.body as SenatorCompanyAssetClass)
    );
  }

  /**
   * @param params The `SenatorCompanyAssetClassService.MergeSenatorCompanyAssetClassParams` containing the following parameters:
   *
   * - `toAssetId`:
   *
   * - `assetId`:
   *
   * @return it will return the merged to asset class, or null object if not successful
   */
  mergeSenatorCompanyAssetClassResponse(params: SenatorCompanyAssetClassService.MergeSenatorCompanyAssetClassParams): __Observable<__StrictHttpResponse<SenatorCompanyAssetClass>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorCompanyAssetClass/${encodeURIComponent(String(params.assetId))}/merge_to/${encodeURIComponent(String(params.toAssetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompanyAssetClass>;
      })
    );
  }
  /**
   * @param params The `SenatorCompanyAssetClassService.MergeSenatorCompanyAssetClassParams` containing the following parameters:
   *
   * - `toAssetId`:
   *
   * - `assetId`:
   *
   * @return it will return the merged to asset class, or null object if not successful
   */
  mergeSenatorCompanyAssetClass(params: SenatorCompanyAssetClassService.MergeSenatorCompanyAssetClassParams): __Observable<SenatorCompanyAssetClass> {
    return this.mergeSenatorCompanyAssetClassResponse(params).pipe(
      __map(_r => _r.body as SenatorCompanyAssetClass)
    );
  }

  /**
   * @param demergerAssetClass This include companyId.
   * @return it will return the demerged to asset class, or null object if not successful
   */
  deMergerSenatorCompanyAssetClassResponse(demergerAssetClass: SenatorCompanyAssetClass): __Observable<__StrictHttpResponse<SenatorCompanyAssetClass>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = demergerAssetClass;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorCompanyAssetClass/demerger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompanyAssetClass>;
      })
    );
  }
  /**
   * @param demergerAssetClass This include companyId.
   * @return it will return the demerged to asset class, or null object if not successful
   */
  deMergerSenatorCompanyAssetClass(demergerAssetClass: SenatorCompanyAssetClass): __Observable<SenatorCompanyAssetClass> {
    return this.deMergerSenatorCompanyAssetClassResponse(demergerAssetClass).pipe(
      __map(_r => _r.body as SenatorCompanyAssetClass)
    );
  }
}

module SenatorCompanyAssetClassService {

  /**
   * Parameters for mergeSenatorCompanyAssetClass
   */
  export interface MergeSenatorCompanyAssetClassParams {
    toAssetId: number;
    assetId: number;
  }
}

export { SenatorCompanyAssetClassService }

/* tslint:disable */

/**
 * a model that tells what type of quality gate need to be displayed
 */
type SenatorQualityGateType =
  'ALL_COMPANIES' |
  'Listed_Options';
module SenatorQualityGateType {
  export const ALL_COMPANIES: SenatorQualityGateType = 'ALL_COMPANIES';
  export const LISTED_OPTIONS: SenatorQualityGateType = 'Listed_Options';
  export function values(): SenatorQualityGateType[] {
    return [
      ALL_COMPANIES,
      LISTED_OPTIONS
    ];
  }
}

export { SenatorQualityGateType }
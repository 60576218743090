/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorBiography } from '../models/senator-biography';
@Injectable({
  providedIn: 'root',
})
class SenatorBiographyService extends __BaseService {
  static readonly getSenatorBiographiesPath = '/senatorBiography/{congressType}/';
  static readonly updateSenatorBiographyPath = '/senatorBiography/{congressType}/';
  static readonly updateSenatorBiographiesFromProPublicaPath = '/senatorBiography/update/from/propublica';
  static readonly doSenatorBiographyLockActionPath = '/senatorBiography/lock/{action}/{id}/{congressType}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SenatorBiographyService.GetSenatorBiographiesParams` containing the following parameters:
   *
   * - `congressType`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `isApproved`:
   *
   * @return A list of senator biography.
   */
  getSenatorBiographiesResponse(params: SenatorBiographyService.GetSenatorBiographiesParams): __Observable<__StrictHttpResponse<Array<SenatorBiography>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.isApproved != null) __params = __params.set('isApproved', params.isApproved.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorBiography/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorBiography>>;
      })
    );
  }
  /**
   * @param params The `SenatorBiographyService.GetSenatorBiographiesParams` containing the following parameters:
   *
   * - `congressType`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `isApproved`:
   *
   * @return A list of senator biography.
   */
  getSenatorBiographies(params: SenatorBiographyService.GetSenatorBiographiesParams): __Observable<Array<SenatorBiography>> {
    return this.getSenatorBiographiesResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorBiography>)
    );
  }

  /**
   * @param params The `SenatorBiographyService.UpdateSenatorBiographyParams` containing the following parameters:
   *
   * - `senatorBiography`:
   *
   * - `congressType`:
   *
   * @return a model which is updated will be returned.
   */
  updateSenatorBiographyResponse(params: SenatorBiographyService.UpdateSenatorBiographyParams): __Observable<__StrictHttpResponse<SenatorBiography>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.senatorBiography;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorBiography/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorBiography>;
      })
    );
  }
  /**
   * @param params The `SenatorBiographyService.UpdateSenatorBiographyParams` containing the following parameters:
   *
   * - `senatorBiography`:
   *
   * - `congressType`:
   *
   * @return a model which is updated will be returned.
   */
  updateSenatorBiography(params: SenatorBiographyService.UpdateSenatorBiographyParams): __Observable<SenatorBiography> {
    return this.updateSenatorBiographyResponse(params).pipe(
      __map(_r => _r.body as SenatorBiography)
    );
  }

  /**
   * @return A message will be shown on successfull update
   */
  updateSenatorBiographiesFromProPublicaResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorBiography/update/from/propublica`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return A message will be shown on successfull update
   */
  updateSenatorBiographiesFromProPublica(): __Observable<string> {
    return this.updateSenatorBiographiesFromProPublicaResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `SenatorBiographyService.DoSenatorBiographyLockActionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `congressType`:
   *
   * - `action`: it should be LOCK or UNLOCK
   *
   * @return whether operation does something
   */
  doSenatorBiographyLockActionResponse(params: SenatorBiographyService.DoSenatorBiographyLockActionParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorBiography/lock/${encodeURIComponent(String(params.action))}/${encodeURIComponent(String(params.id))}/${encodeURIComponent(String(params.congressType))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `SenatorBiographyService.DoSenatorBiographyLockActionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `congressType`:
   *
   * - `action`: it should be LOCK or UNLOCK
   *
   * @return whether operation does something
   */
  doSenatorBiographyLockAction(params: SenatorBiographyService.DoSenatorBiographyLockActionParams): __Observable<boolean> {
    return this.doSenatorBiographyLockActionResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module SenatorBiographyService {

  /**
   * Parameters for getSenatorBiographies
   */
  export interface GetSenatorBiographiesParams {
    congressType: string;
    pageSize?: number;
    pageNumber?: number;
    isApproved?: boolean;
  }

  /**
   * Parameters for updateSenatorBiography
   */
  export interface UpdateSenatorBiographyParams {
    senatorBiography: SenatorBiography;
    congressType: string;
  }

  /**
   * Parameters for doSenatorBiographyLockAction
   */
  export interface DoSenatorBiographyLockActionParams {
    id: number;
    congressType: string;

    /**
     * it should be LOCK or UNLOCK
     */
    action: string;
  }
}

export { SenatorBiographyService }

/* tslint:disable */
type SenatorOwnerTypes =
  'Not Disclosed' |
  'Child' |
  'Joint' |
  'Self' |
  'Spouse';
module SenatorOwnerTypes {
  export const NOT_DISCLOSED: SenatorOwnerTypes = 'Not Disclosed';
  export const CHILD: SenatorOwnerTypes = 'Child';
  export const JOINT: SenatorOwnerTypes = 'Joint';
  export const SELF: SenatorOwnerTypes = 'Self';
  export const SPOUSE: SenatorOwnerTypes = 'Spouse';
  export function values(): SenatorOwnerTypes[] {
    return [
      NOT_DISCLOSED,
      CHILD,
      JOINT,
      SELF,
      SPOUSE
    ];
  }
}

export { SenatorOwnerTypes }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompanyBuybackUrl } from '../models/company-buyback-url';
import { PendingMarkets } from '../models/pending-markets';
import { BuybackMarketsStatus } from '../models/buyback-markets-status';
import { AnalystsReportData } from '../models/analysts-report-data';
import { AnalystTradesData } from '../models/analyst-trades-data';
import { AnalystTradeReportFilter } from '../models/analyst-trade-report-filter';
@Injectable({
  providedIn: 'root',
})
class InternalReportsService extends __BaseService {
  static readonly getCompanyBuybackUrlsPath = '/companyBuybackUrls/{isAdminSection}';
  static readonly saveCompanyBuybackUrlPath = '/saveCompanyBuybackUrl';
  static readonly markAsDonePath = '/markAsDone/{companyID}/{isDone}';
  static readonly getBuybackCountriesListPath = '/buybackCountriesList';
  static readonly markOffCountryPath = '/markOffCountry';
  static readonly getAnalystsReportDataPath = '/analystsReportData/{fromDate}/{toDate}';
  static readonly getAnalystsTradesDataPath = '/analystsTrades/data';
  static readonly getNotReportableDataPath = '/analystsTrades/notReportable';
  static readonly getQualityCheckPassedDataPath = '/analystsTrades/QualityChecksPassed';
  static readonly getUpdatedBuybacksDataPath = '/analystsTrades/updatedBuybacks';
  static readonly getDeletedBuybacksDataPath = '/analystsTrades/deletedBuybacks';
  static readonly getOnlyAssignedAnnouncementsDataPath = '/analystsTrades/onlyAssignedAnnouncements';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param isAdminSection This means do you want records for admin or normal user. Normal user can only see markable records.
   * @return Return Company's buyback Urls.
   */
  getCompanyBuybackUrlsResponse(isAdminSection: boolean): __Observable<__StrictHttpResponse<Array<CompanyBuybackUrl>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/companyBuybackUrls/${encodeURIComponent(String(isAdminSection))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyBuybackUrl>>;
      })
    );
  }
  /**
   * @param isAdminSection This means do you want records for admin or normal user. Normal user can only see markable records.
   * @return Return Company's buyback Urls.
   */
  getCompanyBuybackUrls(isAdminSection: boolean): __Observable<Array<CompanyBuybackUrl>> {
    return this.getCompanyBuybackUrlsResponse(isAdminSection).pipe(
      __map(_r => _r.body as Array<CompanyBuybackUrl>)
    );
  }

  /**
   * @param CompanyBuybackUrl Post CompanyBuybackUrl model will save new record for the company url.
   * @return Return the saved object.
   */
  saveCompanyBuybackUrlResponse(CompanyBuybackUrl: CompanyBuybackUrl): __Observable<__StrictHttpResponse<CompanyBuybackUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = CompanyBuybackUrl;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/saveCompanyBuybackUrl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBuybackUrl>;
      })
    );
  }
  /**
   * @param CompanyBuybackUrl Post CompanyBuybackUrl model will save new record for the company url.
   * @return Return the saved object.
   */
  saveCompanyBuybackUrl(CompanyBuybackUrl: CompanyBuybackUrl): __Observable<CompanyBuybackUrl> {
    return this.saveCompanyBuybackUrlResponse(CompanyBuybackUrl).pipe(
      __map(_r => _r.body as CompanyBuybackUrl)
    );
  }

  /**
   * @param params The `InternalReportsService.MarkAsDoneParams` containing the following parameters:
   *
   * - `isDone`: isDone
   *
   * - `companyID`: companyId
   *
   * @return Return CompanyBuybackUrl if updated.
   */
  markAsDoneResponse(params: InternalReportsService.MarkAsDoneParams): __Observable<__StrictHttpResponse<CompanyBuybackUrl>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/markAsDone/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.isDone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyBuybackUrl>;
      })
    );
  }
  /**
   * @param params The `InternalReportsService.MarkAsDoneParams` containing the following parameters:
   *
   * - `isDone`: isDone
   *
   * - `companyID`: companyId
   *
   * @return Return CompanyBuybackUrl if updated.
   */
  markAsDone(params: InternalReportsService.MarkAsDoneParams): __Observable<CompanyBuybackUrl> {
    return this.markAsDoneResponse(params).pipe(
      __map(_r => _r.body as CompanyBuybackUrl)
    );
  }

  /**
   * @param date get unprocessed latest pending anns wrt date
   * @return get buyback countries list
   */
  getBuybackCountriesListResponse(date?: string): __Observable<__StrictHttpResponse<Array<PendingMarkets>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buybackCountriesList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PendingMarkets>>;
      })
    );
  }
  /**
   * @param date get unprocessed latest pending anns wrt date
   * @return get buyback countries list
   */
  getBuybackCountriesList(date?: string): __Observable<Array<PendingMarkets>> {
    return this.getBuybackCountriesListResponse(date).pipe(
      __map(_r => _r.body as Array<PendingMarkets>)
    );
  }

  /**
   * @param buybackMarketsStatus Post buybackMarketsStatus model to mark off a country
   * @return Return boolean if saved.
   */
  markOffCountryResponse(buybackMarketsStatus: BuybackMarketsStatus): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = buybackMarketsStatus;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/markOffCountry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param buybackMarketsStatus Post buybackMarketsStatus model to mark off a country
   * @return Return boolean if saved.
   */
  markOffCountry(buybackMarketsStatus: BuybackMarketsStatus): __Observable<boolean> {
    return this.markOffCountryResponse(buybackMarketsStatus).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `InternalReportsService.GetAnalystsReportDataParams` containing the following parameters:
   *
   * - `toDate`: To Date
   *
   * - `fromDate`: From Date
   *
   * @return Return data for analysts report.
   */
  getAnalystsReportDataResponse(params: InternalReportsService.GetAnalystsReportDataParams): __Observable<__StrictHttpResponse<Array<AnalystsReportData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/analystsReportData/${encodeURIComponent(String(params.fromDate))}/${encodeURIComponent(String(params.toDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystsReportData>>;
      })
    );
  }
  /**
   * @param params The `InternalReportsService.GetAnalystsReportDataParams` containing the following parameters:
   *
   * - `toDate`: To Date
   *
   * - `fromDate`: From Date
   *
   * @return Return data for analysts report.
   */
  getAnalystsReportData(params: InternalReportsService.GetAnalystsReportDataParams): __Observable<Array<AnalystsReportData>> {
    return this.getAnalystsReportDataResponse(params).pipe(
      __map(_r => _r.body as Array<AnalystsReportData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return data for analysts report.
   */
  getAnalystsTradesDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return data for analysts report.
   */
  getAnalystsTradesData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getAnalystsTradesDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Not Reportable data for analysts report.
   */
  getNotReportableDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/notReportable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Not Reportable data for analysts report.
   */
  getNotReportableData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getNotReportableDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Quality Check Passed data for analysts report.
   */
  getQualityCheckPassedDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/QualityChecksPassed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Quality Check Passed data for analysts report.
   */
  getQualityCheckPassedData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getQualityCheckPassedDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Updated Buybacks data for analysts report.
   */
  getUpdatedBuybacksDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/updatedBuybacks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Updated Buybacks data for analysts report.
   */
  getUpdatedBuybacksData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getUpdatedBuybacksDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Deleted Buybacks data for analysts report.
   */
  getDeletedBuybacksDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/deletedBuybacks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return Deleted Buybacks data for analysts report.
   */
  getDeletedBuybacksData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getDeletedBuybacksDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }

  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return ONly Assigned Announcements data for analysts report.
   */
  getOnlyAssignedAnnouncementsDataResponse(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<__StrictHttpResponse<Array<AnalystTradesData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnalystTradeFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analystsTrades/onlyAssignedAnnouncements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnalystTradesData>>;
      })
    );
  }
  /**
   * @param AnalystTradeFilter Contains filter to select announcements that need a review.
   * @return Return ONly Assigned Announcements data for analysts report.
   */
  getOnlyAssignedAnnouncementsData(AnalystTradeFilter: AnalystTradeReportFilter): __Observable<Array<AnalystTradesData>> {
    return this.getOnlyAssignedAnnouncementsDataResponse(AnalystTradeFilter).pipe(
      __map(_r => _r.body as Array<AnalystTradesData>)
    );
  }
}

module InternalReportsService {

  /**
   * Parameters for markAsDone
   */
  export interface MarkAsDoneParams {

    /**
     * isDone
     */
    isDone: boolean;

    /**
     * companyId
     */
    companyID: number;
  }

  /**
   * Parameters for getAnalystsReportData
   */
  export interface GetAnalystsReportDataParams {

    /**
     * To Date
     */
    toDate: string;

    /**
     * From Date
     */
    fromDate: string;
  }
}

export { InternalReportsService }

/* tslint:disable */
type SubDisclosureTypeSelection =
  'ShortSellingPosition' |
  'ShortSellingVolume';
module SubDisclosureTypeSelection {
  export const SHORT_SELLING_POSITION: SubDisclosureTypeSelection = 'ShortSellingPosition';
  export const SHORT_SELLING_VOLUME: SubDisclosureTypeSelection = 'ShortSellingVolume';
  export function values(): SubDisclosureTypeSelection[] {
    return [
      SHORT_SELLING_POSITION,
      SHORT_SELLING_VOLUME
    ];
  }
}

export { SubDisclosureTypeSelection }
/* tslint:disable */

/**
 * A enum contains style for stock options
 */
type StockOptionStyle =
  'American' |
  'Bermudan' |
  'European';
module StockOptionStyle {
  export const AMERICAN: StockOptionStyle = 'American';
  export const BERMUDAN: StockOptionStyle = 'Bermudan';
  export const EUROPEAN: StockOptionStyle = 'European';
  export function values(): StockOptionStyle[] {
    return [
      AMERICAN,
      BERMUDAN,
      EUROPEAN
    ];
  }
}

export { StockOptionStyle }
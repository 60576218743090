import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listed-option-trades',
  templateUrl: './listed-option-trades.component.html',
  styleUrls: ['./listed-option-trades.component.css']
})
export class ListedOptionTradesComponent implements OnInit {

  tradesLoading = false;
  mode = 'LISTING-OPTIONS';

  constructor() { }

  ngOnInit(): void {
  }

}

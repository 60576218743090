/* tslint:disable */
type Senator2iqTransactionTypes =
  'Exchange' |
  'Received' |
  'Purchase' |
  'Sale' |
  'Sale (Partial)' |
  'Sale (Full)';
module Senator2iqTransactionTypes {
  export const EXCHANGE: Senator2iqTransactionTypes = 'Exchange';
  export const RECEIVED: Senator2iqTransactionTypes = 'Received';
  export const PURCHASE: Senator2iqTransactionTypes = 'Purchase';
  export const SALE: Senator2iqTransactionTypes = 'Sale';
  export const SALE_PARTIAL_: Senator2iqTransactionTypes = 'Sale (Partial)';
  export const SALE_FULL_: Senator2iqTransactionTypes = 'Sale (Full)';
  export function values(): Senator2iqTransactionTypes[] {
    return [
      EXCHANGE,
      RECEIVED,
      PURCHASE,
      SALE,
      SALE_PARTIAL_,
      SALE_FULL_
    ];
  }
}

export { Senator2iqTransactionTypes }
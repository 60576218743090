import {Component, Inject, OnInit} from '@angular/core';
import {SenatorCompany} from '../../../../../../api/models/senator-company';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SenatorCompanyService} from '../../../../../../api/services/senator-company.service';
import {SenatorCompanyAssetClass} from '../../../../../../api/models/senator-company-asset-class';
import {SenatorCompanyAssetClassService} from '../../../../../../api/services/senator-company-asset-class.service';
import {AddEditAssetClassComponent} from './add-edit-asset-class/add-edit-asset-class.component';
import {CompanyService, SenatorQualityGateService} from '../../../../../../api/services';
import {Company} from '../../../../../../api/models/company';
import {SnackbarMessageService} from '../../../../services/snackbar-message.service';
import {Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {TitleCasePipe} from '@angular/common';
import {SenatorCompanyMerger} from '../../../../../../api/models/senator-company-merger';
import CompanySearchParams = CompanyService.CompanySearchParams;

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
  providers: [TitleCasePipe]
})
export class EditCompanyComponent implements OnInit {

  loadingData = false;
  companyForm: FormGroup;
  displayedColumns = ['x2iqAssetClass', 'x2iqFinInst', 'compustatSec', 'is_market_traded', 'bbg_ticker',
    'isPrimary', 'tradeCount', 'help', 'updatedBy', 'nameChangeUrl', 'cusip', 'action'];
  securities = [] as SenatorCompanyAssetClass[];
  pageSize = 10;
  pageSizeOptions = [10, 20, 50];
  pageEvent: PageEvent;
  companyId: number;

  // _senatorCompanyService subscriptions
  messageSubscription: Subscription;
  readonly ASSET_CLASS_OTHER_COMPANY: 'Other Company';
  readonly ASSET_CLASS_WITHIN_COMPANY: 'Within Company';

  assetNamesLoading: boolean;
  twoIQAssetsList: Company[] = [];
  dialogResult = '';

  constructor(@Inject(MAT_DIALOG_DATA) public selectedCompany: any,
              private dialog: MatDialog,
              private titleCasePipe: TitleCasePipe,
              private dialogRef: MatDialogRef<EditCompanyComponent>,
              private _senatorCompanyService: SenatorCompanyService,
              private assetClassService: SenatorCompanyAssetClassService,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private _companyService: CompanyService,
              private matDialog: MatDialog,
              private snackbarMessageService: SnackbarMessageService) {

    this.messageSubscription = this.snackbarMessageService.getSnackBarMessage().subscribe(res => {
      this.assignDummyGvkey();
    });
  }

  ngOnInit() {
    this.createCompanyForm();

    if (this.selectedCompany.ID || this.selectedCompany.senatorCompanyId) {
      this.companyId = this.selectedCompany.ID || this.selectedCompany.senatorCompanyId;
      this.getSenatorCompany(this.companyId);
    }
  }

  createCompanyForm() {
    this.companyForm = this.fb.group({
      id: [this.selectedCompany.ID, Validators.required],
      name: [this.selectedCompany.name, Validators.required],
      ticker: [this.selectedCompany.ticker],
      bbg_ticker: [this.selectedCompany.bbg_ticker],
      gvkey: [this.selectedCompany.gvkey, [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      permid: [this.selectedCompany.permId, [Validators.pattern('^[0-9]{1}\\-[0-9]{10,11}$')]],
      permid_url: [this.selectedCompany.permid_url],
      ispublic_url: [this.selectedCompany.ispublic_url],
      isPublic: [this.selectedCompany.isPublic, [Validators.required]],
      sentForCreation: [this.selectedCompany.sentForCreation],
      cik: [this.selectedCompany.cik]
    });
  }

  onUpdateCompany() {
    this.selectedCompany.permId = this.emptyToNull(this.companyForm.get('permid').value);
    this.selectedCompany.ticker = this.emptyToNull(this.companyForm.get('ticker').value);
    this.selectedCompany.gvkey = this.emptyToZero(this.companyForm.get('gvkey').value);
    this.selectedCompany.isPublic = this.companyForm.get('isPublic').value;
    this.selectedCompany.sentForCreation = this.companyForm.get('sentForCreation').value;
    this.selectedCompany.name = this.emptyToNull(this.companyForm.get('name').value);
    this.selectedCompany.permid_url = this.emptyToNull(this.companyForm.get('permid_url').value);
    this.selectedCompany.ispublic_url = this.emptyToNull(this.companyForm.get('ispublic_url').value);
    this.selectedCompany.cik = this.emptyToNull(this.companyForm.get('cik').value);

    this._senatorCompanyService.addUpdateSenatorCompany(this.selectedCompany)
      .subscribe(
        value => {
          this.selectedCompany = value;
        }, error => {
          this.openSnackBar(error.error.message, 'close');
        }, () => {
          // this.dialogRef.close(true);
          this.openSnackBar('Company data has been updated successfully!', 'close');
        }
      );

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  onAddCompany() {
    this.selectedCompany.ID = null;
    this.selectedCompany.permId = this.emptyToNull(this.companyForm.get('permid').value);
    this.selectedCompany.ticker = this.emptyToNull(this.companyForm.get('ticker').value);
    this.selectedCompany.gvkey = this.emptyToZero(this.companyForm.get('gvkey').value);
    this.selectedCompany.isPublic = this.companyForm.get('isPublic').value;
    this.selectedCompany.sentForCreation = this.companyForm.get('sentForCreation').value;
    this.selectedCompany.name = this.emptyToNull(this.companyForm.get('name').value);
    this.selectedCompany.permid_url = this.emptyToNull(this.companyForm.get('permid_url').value);
    this.selectedCompany.ispublic_url = this.emptyToNull(this.companyForm.get('ispublic_url').value);
    this.selectedCompany.cik = this.emptyToNull(this.companyForm.get('cik').value);
    this._senatorCompanyService.addUpdateSenatorCompany(this.selectedCompany)
      .subscribe(
        value => {
          this.selectedCompany = value;
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {
          // this.dialogRef.close(true);
          this.openSnackBar('Company data has been added successfully!', 'close');
        }
      );
  }

  onPaginateChange(event: PageEvent) {
  }

  onAddUpdateAssetClass(selectedAssetClass?: SenatorCompanyAssetClass) {
    if (selectedAssetClass === undefined) {
      selectedAssetClass = {ID: 0} as SenatorCompanyAssetClass;
    }
    const dialogRef = this.dialog.open(AddEditAssetClassComponent, {
      disableClose: true,
      width: '1200px',
      data: {
        selectedCompany: this.selectedCompany,
        selectedAssetClass: JSON.parse(JSON.stringify(selectedAssetClass))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSenatorCompany(this.companyId);
        if (result == 'merged') {
          this.dialogResult = 'merged';
        }
      }
    });
  }

  demergeAssetClass(selectedAssetClass: SenatorCompanyAssetClass) {
    if (selectedAssetClass === undefined) {
      selectedAssetClass = {ID: 0} as SenatorCompanyAssetClass;
    }
    const dialogRef = this.dialog.open(AssetClassDemergeDialogComponent, {
      disableClose: true,
      width: '500px',
      data: {
        selectedCompany: this.selectedCompany,
        selectedAssetClass: JSON.parse(JSON.stringify(selectedAssetClass)),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSenatorCompany(this.companyId);
        if (result == 'merged') {
          this.dialogResult = 'merged';
        }
      }
    });
  }


  getSenatorCompany(companyId: number) {
    this._senatorCompanyService.getSenatorCompanyById(companyId).subscribe(
      (res: SenatorCompany) => {
        this.selectedCompany = res;
      },
      error => {
      },
      () => {
        this.createCompanyForm();
        this.getAssetClasses();
      }
    );
  }

  searchAssetNames(companyName: string) {
    let searchType;
    if (companyName.indexOf('@') >= 0) {
      searchType = 'ticker';
    } else {
      searchType = 'name';
    }

    this.assetNamesLoading = true;
    const params: CompanySearchParams = {
      searchValue: companyName,
      searchBy: searchType
    };

    this._companyService.companySearch(params).subscribe((res: Company[]) => {
      this.twoIQAssetsList = res;
    }, (err) => {
      this.assetNamesLoading = false;
      if (err.status === 400 || err.status === 500) {
        this.openSnackBar('Something went wrong! please enter a valid company name.', 'Close');
      } else {
        this.openSnackBar(err.error.message, 'Close');
      }

    }, () => {
      this.assetNamesLoading = false;
      if (this.twoIQAssetsList.length === 0) {
        this.snackBar.openFromComponent(SnackBar3Component, {
          data: {
            message: 'No Company found,Populate this text and assign dummy gvkey',
            text: companyName,
          },
          duration: 10000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom'
        });
      }
    });
  }

  resetGvkey() {
    this.companyForm.get('gvkey').setValue(null);
    this.companyForm.get('permid').setValue(null);
    this.companyForm.get('ticker').setValue(null);
  }

  mapCompanyWith2IQ(event, company: SenatorCompany) {
    if (event.source.selected) {
      this.companyForm.get('gvkey').setValue(company.gvkey);
      this.companyForm.get('permid').setValue(company.permId);

      if (company.ticker !== null) {
        this.companyForm.get('ticker').setValue(company.ticker);
      } else {
        this.companyForm.get('ticker').setValue('--');
      }

    }
  }

  onCompanyExcludeClick() {
    const fileDeleteDialogRef = this.matDialog.open(CompanyExcludeDialogComponent, {
      width: '550px',
      height: '250px',
      data: {
        company: this.selectedCompany
      }
    });
    fileDeleteDialogRef.afterClosed().subscribe((res) => {

    });
  }


  assignDummyGvkey() {
    this.companyForm.get('gvkey').setValue('000000');
  }

  simpleEnglish(value: any): string {
    if (value == null) {
      return null;
    }
    if (value == true) {
      return 'Yes';
    }
    return 'No';
  }

  cleanTheName(updatedBy: any): string {
    if (updatedBy == null) {
      return null;
    }
    if (updatedBy == 'Auto') {
      return updatedBy;
    }
    return this.titleCasePipe.transform(updatedBy.toString().split('@')[0].split('.')[0]);
  }

  private emptyToZero(value: any) {
    if (value == null)
      return '000000';
    if (value == '')
      return '000000';
    return value;
  }

  private emptyToNull(value: any) {
    if (value == null)
      return null;
    if (value == '')
      return null;
    return value;
  }

  private getAssetClasses() {
    this.assetClassService.getAssetClassesOfTheCompany(this.selectedCompany)
      .subscribe(
        value => this.securities = value
      );
  }
}


@Component({
  selector: 'app-snack-bar3',
  template: `<span class="example-pizza-party">
  {{data?.message}}
</span>
  <div style="margin-top: 5px" fxLayout fxLayoutAlign="flex-end" fxLayoutGap="10px">
    <button mat-raised-button
            color="primary"
            (click)="snackBarRef.dismiss();assignDummyGvkey()">yes
    </button>
    <button mat-raised-button
            color="accent"
            (click)="snackBarRef.dismiss()">close
    </button>
  </div>
  `
})
export class SnackBar3Component {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBar3Component>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private messageService: SnackbarMessageService) {
  }

  assignDummyGvkey() {
    this.messageService.assignDummyGvkey(this.data);
  }
}


@Component({
  selector: 'app-company-exclude-dialog-Component',
  template: `<h1 mat-dialog-title>Exclude Company</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <h5>Are you sure, you want to Exclude the Company ?</h5>
    <span>Company Name: <b>{{data.company.name}}</b>  </span>
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="fileDeleting"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onExcludeClick()">Exclude</button>
  </div>`,
})
export class CompanyExcludeDialogComponent implements OnInit {
  fileDeleting = false;
  filingId: number;
  congressType: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyExcludeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private senatorQualityGateService: SenatorQualityGateService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onExcludeClick() {

    this.fileDeleting = true;
    const params = this.data.company.senatorCompanyId || this.data.company.ID;

    this.senatorQualityGateService.updateSenatorQualityGateForExcludeCompany(params).subscribe((res) => {
    }, (err) => {

      this.fileDeleting = false;
      this.openSnackBar(`Error!,  ${JSON.parse(err.error).message}`, 'Close');
    }, () => {
      this.fileDeleting = false;
      this.dialogRef.close();
      this.openSnackBar(`Company ${this.data.company.name} has been excluded successfully!`, 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

@Component({
  selector: 'app-asset-calss-demerge-dialog-Component',
  template: `<h1 mat-dialog-title>Asset Class Demerge</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <h5>Are you sure, you want to Demerge Asset Class ?</h5>
    <span>Asset Class Name: <b>{{data.selectedAssetClass.mergedWithAssetName}}</b>  </span><br>
    <span>Company Name: <b>{{data.selectedAssetClass.mergedWithCompanyName}}</b>  </span>
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="isProcessing"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onDemergeClick()">Demerge</button>
  </div>`,
})
export class AssetClassDemergeDialogComponent implements OnInit {
  isProcessing = false;
  filingId: number;
  congressType: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyExcludeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private senatorCompanyAssetClassService: SenatorCompanyAssetClassService,
    private _senatorCompanyService: SenatorCompanyService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDemergeClick() {

    const params: SenatorCompanyMerger = {
      is_executed: false,

      listOfMergingAssetClasses: [{
        old_senator_asset_class_id: this.data.selectedAssetClass.ID,
        new_senator_asset_class_id: null
      }],
      new_senator_company_id: null,
      old_senator_company_id: this.data.selectedCompany.ID,
    };

    this._senatorCompanyService.mergeSenatorCompany(params)
      .subscribe(
        value => {
          this.dialogRef.close('merged');
          this.openSnackBar('Asset Class has been Demerged successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {

        }
      );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

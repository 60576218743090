import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SenatorBiographyService} from '../../../../api/services/senator-biography.service';
import {SenatorBiography} from '../../../../api/models/senator-biography';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CongressTypeEnum} from '../../../../api/models/congress-type-enum';
import {Gender} from '../../../../api/models/gender';
import {MatDialog} from '@angular/material/dialog';
import UpdateSenatorBiographyParams = SenatorBiographyService.UpdateSenatorBiographyParams;
import {DatePickerDialogComponent} from './date-picker-dialog/date-picker-dialog.component';
import {BiographyCongressTenure} from '../../../../api/models/biography-congress-tenure';
import {BiographyCongressTenureService} from '../../../../api/services/biography-congress-tenure.service';
import {RepresentativeDistrictService} from '../../../../api/services/representative-district.service';


export enum Mode {
  create = 'Create',
  edit = 'Edit'
}

@Component({
  selector: 'app-edit-and-create-biography-dialog',
  templateUrl: './edit-and-create-biography-dialog.component.html',
  styleUrls: ['./edit-and-create-biography-dialog.component.scss'],
})
export class EditAndCreateBiographyDialogComponent implements OnInit {
  districts = [];
  tenureList: BiographyCongressTenure[];
  profileForm: FormGroup;
  stateCode;
  parties = [
    {value: 'Republican', viewValue: 'Republican'},
    {value: 'Democrat', viewValue: 'Democrat'},
    {value: 'Libertarian', viewValue: 'Libertarian'},
    {value: 'Independent', viewValue: 'Independent'},
    {value: 'Independent Democrat', viewValue: 'Independent Democrat'},
    {value: 'Independent Republican', viewValue: 'Independent Republican'},
    {value: 'Other', viewValue: 'Other'}
  ].sort((a, b) => {
    const x = a.value.toLowerCase();
    const y = b.value.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
  congressTypes = CongressTypeEnum.values();
  genders = Gender.values();
  states = [
    {value: 'Alabama', viewValue: 'Alabama', stateCode: 'AL'},
    {value: 'Alaska', viewValue: 'Alaska', stateCode: 'AK'},
    {value: 'American Samoa', viewValue: 'American Samoa', stateCode: 'AS'},
    {value: 'Arizona', viewValue: 'Arizona', stateCode: 'AZ'},
    {value: 'Arkansas', viewValue: 'Arkansas', stateCode: 'AR'},
    {value: 'California', viewValue: 'California', stateCode: 'CA'},
    {value: 'Colorado', viewValue: 'Colorado', stateCode: 'CO'},
    {value: 'Connecticut', viewValue: 'Connecticut', stateCode: 'CT'},
    {value: 'Delaware', viewValue: 'Delaware', stateCode: 'DE'},
    {value: 'District Of Columbia', viewValue: 'District Of Columbia', stateCode: 'DC'},
    {value: 'Federated States Of Micronesia', viewValue: 'Federated States Of Micronesia', stateCode: 'FM'},
    {value: 'Florida', viewValue: 'Florida', stateCode: 'FL'},
    {value: 'Georgia', viewValue: 'Georgia', stateCode: 'GA'},
    {value: 'Guam', viewValue: 'Guam', stateCode: 'GU'},
    {value: 'Hawaii', viewValue: 'Hawaii', stateCode: 'HI'},
    {value: 'Idaho', viewValue: 'Idaho', stateCode: 'ID'},
    {value: 'Illinois', viewValue: 'Illinois', stateCode: 'IL'},
    {value: 'Indiana', viewValue: 'Indiana', stateCode: 'IN'},
    {value: 'Iowa', viewValue: 'Iowa', stateCode: 'IA'},
    {value: 'Kansas', viewValue: 'Kansas', stateCode: 'KS'},
    {value: 'Kentucky', viewValue: 'Kentucky', stateCode: 'KY'},
    {value: 'Louisiana', viewValue: 'Louisiana', stateCode: 'LA'},
    {value: 'Maine', viewValue: 'Maine', stateCode: 'ME'},
    {value: 'Marshall Islands', viewValue: 'Marshall Islands', stateCode: 'MH'},
    {value: 'Maryland', viewValue: 'Maryland', stateCode: 'MD'},
    {value: 'Massachusetts', viewValue: 'Massachusetts', stateCode: 'MA'},
    {value: 'Michigan', viewValue: 'Michigan', stateCode: 'MI'},
    {value: 'Minnesota', viewValue: 'Minnesota', stateCode: 'MN'},
    {value: 'Mississippi', viewValue: 'Mississippi', stateCode: 'MS'},
    {value: 'Missouri', viewValue: 'Missouri', stateCode: 'MO'},
    {value: 'Montana', viewValue: 'Montana', stateCode: 'MT'},
    {value: 'Nebraska', viewValue: 'Nebraska', stateCode: 'NE'},
    {value: 'Nevada', viewValue: 'Nevada', stateCode: 'NV'},
    {value: 'New Hampshire', viewValue: 'New Hampshire', stateCode: 'NH'},
    {value: 'New Jersey', viewValue: 'New Jersey', stateCode: 'NJ'},
    {value: 'New Mexico', viewValue: 'New Mexico', stateCode: 'NM'},
    {value: 'New York', viewValue: 'New York', stateCode: 'NY'},
    {value: 'North Carolina', viewValue: 'North Carolina', stateCode: 'NC'},
    {value: 'North Dakota', viewValue: 'North Dakota', stateCode: 'ND'},
    {value: 'Northern Mariana Islands', viewValue: 'Northern Mariana Islands', stateCode: 'MP'},
    {value: 'Ohio', viewValue: 'Ohio', stateCode: 'OH'},
    {value: 'Oklahoma', viewValue: 'Oklahoma', stateCode: 'OK'},
    {value: 'Oregon', viewValue: 'Oregon', stateCode: 'OR'},
    {value: 'Palau', viewValue: 'Palau', stateCode: 'PW'},
    {value: 'Pennsylvania', viewValue: 'Pennsylvania', stateCode: 'PA'},
    {value: 'Puerto Rico', viewValue: 'Puerto Rico', stateCode: 'PR'},
    {value: 'Rhode Island', viewValue: 'Rhode Island', stateCode: 'RI'},
    {value: 'South Carolina', viewValue: 'South Carolina', stateCode: 'SC'},
    {value: 'South Dakota', viewValue: 'South Dakota', stateCode: 'SD'},
    {value: 'Tennessee', viewValue: 'Tennessee', stateCode: 'TN'},
    {value: 'Texas', viewValue: 'Texas', stateCode: 'TX'},
    {value: 'Utah', viewValue: 'Utah', stateCode: 'UT'},
    {value: 'Vermont', viewValue: 'Vermont', stateCode: 'VT'},
    {value: 'Virgin Islands', viewValue: 'Virgin Islands', stateCode: 'VI'},
    {value: 'Virginia', viewValue: 'Virginia', stateCode: 'VA'},
    {value: 'Washington', viewValue: 'Washington', stateCode: 'WA'},
    {value: 'West Virginia', viewValue: 'West Virginia', stateCode: 'WV'},
    {value: 'Wisconsin', viewValue: 'Wisconsin', stateCode: 'WI'},
    {value: 'Wyoming', viewValue: 'Wyoming', stateCode: 'WY'}];
  isUpdating: boolean;
  animal: string;
  name: string;

  create = Mode.create;
  edit = Mode.edit;

  constructor(@Inject(MAT_DIALOG_DATA) public profileData: any,
              private dialogRef: MatDialogRef<EditAndCreateBiographyDialogComponent>,
              private _fb: FormBuilder, private serviceSenatorBio: SenatorBiographyService,
              private _biographyCongressTenureService: BiographyCongressTenureService,
              private _representativeDistrictService: RepresentativeDistrictService,
              public snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  ngOnInit() {

    this.initProfileForm();

    this._representativeDistrictService.getRepresentativeDistrict().subscribe((res: SenatorBiography[]) => {
      this.districts = res;
    }, error => {
      this.openSnackBar(error.error.message, 'close');
    }, () => {
    });

    if (this.profileData.mode === Mode.edit) {
      this.getTenuresList();
      if (this.profileData.data.state && (this.profileData.data.stateCode === null || this.profileData.data.stateCode === '')) {
        this.onStateSelection(this.profileData.data.state);
      }
    }
  }

  initProfileForm() {

    switch (this.profileData.mode) {
      case  Mode.edit :
        this.profileForm = this._fb.group({
          id: [this.profileData.data.id],
          imageUrl: [this.profileData.data.imageUrl, [Validators.required]],
          firstName: [this.profileData.data.firstName, [Validators.required]],
          middleName: [this.profileData.data.middleName],
          lastName: [this.profileData.data.lastName, [Validators.required]],
          x2iqName: [this.profileData.data.x2iqName, [Validators.required]],
          shortName: [this.profileData.data.shortName, [Validators.required]],
          name: [this.profileData.name],
          gender: [this.profileData.data.gender, [Validators.required]],
          party: [this.profileData.data.party, [Validators.required]],
          congressType: [this.profileData.data.congressType, [Validators.required]],
          congressId: [this.profileData.data.congressId, [Validators.required]],
          representativeDistrict: [this.profileData.data.representativeDistrict || '0', [Validators.required]],
          state: [this.profileData.data.state, [Validators.required]],
          stateCode: [this.profileData.data.stateCode],
          netWorth: [this.profileData.data.netWorth],
          twitterHandler: [this.profileData.data.twitterHandler],
          secondTwitterHandler: [this.profileData.data.secondTwitterHandler],
          facebookAccount: [this.profileData.data.facebookAccount],
          youtubeAccount: [this.profileData.data.youtubeAccount],
          description: [this.profileData.data.description, [Validators.required]],
          duration: [this.profileData.data.duration, [Validators.required]],
          webUrl: [this.profileData.data.webUrl, [Validators.required]],
          dateOfBirth: [this.profileData.data.dateOfBirth, [Validators.required]],
          isApproved: [true]
        });
        break;
      case  Mode.create :
        this.profileForm = this._fb.group({
          id: [null],
          imageUrl: [null, [Validators.required]],
          firstName: [null],
          middleName: [null],
          lastName: [null],
          x2iqName: [null, [Validators.required]],
          shortName: [null, [Validators.required]],
          name: [null],
          gender: [null, [Validators.required]],
          party: [null, [Validators.required]],
          congressType: [this.profileData.congressType, [Validators.required]],
          congressId: [null, [Validators.required]],
          representativeDistrict: ['0', [Validators.required]],
          state: [null, [Validators.required]],
          stateCode: [null],
          netWorth: [''],
          twitterHandler: [null],
          secondTwitterHandler: [null],
          facebookAccount: [null],
          youtubeAccount: [null],
          description: [null, [Validators.required]],
          duration: [null, [Validators.required]],
          webUrl: [null, [Validators.required]],
          dateOfBirth: [null, [Validators.required]],
          isApproved: [true]
        });
        break;
    }

  }

  onSubmitProfileForm() {
    this.isUpdating = true;

    const params: UpdateSenatorBiographyParams = {
      congressType: this.profileData.congressType,
      senatorBiography: this.profileForm.value
    };
    params.senatorBiography.name = this._x2iqName.value;
    params.senatorBiography.dateOfBirth = this.getFormatDate(this._dateOfBirth.value);

    this.serviceSenatorBio.updateSenatorBiography(params).subscribe((res: SenatorBiography) => {
    }, (err) => {
      this.isUpdating = false;

    }, () => {
      this.isUpdating = false;
      this.openSnackBar('Profile data has been updated successfully!', 'close');
      this.dialogRef.close();
    });
  }

  getTenuresList() {
    this._biographyCongressTenureService.getCongressTenureByBiographyId(this.profileData.data.id).subscribe((res: BiographyCongressTenure[]) => {
      this.tenureList = res;
    }, error => {
      this.openSnackBar(error.error.message, 'close');
    }, () => {
    });
  }


  onClickTenureListDialog(): void {
    const dialogRef = this.dialog.open(DatePickerDialogComponent, {
      width: '55vw',
      data: {
        biographyId: this.profileData.data.id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.animal = result;
    });
  }

  onStateSelection(stateName: string) {

    this.profileForm.controls['stateCode'].setValue(this.states.filter(item => item.value === stateName)[0].stateCode);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  getFormatDate(date: Date): any {
    date = new Date(date);
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

  }

  get _imageUrl() {
    return this.profileForm.get('imageUrl');
  }

  get _x2iqName() {
    return this.profileForm.get('x2iqName');
  }

  get _state() {
    return this.profileForm.get('state');
  }

  get _dateOfBirth() {
    return this.profileForm.get('dateOfBirth');
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BiographyCongressTenure} from '../../../../../api/models/biography-congress-tenure';
import {BiographyCongressTenureService} from '../../../../../api/services/biography-congress-tenure.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrls: ['./date-picker-dialog.component.css']
})
export class DatePickerDialogComponent implements OnInit {
  selectedRowId: number;
  isAdding: boolean;
  isEditing: boolean;
  tableDataSource: BiographyCongressTenure[] = [];
  parties = [
    {value: 'Republican', viewValue: 'Republican'},
    {value: 'Democrat', viewValue: 'Democrat'},
    {value: 'Libertarian', viewValue: 'Libertarian'},
    {value: 'Independent', viewValue: 'Independent'},
    {value: 'Independent Democrat', viewValue: 'Independent Democrat'},
    {value: 'Independent Republican', viewValue: 'Independent Republican'},
    {value: 'Other', viewValue: 'Other'}
  ].sort((a, b) => {
    const x = a.value.toLowerCase();
    const y = b.value.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
  states = [
    {value: 'Alabama', viewValue: 'Alabama [AL]', abbreviation: 'AL'},
    {value: 'Alaska', viewValue: 'Alaska [AK]', abbreviation: 'AK'},
    {value: 'American Samoa', viewValue: 'American Samoa [AS]', abbreviation: 'AS'},
    {value: 'Arizona', viewValue: 'Arizona [AZ]', abbreviation: 'AZ'},
    {value: 'Arkansas', viewValue: 'Arkansas [AR]', abbreviation: 'AR'},
    {value: 'California', viewValue: 'California [CA]', abbreviation: 'CA'},
    {value: 'Colorado', viewValue: 'Colorado [CO]', abbreviation: 'CO'},
    {value: 'Connecticut', viewValue: 'Connecticut [CT]', abbreviation: 'CT'},
    {value: 'Delaware', viewValue: 'Delaware [DE]', abbreviation: 'DE'},
    {value: 'District Of Columbia', viewValue: 'District Of Columbia [DC]', abbreviation: 'DC'},
    {value: 'Federated States Of Micronesia [FM]', viewValue: 'Federated States Of Micronesia', abbreviation: 'FM'},
    {value: 'Florida', viewValue: 'Florida [FL]', abbreviation: 'FL'},
    {value: 'Georgia', viewValue: 'Georgia [GA]', abbreviation: 'GA'},
    {value: 'Guam', viewValue: 'Guam [GU]', abbreviation: 'GU'},
    {value: 'Hawaii', viewValue: 'Hawaii [HI]', abbreviation: 'HI'},
    {value: 'Idaho', viewValue: 'Idaho [ID]', abbreviation: 'ID'},
    {value: 'Illinois', viewValue: 'Illinois [IL]', abbreviation: 'IL'},
    {value: 'Indiana', viewValue: 'Indiana [IN]', abbreviation: 'IN'},
    {value: 'Iowa', viewValue: 'Iowa [IA]', abbreviation: 'IA'},
    {value: 'Kansas', viewValue: 'Kansas [KS]', abbreviation: 'KS'},
    {value: 'Kentucky', viewValue: 'Kentucky [KY]', abbreviation: 'KY'},
    {value: 'Louisiana', viewValue: 'Louisiana [LA]', abbreviation: 'LA'},
    {value: 'Maine', viewValue: 'Maine [ME]', abbreviation: 'ME'},
    {value: 'Marshall Islands', viewValue: 'Marshall Islands [MH]', abbreviation: 'MH'},
    {value: 'Maryland', viewValue: 'Maryland [MD]', abbreviation: 'MD'},
    {value: 'Massachusetts', viewValue: 'Massachusetts [MA]', abbreviation: 'MA'},
    {value: 'Michigan', viewValue: 'Michigan [MI]', abbreviation: 'MI'},
    {value: 'Minnesota', viewValue: 'Minnesota [MN]', abbreviation: 'MN'},
    {value: 'Mississippi', viewValue: 'Mississippi [MS]', abbreviation: 'MS'},
    {value: 'Missouri', viewValue: 'Missouri [MO]', abbreviation: 'MO'},
    {value: 'Montana', viewValue: 'Montana [MT]', abbreviation: 'MT'},
    {value: 'Nebraska', viewValue: 'Nebraska [NE]', abbreviation: 'NE'},
    {value: 'Nevada', viewValue: 'Nevada [NV]', abbreviation: 'NV'},
    {value: 'New Hampshire', viewValue: 'New Hampshire [NH]', abbreviation: 'NH'},
    {value: 'New Jersey', viewValue: 'New Jersey [NJ]', abbreviation: 'NJ'},
    {value: 'New Mexico', viewValue: 'New Mexico [NM]', abbreviation: 'NM'},
    {value: 'New York', viewValue: 'New York [NY]', abbreviation: 'NY'},
    {value: 'North Carolina', viewValue: 'North Carolina [NC]', abbreviation: 'NC'},
    {value: 'North Dakota', viewValue: 'North Dakota [ND]', abbreviation: 'ND'},
    {value: 'Northern Mariana Islands', viewValue: 'Northern Mariana Islands [MP]', abbreviation: 'MP'},
    {value: 'Ohio', viewValue: 'Ohio [OH]', abbreviation: 'OH'},
    {value: 'Oklahoma', viewValue: 'Oklahoma [OK]', abbreviation: 'OK'},
    {value: 'Oregon', viewValue: 'Oregon [OR]', abbreviation: 'OR'},
    {value: 'Palau', viewValue: 'Palau [PW]', abbreviation: 'PW'},
    {value: 'Pennsylvania', viewValue: 'Pennsylvania [PA]', abbreviation: 'PA'},
    {value: 'Puerto Rico', viewValue: 'Puerto Rico [PR]', abbreviation: 'PR'},
    {value: 'Rhode Island', viewValue: 'Rhode Island [RI]', abbreviation: 'RI'},
    {value: 'South Carolina', viewValue: 'South Carolina [SC]', abbreviation: 'SC'},
    {value: 'South Dakota', viewValue: 'South Dakota [SD]', abbreviation: 'SD'},
    {value: 'Tennessee', viewValue: 'Tennessee [TN]', abbreviation: 'TN'},
    {value: 'Texas', viewValue: 'Texas [TX]', abbreviation: 'TX'},
    {value: 'Utah', viewValue: 'Utah [UT]', abbreviation: 'UT'},
    {value: 'Vermont', viewValue: 'Vermont [VT]', abbreviation: 'VT'},
    {value: 'Virgin Islands', viewValue: 'Virgin Islands [VI]', abbreviation: 'VI'},
    {value: 'Virginia', viewValue: 'Virginia [VA]', abbreviation: 'VA'},
    {value: 'Washington', viewValue: 'Washington [WA]', abbreviation: 'WA'},
    {value: 'West Virginia', viewValue: 'West Virginia [WV]', abbreviation: 'WV'},
    {value: 'Wisconsin', viewValue: 'Wisconsin [WI]', abbreviation: 'WI'},
    {value: 'Wyoming', viewValue: 'Wyoming [WY]', abbreviation: 'WY'}];

  constructor(
    public dialogRef: MatDialogRef<DatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private _biographyCongressTenureService: BiographyCongressTenureService,
  ) {
    console.log('data: ', data);
  }


  ngOnInit(): void {
    this.isAdding = false;
    this.isEditing = false;
    this.getTenuresList();
  }

  getTenuresList() {
    let tenureList;
    this._biographyCongressTenureService.getCongressTenureByBiographyId(this.data['biographyId']).subscribe((res: BiographyCongressTenure[]) => {
      tenureList = res;
    }, error => {
      this.isAdding = true;

      this.openSnackBar(error.error.message, 'close');
    }, () => {
      this.tableDataSource = tenureList;
      console.log(this.tableDataSource);
    });

  }

  onAddNewTenure() {
    this.isAdding = true;
    const newTenure: BiographyCongressTenure = {
      id: 0,
      congressId: null,
      startDate: null,
      endDate: null,
      chamber: null,
      party: null,
      state: null
    };
    this.selectedRowId = newTenure.id;
    this.tableDataSource.push(newTenure);
  }

  onSaveOrUpdateTenureRow(biographyTenure: BiographyCongressTenure) {

    const startDateTemp = new Date(biographyTenure.startDate);
    const endDateTemp = new Date(biographyTenure.endDate);

    if (startDateTemp !== null) {
      biographyTenure.startDate = `${startDateTemp.getFullYear()}-${startDateTemp.getMonth() + 1}-${startDateTemp.getDate()}`;
    }

    if (endDateTemp !== null) {
      biographyTenure.endDate = `${endDateTemp.getFullYear()}-${endDateTemp.getMonth() + 1}-${endDateTemp.getDate()}`;
    }

    if (this.isAdding) {

      const params: BiographyCongressTenureService.CreateBiographyCongressTenureParams = {
        biographyCongressTenure: biographyTenure,
        biographyId: this.data.biographyId
      };
      let newTenure: BiographyCongressTenure;
      this._biographyCongressTenureService.createBiographyCongressTenure(params).subscribe((res: BiographyCongressTenure) => {
        newTenure = res;
      }, error => {
        this.isAdding = true;

        this.openSnackBar(error.error.message, 'close');
      }, () => {
        this.isAdding = false;
        this.selectedRowId = null;
        this.openSnackBar('New Tenure has been added successfully!!', 'close');
        this.tableDataSource.pop();
        this.tableDataSource.push(newTenure);

      });

    } else if (this.isEditing) {

      const params: BiographyCongressTenure = biographyTenure;
      this._biographyCongressTenureService.updateBiographyCongressTenure(params).subscribe(res => {

      }, error => {
        this.isEditing = false;
        this.openSnackBar(error.error.message, 'close');
      }, () => {
        this.isEditing = false;
        this.selectedRowId = null;
        this.openSnackBar('Tenure has been updated successfully!', 'close');
      });
    }

  }

  onDeleteClick(biographyTenure: BiographyCongressTenure) {

    if (this.isAdding) {
      this.tableDataSource.pop();
      this.tableDataSource = this.tableDataSource.filter(el => el.id !== 0);
      this.isAdding = false;
    } else {

      this._biographyCongressTenureService.deleteBiographyCongressTenure(biographyTenure.id).subscribe(res => {
        this.tableDataSource = this.tableDataSource.filter(el => el.id !== biographyTenure.id);
      }, error => {
        this.openSnackBar(error.error.message, 'close');
      }, () => {
        this.openSnackBar('Tenure has been deleted successfully!', 'close');
      });

    }

  }

  onStateSelection(stateName: string, biographyTenure: BiographyCongressTenure) {
    const state = this.states.filter(item => item.value === stateName);

    const index = this.tableDataSource.findIndex(el => el.id === biographyTenure.id);
    this.tableDataSource[index].stateCode = state[0].abbreviation;
  }

  onEditTenure(row: BiographyCongressTenure) {
    this.isEditing = true;
    this.selectedRowId = row.id;
    // console.log(this.isEditing);

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IssuerDetail } from '../models/issuer-detail';
import { Manager } from '../models/manager';
@Injectable({
  providedIn: 'root',
})
class ShortInterestOverviewService extends __BaseService {
  static readonly getTop10MostShortedStocksPath = '/ShortInterestsOverview/TopMostShortedStocks/{countryId}';
  static readonly getTop10MostCoveredStocksPath = '/ShortInterestsOverview/TopMostCoveredStocks/{countryId}';
  static readonly getTop10ManagersByValueOfActivePositionsPath = '/ShortInterestsOverview/TopManagers/{countryId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShortInterestOverviewService.GetTop10MostShortedStocksParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero means all
   *
   * - `sortOption`: Sort by percentage or value
   *
   * @return Returns top most shorted stocks by country
   */
  getTop10MostShortedStocksResponse(params: ShortInterestOverviewService.GetTop10MostShortedStocksParams): __Observable<__StrictHttpResponse<Array<IssuerDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortOption != null) __params = __params.set('sortOption', params.sortOption.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ShortInterestsOverview/TopMostShortedStocks/${encodeURIComponent(String(params.countryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerDetail>>;
      })
    );
  }
  /**
   * @param params The `ShortInterestOverviewService.GetTop10MostShortedStocksParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero means all
   *
   * - `sortOption`: Sort by percentage or value
   *
   * @return Returns top most shorted stocks by country
   */
  getTop10MostShortedStocks(params: ShortInterestOverviewService.GetTop10MostShortedStocksParams): __Observable<Array<IssuerDetail>> {
    return this.getTop10MostShortedStocksResponse(params).pipe(
      __map(_r => _r.body as Array<IssuerDetail>)
    );
  }

  /**
   * @param params The `ShortInterestOverviewService.GetTop10MostCoveredStocksParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero means all
   *
   * - `sortOption`: Sort by percentage or value
   *
   * @return Returns top most covered stocks by country
   */
  getTop10MostCoveredStocksResponse(params: ShortInterestOverviewService.GetTop10MostCoveredStocksParams): __Observable<__StrictHttpResponse<Array<IssuerDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortOption != null) __params = __params.set('sortOption', params.sortOption.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ShortInterestsOverview/TopMostCoveredStocks/${encodeURIComponent(String(params.countryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerDetail>>;
      })
    );
  }
  /**
   * @param params The `ShortInterestOverviewService.GetTop10MostCoveredStocksParams` containing the following parameters:
   *
   * - `countryId`: Return data according to this countryId, zero means all
   *
   * - `sortOption`: Sort by percentage or value
   *
   * @return Returns top most covered stocks by country
   */
  getTop10MostCoveredStocks(params: ShortInterestOverviewService.GetTop10MostCoveredStocksParams): __Observable<Array<IssuerDetail>> {
    return this.getTop10MostCoveredStocksResponse(params).pipe(
      __map(_r => _r.body as Array<IssuerDetail>)
    );
  }

  /**
   * @param countryId Return data according to this countryId, zero means all
   * @return Returns top most managers by country
   */
  getTop10ManagersByValueOfActivePositionsResponse(countryId: number): __Observable<__StrictHttpResponse<Array<Manager>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ShortInterestsOverview/TopManagers/${encodeURIComponent(String(countryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Manager>>;
      })
    );
  }
  /**
   * @param countryId Return data according to this countryId, zero means all
   * @return Returns top most managers by country
   */
  getTop10ManagersByValueOfActivePositions(countryId: number): __Observable<Array<Manager>> {
    return this.getTop10ManagersByValueOfActivePositionsResponse(countryId).pipe(
      __map(_r => _r.body as Array<Manager>)
    );
  }
}

module ShortInterestOverviewService {

  /**
   * Parameters for getTop10MostShortedStocks
   */
  export interface GetTop10MostShortedStocksParams {

    /**
     * Return data according to this countryId, zero means all
     */
    countryId: number;

    /**
     * Sort by percentage or value
     */
    sortOption?: 'PERCENTAGE' | 'VALUE';
  }

  /**
   * Parameters for getTop10MostCoveredStocks
   */
  export interface GetTop10MostCoveredStocksParams {

    /**
     * Return data according to this countryId, zero means all
     */
    countryId: number;

    /**
     * Sort by percentage or value
     */
    sortOption?: 'PERCENTAGE' | 'VALUE';
  }
}

export { ShortInterestOverviewService }

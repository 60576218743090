/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CommonFilterMenu } from '../models/common-filter-menu';
import { SubFilterMenu } from '../models/sub-filter-menu';
import { AnnouncementFilter } from '../models/announcement-filter';
import { Keywords } from '../models/keywords';
@Injectable({
  providedIn: 'root',
})
class HeadlinesService extends __BaseService {
  static readonly getCommonFilterMenuSetUpPath = '/commonFilterMenuSetUp/{countryID}';
  static readonly getSubMenuFilterPath = '/subMenuFilter/{countryID}/{filterTypeID}';
  static readonly getTopFiltersAndHeadlinePath = '/topFiltersAndHeadline/{countryID}/{filterType}/{filterTypeSpecificID}';
  static readonly addOrUpdateKeywordsPath = '/addOrUpdateKeywords';
  static readonly getAllKeywordsPath = '/getAllKeywords';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param countryID Country ID.
   * @return Filter menu list.
   */
  getCommonFilterMenuSetUpResponse(countryID: string): __Observable<__StrictHttpResponse<Array<CommonFilterMenu>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/commonFilterMenuSetUp/${encodeURIComponent(String(countryID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CommonFilterMenu>>;
      })
    );
  }
  /**
   * @param countryID Country ID.
   * @return Filter menu list.
   */
  getCommonFilterMenuSetUp(countryID: string): __Observable<Array<CommonFilterMenu>> {
    return this.getCommonFilterMenuSetUpResponse(countryID).pipe(
      __map(_r => _r.body as Array<CommonFilterMenu>)
    );
  }

  /**
   * @param params The `HeadlinesService.GetSubMenuFilterParams` containing the following parameters:
   *
   * - `filterTypeID`: FilterTypeID Id selected from parent Menu.
   *
   * - `countryID`: Country Id selected.
   *
   * @return Filter sub-menu list.
   */
  getSubMenuFilterResponse(params: HeadlinesService.GetSubMenuFilterParams): __Observable<__StrictHttpResponse<Array<SubFilterMenu>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/subMenuFilter/${encodeURIComponent(String(params.countryID))}/${encodeURIComponent(String(params.filterTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SubFilterMenu>>;
      })
    );
  }
  /**
   * @param params The `HeadlinesService.GetSubMenuFilterParams` containing the following parameters:
   *
   * - `filterTypeID`: FilterTypeID Id selected from parent Menu.
   *
   * - `countryID`: Country Id selected.
   *
   * @return Filter sub-menu list.
   */
  getSubMenuFilter(params: HeadlinesService.GetSubMenuFilterParams): __Observable<Array<SubFilterMenu>> {
    return this.getSubMenuFilterResponse(params).pipe(
      __map(_r => _r.body as Array<SubFilterMenu>)
    );
  }

  /**
   * @param params The `HeadlinesService.GetTopFiltersAndHeadlineParams` containing the following parameters:
   *
   * - `filterTypeSpecificID`: Filter Type Specific Id selected from SubMenuFilter.
   *
   * - `filterType`: FilterType selected from SubMenuFilter.
   *
   * - `countryID`: Country Id selected.
   *
   * @return List of head lines.
   */
  getTopFiltersAndHeadlineResponse(params: HeadlinesService.GetTopFiltersAndHeadlineParams): __Observable<__StrictHttpResponse<Array<AnnouncementFilter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/topFiltersAndHeadline/${encodeURIComponent(String(params.countryID))}/${encodeURIComponent(String(params.filterType))}/${encodeURIComponent(String(params.filterTypeSpecificID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnnouncementFilter>>;
      })
    );
  }
  /**
   * @param params The `HeadlinesService.GetTopFiltersAndHeadlineParams` containing the following parameters:
   *
   * - `filterTypeSpecificID`: Filter Type Specific Id selected from SubMenuFilter.
   *
   * - `filterType`: FilterType selected from SubMenuFilter.
   *
   * - `countryID`: Country Id selected.
   *
   * @return List of head lines.
   */
  getTopFiltersAndHeadline(params: HeadlinesService.GetTopFiltersAndHeadlineParams): __Observable<Array<AnnouncementFilter>> {
    return this.getTopFiltersAndHeadlineResponse(params).pipe(
      __map(_r => _r.body as Array<AnnouncementFilter>)
    );
  }

  /**
   * @param keywords This will have keywords (semi column seperated) information for the country.
   * @return This will return keywords saved for the country
   */
  addOrUpdateKeywordsResponse(keywords: Array<Keywords>): __Observable<__StrictHttpResponse<Array<Keywords>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = keywords;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/addOrUpdateKeywords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Keywords>>;
      })
    );
  }
  /**
   * @param keywords This will have keywords (semi column seperated) information for the country.
   * @return This will return keywords saved for the country
   */
  addOrUpdateKeywords(keywords: Array<Keywords>): __Observable<Array<Keywords>> {
    return this.addOrUpdateKeywordsResponse(keywords).pipe(
      __map(_r => _r.body as Array<Keywords>)
    );
  }

  /**
   * @return This will return keywords saved for the country
   */
  getAllKeywordsResponse(): __Observable<__StrictHttpResponse<Array<Keywords>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getAllKeywords`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Keywords>>;
      })
    );
  }
  /**
   * @return This will return keywords saved for the country
   */
  getAllKeywords(): __Observable<Array<Keywords>> {
    return this.getAllKeywordsResponse().pipe(
      __map(_r => _r.body as Array<Keywords>)
    );
  }
}

module HeadlinesService {

  /**
   * Parameters for getSubMenuFilter
   */
  export interface GetSubMenuFilterParams {

    /**
     * FilterTypeID Id selected from parent Menu.
     */
    filterTypeID: number;

    /**
     * Country Id selected.
     */
    countryID: number;
  }

  /**
   * Parameters for getTopFiltersAndHeadline
   */
  export interface GetTopFiltersAndHeadlineParams {

    /**
     * Filter Type Specific Id selected from SubMenuFilter.
     */
    filterTypeSpecificID: number;

    /**
     * FilterType selected from SubMenuFilter.
     */
    filterType: number;

    /**
     * Country Id selected.
     */
    countryID: number;
  }
}

export { HeadlinesService }

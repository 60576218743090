import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FilesComponent} from "./components/files/files.component";
import {FileProcessingComponent} from "./components/file-processing/file-procesing.component";
import {AnnualReportsComponent} from "./components/annual-reports/annual-reports.component";
import {
  AnnualReportProcessingComponent
} from "./components/annual-report-processing/annual-report-processing.component";
import {ListedOptionTradesComponent} from "./components/listed-option-trades/listed-option-trades.component";
import {
  IssuerTradesProcessingComponent
} from "./components/issuer-trades-processing/issuer-trades-processing.component";
import {
  FocusedFileIssuerMappingComponent
} from "./components/focused-file-issuer-mapping/focused-file-issuer-mapping.component";
import {BiographyComponent} from "./components/biography/biography.component";
import {CommitteesComponent} from "./components/committes/committees.component";
import {QualityGatesComponent} from "./components/quality-gates/quality-gates.component";
import {StatsComponent} from "./components/stats/stats.component";
import {AllTradesComponent} from "./components/all-trades/all-trades.component";


const senator_children: Routes = [
  {path: '', component: FilesComponent},
  {path: 'file/:congressType/:filingId', component: FileProcessingComponent},
  {path: 'annual-reports', component: AnnualReportsComponent},
  {path: 'annual-report/:congressType/:filingId/:sourceUrl', component: AnnualReportProcessingComponent},
  {path: 'trades/listed-options/:optionListingId', component: ListedOptionTradesComponent},
  {path: 'trades/:senatorCompanyId', component: IssuerTradesProcessingComponent},
  {path: 'trades/:senatorCompanyId/:assetId', component: IssuerTradesProcessingComponent},
  {path: 'trades/:senatorCompanyId/:assetId/:date', component: IssuerTradesProcessingComponent},
  {path: 'issuer-mapping/:congressType/:senatorCompanyId', component: FocusedFileIssuerMappingComponent},
  {path: 'biographies', component: BiographyComponent},
  {path: 'committees', component: CommitteesComponent},
  {path: 'qualityGates', component: QualityGatesComponent},
  {path: 'stats', component: StatsComponent},
  {path: 'alltrades', component: AllTradesComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

export const SENATORS_ROUTES: Routes = [
  {path: '', children: senator_children}
];


@NgModule({
  imports: [RouterModule.forRoot(SENATORS_ROUTES, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}



/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StockOptionListing } from '../models/stock-option-listing';
@Injectable({
  providedIn: 'root',
})
class StockOptionListingService extends __BaseService {
  static readonly getStockOptionListingsPath = '/stockOptionListing';
  static readonly addNewStockOptionListingPath = '/stockOptionListing';
  static readonly updateStockOptionListingPath = '/stockOptionListing';
  static readonly deleteStockOptionListingPath = '/stockOptionListing';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param assetClassId undefined
   * @return it will return the saved stock options
   */
  getStockOptionListingsResponse(assetClassId: number): __Observable<__StrictHttpResponse<Array<StockOptionListing>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (assetClassId != null) __params = __params.set('assetClassId', assetClassId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/stockOptionListing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StockOptionListing>>;
      })
    );
  }
  /**
   * @param assetClassId undefined
   * @return it will return the saved stock options
   */
  getStockOptionListings(assetClassId: number): __Observable<Array<StockOptionListing>> {
    return this.getStockOptionListingsResponse(assetClassId).pipe(
      __map(_r => _r.body as Array<StockOptionListing>)
    );
  }

  /**
   * @param params The `StockOptionListingService.AddNewStockOptionListingParams` containing the following parameters:
   *
   * - `stockOptionListing`:
   *
   * - `senatorCompanyAssetClassId`:
   *
   * @return it will return the saved stock options
   */
  addNewStockOptionListingResponse(params: StockOptionListingService.AddNewStockOptionListingParams): __Observable<__StrictHttpResponse<StockOptionListing>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stockOptionListing;
    if (params.senatorCompanyAssetClassId != null) __params = __params.set('senatorCompanyAssetClassId', params.senatorCompanyAssetClassId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/stockOptionListing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StockOptionListing>;
      })
    );
  }
  /**
   * @param params The `StockOptionListingService.AddNewStockOptionListingParams` containing the following parameters:
   *
   * - `stockOptionListing`:
   *
   * - `senatorCompanyAssetClassId`:
   *
   * @return it will return the saved stock options
   */
  addNewStockOptionListing(params: StockOptionListingService.AddNewStockOptionListingParams): __Observable<StockOptionListing> {
    return this.addNewStockOptionListingResponse(params).pipe(
      __map(_r => _r.body as StockOptionListing)
    );
  }

  /**
   * @param stockOptionListing undefined
   * @return it will return the saved stock option
   */
  updateStockOptionListingResponse(stockOptionListing: StockOptionListing): __Observable<__StrictHttpResponse<StockOptionListing>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = stockOptionListing;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/stockOptionListing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StockOptionListing>;
      })
    );
  }
  /**
   * @param stockOptionListing undefined
   * @return it will return the saved stock option
   */
  updateStockOptionListing(stockOptionListing: StockOptionListing): __Observable<StockOptionListing> {
    return this.updateStockOptionListingResponse(stockOptionListing).pipe(
      __map(_r => _r.body as StockOptionListing)
    );
  }

  /**
   * @param stockOptionListingId undefined
   * @return whether it is deleted or not (whether not available to delete or already in use)
   */
  deleteStockOptionListingResponse(stockOptionListingId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (stockOptionListingId != null) __params = __params.set('stockOptionListingId', stockOptionListingId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/stockOptionListing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param stockOptionListingId undefined
   * @return whether it is deleted or not (whether not available to delete or already in use)
   */
  deleteStockOptionListing(stockOptionListingId: number): __Observable<boolean> {
    return this.deleteStockOptionListingResponse(stockOptionListingId).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module StockOptionListingService {

  /**
   * Parameters for addNewStockOptionListing
   */
  export interface AddNewStockOptionListingParams {
    stockOptionListing: StockOptionListing;
    senatorCompanyAssetClassId: number;
  }
}

export { StockOptionListingService }

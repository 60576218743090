/* tslint:disable */

/**
 * A enum contains types for stock options
 */
type StockOptionType =
  'Call Options' |
  'Put Options' |
  'Others';
module StockOptionType {
  export const CALL_OPTIONS: StockOptionType = 'Call Options';
  export const PUT_OPTIONS: StockOptionType = 'Put Options';
  export const OTHERS: StockOptionType = 'Others';
  export function values(): StockOptionType[] {
    return [
      CALL_OPTIONS,
      PUT_OPTIONS,
      OTHERS
    ];
  }
}

export { StockOptionType }
/* tslint:disable */
type SenatorAssetClass =
  'Indices' |
  'Warrants' |
  'Crypto' |
  'ETF' |
  'ETN' |
  'Futures' |
  'Hedge/Pvt Eq Funds (non-EIF)' |
  'Municipal Security' |
  'Mutual Fund' |
  'Non-Public Stock' |
  'Other Investment Fund' |
  'Other Securities' |
  'Ownership Interest' |
  'Variable Annuity' |
  'Private Equity Fund' |
  'REIT' |
  'ABS' |
  'Stock' |
  'Stock Options' |
  'Corporate Bond' |
  'Stock Appreciation Right' |
  'Venture Capital' |
  'Preferred Shares' |
  'Bank Deposit' |
  'Annuity Fixed' |
  'Real Estate (Although we have a category of Property in the list)' |
  'Retirement Plan - IRA' |
  'Brokerage/Managed Account';
module SenatorAssetClass {
  export const INDICES: SenatorAssetClass = 'Indices';
  export const WARRANTS: SenatorAssetClass = 'Warrants';
  export const CRYPTO: SenatorAssetClass = 'Crypto';
  export const ETF: SenatorAssetClass = 'ETF';
  export const ETN: SenatorAssetClass = 'ETN';
  export const FUTURES: SenatorAssetClass = 'Futures';
  export const HEDGE_PVT_EQ_FUNDS_NON_EIF_: SenatorAssetClass = 'Hedge/Pvt Eq Funds (non-EIF)';
  export const MUNICIPAL_SECURITY: SenatorAssetClass = 'Municipal Security';
  export const MUTUAL_FUND: SenatorAssetClass = 'Mutual Fund';
  export const NON_PUBLIC_STOCK: SenatorAssetClass = 'Non-Public Stock';
  export const OTHER_INVESTMENT_FUND: SenatorAssetClass = 'Other Investment Fund';
  export const OTHER_SECURITIES: SenatorAssetClass = 'Other Securities';
  export const OWNERSHIP_INTEREST: SenatorAssetClass = 'Ownership Interest';
  export const VARIABLE_ANNUITY: SenatorAssetClass = 'Variable Annuity';
  export const PRIVATE_EQUITY_FUND: SenatorAssetClass = 'Private Equity Fund';
  export const REIT: SenatorAssetClass = 'REIT';
  export const ABS: SenatorAssetClass = 'ABS';
  export const STOCK: SenatorAssetClass = 'Stock';
  export const STOCK_OPTIONS: SenatorAssetClass = 'Stock Options';
  export const CORPORATE_BOND: SenatorAssetClass = 'Corporate Bond';
  export const STOCK_APPRECIATION_RIGHT: SenatorAssetClass = 'Stock Appreciation Right';
  export const VENTURE_CAPITAL: SenatorAssetClass = 'Venture Capital';
  export const PREFERRED_SHARES: SenatorAssetClass = 'Preferred Shares';
  export const BANK_DEPOSIT: SenatorAssetClass = 'Bank Deposit';
  export const ANNUITY_FIXED: SenatorAssetClass = 'Annuity Fixed';
  export const REAL_ESTATE_ALTHOUGH_WE_HAVE_A_CATEGORY_OF_PROPERTY_IN_THE_LIST_: SenatorAssetClass = 'Real Estate (Although we have a category of Property in the list)';
  export const RETIREMENT_PLAN_IRA: SenatorAssetClass = 'Retirement Plan - IRA';
  export const BROKERAGE_MANAGED_ACCOUNT: SenatorAssetClass = 'Brokerage/Managed Account';
  export function values(): SenatorAssetClass[] {
    return [
      INDICES,
      WARRANTS,
      CRYPTO,
      ETF,
      ETN,
      FUTURES,
      HEDGE_PVT_EQ_FUNDS_NON_EIF_,
      MUNICIPAL_SECURITY,
      MUTUAL_FUND,
      NON_PUBLIC_STOCK,
      OTHER_INVESTMENT_FUND,
      OTHER_SECURITIES,
      OWNERSHIP_INTEREST,
      VARIABLE_ANNUITY,
      PRIVATE_EQUITY_FUND,
      REIT,
      ABS,
      STOCK,
      STOCK_OPTIONS,
      CORPORATE_BOND,
      STOCK_APPRECIATION_RIGHT,
      VENTURE_CAPITAL,
      PREFERRED_SHARES,
      BANK_DEPOSIT,
      ANNUITY_FIXED,
      REAL_ESTATE_ALTHOUGH_WE_HAVE_A_CATEGORY_OF_PROPERTY_IN_THE_LIST_,
      RETIREMENT_PLAN_IRA,
      BROKERAGE_MANAGED_ACCOUNT
    ];
  }
}

export { SenatorAssetClass }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OnMarketTest } from '../models/on-market-test';
import { BuybackNote } from '../models/buyback-note';
import { Buyback } from '../models/buyback';
import { BuybackWithResponse } from '../models/buyback-with-response';
import { ShareHeldTypeUdate } from '../models/share-held-type-udate';
import { SecurityUpdate } from '../models/security-update';
import { CalculateValues } from '../models/calculate-values';
import { UniqueValue } from '../models/unique-value';
import { QualityCheckMarker } from '../models/quality-check-marker';
import { ClosePrice } from '../models/close-price';
import { SharesCapital } from '../models/shares-capital';
import { BuybackBarChartData } from '../models/buyback-bar-chart-data';
@Injectable({
  providedIn: 'root',
})
class BuybackService extends __BaseService {
  static readonly getBuybackByPath = '/buyback/by';
  static readonly mergeIssueAndBrokerInBuybackByPath = '/buyback/updateBuyback';
  static readonly deleteBuybackPath = '/buyback/delete/{buybackID}';
  static readonly checkDuplicateBuybackPath = '/buyback/duplicate/check/{companyID}/{buybackDate}/{announcementCountryISO2}/{buybackId}';
  static readonly checkDuplicateBuybackWihtRespectAmountPath = '/buyback/duplicate/withSameAmount/{companyID}/{buybackDate}/{securityID}/{amountOfShare}';
  static readonly getBuybackHoldingTypePath = '/buyback/holdingType';
  static readonly createNotePath = '/buyback/note';
  static readonly getBuybackNotesPath = '/buyback/notes/{isDelete}';
  static readonly processBuybackPath = '/buyback/process';
  static readonly multiProcessBuybackPath = '/buyback/multiProcess';
  static readonly updateBuybackPath = '/buyback/update/{buybackId}';
  static readonly updateShareTypePath = '/buyback/updateShareHeldType';
  static readonly updateSecurityPath = '/buyback/updateSecurity';
  static readonly getBuybackTransactionNotesPath = '/buyback/transactionNotes/{companyID}';
  static readonly getBuybackViaPath = '/buyback/via';
  static readonly getBuybackHistoryPath = '/buyback/history/{countryCode}/{companyID}/{securityID}/{numberOfRecords}';
  static readonly getBuybackHistoryUsingIIDPath = '/buyback/{companyID}/{IID}/history';
  static readonly getBuybackHistoryByIdPath = '/buyback/history/{buybackId}/';
  static readonly calculateValuesForIntentionPath = '/calculateValuesForIntention/{intentionValue}/{currencyCode}/{announcementDate}/{DaysDifference}';
  static readonly getTransactionTypesPath = '/getTransactionType';
  static readonly checkValueUniquePath = '/isValueUnique';
  static readonly hasBuybackProcessPath = '/hasBuybackProcess/{announcementID}';
  static readonly saveUpdateHoldingPath = '/holding';
  static readonly checkOnMarketTestPath = '/onMarketTest/{gvkey}/{securityID}/{iid}/{transPrice}/{transTypeID}/{transDate}/{transCurrency}/{isPrimarySecurity}/{tickerCurrency}/{isUSBatchProcess}';
  static readonly calculateValueWithRatePath = '/calculateValue/{currencyCode}/{transactionDate}/{customDaysDifference}/{price}/{amount}/';
  static readonly calculateTransactionVolumePercentPath = '/volumePercent/{transactionDate}/{companyID}/{amount}/{iid}/';
  static readonly calculateBuybackPercentPath = '/buyBackPercent/{transactionDate}/{companyID}/{amount}/{iid}/';
  static readonly getEURMcapPath = '/eurMarketCap/{companyID}';
  static readonly calculateMarketCapPercentagePath = '/marketCapPercentage/{euroValue}/{euroMarketCap}/';
  static readonly markQualityCheckAsPassedPath = '/markAsPassed';
  static readonly updateBuybackIssuerAnnouncedInPath = '/updateBuybackIssuerAnnouncedIn/{buybackID}/{companyID}';
  static readonly getLastClosePricePath = '/lastClosePrice/{companyID}/{iid}/{buybackDate}';
  static readonly saveIntentionTypesPath = '/intentionValues/{type}/{value}';
  static readonly updateIntentionIssuerAnnouncedInPath = '/updateIntentionIssuerAnnouncedIn/{intentionID}/{companyID}';
  static readonly updateSharesCapitalPath = '/updateSharesCapital/{companyID}/{securityID}/{sharesCapital}/{shareCapitalDate}/';
  static readonly getSharesCapitalPath = '/sharesCapital/{companyID}/{securityID}';
  static readonly getBuybackDataForBarChartPath = '/getBuybackData/{countryCode}/{startDate}/{endDate}';
  static readonly getNumberOfDuplicatesPath = '/buyback/duplicate/{companyId}/{securityId}/{price}/{amount}/{buybackDate}/count';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Buyback Buyback By.
   */
  getBuybackByResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/by`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Buyback Buyback By.
   */
  getBuybackBy(): __Observable<Array<string>> {
    return this.getBuybackByResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @return Merge Issuer and Broker in BuybackBy.
   */
  mergeIssueAndBrokerInBuybackByResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/buyback/updateBuyback`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Merge Issuer and Broker in BuybackBy.
   */
  mergeIssueAndBrokerInBuybackBy(): __Observable<boolean> {
    return this.mergeIssueAndBrokerInBuybackByResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.DeleteBuybackParams` containing the following parameters:
   *
   * - `noteText`: Text of the note which is attached with this Buyback
   *
   * - `buybackID`: BuybackId that needs to be deleted.
   *
   * @return Buyback deleted.
   */
  deleteBuybackResponse(params: BuybackService.DeleteBuybackParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.noteText;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/buyback/delete/${encodeURIComponent(String(params.buybackID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.DeleteBuybackParams` containing the following parameters:
   *
   * - `noteText`: Text of the note which is attached with this Buyback
   *
   * - `buybackID`: BuybackId that needs to be deleted.
   *
   * @return Buyback deleted.
   */
  deleteBuyback(params: BuybackService.DeleteBuybackParams): __Observable<boolean> {
    return this.deleteBuybackResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.CheckDuplicateBuybackParams` containing the following parameters:
   *
   * - `companyID`: Company ID
   *
   * - `buybackId`: buybackId is the zero if new buyback and will be passed if updating
   *
   * - `buybackDate`: Buyback Date
   *
   * - `announcementCountryISO2`: ISO 2 Country Code
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateBuybackResponse(params: BuybackService.CheckDuplicateBuybackParams): __Observable<__StrictHttpResponse<OnMarketTest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/duplicate/check/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.buybackDate))}/${encodeURIComponent(String(params.announcementCountryISO2))}/${encodeURIComponent(String(params.buybackId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnMarketTest>;
      })
    );
  }
  /**
   * @param params The `BuybackService.CheckDuplicateBuybackParams` containing the following parameters:
   *
   * - `companyID`: Company ID
   *
   * - `buybackId`: buybackId is the zero if new buyback and will be passed if updating
   *
   * - `buybackDate`: Buyback Date
   *
   * - `announcementCountryISO2`: ISO 2 Country Code
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateBuyback(params: BuybackService.CheckDuplicateBuybackParams): __Observable<OnMarketTest> {
    return this.checkDuplicateBuybackResponse(params).pipe(
      __map(_r => _r.body as OnMarketTest)
    );
  }

  /**
   * @param params The `BuybackService.CheckDuplicateBuybackWihtRespectAmountParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * - `buybackDate`: Buyback Date
   *
   * - `amountOfShare`: Amount Of Share
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateBuybackWihtRespectAmountResponse(params: BuybackService.CheckDuplicateBuybackWihtRespectAmountParams): __Observable<__StrictHttpResponse<OnMarketTest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/duplicate/withSameAmount/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.buybackDate))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.amountOfShare))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnMarketTest>;
      })
    );
  }
  /**
   * @param params The `BuybackService.CheckDuplicateBuybackWihtRespectAmountParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * - `buybackDate`: Buyback Date
   *
   * - `amountOfShare`: Amount Of Share
   *
   * @return Using the same model as OnMarketTest as it is also applicable here
   */
  checkDuplicateBuybackWihtRespectAmount(params: BuybackService.CheckDuplicateBuybackWihtRespectAmountParams): __Observable<OnMarketTest> {
    return this.checkDuplicateBuybackWihtRespectAmountResponse(params).pipe(
      __map(_r => _r.body as OnMarketTest)
    );
  }

  /**
   * @return Buyback Buyback Holding Type.
   */
  getBuybackHoldingTypeResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/holdingType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Buyback Buyback Holding Type.
   */
  getBuybackHoldingType(): __Observable<Array<string>> {
    return this.getBuybackHoldingTypeResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param noteText Note text that needs to be saved.
   * @return Last inserted Note
   */
  createNoteResponse(noteText: string): __Observable<__StrictHttpResponse<BuybackNote>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = noteText;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/buyback/note`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BuybackNote>;
      })
    );
  }
  /**
   * @param noteText Note text that needs to be saved.
   * @return Last inserted Note
   */
  createNote(noteText: string): __Observable<BuybackNote> {
    return this.createNoteResponse(noteText).pipe(
      __map(_r => _r.body as BuybackNote)
    );
  }

  /**
   * @param isDelete Is Deleted
   * @return Buyback Notes
   */
  getBuybackNotesResponse(isDelete: boolean): __Observable<__StrictHttpResponse<Array<BuybackNote>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/notes/${encodeURIComponent(String(isDelete))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackNote>>;
      })
    );
  }
  /**
   * @param isDelete Is Deleted
   * @return Buyback Notes
   */
  getBuybackNotes(isDelete: boolean): __Observable<Array<BuybackNote>> {
    return this.getBuybackNotesResponse(isDelete).pipe(
      __map(_r => _r.body as Array<BuybackNote>)
    );
  }

  /**
   * @param Buyback model Post buyback detail.
   * @return Buyback Post Result.
   */
  processBuybackResponse(BuybackModel: Buyback): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = BuybackModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/buyback/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param Buyback model Post buyback detail.
   * @return Buyback Post Result.
   */
  processBuyback(BuybackModel: Buyback): __Observable<{}> {
    return this.processBuybackResponse(BuybackModel).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param buybacks Detail of multiple buyback posts
   * @return Buyback Post Result.
   */
  multiProcessBuybackResponse(buybacks: Array<Buyback>): __Observable<__StrictHttpResponse<Array<BuybackWithResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = buybacks;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/buyback/multiProcess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackWithResponse>>;
      })
    );
  }
  /**
   * @param buybacks Detail of multiple buyback posts
   * @return Buyback Post Result.
   */
  multiProcessBuyback(buybacks: Array<Buyback>): __Observable<Array<BuybackWithResponse>> {
    return this.multiProcessBuybackResponse(buybacks).pipe(
      __map(_r => _r.body as Array<BuybackWithResponse>)
    );
  }

  /**
   * @param params The `BuybackService.UpdateBuybackParams` containing the following parameters:
   *
   * - `buybackId`:
   *
   * - `Buyback model`: Post buyback detail.
   *
   * @return Buyback Post Result.
   */
  updateBuybackResponse(params: BuybackService.UpdateBuybackParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.BuybackModel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/buyback/update/${encodeURIComponent(String(params.buybackId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `BuybackService.UpdateBuybackParams` containing the following parameters:
   *
   * - `buybackId`:
   *
   * - `Buyback model`: Post buyback detail.
   *
   * @return Buyback Post Result.
   */
  updateBuyback(params: BuybackService.UpdateBuybackParams): __Observable<{}> {
    return this.updateBuybackResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param Share held Post update detail.
   * @return Buyback Post Result.
   */
  updateShareTypeResponse(ShareHeld: ShareHeldTypeUdate): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ShareHeld;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/buyback/updateShareHeldType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param Share held Post update detail.
   * @return Buyback Post Result.
   */
  updateShareType(ShareHeld: ShareHeldTypeUdate): __Observable<{}> {
    return this.updateShareTypeResponse(ShareHeld).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param buybackIds Post update detail.
   * @return Buyback Post Result.
   */
  updateSecurityResponse(buybackIds: SecurityUpdate): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = buybackIds;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/buyback/updateSecurity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param buybackIds Post update detail.
   * @return Buyback Post Result.
   */
  updateSecurity(buybackIds: SecurityUpdate): __Observable<{}> {
    return this.updateSecurityResponse(buybackIds).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param companyID Company ID
   * @return Buyback Transation Notes
   */
  getBuybackTransactionNotesResponse(companyID: number): __Observable<__StrictHttpResponse<Array<BuybackNote>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/transactionNotes/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackNote>>;
      })
    );
  }
  /**
   * @param companyID Company ID
   * @return Buyback Transation Notes
   */
  getBuybackTransactionNotes(companyID: number): __Observable<Array<BuybackNote>> {
    return this.getBuybackTransactionNotesResponse(companyID).pipe(
      __map(_r => _r.body as Array<BuybackNote>)
    );
  }

  /**
   * @return Buyback Via.
   */
  getBuybackViaResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/via`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Buyback Via.
   */
  getBuybackVia(): __Observable<Array<string>> {
    return this.getBuybackViaResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `BuybackService.GetBuybackHistoryParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `numberOfRecords`: Number of records
   *
   * - `countryCode`: Country Code
   *
   * - `companyID`: Company ID
   *
   * @return Buyback History List
   */
  getBuybackHistoryResponse(params: BuybackService.GetBuybackHistoryParams): __Observable<__StrictHttpResponse<Array<Buyback>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/history/${encodeURIComponent(String(params.countryCode))}/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.numberOfRecords))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Buyback>>;
      })
    );
  }
  /**
   * @param params The `BuybackService.GetBuybackHistoryParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `numberOfRecords`: Number of records
   *
   * - `countryCode`: Country Code
   *
   * - `companyID`: Company ID
   *
   * @return Buyback History List
   */
  getBuybackHistory(params: BuybackService.GetBuybackHistoryParams): __Observable<Array<Buyback>> {
    return this.getBuybackHistoryResponse(params).pipe(
      __map(_r => _r.body as Array<Buyback>)
    );
  }

  /**
   * @param params The `BuybackService.GetBuybackHistoryUsingIIDParams` containing the following parameters:
   *
   * - `companyID`: Company ID
   *
   * - `IID`: IID
   *
   * - `numberOfRecords`: Number of records
   *
   * @return Buyback History List
   */
  getBuybackHistoryUsingIIDResponse(params: BuybackService.GetBuybackHistoryUsingIIDParams): __Observable<__StrictHttpResponse<Array<Buyback>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.numberOfRecords != null) __params = __params.set('numberOfRecords', params.numberOfRecords.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.IID))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Buyback>>;
      })
    );
  }
  /**
   * @param params The `BuybackService.GetBuybackHistoryUsingIIDParams` containing the following parameters:
   *
   * - `companyID`: Company ID
   *
   * - `IID`: IID
   *
   * - `numberOfRecords`: Number of records
   *
   * @return Buyback History List
   */
  getBuybackHistoryUsingIID(params: BuybackService.GetBuybackHistoryUsingIIDParams): __Observable<Array<Buyback>> {
    return this.getBuybackHistoryUsingIIDResponse(params).pipe(
      __map(_r => _r.body as Array<Buyback>)
    );
  }

  /**
   * @param buybackId Company ID
   * @return Buyback History List
   */
  getBuybackHistoryByIdResponse(buybackId: number): __Observable<__StrictHttpResponse<Buyback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/history/${encodeURIComponent(String(buybackId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Buyback>;
      })
    );
  }
  /**
   * @param buybackId Company ID
   * @return Buyback History List
   */
  getBuybackHistoryById(buybackId: number): __Observable<Buyback> {
    return this.getBuybackHistoryByIdResponse(buybackId).pipe(
      __map(_r => _r.body as Buyback)
    );
  }

  /**
   * @param params The `BuybackService.CalculateValuesForIntentionParams` containing the following parameters:
   *
   * - `intentionValue`: Intention Value
   *
   * - `currencyCode`: Intention Currency code.
   *
   * - `announcementDate`: Announcement Date.
   *
   * - `DaysDifference`: DaysDifference value always be 5.
   *
   * @return Return GBP, EUR, USD Values.
   */
  calculateValuesForIntentionResponse(params: BuybackService.CalculateValuesForIntentionParams): __Observable<__StrictHttpResponse<CalculateValues>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/calculateValuesForIntention/${encodeURIComponent(String(params.intentionValue))}/${encodeURIComponent(String(params.currencyCode))}/${encodeURIComponent(String(params.announcementDate))}/${encodeURIComponent(String(params.DaysDifference))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CalculateValues>;
      })
    );
  }
  /**
   * @param params The `BuybackService.CalculateValuesForIntentionParams` containing the following parameters:
   *
   * - `intentionValue`: Intention Value
   *
   * - `currencyCode`: Intention Currency code.
   *
   * - `announcementDate`: Announcement Date.
   *
   * - `DaysDifference`: DaysDifference value always be 5.
   *
   * @return Return GBP, EUR, USD Values.
   */
  calculateValuesForIntention(params: BuybackService.CalculateValuesForIntentionParams): __Observable<CalculateValues> {
    return this.calculateValuesForIntentionResponse(params).pipe(
      __map(_r => _r.body as CalculateValues)
    );
  }

  /**
   * @return Get list of transaction types that are acive
   */
  getTransactionTypesResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getTransactionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Get list of transaction types that are acive
   */
  getTransactionTypes(): __Observable<Array<string>> {
    return this.getTransactionTypesResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param UniqueValue Post UniqueValue model.
   * @return Return boolean on checking RNSID or SOURCEURL passed is unqiue or already exists in database.
   */
  checkValueUniqueResponse(UniqueValue: UniqueValue): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UniqueValue;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/isValueUnique`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param UniqueValue Post UniqueValue model.
   * @return Return boolean on checking RNSID or SOURCEURL passed is unqiue or already exists in database.
   */
  checkValueUnique(UniqueValue: UniqueValue): __Observable<boolean> {
    return this.checkValueUniqueResponse(UniqueValue).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param announcementID Announcement ID
   * @return Get Country of a specific announcement
   */
  hasBuybackProcessResponse(announcementID: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/hasBuybackProcess/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param announcementID Announcement ID
   * @return Get Country of a specific announcement
   */
  hasBuybackProcess(announcementID: number): __Observable<boolean> {
    return this.hasBuybackProcessResponse(announcementID).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.SaveUpdateHoldingParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `holdingUpdate`: Holding Update
   *
   * - `holding`: IID
   *
   * - `emptyTreasury`: Empty Treasury
   *
   * - `companyID`: Company ID
   *
   * @return Return true or false indicating whether the Holding is Updated/Saved
   */
  saveUpdateHoldingResponse(params: BuybackService.SaveUpdateHoldingParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.securityID != null) __params = __params.set('securityID', params.securityID.toString());
    if (params.holdingUpdate != null) __params = __params.set('holdingUpdate', params.holdingUpdate.toString());
    if (params.holding != null) __params = __params.set('holding', params.holding.toString());
    if (params.emptyTreasury != null) __params = __params.set('emptyTreasury', params.emptyTreasury.toString());
    if (params.companyID != null) __params = __params.set('companyID', params.companyID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/holding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.SaveUpdateHoldingParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `holdingUpdate`: Holding Update
   *
   * - `holding`: IID
   *
   * - `emptyTreasury`: Empty Treasury
   *
   * - `companyID`: Company ID
   *
   * @return Return true or false indicating whether the Holding is Updated/Saved
   */
  saveUpdateHolding(params: BuybackService.SaveUpdateHoldingParams): __Observable<boolean> {
    return this.saveUpdateHoldingResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.CheckOnMarketTestParams` containing the following parameters:
   *
   * - `transTypeID`: Transaction Type ID
   *
   * - `transPrice`: Transaction Price
   *
   * - `transDate`: Transaction Date
   *
   * - `transCurrency`: Transaction Currency
   *
   * - `tickerCurrency`: Ticker Currency
   *
   * - `securityID`: Security ID
   *
   * - `isUSBatchProcess`: is US Batch Process
   *
   * - `isPrimarySecurity`: is Primary Security
   *
   * - `iid`: IID
   *
   * - `gvkey`: gvkey
   *
   * @return Get GBP, EUR, USD Values and Exchange Rates
   */
  checkOnMarketTestResponse(params: BuybackService.CheckOnMarketTestParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;










    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/onMarketTest/${encodeURIComponent(String(params.gvkey))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.iid))}/${encodeURIComponent(String(params.transPrice))}/${encodeURIComponent(String(params.transTypeID))}/${encodeURIComponent(String(params.transDate))}/${encodeURIComponent(String(params.transCurrency))}/${encodeURIComponent(String(params.isPrimarySecurity))}/${encodeURIComponent(String(params.tickerCurrency))}/${encodeURIComponent(String(params.isUSBatchProcess))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.CheckOnMarketTestParams` containing the following parameters:
   *
   * - `transTypeID`: Transaction Type ID
   *
   * - `transPrice`: Transaction Price
   *
   * - `transDate`: Transaction Date
   *
   * - `transCurrency`: Transaction Currency
   *
   * - `tickerCurrency`: Ticker Currency
   *
   * - `securityID`: Security ID
   *
   * - `isUSBatchProcess`: is US Batch Process
   *
   * - `isPrimarySecurity`: is Primary Security
   *
   * - `iid`: IID
   *
   * - `gvkey`: gvkey
   *
   * @return Get GBP, EUR, USD Values and Exchange Rates
   */
  checkOnMarketTest(params: BuybackService.CheckOnMarketTestParams): __Observable<boolean> {
    return this.checkOnMarketTestResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.CalculateValueWithRateParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `price`: Price
   *
   * - `customDaysDifference`: Custom day difference
   *
   * - `currencyCode`: Currency Code
   *
   * - `amount`: Amount
   *
   * @return Get GBP, EUR, USD Values and Exchange Rates
   */
  calculateValueWithRateResponse(params: BuybackService.CalculateValueWithRateParams): __Observable<__StrictHttpResponse<CalculateValues>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/calculateValue/${encodeURIComponent(String(params.currencyCode))}/${encodeURIComponent(String(params.transactionDate))}/${encodeURIComponent(String(params.customDaysDifference))}/${encodeURIComponent(String(params.price))}/${encodeURIComponent(String(params.amount))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CalculateValues>;
      })
    );
  }
  /**
   * @param params The `BuybackService.CalculateValueWithRateParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `price`: Price
   *
   * - `customDaysDifference`: Custom day difference
   *
   * - `currencyCode`: Currency Code
   *
   * - `amount`: Amount
   *
   * @return Get GBP, EUR, USD Values and Exchange Rates
   */
  calculateValueWithRate(params: BuybackService.CalculateValueWithRateParams): __Observable<CalculateValues> {
    return this.calculateValueWithRateResponse(params).pipe(
      __map(_r => _r.body as CalculateValues)
    );
  }

  /**
   * @param params The `BuybackService.CalculateTransactionVolumePercentParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `iid`: iid of the company
   *
   * - `companyID`: Company Id
   *
   * - `amount`: Amount
   *
   * @return Get a single Volume percentage value
   */
  calculateTransactionVolumePercentResponse(params: BuybackService.CalculateTransactionVolumePercentParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/volumePercent/${encodeURIComponent(String(params.transactionDate))}/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.amount))}/${encodeURIComponent(String(params.iid))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BuybackService.CalculateTransactionVolumePercentParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `iid`: iid of the company
   *
   * - `companyID`: Company Id
   *
   * - `amount`: Amount
   *
   * @return Get a single Volume percentage value
   */
  calculateTransactionVolumePercent(params: BuybackService.CalculateTransactionVolumePercentParams): __Observable<number> {
    return this.calculateTransactionVolumePercentResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `BuybackService.CalculateBuybackPercentParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `iid`: iid of the company
   *
   * - `companyID`: Company Id
   *
   * - `amount`: Amount
   *
   * @return Get a single Buyback percentage value
   */
  calculateBuybackPercentResponse(params: BuybackService.CalculateBuybackPercentParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyBackPercent/${encodeURIComponent(String(params.transactionDate))}/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.amount))}/${encodeURIComponent(String(params.iid))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BuybackService.CalculateBuybackPercentParams` containing the following parameters:
   *
   * - `transactionDate`: Transaction Date
   *
   * - `iid`: iid of the company
   *
   * - `companyID`: Company Id
   *
   * - `amount`: Amount
   *
   * @return Get a single Buyback percentage value
   */
  calculateBuybackPercent(params: BuybackService.CalculateBuybackPercentParams): __Observable<number> {
    return this.calculateBuybackPercentResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param companyID Company ID
   * @return Get a single EURO Market value
   */
  getEURMcapResponse(companyID: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/eurMarketCap/${encodeURIComponent(String(companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param companyID Company ID
   * @return Get a single EURO Market value
   */
  getEURMcap(companyID: number): __Observable<number> {
    return this.getEURMcapResponse(companyID).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `BuybackService.CalculateMarketCapPercentageParams` containing the following parameters:
   *
   * - `euroValue`: Euro Value
   *
   * - `euroMarketCap`: Euro Market Cap
   *
   * @return Get a single EURO Market Cap Percentage value
   */
  calculateMarketCapPercentageResponse(params: BuybackService.CalculateMarketCapPercentageParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/marketCapPercentage/${encodeURIComponent(String(params.euroValue))}/${encodeURIComponent(String(params.euroMarketCap))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BuybackService.CalculateMarketCapPercentageParams` containing the following parameters:
   *
   * - `euroValue`: Euro Value
   *
   * - `euroMarketCap`: Euro Market Cap
   *
   * @return Get a single EURO Market Cap Percentage value
   */
  calculateMarketCapPercentage(params: BuybackService.CalculateMarketCapPercentageParams): __Observable<number> {
    return this.calculateMarketCapPercentageResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param qualityCheck undefined
   * @return Is QualityGateTrade is marked as passed for the specific quality gate.
   */
  markQualityCheckAsPassedResponse(qualityCheck?: QualityCheckMarker): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityCheck;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/markAsPassed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param qualityCheck undefined
   * @return Is QualityGateTrade is marked as passed for the specific quality gate.
   */
  markQualityCheckAsPassed(qualityCheck?: QualityCheckMarker): __Observable<boolean> {
    return this.markQualityCheckAsPassedResponse(qualityCheck).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.UpdateBuybackIssuerAnnouncedInParams` containing the following parameters:
   *
   * - `companyID`: Company Id.
   *
   * - `buybackID`: Buyback Id.
   *
   * @return Response for update IssuerAnnouncedIn
   */
  updateBuybackIssuerAnnouncedInResponse(params: BuybackService.UpdateBuybackIssuerAnnouncedInParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/updateBuybackIssuerAnnouncedIn/${encodeURIComponent(String(params.buybackID))}/${encodeURIComponent(String(params.companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.UpdateBuybackIssuerAnnouncedInParams` containing the following parameters:
   *
   * - `companyID`: Company Id.
   *
   * - `buybackID`: Buyback Id.
   *
   * @return Response for update IssuerAnnouncedIn
   */
  updateBuybackIssuerAnnouncedIn(params: BuybackService.UpdateBuybackIssuerAnnouncedInParams): __Observable<boolean> {
    return this.updateBuybackIssuerAnnouncedInResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.GetLastClosePriceParams` containing the following parameters:
   *
   * - `iid`: IID.
   *
   * - `companyID`: Company Id.
   *
   * - `buybackDate`: Buyback Date
   *
   * @return Return ClosePrice model.
   */
  getLastClosePriceResponse(params: BuybackService.GetLastClosePriceParams): __Observable<__StrictHttpResponse<ClosePrice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/lastClosePrice/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.iid))}/${encodeURIComponent(String(params.buybackDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClosePrice>;
      })
    );
  }
  /**
   * @param params The `BuybackService.GetLastClosePriceParams` containing the following parameters:
   *
   * - `iid`: IID.
   *
   * - `companyID`: Company Id.
   *
   * - `buybackDate`: Buyback Date
   *
   * @return Return ClosePrice model.
   */
  getLastClosePrice(params: BuybackService.GetLastClosePriceParams): __Observable<ClosePrice> {
    return this.getLastClosePriceResponse(params).pipe(
      __map(_r => _r.body as ClosePrice)
    );
  }

  /**
   * @param params The `BuybackService.SaveIntentionTypesParams` containing the following parameters:
   *
   * - `value`: option value.
   *
   * - `type`: Indicate type of the option that need to be save, Possible values are IntentionType, HoldingType, BuybackVia or BuybackBy.
   *
   * - `sortOrder`: sort order.
   *
   * @return Return last inserted ID if save successfuly.
   */
  saveIntentionTypesResponse(params: BuybackService.SaveIntentionTypesParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.sortOrder != null) __params = __params.set('sortOrder', params.sortOrder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/intentionValues/${encodeURIComponent(String(params.type))}/${encodeURIComponent(String(params.value))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BuybackService.SaveIntentionTypesParams` containing the following parameters:
   *
   * - `value`: option value.
   *
   * - `type`: Indicate type of the option that need to be save, Possible values are IntentionType, HoldingType, BuybackVia or BuybackBy.
   *
   * - `sortOrder`: sort order.
   *
   * @return Return last inserted ID if save successfuly.
   */
  saveIntentionTypes(params: BuybackService.SaveIntentionTypesParams): __Observable<number> {
    return this.saveIntentionTypesResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `BuybackService.UpdateIntentionIssuerAnnouncedInParams` containing the following parameters:
   *
   * - `intentionID`: Intention Id.
   *
   * - `companyID`: Company Id.
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Response for update IssuerAnnouncedIn for Intention
   */
  updateIntentionIssuerAnnouncedInResponse(params: BuybackService.UpdateIntentionIssuerAnnouncedInParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.buybackIntentionGroupByID != null) __params = __params.set('buybackIntentionGroupByID', params.buybackIntentionGroupByID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/updateIntentionIssuerAnnouncedIn/${encodeURIComponent(String(params.intentionID))}/${encodeURIComponent(String(params.companyID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.UpdateIntentionIssuerAnnouncedInParams` containing the following parameters:
   *
   * - `intentionID`: Intention Id.
   *
   * - `companyID`: Company Id.
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Response for update IssuerAnnouncedIn for Intention
   */
  updateIntentionIssuerAnnouncedIn(params: BuybackService.UpdateIntentionIssuerAnnouncedInParams): __Observable<boolean> {
    return this.updateIntentionIssuerAnnouncedInResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.UpdateSharesCapitalParams` containing the following parameters:
   *
   * - `sharesCapital`: shares capital
   *
   * - `shareCapitalDate`: Shares Capital Date
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Return true or false indicating whether the shares capital is Updated/Saved
   */
  updateSharesCapitalResponse(params: BuybackService.UpdateSharesCapitalParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/updateSharesCapital/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}/${encodeURIComponent(String(params.sharesCapital))}/${encodeURIComponent(String(params.shareCapitalDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `BuybackService.UpdateSharesCapitalParams` containing the following parameters:
   *
   * - `sharesCapital`: shares capital
   *
   * - `shareCapitalDate`: Shares Capital Date
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company ID
   *
   * @return Return true or false indicating whether the shares capital is Updated/Saved
   */
  updateSharesCapital(params: BuybackService.UpdateSharesCapitalParams): __Observable<boolean> {
    return this.updateSharesCapitalResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `BuybackService.GetSharesCapitalParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company Id
   *
   * @return Shares Capital
   */
  getSharesCapitalResponse(params: BuybackService.GetSharesCapitalParams): __Observable<__StrictHttpResponse<SharesCapital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sharesCapital/${encodeURIComponent(String(params.companyID))}/${encodeURIComponent(String(params.securityID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SharesCapital>;
      })
    );
  }
  /**
   * @param params The `BuybackService.GetSharesCapitalParams` containing the following parameters:
   *
   * - `securityID`: Security ID
   *
   * - `companyID`: Company Id
   *
   * @return Shares Capital
   */
  getSharesCapital(params: BuybackService.GetSharesCapitalParams): __Observable<SharesCapital> {
    return this.getSharesCapitalResponse(params).pipe(
      __map(_r => _r.body as SharesCapital)
    );
  }

  /**
   * @param params The `BuybackService.GetBuybackDataForBarChartParams` containing the following parameters:
   *
   * - `startDate`: It is string that will determine that data required is started from date
   *
   * - `endDate`: It is string that will determine that data required is started until date
   *
   * - `countryCode`: It is string that will determine that data required is for US or Non-US
   *
   * @return Return boolean if updated.
   */
  getBuybackDataForBarChartResponse(params: BuybackService.GetBuybackDataForBarChartParams): __Observable<__StrictHttpResponse<Array<BuybackBarChartData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getBuybackData/${encodeURIComponent(String(params.countryCode))}/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BuybackBarChartData>>;
      })
    );
  }
  /**
   * @param params The `BuybackService.GetBuybackDataForBarChartParams` containing the following parameters:
   *
   * - `startDate`: It is string that will determine that data required is started from date
   *
   * - `endDate`: It is string that will determine that data required is started until date
   *
   * - `countryCode`: It is string that will determine that data required is for US or Non-US
   *
   * @return Return boolean if updated.
   */
  getBuybackDataForBarChart(params: BuybackService.GetBuybackDataForBarChartParams): __Observable<Array<BuybackBarChartData>> {
    return this.getBuybackDataForBarChartResponse(params).pipe(
      __map(_r => _r.body as Array<BuybackBarChartData>)
    );
  }

  /**
   * @param params The `BuybackService.GetNumberOfDuplicatesParams` containing the following parameters:
   *
   * - `securityId`: security ID of the company
   *
   * - `price`: buyback price
   *
   * - `companyId`: ID of the Company
   *
   * - `buybackDate`: Date of BuyBack
   *
   * - `amount`: ammount of buyback
   *
   * @return Return number of duplicates.
   */
  getNumberOfDuplicatesResponse(params: BuybackService.GetNumberOfDuplicatesParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/buyback/duplicate/${encodeURIComponent(String(params.companyId))}/${encodeURIComponent(String(params.securityId))}/${encodeURIComponent(String(params.price))}/${encodeURIComponent(String(params.amount))}/${encodeURIComponent(String(params.buybackDate))}/count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BuybackService.GetNumberOfDuplicatesParams` containing the following parameters:
   *
   * - `securityId`: security ID of the company
   *
   * - `price`: buyback price
   *
   * - `companyId`: ID of the Company
   *
   * - `buybackDate`: Date of BuyBack
   *
   * - `amount`: ammount of buyback
   *
   * @return Return number of duplicates.
   */
  getNumberOfDuplicates(params: BuybackService.GetNumberOfDuplicatesParams): __Observable<number> {
    return this.getNumberOfDuplicatesResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module BuybackService {

  /**
   * Parameters for deleteBuyback
   */
  export interface DeleteBuybackParams {

    /**
     * Text of the note which is attached with this Buyback
     */
    noteText: string;

    /**
     * BuybackId that needs to be deleted.
     */
    buybackID: number;
  }

  /**
   * Parameters for checkDuplicateBuyback
   */
  export interface CheckDuplicateBuybackParams {

    /**
     * Company ID
     */
    companyID: number;

    /**
     * buybackId is the zero if new buyback and will be passed if updating
     */
    buybackId: number;

    /**
     * Buyback Date
     */
    buybackDate: string;

    /**
     * ISO 2 Country Code
     */
    announcementCountryISO2: string;
  }

  /**
   * Parameters for checkDuplicateBuybackWihtRespectAmount
   */
  export interface CheckDuplicateBuybackWihtRespectAmountParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company ID
     */
    companyID: number;

    /**
     * Buyback Date
     */
    buybackDate: string;

    /**
     * Amount Of Share
     */
    amountOfShare: number;
  }

  /**
   * Parameters for updateBuyback
   */
  export interface UpdateBuybackParams {
    buybackId: number;

    /**
     * Post buyback detail.
     */
    BuybackModel: Buyback;
  }

  /**
   * Parameters for getBuybackHistory
   */
  export interface GetBuybackHistoryParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Number of records
     */
    numberOfRecords: number;

    /**
     * Country Code
     */
    countryCode: string;

    /**
     * Company ID
     */
    companyID: number;
  }

  /**
   * Parameters for getBuybackHistoryUsingIID
   */
  export interface GetBuybackHistoryUsingIIDParams {

    /**
     * Company ID
     */
    companyID: number;

    /**
     * IID
     */
    IID: string;

    /**
     * Number of records
     */
    numberOfRecords?: number;
  }

  /**
   * Parameters for calculateValuesForIntention
   */
  export interface CalculateValuesForIntentionParams {

    /**
     * Intention Value
     */
    intentionValue: number;

    /**
     * Intention Currency code.
     */
    currencyCode: string;

    /**
     * Announcement Date.
     */
    announcementDate: string;

    /**
     * DaysDifference value always be 5.
     */
    DaysDifference: number;
  }

  /**
   * Parameters for saveUpdateHolding
   */
  export interface SaveUpdateHoldingParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Holding Update
     */
    holdingUpdate: string;

    /**
     * IID
     */
    holding: number;

    /**
     * Empty Treasury
     */
    emptyTreasury: boolean;

    /**
     * Company ID
     */
    companyID: number;
  }

  /**
   * Parameters for checkOnMarketTest
   */
  export interface CheckOnMarketTestParams {

    /**
     * Transaction Type ID
     */
    transTypeID: number;

    /**
     * Transaction Price
     */
    transPrice: number;

    /**
     * Transaction Date
     */
    transDate: string;

    /**
     * Transaction Currency
     */
    transCurrency: string;

    /**
     * Ticker Currency
     */
    tickerCurrency: string;

    /**
     * Security ID
     */
    securityID: number;

    /**
     * is US Batch Process
     */
    isUSBatchProcess: boolean;

    /**
     * is Primary Security
     */
    isPrimarySecurity: boolean;

    /**
     * IID
     */
    iid: string;

    /**
     * gvkey
     */
    gvkey: string;
  }

  /**
   * Parameters for calculateValueWithRate
   */
  export interface CalculateValueWithRateParams {

    /**
     * Transaction Date
     */
    transactionDate: string;

    /**
     * Price
     */
    price: number;

    /**
     * Custom day difference
     */
    customDaysDifference: number;

    /**
     * Currency Code
     */
    currencyCode: string;

    /**
     * Amount
     */
    amount: number;
  }

  /**
   * Parameters for calculateTransactionVolumePercent
   */
  export interface CalculateTransactionVolumePercentParams {

    /**
     * Transaction Date
     */
    transactionDate: string;

    /**
     * iid of the company
     */
    iid: string;

    /**
     * Company Id
     */
    companyID: number;

    /**
     * Amount
     */
    amount: number;
  }

  /**
   * Parameters for calculateBuybackPercent
   */
  export interface CalculateBuybackPercentParams {

    /**
     * Transaction Date
     */
    transactionDate: string;

    /**
     * iid of the company
     */
    iid: string;

    /**
     * Company Id
     */
    companyID: number;

    /**
     * Amount
     */
    amount: number;
  }

  /**
   * Parameters for calculateMarketCapPercentage
   */
  export interface CalculateMarketCapPercentageParams {

    /**
     * Euro Value
     */
    euroValue: number;

    /**
     * Euro Market Cap
     */
    euroMarketCap: number;
  }

  /**
   * Parameters for updateBuybackIssuerAnnouncedIn
   */
  export interface UpdateBuybackIssuerAnnouncedInParams {

    /**
     * Company Id.
     */
    companyID: number;

    /**
     * Buyback Id.
     */
    buybackID: number;
  }

  /**
   * Parameters for getLastClosePrice
   */
  export interface GetLastClosePriceParams {

    /**
     * IID.
     */
    iid: string;

    /**
     * Company Id.
     */
    companyID: number;

    /**
     * Buyback Date
     */
    buybackDate: string;
  }

  /**
   * Parameters for saveIntentionTypes
   */
  export interface SaveIntentionTypesParams {

    /**
     * option value.
     */
    value: string;

    /**
     * Indicate type of the option that need to be save, Possible values are IntentionType, HoldingType, BuybackVia or BuybackBy.
     */
    type: string;

    /**
     * sort order.
     */
    sortOrder?: number;
  }

  /**
   * Parameters for updateIntentionIssuerAnnouncedIn
   */
  export interface UpdateIntentionIssuerAnnouncedInParams {

    /**
     * Intention Id.
     */
    intentionID: number;

    /**
     * Company Id.
     */
    companyID: number;

    /**
     * Buyback Intention Group Id
     */
    buybackIntentionGroupByID?: number;
  }

  /**
   * Parameters for updateSharesCapital
   */
  export interface UpdateSharesCapitalParams {

    /**
     * shares capital
     */
    sharesCapital: number;

    /**
     * Shares Capital Date
     */
    shareCapitalDate: string;

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company ID
     */
    companyID: number;
  }

  /**
   * Parameters for getSharesCapital
   */
  export interface GetSharesCapitalParams {

    /**
     * Security ID
     */
    securityID: number;

    /**
     * Company Id
     */
    companyID: number;
  }

  /**
   * Parameters for getBuybackDataForBarChart
   */
  export interface GetBuybackDataForBarChartParams {

    /**
     * It is string that will determine that data required is started from date
     */
    startDate: string;

    /**
     * It is string that will determine that data required is started until date
     */
    endDate: string;

    /**
     * It is string that will determine that data required is for US or Non-US
     */
    countryCode: string;
  }

  /**
   * Parameters for getNumberOfDuplicates
   */
  export interface GetNumberOfDuplicatesParams {

    /**
     * security ID of the company
     */
    securityId: number;

    /**
     * buyback price
     */
    price: number;

    /**
     * ID of the Company
     */
    companyId: number;

    /**
     * Date of BuyBack
     */
    buybackDate: string;

    /**
     * ammount of buyback
     */
    amount: number;
  }
}

export { BuybackService }

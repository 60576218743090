/* tslint:disable */

/**
 * A model that is enum type for the types of gates.
 */
type QualityGateType =
  'PriceFailure' |
  'HighValueFailure' |
  'SmallValueFailure' |
  'HighVolumeFailure' |
  'HighBuybackPercentageFailure' |
  'NetTagQualityCheck' |
  'InActiveCompany' |
  'ByUserEmail';
module QualityGateType {
  export const PRICE_FAILURE: QualityGateType = 'PriceFailure';
  export const HIGH_VALUE_FAILURE: QualityGateType = 'HighValueFailure';
  export const SMALL_VALUE_FAILURE: QualityGateType = 'SmallValueFailure';
  export const HIGH_VOLUME_FAILURE: QualityGateType = 'HighVolumeFailure';
  export const HIGH_BUYBACK_PERCENTAGE_FAILURE: QualityGateType = 'HighBuybackPercentageFailure';
  export const NET_TAG_QUALITY_CHECK: QualityGateType = 'NetTagQualityCheck';
  export const IN_ACTIVE_COMPANY: QualityGateType = 'InActiveCompany';
  export const BY_USER_EMAIL: QualityGateType = 'ByUserEmail';
  export function values(): QualityGateType[] {
    return [
      PRICE_FAILURE,
      HIGH_VALUE_FAILURE,
      SMALL_VALUE_FAILURE,
      HIGH_VOLUME_FAILURE,
      HIGH_BUYBACK_PERCENTAGE_FAILURE,
      NET_TAG_QUALITY_CHECK,
      IN_ACTIVE_COMPANY,
      BY_USER_EMAIL
    ];
  }
}

export { QualityGateType }
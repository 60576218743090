/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RegionalIssuersManagersFilter } from '../models/regional-issuers-managers-filter';
import { TopManagers } from '../models/top-managers';
import { Manager } from '../models/manager';
import { ManagerName } from '../models/manager-name';
@Injectable({
  providedIn: 'root',
})
class ManagerService extends __BaseService {
  static readonly getManagersByissuerNamePath = '/manager/{issuerName}/{activeOnly}/';
  static readonly getManagersByNamePath = '/manager/search/name';
  static readonly getTop5ManagersByIssuerPath = '/manager/top5/{issuerName}';
  static readonly getTop5ManagersPath = '/manager/top5';
  static readonly mapManagerSuggestionsSearchPath = '/manager/search-map-suggestions';
  static readonly lockManagerPath = '/manager/lock';
  static readonly unlockManagerPath = '/manager/unlock';
  static readonly getManagersToMatchPath = '/manager/matching/names';
  static readonly getJapaneseManagersForTranslationPath = '/manager/translation/japanese/names';
  static readonly saveJapansesManagerTranslationPath = '/manager/translation/japanese/names';
  static readonly getEuropeanUnmappedManagersPath = '/manager/sds/european/unmapped/';
  static readonly saveManagerMappingPath = '/manager/sds/to-map/';
  static readonly getMappedManagersPath = '/manager/sds/mapped/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ManagerService.GetManagersByissuerNameParams` containing the following parameters:
   *
   * - `issuerName`: Search Value By IssuerName
   *
   * - `activeOnly`: Default it will be false
   *
   * @return List of Managers matching the gvkey.
   */
  getManagersByissuerNameResponse(params: ManagerService.GetManagersByissuerNameParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/${encodeURIComponent(String(params.issuerName))}/${encodeURIComponent(String(params.activeOnly))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `ManagerService.GetManagersByissuerNameParams` containing the following parameters:
   *
   * - `issuerName`: Search Value By IssuerName
   *
   * - `activeOnly`: Default it will be false
   *
   * @return List of Managers matching the gvkey.
   */
  getManagersByissuerName(params: ManagerService.GetManagersByissuerNameParams): __Observable<Array<string>> {
    return this.getManagersByissuerNameResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param regionalIssuersManagersFilter undefined
   * @return List of Managers
   */
  getManagersByNameResponse(regionalIssuersManagersFilter: RegionalIssuersManagersFilter): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = regionalIssuersManagersFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/search/name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param regionalIssuersManagersFilter undefined
   * @return List of Managers
   */
  getManagersByName(regionalIssuersManagersFilter: RegionalIssuersManagersFilter): __Observable<Array<string>> {
    return this.getManagersByNameResponse(regionalIssuersManagersFilter).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param issuerName Search Value By issuerName
   * @return List of Managers matching the managerName.
   */
  getTop5ManagersByIssuerResponse(issuerName: string): __Observable<__StrictHttpResponse<Array<TopManagers>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/top5/${encodeURIComponent(String(issuerName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TopManagers>>;
      })
    );
  }
  /**
   * @param issuerName Search Value By issuerName
   * @return List of Managers matching the managerName.
   */
  getTop5ManagersByIssuer(issuerName: string): __Observable<Array<TopManagers>> {
    return this.getTop5ManagersByIssuerResponse(issuerName).pipe(
      __map(_r => _r.body as Array<TopManagers>)
    );
  }

  /**
   * @return List of Managers matching the managerName.
   */
  getTop5ManagersResponse(): __Observable<__StrictHttpResponse<Array<Manager>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/top5`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Manager>>;
      })
    );
  }
  /**
   * @return List of Managers matching the managerName.
   */
  getTop5Managers(): __Observable<Array<Manager>> {
    return this.getTop5ManagersResponse().pipe(
      __map(_r => _r.body as Array<Manager>)
    );
  }

  /**
   * @param searchValue Search Value
   * @return Managers.
   */
  mapManagerSuggestionsSearchResponse(searchValue: string): __Observable<__StrictHttpResponse<Array<ManagerName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = searchValue;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/search-map-suggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManagerName>>;
      })
    );
  }
  /**
   * @param searchValue Search Value
   * @return Managers.
   */
  mapManagerSuggestionsSearch(searchValue: string): __Observable<Array<ManagerName>> {
    return this.mapManagerSuggestionsSearchResponse(searchValue).pipe(
      __map(_r => _r.body as Array<ManagerName>)
    );
  }

  /**
   * @param managerName Manager Name
   * @return Returns true or false about is locked.
   */
  lockManagerResponse(managerName: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = managerName;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param managerName Manager Name
   * @return Returns true or false about is locked.
   */
  lockManager(managerName: string): __Observable<boolean> {
    return this.lockManagerResponse(managerName).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Returns true or false about isUnlocked.
   */
  unlockManagerResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/unlock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Returns true or false about isUnlocked.
   */
  unlockManager(): __Observable<boolean> {
    return this.unlockManagerResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `ManagerService.GetManagersToMatchParams` containing the following parameters:
   *
   * - `showAll`:
   *
   * - `pageNumber`:
   *
   * @return List of Managers matching the managerName.
   */
  getManagersToMatchResponse(params: ManagerService.GetManagersToMatchParams): __Observable<__StrictHttpResponse<Array<ManagerName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showAll != null) __params = __params.set('showAll', params.showAll.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/matching/names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManagerName>>;
      })
    );
  }
  /**
   * @param params The `ManagerService.GetManagersToMatchParams` containing the following parameters:
   *
   * - `showAll`:
   *
   * - `pageNumber`:
   *
   * @return List of Managers matching the managerName.
   */
  getManagersToMatch(params: ManagerService.GetManagersToMatchParams): __Observable<Array<ManagerName>> {
    return this.getManagersToMatchResponse(params).pipe(
      __map(_r => _r.body as Array<ManagerName>)
    );
  }

  /**
   * @param pageNumber undefined
   * @return List of Japanese Managers pending translation.
   */
  getJapaneseManagersForTranslationResponse(pageNumber?: number): __Observable<__StrictHttpResponse<Array<ManagerName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageNumber != null) __params = __params.set('pageNumber', pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/translation/japanese/names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManagerName>>;
      })
    );
  }
  /**
   * @param pageNumber undefined
   * @return List of Japanese Managers pending translation.
   */
  getJapaneseManagersForTranslation(pageNumber?: number): __Observable<Array<ManagerName>> {
    return this.getJapaneseManagersForTranslationResponse(pageNumber).pipe(
      __map(_r => _r.body as Array<ManagerName>)
    );
  }

  /**
   * @param manager undefined
   * @return Operation's success indication.
   */
  saveJapansesManagerTranslationResponse(manager: ManagerName): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = manager;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/translation/japanese/names`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param manager undefined
   * @return Operation's success indication.
   */
  saveJapansesManagerTranslation(manager: ManagerName): __Observable<boolean> {
    return this.saveJapansesManagerTranslationResponse(manager).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param pageNumber undefined
   * @return List of European Managers pending mapping.
   */
  getEuropeanUnmappedManagersResponse(pageNumber?: number): __Observable<__StrictHttpResponse<Array<ManagerName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageNumber != null) __params = __params.set('pageNumber', pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/sds/european/unmapped/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManagerName>>;
      })
    );
  }
  /**
   * @param pageNumber undefined
   * @return List of European Managers pending mapping.
   */
  getEuropeanUnmappedManagers(pageNumber?: number): __Observable<Array<ManagerName>> {
    return this.getEuropeanUnmappedManagersResponse(pageNumber).pipe(
      __map(_r => _r.body as Array<ManagerName>)
    );
  }

  /**
   * @param manager undefined
   * @return Operation's success indication.
   */
  saveManagerMappingResponse(manager: ManagerName): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = manager;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/manager/sds/to-map/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param manager undefined
   * @return Operation's success indication.
   */
  saveManagerMapping(manager: ManagerName): __Observable<boolean> {
    return this.saveManagerMappingResponse(manager).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param pageNumber undefined
   * @return List of European Managers already mapped.
   */
  getMappedManagersResponse(pageNumber?: number): __Observable<__StrictHttpResponse<Array<ManagerName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageNumber != null) __params = __params.set('pageNumber', pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/manager/sds/mapped/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManagerName>>;
      })
    );
  }
  /**
   * @param pageNumber undefined
   * @return List of European Managers already mapped.
   */
  getMappedManagers(pageNumber?: number): __Observable<Array<ManagerName>> {
    return this.getMappedManagersResponse(pageNumber).pipe(
      __map(_r => _r.body as Array<ManagerName>)
    );
  }
}

module ManagerService {

  /**
   * Parameters for getManagersByissuerName
   */
  export interface GetManagersByissuerNameParams {

    /**
     * Search Value By IssuerName
     */
    issuerName: string;

    /**
     * Default it will be false
     */
    activeOnly: boolean;
  }

  /**
   * Parameters for getManagersToMatch
   */
  export interface GetManagersToMatchParams {
    showAll?: boolean;
    pageNumber?: number;
  }
}

export { ManagerService }

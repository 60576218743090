import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SenatorTradeFilter} from '../../../api/models/senator-trade-filter';
import {SenatorFiling} from '../../../api/models/senator-filing';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SenatorService} from '../../../api/services/senator.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {SenatorTransactionTypes} from '../../../api/models/senator-transaction-types';

import {Observable, Subject} from 'rxjs';
import {SenatorTrades} from '../../../api/models/senator-trades';
import {DatePipe} from '@angular/common';

//import {TwitterImgFileDialogComponent} from './twitter-img-file-dialog/twitter-img-file-dialog.component';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CongressType} from 'src/api/models';
import DeleteSenatorFilingByFilingIdParams = SenatorService.DeleteSenatorFilingByFilingIdParams;
import SaveSenatorFilingParams = SenatorService.SaveSenatorFilingParams;
import UnlockFilingByIdParams = SenatorService.UnlockFilingByIdParams;
import GetSenatorTradesByFilingIdParams = SenatorService.GetSenatorTradesByFilingIdParams;
import SplitFileParams = SenatorService.SplitFileParams;
import CombineFileParams = SenatorService.CombineFileParams;

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})

export class FilesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['filingId', 'fileStatus', 'processedBy', 'firstName', 'lastName', 'officeName',
    'reportType', 'sourceLink', 'filingDate', 'actions'];
  senatorFilling: MatTableDataSource<SenatorFiling>;
  fileSearchMinDate: Date;
  fileSearchMaxDate: Date;
  // MatPaginator Inputs
  length = 0;
  pagination = {pageSize: 10, pageNumber: 1} as SenatorTradeFilter;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  user: string = null;
  isFilingLoading = false;
  loginUser: string;
  congressTypes;

  readonly DATE_FORMAT = 'yyyy-MM-dd';
  transactionTypes: SenatorTransactionTypes[];

  constructor(private _senatorService: SenatorService, private router: Router,
              private datePipe: DatePipe,
              private matDialog: MatDialog, public snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit() {

    this.user = localStorage.getItem('userId');
    this.initVars();
    this.getFiles();
  }

  ngAfterViewInit() {
    if (this.senatorFilling) {
      this.senatorFilling.paginator = this.paginator;
    }
  }

  initVars() {
    // getting current login user info
    this.loginUser = localStorage.getItem('userId');
    this.fileSearchMinDate = new Date(2010, 0, 1);
    this.fileSearchMaxDate = new Date();
    this.transactionTypes = SenatorTransactionTypes.values();
    this.congressTypes = CongressType.values();
    const savedFileSearchFilters = JSON.parse(localStorage.getItem('fileSearchFilters'));
    const timestamp = new Date(localStorage.getItem('fileSearchFiltersStoredAt'));
    let timeSuitable = false;
    if (timestamp !== null) {
      timeSuitable = timestamp.getDate() == new Date().getDate();
    }
    if (!savedFileSearchFilters || !timeSuitable) {

      const toDateFormat = this.datePipe.transform(new Date(), this.DATE_FORMAT);
      this.pagination = {
        fromDate: '2010-01-01',
        toDate: toDateFormat,
        pageNumber: 1,
        pageSize: 10,
        congressType: 'Representative',
        politicianName: '',
        companyName: null,
        filingId: null,
        isPaperFilesOnly: false,
        isApproved: false,
        isMissingGvkey: false,
        isMissingFilingName: false,
        isSplittedFiles: false,
        tradeType: [],
        url: null
      };
      this.setFiltersInLocalStorage();
    } else {
      this.pagination = this.getFilterFromLocalStorage();

    }

  }


  applyFilterAndSearchFiles() {

    this.paginator.firstPage();
    this.setFiltersInLocalStorage();
    this.getFiles();
  }

  getFiles() {
    this.isFilingLoading = true;
    // setting date formats

    const filters = this.getFilterFromLocalStorage();

    this._senatorService.getSenatorFilings(filters).subscribe((res: SenatorFiling[]) => {
      this.senatorFilling = new MatTableDataSource<SenatorFiling>(res);
      this.senatorFilling.sort = this.sort;

      // this.senatorFilling.paginator.pageSize = this.fileSearchFilters.pageSize;
      // this.senatorFilling.paginator.pageIndex = this.fileSearchFilters.pageNumber - 1;


    }, (err) => {
      console.log('err', err);
    }, () => {
      // console.log('this.senatorFilling', this.senatorFilling);
      if (this.paginator != null) {
        this.paginator.pageIndex = this.pagination.pageNumber - 1;
        this.paginator.pageSize = this.pagination.pageSize;
      }
      this.isFilingLoading = false;
    });
  }

  fileSearchDateToggle() {
    if (this.pagination.fromDate === null) {
      this.pagination.fromDate = '2010-01-01';
    } else if (this.pagination.toDate == null) {
      const todayDate = new Date();
      this.pagination.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
    }
    this.paginator.firstPage();
    this.setFiltersInLocalStorage();
    this.getFiles();
  }

  onClickMarkAsAmended(file: SenatorFiling, action: String) {
    const fileToBeMark: SenatorFiling = {
      filingId: file.filingId,
      firstName: file.firstName || '',
      lastName: file.lastName || '',
      officeName: file.officeName || '',
      filerType: file.filerType || '',
      filingDate: file.filingDate || '',
      sourceUrl: file.sourceUrl || '',
      reportType: file.reportType || null,
      isAmended: file.isAmended,
      congressType: file.congressType,
      amendmentFilingId: file.amendmentFilingId
    };
    switch (action) {
      case 'amend':
        fileToBeMark.isAmended = true;
        const fileAmendmentDialogRef = this.matDialog.open(FileAmendmentDialogComponent, {
          width: '550px',
          height: '250px',
          data: {
            file: fileToBeMark
          }
        });
        fileAmendmentDialogRef.afterClosed().subscribe((res) => {
          this.getFiles();
        });
        break;
      case 'unamend':
        fileToBeMark.isAmended = false;
        this.updateFile(fileToBeMark);
        break;
    }

  }

  updateFile(file: SenatorFiling) {

    this.isFilingLoading = true;
    const params: SaveSenatorFilingParams = {
      congressType: this.pagination.congressType,
      senatorFiling: file
    };
    this._senatorService.saveSenatorFiling(params).subscribe((res: SenatorFiling) => {

      // console.log('res', res);
    }, (err) => {
      console.log('file update Error:');
      console.log('err', err);
      this.openSnackBar(`File Update Error! ${err.error.message}`, 'Close');
      this.isFilingLoading = false;
    }, () => {
      this.isFilingLoading = false;
      this.getFiles();
      this.openSnackBar('File has been marked unamended', 'Close');
    });

  }

  unlockFile(filingId: number) {
    this.isFilingLoading = true;
    const params: UnlockFilingByIdParams = {
      congressType: this.pagination.congressType,
      filingId: filingId
    };
    this._senatorService.unlockFilingById(params).subscribe((res) => {

      // console.log('res', res);
    }, (err) => {
      console.log('file update Error:');
      console.log('err', err);
      this.openSnackBar(`${filingId} File Unlock Error! ${err.error.message}`, 'Close');
      this.isFilingLoading = false;
    }, () => {
      this.isFilingLoading = false;
      this.openSnackBar(`${filingId} File has been Unlocked Successfully!`, 'Close');
      this.getFiles();
    });
  }

  openUnlockFileDialog(file) {
    const fileSplitDialogRef = this.matDialog.open(FileUnlockDialogComponent, {
      width: '550px',
      height: '250px',
      data: {
        file: file
      }
    });
    fileSplitDialogRef.afterClosed().subscribe((res) => {
      switch (res.event) {
        case 'unlock':
          this.unlockFile(file.filingId);
          break;
      }
    });

  }

  isFileLocked(file: SenatorFiling): Observable<boolean> {
    const subject = new Subject<boolean>();
    const params: GetSenatorTradesByFilingIdParams = {
      filingId: file.filingId,
      congressType: file.congressType
    };
    this._senatorService.getSenatorTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      subject.next(false);
    }, (err) => {
      this.openSnackBar(err.error.message, 'Close');
      subject.next(true);
    }, () => {
    });
    return subject.asObservable();
  }

  onFileEditInFullPageClick(file: SenatorFiling) {
    this.isFileLocked(file).subscribe(lock => {
      if (!lock) {
        this.router.navigate(['/file', file.congressType, file.filingId]);
      }
    });
  }

  onFocusedIssuerMapping(file: SenatorFiling) {
    this.isFileLocked(file).subscribe(lock => {
      if (!lock) {
        this.router.navigate(['/senators/issuer-mapping', this.pagination.congressType, file.filingId]);
      }

    });
  }

  openDialog(file: SenatorFiling): void {
    /*  const dialogRef = this.dialog.open(TwitterImgFileDialogComponent, {
        width: '1000px',
        height: '800px',
        data: {
          file: file
        }
      });

      dialogRef.afterClosed().subscribe(result => {
      });*/
  }

  onFileDeleteClick(file) {
    const fileDeleteDialogRef = this.matDialog.open(FileDeleteDialogComponent, {
      width: '550px',
      height: '250px',
      data: {
        file: file
      }
    });
    fileDeleteDialogRef.afterClosed().subscribe((res) => {
      this.getFiles();
    });
  }

  addNewFileClick() {

    const dialogRef = this.matDialog.open(AddNewFileComponent, {
      disableClose: true,
      width: '600px',
      data: {
        congressType: this.pagination.congressType
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getFiles();
    });
  }

  onSplitFile(file) {
    const fileSplitDialogRef = this.matDialog.open(FileSplitDialogComponent, {
      width: '550px',
      height: '250px',
      data: {
        file: file
      }
    });
    fileSplitDialogRef.afterClosed().subscribe((res) => {
      this.getFiles();
    });
  }

  onCombineFile(file) {
    const fileCombineDialogRef = this.matDialog.open(FileCombineDialogComponent, {
      width: '550px',
      height: '250px',
      data: {
        file: file
      }
    });
    fileCombineDialogRef.afterClosed().subscribe((res) => {
      this.getFiles();
    });

  }

  onPaginateChange(event: PageEvent) {
    if (event == null) {
      return;
    }
    this.pagination.pageNumber = event.pageIndex + 1;
    this.pagination.pageSize = event.pageSize;
    if (this.paginator == null) {
      return;
    }

    this.setFiltersInLocalStorage();
    this.getFiles();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'left'
    });
  }

  private setFiltersInLocalStorage() {
    const fileSearchFromDate = new Date(this.pagination.fromDate);
    const fileSearchToDate = new Date(this.pagination.toDate);
    const fileSearchFromDateFormat = this.datePipe.transform(fileSearchFromDate, this.DATE_FORMAT);
    const fileSearchToDateFormat = this.datePipe.transform(fileSearchToDate, this.DATE_FORMAT);


    const filters: SenatorTradeFilter = {
      fromDate: fileSearchFromDateFormat,
      toDate: fileSearchToDateFormat,
      pageNumber: this.pagination.pageNumber,
      pageSize: this.pagination.pageSize,
      congressType: this.pagination.congressType,
      isPaperFilesOnly: this.pagination.isPaperFilesOnly,
      isApproved: this.pagination.isApproved,
      politicianName: this.pagination.politicianName,
      companyName: this.pagination.companyName,
      filingId: this.pagination.filingId,
      isMissingGvkey: this.pagination.isMissingGvkey,
      isMissingFilingName: this.pagination.isMissingFilingName,
      tradeType: this.pagination.tradeType,
      isSplittedFiles: this.pagination.isSplittedFiles,
      url: this.pagination.url
    };

    localStorage.setItem('fileSearchFilters', JSON.stringify(filters));
    localStorage.setItem('fileSearchFiltersStoredAt', this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm'));
  }

  private getFilterFromLocalStorage(): SenatorTradeFilter {
    return JSON.parse(localStorage.getItem('fileSearchFilters'));
  }

}

@Component({
  selector: 'app-file-delete-dialog-Component',
  template: `<h1 mat-dialog-title>Delete File</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <h5>Are you sure, you want to delete the file <b>{{data?.file.filingId}}</b>?</h5>
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="fileDeleting"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onDeleteFile()">Delete</button>
  </div>`,
})
export class FileDeleteDialogComponent implements OnInit {
  fileDeleting = false;
  filingId: number;
  congressType: string;

  constructor(
    public dialogRef: MatDialogRef<FileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _senatorMappingService: SenatorService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDeleteFile() {

    this.fileDeleting = true;
    const params: DeleteSenatorFilingByFilingIdParams = {
      filingId: this.data.file.filingId,
      congressType: this.data.file.congressType
    };
    this._senatorMappingService.deleteSenatorFilingByFilingId(params).subscribe((res) => {
    }, (err) => {

      this.fileDeleting = false;
      this.openSnackBar(`Error!,  ${JSON.parse(err.error).message}`, 'Close');
    }, () => {
      this.fileDeleting = false;
      this.dialogRef.close();
      this.openSnackBar('File has been deleted successfully!', 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

@Component({
  selector: 'app-file-combine-dialog-Component',
  template: `<h1 mat-dialog-title>Combine Split Files</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <p>Are you sure, you want to Combine all Child {{data.file.childFilingCount}} files into parent
      file {{data.file.filingId}} ?</p>
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="fileCombining"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onCombineFile()">Combine</button>
  </div>`,
})
export class FileCombineDialogComponent implements OnInit {
  fileCombining = false;
  filingId: number;
  congressType: string;

  constructor(
    public dialogRef: MatDialogRef<FileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _senatorMappingService: SenatorService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCombineFile() {

    this.fileCombining = true;
    const params: CombineFileParams = {
      filingId: this.data.file.filingId,
      congressType: this.data.file.congressType
    };
    this._senatorMappingService.combineFile(params).subscribe((res) => {
    }, (err) => {
      this.fileCombining = false;
      this.openSnackBar(`Error!,  ${err.error.message}`, 'Close');
    }, () => {
      this.fileCombining = false;
      this.dialogRef.close();
      this.openSnackBar(`${this.data.file.childFilingCount} child splits has been Combined in ${this.data.file.filingId} successfully!`, 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}


@Component({
  selector: 'app-file-split-dialog-Component',
  template: `<h1 mat-dialog-title>Split File</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <p>How many parts you want to split this file?</p>


    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>parts</mat-label>
      <input matInput type="number" [(ngModel)]="parts">
      <!--    <md-error>Please enter a value between 0 and 10</md-error>-->
      <mat-hint>Enter the value between 2 to 10.</mat-hint>
    </mat-form-field>
  </div>


  <mat-progress-spinner class="progressSpinner" *ngIf="fileSplitting"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button [disabled]="parts < 2 || parts > 10" mat-raised-button color="warn" (click)="onSplitFile()">Split</button>
  </div>`,
})

export class FileSplitDialogComponent implements OnInit {
  fileSplitting = false;
  filingId: number;
  congressType: string;
  parts = 0;

  constructor(
    public dialogRef: MatDialogRef<FileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _senatorMappingService: SenatorService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSplitFile() {

    this.fileSplitting = true;
    const params: SplitFileParams = {
      filingId: this.data.file.filingId,
      congressType: this.data.file.congressType,
      parts: this.parts
    };

    this._senatorMappingService.splitFile(params).subscribe((res) => {
    }, (err) => {

      this.fileSplitting = false;
      this.openSnackBar(`Error!,  ${JSON.parse(err.error).message}`, 'Close');
    }, () => {
      this.fileSplitting = false;
      this.dialogRef.close();
      this.openSnackBar(`File ${this.data.file.filingId} has been split in ${this.parts} successfully!`, 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

@Component({
  selector: 'app-file-amendment-dialog-component',
  template: `<h1 mat-dialog-title>{{file.filingId}}, File Mark As Amended</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <p>Please enter the filing id of this amendment.</p>
    <input matInput type="number" maxlength="20" placeholder="Amendment Filing id"
           [(ngModel)]="file.amendmentFilingId">
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="isFilingUpdating"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="warn" (click)="onMarkAmendedClick()"
            [disabled]="file?.amendmentFilingId === null">
      Mark Amended
    </button>
  </div>`,
})
export class FileAmendmentDialogComponent implements OnInit {

  isFilingUpdating = false;
  file: SenatorFiling;

  constructor(
    public dialogRef: MatDialogRef<FileAmendmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _senatorService: SenatorService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // console.log('Amendment file data: ', this.data['file'])
    this.file = this.data['file'];
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onMarkAmendedClick() {
    this.isFilingUpdating = true;
    const params: SaveSenatorFilingParams = {
      congressType: this.file.congressType,
      senatorFiling: this.file
    };
    this._senatorService.saveSenatorFiling(params).subscribe((res: SenatorFiling) => {

      // console.log('res', res);
    }, (err) => {
      console.log('err', err);
      this.openSnackBar(`File Update Error! ${err.error.message}`, 'Close');
      this.isFilingUpdating = false;
    }, () => {
      this.isFilingUpdating = false;
      this.dialogRef.close();
      this.openSnackBar('File has been marked as Amended', 'Close');
    });
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}

@Component({
  selector: 'app-file-unlock-dialog-component',
  template: `<h1 mat-dialog-title>Unlock File</h1>
  <div mat-dialog-content class="mat-dialog-height">
    <p>Are you sure, you want to unlock the file <b>{{file?.filingId}}</b> ?<br></p>
    <p> Locked by: <b>{{file?.lockedBy}}</b></p>
  </div>

  <mat-progress-spinner class="progressSpinner" *ngIf="isFilingUpdating"
                        [mode]="'indeterminate'" diameter="25"></mat-progress-spinner>


  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onUnlockClick()">
      Unlock
    </button>
  </div>`,
})
export class FileUnlockDialogComponent implements OnInit {

  isFilingUpdating = false;
  file: SenatorFiling;

  constructor(
    public dialogRef: MatDialogRef<FileAmendmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _senatorService: SenatorService,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.file = this.data['file'];
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onUnlockClick() {
    this.dialogRef.close({event: 'unlock'});
  }


}


@Component({
  selector: 'app-add-new-file-dialog-Component',
  template: `
    <h2 mat-dialog-title>Add New File</h2>

    <div *ngIf="fileForm" mat-dialog-content class="mat-typography">
      <form [formGroup]="fileForm">

        <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="15px">
          <div fxLayoutAlign="center center" fxFlex *ngIf="isProcessing" style="position: absolute;left: 50%;top: 50%;">
            <mat-progress-spinner class="progressSpinner"
                                  [mode]="'indeterminate'" diameter="40"></mat-progress-spinner>
          </div>
          <ng-container>


            <mat-form-field fxFlex="45">
              <mat-label>First name</mat-label>
              <input matInput placeholder="first name" formControlName="firstName">
            </mat-form-field>


            <mat-form-field fxFlex="45">
              <mat-label>Lastname</mat-label>
              <input matInput placeholder="last name" formControlName="lastName">
            </mat-form-field>

            <mat-form-field fxFlex="45">
              <mat-label>Full name</mat-label>
              <input matInput placeholder="full name" formControlName="officeName">
            </mat-form-field>


            <mat-form-field fxFlex="45">
              <mat-label>House</mat-label>
              <mat-select placeholder="House" name="congressType" formControlName="congressType">
                <mat-option *ngFor="let congressType of congressTypes" [value]="congressType">
                  {{congressType}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field fxFlex="45">
              <mat-label>Filing date</mat-label>
              <input matInput [matDatepicker]="picker" placeholder="Filing date" formControlName="filingDate"
                     [max]='maxFilingDate'>
              <mat-error *ngIf="_filingDate?.hasError('required')">*required</mat-error>
              <mat-error
                *ngIf="_filingDate?.errors['dateError']">
                <strong>Invalid date</strong>
              </mat-error>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="45">
              <mat-label>Source url</mat-label>
              <input matInput placeholder="srouce url" name="sourceUrl"
                     formControlName="sourceUrl">
            </mat-form-field>

          </ng-container>
        </div>
      </form>

    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button (click)="onSaveFile()" mat-raised-button color="primary"
              [disabled]="fileForm.invalid ||  isProcessing">Save
      </button>
    </mat-dialog-actions>

  `,
})

export class AddNewFileComponent implements OnInit {
  isProcessing = false;
  congressTypes = CongressType.values();
  fileForm: FormGroup;
  maxFilingDate: Date;

  constructor(
    public dialogRef: MatDialogRef<FileDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder, private _senatorService: SenatorService,
    public snackBar: MatSnackBar) {

  }

  get _filingDate(): AbstractControl {
    return this.fileForm.get('filingDate');
  }

  get _congressType(): AbstractControl {
    return this.fileForm.get('congressType');
  }

  get _officeName(): AbstractControl {
    return this.fileForm.get('officeName');
  }

  get _sourceUrl(): AbstractControl {
    return this.fileForm.get('sourceUrl');
  }

  get _firstName(): AbstractControl {
    return this.fileForm.get('firstName');
  }

  get _lastName(): AbstractControl {
    return this.fileForm.get('lastName');
  }

  ngOnInit() {
    this.initFileForm();
    this.maxFilingDate = new Date();
  }

  initFileForm() {

    this.fileForm = this._fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      officeName: ['', [Validators.required]],
      congressType: [this.data.congressType, [Validators.required]],
      filingDate: ['', [Validators.required]],
      sourceUrl: ['', [Validators.required]],
    });

  }

  onSaveFile() {

    const filingDateStandard = new Date(this._filingDate.value);
    let filingDateFormat = '';
    if (this._filingDate.value !== '') {
      filingDateFormat = `${filingDateStandard.getMonth() + 1}/${filingDateStandard.getDate()}/${filingDateStandard.getFullYear()}`;
    }


    this.isProcessing = true;
    this.fileForm.disable();

    const body: SenatorFiling = {
      officeName: this._officeName.value || '',
      filingDate: filingDateFormat || '',
      sourceUrl: this._sourceUrl.value,
      filerType: '',
      firstName: this._firstName.value,
      lastName: this._lastName.value,
      isApproved: false,
      isAmended: false
    };

    if (this.data.congressType === 'Representative') {

      const tempStr = body.sourceUrl.split('.gov');
      body.sourceUrl = tempStr[0] + '.gov//' + tempStr[1].replace(/^\/+/, '');
    }

    const params = {
      senatorFiling: body,
      congressType: String(this._congressType.value)
    };

    let response;
    this._senatorService.addSenatorFiling(params).subscribe((res: SenatorFiling) => {
      response = res;
    }, (err) => {
      this.isProcessing = false;
      this.fileForm.enable();
      this.openSnackBar(err.error.message, 'Close');
    }, () => {
      this.fileForm.enable();
      this.isProcessing = false;
      this.dialogRef.close();


      this.openSnackBar(`New File has been Created With id: ${response.filingId}`, 'Close');
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 9000,
      panelClass: ['green-snackbar']
    });
  }

}


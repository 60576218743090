import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SenatorCommitteeService, SenatorService} from '../../../../api/services';
import {SenatorCommittee} from '../../../../api/models/senator-committee';
import {Politician} from '../../../../api/models/politician';
import {SenatorCommitteeMember} from '../../../../api/models/senator-committee-member';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-edit-committee-dialog',
  templateUrl: './edit-committee-dialog.component.html',
  styleUrls: ['./edit-committee-dialog.component.scss']
})
export class EditCommitteeDialogComponent implements OnInit {
  loadingData: boolean;
  committeeDetails: SenatorCommittee;
  politiciansResponse: Politician[];
  senators: Politician [];
  representatives: Politician[];
  politicians: Politician[];
  filteredPoliticians: Observable<Politician[]>;


  committeeForm: FormGroup;
  description: string;

  designations = [

    {value: 'Chairman', viewValue: 'Chairman'},
    {value: 'Vice Chairman', viewValue: 'Vice Chairman'},
    {value: 'Ranking Member', viewValue: 'Ranking Member'},
    {value: 'Member', viewValue: 'Member'},
  ];

  // members table vars
  displayedColumns: string[] = ['name', 'designation', 'joiningDate', 'endingDate', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  membersDataSource = new MatTableDataSource<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public committeeData: any,
              private fb: FormBuilder, public snackBar: MatSnackBar,
              private committeeService: SenatorCommitteeService,
              private senatorService: SenatorService) {
  }

  ngOnInit() {
    this.initVars();
    this.createCommitteeForm();
    this.getCommitteeDetails();

  }

  initVars() {
    this.loadingData = false;
  }

  createCommitteeForm() {
    this.committeeForm = this.fb.group({
      id: [this.committeeData.committee.id, Validators.required],
      name: [this.committeeData.committee.name, Validators.required],
      description: [this.committeeData.committee.description, Validators.required],
      members: this.fb.array([])
    });
  }

  initCommitteeMemberForm() {

    this.committeeDetails.members.forEach((member: SenatorCommitteeMember) => {
      // console.log('initCommitteeMemberForm---> member:', member)
      this._committeeFormMembersArray.push(this.createCommitteeFormMemberRow(member));
    });
  }

  createCommitteeFormMemberRow(member: SenatorCommitteeMember):
    FormGroup {
    return this.fb.group({
      biographyId: [member.biographyId, Validators.required],
      name: [member.name, Validators.required],
      congressType: [member.congressType],
      designation: [member.designation, Validators.required],
      joiningDate: [member.joiningDate, Validators.required],
      endingDate: [member.endingDate,]
    });
  }

  getCommitteeDetails() {
    this.loadingData = true;
    this.committeeService.getCommitteeDetail(this.committeeData.committee.id).subscribe(
      (res: SenatorCommittee) => {
        this.committeeDetails = res;
        this.membersDataSource.data = this.committeeDetails.members;
        // console.log("membersDataSource----> : ", this.membersDataSource)
        // console.log("committeeDetails------>: ", this.committeeDetails)
      },
      error => {
        this.loadingData = false;
      },
      () => {
        this.loadingData = false;
        this.initCommitteeMemberForm();
        this.getPoliticians('Senator');
        this.getPoliticians('Representative');
      }
    );
  }

  getPoliticians(congressType: string) {
    this.loadingData = true;
    this.senatorService.getSenatorBiographyNames(congressType).subscribe(
      (res: Politician[]) => {
        this.politiciansResponse = res;
      },
      error => {
        this.loadingData = false;
      },
      () => {
        this.loadingData = false;
        switch (congressType) {
          case 'Senator':
            this.senators = this.politiciansResponse;
            break;
          case 'Representative':
            this.representatives = this.politiciansResponse;
            this.politicians = this.senators.concat(this.representatives);
            break;
        }
        this.membersDataSource.data.forEach((item, i) => {
          this.filteredPoliticians = this._committeeMemberName(i).valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });

      }
    );
  }

  onUpdateCommittee(action: string) {
    this.loadingData = true;
    const params: SenatorCommittee = this.committeeForm.value;
    switch (action) {
      case 'update':
        params.isApproved = false;
        break;
      case 'export':
        params.isApproved = true;
        break;
    }
    console.log(params);
    this.committeeService.saveCommittee(params).subscribe(
      (res: SenatorCommittee) => {
        this.committeeDetails = res;
      },
      error => {
        this.openSnackBar(error.error.message, 'Close');
        this.loadingData = false;
      },
      () => {
        this.loadingData = false;
        switch (action) {
          case 'update':
            this.openSnackBar('Committee data has been updated successfully!', 'close');
            break;
          case 'export':
            this.openSnackBar('Committee data has been exported successfully!', 'close');
            break;
        }

      }
    );
  }

  onAddNewCommitteeMember() {
    const newMember: SenatorCommitteeMember = {
      biographyId: Math.floor(Math.random() * 100000) + 100,
      name: null,
      congressType: 'Senator',
      designation: null,
      joiningDate: null,
      endingDate: null
    };
    this.membersDataSource.data.push(newMember);
    this._committeeFormMembersArray.push(this.createCommitteeFormMemberRow(newMember));
    this.membersDataSource._updateChangeSubscription();
    this.membersDataSource.data.forEach((item, i) => {
      this.filteredPoliticians = this._committeeMemberName(i).valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });

  }

  onDeleteCommitteeMember(index: number, row: SenatorCommitteeMember) {
    (<FormArray>this.committeeForm.controls['members']).removeAt(index);
    this.membersDataSource.data = this.membersDataSource.data.filter(item => item.biographyId !== row.biographyId);
    console.log('committeeForm', this.committeeForm);
  }

  onPoliticianNameSelection(member: Politician, index: number) {
    /*  console.log("onPoliticianNameSelection index:", index)
      console.log("member:", member);*/
    (<FormArray>this.committeeForm.controls['members']).at(index).get('biographyId').setValue(member.id);
    (<FormArray>this.committeeForm.controls['members']).at(index).get('congressType').setValue(member.congressType);

    // console.log("onPoliticianNameSelection");
    // console.log(this.committeeForm);

  }

  _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.politicians.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  get _committeeFormMembersArray(): FormArray {
    return this.committeeForm.get('members') as FormArray;
  }

  _committeeMemberName(index: number) {
    // console.log(this.committeeForm.get('members') as FormArray);
    return (<FormGroup>(this.committeeForm.get('members') as FormArray).controls[index]).controls['name'];
  }

  get _name() {
    return this.committeeForm.get('name');
  }

  get _description() {
    return this.committeeForm.get('description');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }
}

/* tslint:disable */
type SenatorAssetSubCategory =
  'ADR' |
  'Pref Share';
module SenatorAssetSubCategory {
  export const ADR: SenatorAssetSubCategory = 'ADR';
  export const PREF_SHARE: SenatorAssetSubCategory = 'Pref Share';
  export function values(): SenatorAssetSubCategory[] {
    return [
      ADR,
      PREF_SHARE
    ];
  }
}

export { SenatorAssetSubCategory }
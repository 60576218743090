/* tslint:disable */

/**
 * a model to know which type of quality gate is selected
 */
type SDSQualityGateType =
  'Active Position > 1' |
  'Blank Quality Gate' |
  'Is Deleted Disclosure' |
  'InActive Disclosures' |
  'ISIN Mismatch Disclosure' |
  '3% Threshold' |
  '10% Threshold' |
  'Latest Position Historic' |
  'Comments Quality Gate' |
  'Multiple Position in 1 day' |
  'Multiple Country' |
  'Multiple Active Position' |
  'Less than 0.5% Active' |
  'Weekend Transactions' |
  'Same Disc. Diff. Date' |
  'Last Checked Week Ago' |
  'Multiple Isin' |
  'Consecutive Zero Values' |
  'Current Date Last Checked Deleted';
module SDSQualityGateType {
  export const ACTIVE_POSITION_1: SDSQualityGateType = 'Active Position > 1';
  export const BLANK_QUALITY_GATE: SDSQualityGateType = 'Blank Quality Gate';
  export const IS_DELETED_DISCLOSURE: SDSQualityGateType = 'Is Deleted Disclosure';
  export const IN_ACTIVE_DISCLOSURES: SDSQualityGateType = 'InActive Disclosures';
  export const ISIN_MISMATCH_DISCLOSURE: SDSQualityGateType = 'ISIN Mismatch Disclosure';
  export const _3_THRESHOLD: SDSQualityGateType = '3% Threshold';
  export const _10_THRESHOLD: SDSQualityGateType = '10% Threshold';
  export const LATEST_POSITION_HISTORIC: SDSQualityGateType = 'Latest Position Historic';
  export const COMMENTS_QUALITY_GATE: SDSQualityGateType = 'Comments Quality Gate';
  export const MULTIPLE_POSITION_IN_1_DAY: SDSQualityGateType = 'Multiple Position in 1 day';
  export const MULTIPLE_COUNTRY: SDSQualityGateType = 'Multiple Country';
  export const MULTIPLE_ACTIVE_POSITION: SDSQualityGateType = 'Multiple Active Position';
  export const LESS_THAN_0_5_ACTIVE: SDSQualityGateType = 'Less than 0.5% Active';
  export const WEEKEND_TRANSACTIONS: SDSQualityGateType = 'Weekend Transactions';
  export const SAME_DISC_DIFF_DATE: SDSQualityGateType = 'Same Disc. Diff. Date';
  export const LAST_CHECKED_WEEK_AGO: SDSQualityGateType = 'Last Checked Week Ago';
  export const MULTIPLE_ISIN: SDSQualityGateType = 'Multiple Isin';
  export const CONSECUTIVE_ZERO_VALUES: SDSQualityGateType = 'Consecutive Zero Values';
  export const CURRENT_DATE_LAST_CHECKED_DELETED: SDSQualityGateType = 'Current Date Last Checked Deleted';
  export function values(): SDSQualityGateType[] {
    return [
      ACTIVE_POSITION_1,
      BLANK_QUALITY_GATE,
      IS_DELETED_DISCLOSURE,
      IN_ACTIVE_DISCLOSURES,
      ISIN_MISMATCH_DISCLOSURE,
      _3_THRESHOLD,
      _10_THRESHOLD,
      LATEST_POSITION_HISTORIC,
      COMMENTS_QUALITY_GATE,
      MULTIPLE_POSITION_IN_1_DAY,
      MULTIPLE_COUNTRY,
      MULTIPLE_ACTIVE_POSITION,
      LESS_THAN_0_5_ACTIVE,
      WEEKEND_TRANSACTIONS,
      SAME_DISC_DIFF_DATE,
      LAST_CHECKED_WEEK_AGO,
      MULTIPLE_ISIN,
      CONSECUTIVE_ZERO_VALUES,
      CURRENT_DATE_LAST_CHECKED_DELETED
    ];
  }
}

export { SDSQualityGateType }
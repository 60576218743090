import {Component, Inject, OnInit} from '@angular/core';
import {CompanyService} from '../../../../../../../../api/services/company.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SenatorCompany} from '../../../../../../../../api/models/senator-company';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SenatorCompanyAssetClass} from '../../../../../../../../api/models/senator-company-asset-class';
import {
  SenatorCompanyAssetClassService,
  SenatorCompanyService,
  SenatorService
} from '../../../../../../../../api/services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Company} from '../../../../../../../../api/models/company';
import {SenatorCompanyMerger} from '../../../../../../../../api/models/senator-company-merger';


@Component({
  selector: 'app-merge-asset-class',
  templateUrl: './merge-asset-class.component.html',
  styleUrls: ['./merge-asset-class.component.scss']
})
export class MergeAssetClassComponent implements OnInit {

  mergeForm: FormGroup;
  selectedCompany: SenatorCompany = {} as SenatorCompany;
  newSelectedCompany: SenatorCompany = {} as SenatorCompany;
  selectedAssetClass: SenatorCompanyAssetClass = {} as SenatorCompanyAssetClass;
  toMerge: SenatorCompanyAssetClass;
  twoIQAssetsList: SenatorCompany[] = [];
  assetNamesLoading = false;
  assetClassesLoading: boolean;
  suggestionAssetClasses = [] as SenatorCompanyAssetClass[];
  newAssetClasses = [] as SenatorCompanyAssetClass[];

  readonly MERGE_WITH_ASSET_CLASS = 'Asset Class';
  readonly MERGE_WITH_COMPANY = 'Company';
  mergeType: string;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MergeAssetClassComponent>,
    private service: SenatorCompanyAssetClassService,
    private _companyService: CompanyService,
    private _senatorCompanyService: SenatorCompanyService,
    private _senatorAssetClassService: SenatorCompanyAssetClassService,
    private _senatorService: SenatorService,
    private snackBar: MatSnackBar) {
    this.selectedCompany = data.selectedCompany;
    this.selectedAssetClass = data.selectedAssetClass;
    this.mergeType = data.mergeType;
  }

  ngOnInit() {
    this.createMergeForm();
    this.fetchMergeIntoAssetClassSuggestions();
  }

  createMergeForm() {
    this.mergeForm = this.fb.group({
      assetClass: [this.selectedAssetClass.x2iqAssetClass, Validators.required],
      toAssetClass: [null, Validators.required],
      x2iqIssuerName: [null],
      assetSubCategory: [null],
      new_senator_asset_class_id: [null, Validators.required],
    });
  }

  fetchMergeIntoAssetClassSuggestions() {
    const selectedAssetId = this.selectedAssetClass.ID;
    this.service.getAssetClassesOfTheCompany(this.selectedCompany)
      .subscribe(
        value => this.suggestionAssetClasses = value.filter(function (asset) {
          return asset.ID != selectedAssetId;
        })
      );
  }

  merge() {
    switch (this.mergeType) {
      case this.MERGE_WITH_ASSET_CLASS:
        this.mergeWithAssetClass();
        break;
      case this.MERGE_WITH_COMPANY:
        this.mergeWithCompany();
        break;

    }
  }

  mergeWithAssetClass() {
    const params: SenatorCompanyMerger = {
      is_executed: true,

      listOfMergingAssetClasses: [{
        old_senator_asset_class_id: this.selectedAssetClass.ID,
        new_senator_asset_class_id: this.toMerge.ID
      }],
      new_senator_company_id: this.selectedCompany.ID,
      old_senator_company_id: this.selectedCompany.ID,
    };

    this._senatorCompanyService.mergeSenatorCompany(params)
      .subscribe(
        value => {
          this.dialogRef.close('merged');
          this.openSnackBar('Asset Class has been merged successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {

        }
      );
  }

  mergeWithCompany() {
    const params: SenatorCompanyMerger = {
      is_executed: true,
      listOfMergingAssetClasses: [{
        old_senator_asset_class_id: this.selectedAssetClass.ID,
        new_senator_asset_class_id: this.new_senator_asset_class_id.value,
      }],
      old_senator_company_id: this.selectedCompany.ID,
      new_senator_company_id: this.newSelectedCompany.ID
    };
    this._senatorCompanyService.mergeSenatorCompany(params)
      .subscribe(
        value => {
          this.dialogRef.close('merged');
          this.openSnackBar('Asset Class has been merged successfully!', 'close');
        }, error => {
          this.openSnackBar(error.error.message, 'close');

        }, () => {

        }
      );
  }

  onToMergeSelection(event) {
    this.toMerge = event.value;
  }

  searchAssetNames(companyName: string) {
    this.assetNamesLoading = true;
    let companiesList = [] as SenatorCompany[];
    this.twoIQAssetsList = [];
    this._senatorService.getSenatorAssetNames(companyName).subscribe((res: SenatorCompany[]) => {
      companiesList = res;
    }, (err) => {
      this.assetNamesLoading = false;
      this.new_senator_asset_class_id.setValue(null);

      if (err.status === 400 || err.status === 500) {
        this.openSnackBar('Something went wrong! please enter a valid company name.', 'Close');
      } else {
        this.openSnackBar(err.error.message, 'Close');
      }

    }, () => {
      this.assetNamesLoading = false;
      if (companiesList.length === 0) {
        this.new_senator_asset_class_id.setValue(null);
        this.openSnackBar('No Company found with this name', 'Close');
      }

      this.twoIQAssetsList = companiesList.filter(t => t.ID != this.selectedCompany.ID);
    });
  }

  mapCompanyWith2IQ(event, company: SenatorCompany) {
    if (event.source.selected) {
      this.newSelectedCompany = company;
    }
  }

  refreshTheAssetClasses() {
    this.newAssetClasses = [];
    let companyId;
    companyId = this.newSelectedCompany.ID;
    if (companyId == null) {
      this.newAssetClasses = [];
      return;
    }
    this.assetClassesLoading = true;
    this._senatorAssetClassService.getAssetClassesOfTheCompany({gvkey: '', name: '', ID: companyId})
      .subscribe(value1 => this.newAssetClasses = value1, error => {
        this.assetClassesLoading = false;
      }, () => {
        this.assetClassesLoading = false;
      });
  }

  setAssetClassId(event: any, assetClass: SenatorCompanyAssetClass) {
    if (event.source.selected) {
      this.new_senator_asset_class_id.setValue(assetClass.ID);
    }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }

  get new_senator_asset_class_id() {
    return this.mergeForm.get('new_senator_asset_class_id');
  }

}

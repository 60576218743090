import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import {MatTableDataSource} from '@angular/material/table';
import {SenatorCommittee} from '../../../api/models/senator-committee';
import {SenatorCommitteeService} from '../../../api/services';
import {MatDialog} from '@angular/material/dialog';
import {EditCommitteeDialogComponent} from './edit-committee-dialog/edit-committee-dialog.component';
import GetCommitteeListParams = SenatorCommitteeService.GetCommitteeListParams;

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent implements OnInit, AfterViewInit {


  // mat table vars
  displayedColumns: string[] = ['id', 'name', 'actions'];
  committeesTableDataSource: MatTableDataSource<SenatorCommittee>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  pageSize: number;
  pageNumber: number;
  pageSizeOptions: number[];
  pageEvent: PageEvent;

  filters: GetCommitteeListParams;


  congressTypes = [
    {value: 'Representative', viewValue: 'Representative'},
    {value: 'Senator', viewValue: 'Senator'},
    {value: 'Joint', viewValue: 'Joint'}
  ];

  profileStatus = [
    {value: true, viewValue: 'Complete'},
    {value: false, viewValue: 'Incomplete'}
  ];

  loadingData: boolean;


  constructor(private committeeService: SenatorCommitteeService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.initVars();
    this.getCommittees();
  }

  ngAfterViewInit() {
    if (this.committeesTableDataSource) {
      // this.committeesTableDataSource.sort = this.sort;
      // this.committeesTableDataSource.paginator = this.paginator;
    }
  }

  initVars() {
    this.loadingData = false;

    this.pageSize = 10;
    this.pageNumber = 1;
    this.pageSizeOptions = [10, 20, 50];

    this.filters = {
      committeeType: 'Representative',
      isApproved: false
    };
  }

  private getCommittees() {
    this.loadingData = true;

    this.committeeService.getCommitteeList(this.filters).subscribe(
      (res) => {
        this.committeesTableDataSource = new MatTableDataSource<SenatorCommittee>(res);
        // this.committeesTableDataSource.sort = this.sort;
      },
      error => {
        this.loadingData = false;

      },
      () => {
        this.loadingData = false;
        this.committeesTableDataSource.paginator = this.paginator;
        this.committeesTableDataSource.sort = this.sort;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.committeesTableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.committeesTableDataSource.paginator) {
      this.committeesTableDataSource.paginator.firstPage();
    }
  }


  resetPageNumberAndSearchCommittees() {
    this.paginator.firstPage();
    this.getCommittees();
  }


  onPaginateChange(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    /*console.log(' this.pageIndex', this.pageIndex);
    console.log(' this.pageSize', this.pageSize);*/
    // this.getCommittees();
  }


  onClickEditCommittee(row: SenatorCommittee) {
    const dialogRef = this.dialog.open(EditCommitteeDialogComponent, {
      disableClose: true,
      width: '800px',
      data: {committee: row}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCommittees();
    });
  }
}



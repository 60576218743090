/* tslint:disable */

/**
 * This enum contains all quality checks Name
 */
type QualityCheckEnum =
  'Transaction_Date' |
  'Filing_Date' |
  'Transaction_Date_Greater_Filing_Date' |
  'Reporting_Individual_Error';
module QualityCheckEnum {
  export const TRANSACTION_DATE: QualityCheckEnum = 'Transaction_Date';
  export const FILING_DATE: QualityCheckEnum = 'Filing_Date';
  export const TRANSACTION_DATE_GREATER_FILING_DATE: QualityCheckEnum = 'Transaction_Date_Greater_Filing_Date';
  export const REPORTING_INDIVIDUAL_ERROR: QualityCheckEnum = 'Reporting_Individual_Error';
  export function values(): QualityCheckEnum[] {
    return [
      TRANSACTION_DATE,
      FILING_DATE,
      TRANSACTION_DATE_GREATER_FILING_DATE,
      REPORTING_INDIVIDUAL_ERROR
    ];
  }
}

export { QualityCheckEnum }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IssuerMappingModel } from '../models/issuer-mapping-model';
import { FilterIssuerToMap } from '../models/filter-issuer-to-map';
import { TickerMappingModel } from '../models/ticker-mapping-model';
import { TickerMapFilter } from '../models/ticker-map-filter';
import { NameToMapFilter } from '../models/name-to-map-filter';
@Injectable({
  providedIn: 'root',
})
class IssuerMappingsService extends __BaseService {
  static readonly getIssuerMappingsPath = '/issuerMappings';
  static readonly saveIssuerMappingPath = '/issuerMappings/{region}';
  static readonly getTickerMappingsPath = '/issuerMappings/ticker';
  static readonly saveTickerMappingPath = '/issuerMappings/ticker/{region}';
  static readonly deleteIssuerMappingPath = '/issuerMappings/delete/{region}';
  static readonly getIssuerMappingSuggestionsPath = '/issuerMappings/search/issuer-suggestions';
  static readonly doLockingActionToTheMappingPath = '/issuerMappings/{doAction}/lock';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param filterIssuerToMap undefined
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuerMappingsResponse(filterIssuerToMap: FilterIssuerToMap): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterIssuerToMap;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuerMappings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @param filterIssuerToMap undefined
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuerMappings(filterIssuerToMap: FilterIssuerToMap): __Observable<Array<IssuerMappingModel>> {
    return this.getIssuerMappingsResponse(filterIssuerToMap).pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @param params The `IssuerMappingsService.SaveIssuerMappingParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `issuerMappingModel`:
   *
   * @return Returns status.
   */
  saveIssuerMappingResponse(params: IssuerMappingsService.SaveIssuerMappingParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.issuerMappingModel;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuerMappings/${encodeURIComponent(String(params.region))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `IssuerMappingsService.SaveIssuerMappingParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `issuerMappingModel`:
   *
   * @return Returns status.
   */
  saveIssuerMapping(params: IssuerMappingsService.SaveIssuerMappingParams): __Observable<boolean> {
    return this.saveIssuerMappingResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param tickerMapFilter undefined
   * @return Returns Array of objects of TickerMap modal.
   */
  getTickerMappingsResponse(tickerMapFilter: TickerMapFilter): __Observable<__StrictHttpResponse<Array<TickerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tickerMapFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuerMappings/ticker`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TickerMappingModel>>;
      })
    );
  }
  /**
   * @param tickerMapFilter undefined
   * @return Returns Array of objects of TickerMap modal.
   */
  getTickerMappings(tickerMapFilter: TickerMapFilter): __Observable<Array<TickerMappingModel>> {
    return this.getTickerMappingsResponse(tickerMapFilter).pipe(
      __map(_r => _r.body as Array<TickerMappingModel>)
    );
  }

  /**
   * @param params The `IssuerMappingsService.SaveTickerMappingParams` containing the following parameters:
   *
   * - `tickerMappingModel`:
   *
   * - `region`:
   *
   * @return Returns status.
   */
  saveTickerMappingResponse(params: IssuerMappingsService.SaveTickerMappingParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tickerMappingModel;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/issuerMappings/ticker/${encodeURIComponent(String(params.region))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `IssuerMappingsService.SaveTickerMappingParams` containing the following parameters:
   *
   * - `tickerMappingModel`:
   *
   * - `region`:
   *
   * @return Returns status.
   */
  saveTickerMapping(params: IssuerMappingsService.SaveTickerMappingParams): __Observable<boolean> {
    return this.saveTickerMappingResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `IssuerMappingsService.DeleteIssuerMappingParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `issuerMappingModel`:
   *
   * @return Returns boolean for operation successful.
   */
  deleteIssuerMappingResponse(params: IssuerMappingsService.DeleteIssuerMappingParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.issuerMappingModel;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/issuerMappings/delete/${encodeURIComponent(String(params.region))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `IssuerMappingsService.DeleteIssuerMappingParams` containing the following parameters:
   *
   * - `region`:
   *
   * - `issuerMappingModel`:
   *
   * @return Returns boolean for operation successful.
   */
  deleteIssuerMapping(params: IssuerMappingsService.DeleteIssuerMappingParams): __Observable<boolean> {
    return this.deleteIssuerMappingResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param nameToMapFilter undefined
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuerMappingSuggestionsResponse(nameToMapFilter: NameToMapFilter): __Observable<__StrictHttpResponse<Array<IssuerMappingModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = nameToMapFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuerMappings/search/issuer-suggestions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<IssuerMappingModel>>;
      })
    );
  }
  /**
   * @param nameToMapFilter undefined
   * @return Returns Array of objects of IssuerMappingModel modal.
   */
  getIssuerMappingSuggestions(nameToMapFilter: NameToMapFilter): __Observable<Array<IssuerMappingModel>> {
    return this.getIssuerMappingSuggestionsResponse(nameToMapFilter).pipe(
      __map(_r => _r.body as Array<IssuerMappingModel>)
    );
  }

  /**
   * @param params The `IssuerMappingsService.DoLockingActionToTheMappingParams` containing the following parameters:
   *
   * - `issuerMappingsModel`:
   *
   * - `doAction`:
   *
   * @return Returns result of the request
   */
  doLockingActionToTheMappingResponse(params: IssuerMappingsService.DoLockingActionToTheMappingParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.issuerMappingsModel;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/issuerMappings/${encodeURIComponent(String(params.doAction))}/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `IssuerMappingsService.DoLockingActionToTheMappingParams` containing the following parameters:
   *
   * - `issuerMappingsModel`:
   *
   * - `doAction`:
   *
   * @return Returns result of the request
   */
  doLockingActionToTheMapping(params: IssuerMappingsService.DoLockingActionToTheMappingParams): __Observable<boolean> {
    return this.doLockingActionToTheMappingResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module IssuerMappingsService {

  /**
   * Parameters for saveIssuerMapping
   */
  export interface SaveIssuerMappingParams {
    region: string;
    issuerMappingModel: IssuerMappingModel;
  }

  /**
   * Parameters for saveTickerMapping
   */
  export interface SaveTickerMappingParams {
    tickerMappingModel: TickerMappingModel;
    region: string;
  }

  /**
   * Parameters for deleteIssuerMapping
   */
  export interface DeleteIssuerMappingParams {
    region: string;
    issuerMappingModel: IssuerMappingModel;
  }

  /**
   * Parameters for doLockingActionToTheMapping
   */
  export interface DoLockingActionToTheMappingParams {
    issuerMappingsModel: IssuerMappingModel;
    doAction: boolean;
  }
}

export { IssuerMappingsService }

import {Component, OnInit} from '@angular/core';
import {SenatorService} from '../../../api/services';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-issuer-trades-processing',
  templateUrl: './issuer-trades-processing.component.html',
  styleUrls: ['./issuer-trades-processing.component.css']
})
export class IssuerTradesProcessingComponent implements OnInit {

  tradesLoading = false;


  mode = 'QUALITY-GATE';

  constructor(private  _senatorService: SenatorService, private _activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
  }

}

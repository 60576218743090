/* tslint:disable */

/**
 * a model to tell type of seperate specific data storage space
 */
type SdsDatasourceEnum =
  'EUROPE' |
  'JAPAN' |
  'KOREA' |
  'CANADA_VOL' |
  'CANADA_POS_OTC' |
  'AUSTRALIA';
module SdsDatasourceEnum {
  export const EUROPE: SdsDatasourceEnum = 'EUROPE';
  export const JAPAN: SdsDatasourceEnum = 'JAPAN';
  export const KOREA: SdsDatasourceEnum = 'KOREA';
  export const CANADA_VOL: SdsDatasourceEnum = 'CANADA_VOL';
  export const CANADA_POS_OTC: SdsDatasourceEnum = 'CANADA_POS_OTC';
  export const AUSTRALIA: SdsDatasourceEnum = 'AUSTRALIA';
  export function values(): SdsDatasourceEnum[] {
    return [
      EUROPE,
      JAPAN,
      KOREA,
      CANADA_VOL,
      CANADA_POS_OTC,
      AUSTRALIA
    ];
  }
}

export { SdsDatasourceEnum }
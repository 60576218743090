/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShortPositions } from '../models/short-positions';
import { ShortSelling } from '../models/short-selling';
@Injectable({
  providedIn: 'root',
})
class PositionsService extends __BaseService {
  static readonly getPositionsByIssuerManagerAndDatePath = '/positions/{issuerName}/{toDate}/';
  static readonly getSdsDataRecordByIdPath = '/positions/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PositionsService.GetPositionsByIssuerManagerAndDateParams` containing the following parameters:
   *
   * - `toDate`: Date until which records are required.
   *
   * - `issuerName`: Issuer Name
   *
   * - `positionHolder`: Manager Name
   *
   * @return sdsDataForChart
   */
  getPositionsByIssuerManagerAndDateResponse(params: PositionsService.GetPositionsByIssuerManagerAndDateParams): __Observable<__StrictHttpResponse<Array<ShortPositions>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.positionHolder != null) __params = __params.set('positionHolder', params.positionHolder.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/positions/${encodeURIComponent(String(params.issuerName))}/${encodeURIComponent(String(params.toDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShortPositions>>;
      })
    );
  }
  /**
   * @param params The `PositionsService.GetPositionsByIssuerManagerAndDateParams` containing the following parameters:
   *
   * - `toDate`: Date until which records are required.
   *
   * - `issuerName`: Issuer Name
   *
   * - `positionHolder`: Manager Name
   *
   * @return sdsDataForChart
   */
  getPositionsByIssuerManagerAndDate(params: PositionsService.GetPositionsByIssuerManagerAndDateParams): __Observable<Array<ShortPositions>> {
    return this.getPositionsByIssuerManagerAndDateResponse(params).pipe(
      __map(_r => _r.body as Array<ShortPositions>)
    );
  }

  /**
   * @param id Sds data record id
   * @return sdsDataRecordDetails
   */
  getSdsDataRecordByIdResponse(id: number): __Observable<__StrictHttpResponse<ShortSelling>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/positions/${encodeURIComponent(String(id))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ShortSelling>;
      })
    );
  }
  /**
   * @param id Sds data record id
   * @return sdsDataRecordDetails
   */
  getSdsDataRecordById(id: number): __Observable<ShortSelling> {
    return this.getSdsDataRecordByIdResponse(id).pipe(
      __map(_r => _r.body as ShortSelling)
    );
  }
}

module PositionsService {

  /**
   * Parameters for getPositionsByIssuerManagerAndDate
   */
  export interface GetPositionsByIssuerManagerAndDateParams {

    /**
     * Date until which records are required.
     */
    toDate: string;

    /**
     * Issuer Name
     */
    issuerName: string;

    /**
     * Manager Name
     */
    positionHolder?: string;
  }
}

export { PositionsService }

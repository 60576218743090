/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorMarketTradedSecurityConfirmation } from '../models/senator-market-traded-security-confirmation';
import { SenatorQualityGateFilterModel } from '../models/senator-quality-gate-filter-model';
@Injectable({
  providedIn: 'root',
})
class SenatorMarketTradedSecurityConfirmationService extends __BaseService {
  static readonly getSenatorMarketTradedSecurityConfirmationPath = '/senatorMarketTradedConfirmation';
  static readonly updateSenatorMarketTradedSecurityConfirmationPath = '/senatorMarketTradedConfirmation';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param senatorQualityGateFilterModel undefined
   * @return get list of SenatorNoGvKeyConfirmation
   */
  getSenatorMarketTradedSecurityConfirmationResponse(senatorQualityGateFilterModel: SenatorQualityGateFilterModel): __Observable<__StrictHttpResponse<Array<SenatorMarketTradedSecurityConfirmation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorQualityGateFilterModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorMarketTradedConfirmation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorMarketTradedSecurityConfirmation>>;
      })
    );
  }
  /**
   * @param senatorQualityGateFilterModel undefined
   * @return get list of SenatorNoGvKeyConfirmation
   */
  getSenatorMarketTradedSecurityConfirmation(senatorQualityGateFilterModel: SenatorQualityGateFilterModel): __Observable<Array<SenatorMarketTradedSecurityConfirmation>> {
    return this.getSenatorMarketTradedSecurityConfirmationResponse(senatorQualityGateFilterModel).pipe(
      __map(_r => _r.body as Array<SenatorMarketTradedSecurityConfirmation>)
    );
  }

  /**
   * @param senatorMarketTradedSecurityConfirmation undefined
   * @return saved confirmed value.
   */
  updateSenatorMarketTradedSecurityConfirmationResponse(senatorMarketTradedSecurityConfirmation: SenatorMarketTradedSecurityConfirmation): __Observable<__StrictHttpResponse<SenatorMarketTradedSecurityConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorMarketTradedSecurityConfirmation;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorMarketTradedConfirmation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorMarketTradedSecurityConfirmation>;
      })
    );
  }
  /**
   * @param senatorMarketTradedSecurityConfirmation undefined
   * @return saved confirmed value.
   */
  updateSenatorMarketTradedSecurityConfirmation(senatorMarketTradedSecurityConfirmation: SenatorMarketTradedSecurityConfirmation): __Observable<SenatorMarketTradedSecurityConfirmation> {
    return this.updateSenatorMarketTradedSecurityConfirmationResponse(senatorMarketTradedSecurityConfirmation).pipe(
      __map(_r => _r.body as SenatorMarketTradedSecurityConfirmation)
    );
  }
}

module SenatorMarketTradedSecurityConfirmationService {
}

export { SenatorMarketTradedSecurityConfirmationService }

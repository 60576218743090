import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  CongressType,
  Politician,
  SenatorAmountRanges,
  SenatorCompany,
  SenatorFiling,
  SenatorTradeFilter,
  SenatorTrades,
  SenatorTransactionTypes
} from '../../../api/models';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SenatorService} from '../../../api/services/senator.service';
import {Observable} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SenatorCompanyAssetClassService} from '../../../api/services/senator-company-asset-class.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import 'rxjs-compat/add/observable/forkJoin';
import GetSenatorTradesByFilingIdParams = SenatorService.GetSenatorTradesByFilingIdParams;
import SaveSenatorFilingParams = SenatorService.SaveSenatorFilingParams;
import UnlockFilingByIdParams = SenatorService.UnlockFilingByIdParams;

@Component({
  selector: 'app-file-processing',
  templateUrl: './file-processing.component.html',
  styleUrls: ['./file-processing.component.scss'],
})
export class FileProcessingComponent implements OnInit, OnDestroy {


  file: SenatorFiling;
  fileForm: FormGroup;
  congressType: string;
  allPoliticians = [{}] as Politician[];
  politiciansNames: string [];
  isFileUpdating = false;
  tradesLoading = false;
  assetNamesLoading: boolean;
  assetClassesLoading: boolean;
  twoIQAssetsList: SenatorCompany[] = [];
  isIssuerNameSearched: boolean;
  tradeTransactionMinDate: Date;
  tradeTransactionMaxDate: Date;
  issuerSelectionCount: number;
  maxTransactionDate: Date;
  maxFilingDate: Date;
  isFormValid: boolean;

  reportType: string;
  // service subscriptions
  expandTradePanel: boolean;
  trades: SenatorTrades[] = [];
  selection = new SelectionModel<SenatorTrades>(true, []);
  pageEvent: PageEvent;
  transactionTypes: SenatorTransactionTypes [];
  amounts: SenatorAmountRanges[];

  // router params
  paramCongressType: CongressType;
  paramFilingId: number;

  selectedPolitician = {} as Politician;
  private readonly DATE_FORMAT = 'MM/dd/yyyy';
  mode = 'FILE';

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _senatorService: SenatorService,
    private _senatorAssetClassService: SenatorCompanyAssetClassService,
    public snackBar: MatSnackBar,
  ) {
    this._activatedRoute.paramMap
      .subscribe(params => {
          this.paramCongressType = params['params']['congressType'];
          this.paramFilingId = params['params']['filingId'];
          this.getFileDetail();
        }
      );
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unlockFile();
  }

  initVariables() {
    this.assetNamesLoading = false;
    this.assetClassesLoading = false;
    this.isIssuerNameSearched = false;
    this.issuerSelectionCount = 1;
    this.tradeTransactionMinDate = new Date(2010, 0, 1);
    this.tradeTransactionMaxDate = new Date();
    this.maxTransactionDate = new Date(this.file.filingDate);
    this.maxFilingDate = new Date();
    this.isFormValid = false;
    this.expandTradePanel = false;
  }

  initFileForm() {
    const reportType = (this.file.reportType === null) ? ''
      : (this.file.reportType.includes('Amendment') === true) ? 'amendment' : 'new';

    this.fileForm = this.fb.group({
      firstName: [(this.file.firstName === null ? '' : this.file.firstName)],
      lastName: [(this.file.lastName === null ? '' : this.file.lastName)],
      officeName: [(this.file.officeName === null ? '' : this.file.officeName), Validators.required],
      filingDate: [new Date(this.file.filingDate), [Validators.required, this.filingDateValidator.bind(this)]],
      remarks: [this.file.remarks || ''],
      reportType: [reportType, [Validators.required]],
      refBiographyId: [this.file.refBiographyId, Validators.required],
      isGrabbedLate: [this.file.isGrabbedLate],
    });

    const filingDateStandard = new Date(this._filingDate.value);
    let filingDateFormat = '';
    if (this._filingDate.value !== '') {
      filingDateFormat = `${filingDateStandard.getMonth() + 1}/${filingDateStandard.getDate()}/${filingDateStandard.getFullYear()}`;
    }
    switch (this._reportType.value) {
      case '' :
        this.reportType = '';
        break;
      case 'amendment':
        if (this.file.reportType !== null) {
          if (this.file.reportType.includes('Amendment')) {
            this.reportType = this.file.reportType;
          } else {
            this.reportType = 'Periodic Transaction Report (Amendment)';
          }
        } else {
          this.reportType = 'Periodic Transaction Report (Amendment)';
        }
        break;
      case'new':
        this.reportType = `Periodic Transaction Report for ${filingDateFormat}`;
        break;
    }
  }

  filingDateValidator(control: FormControl) {
    const currentValue = new Date(control.value);

    if (currentValue > this.maxFilingDate) {
      return {
        dateError: {
          value: 'invalid'
        }
      };
    }
    return null;
  }

  onUpdateFileDetails() {
    this.disableFileForm();

    const filingDateStandard = new Date(this._filingDate.value);
    let filingDateFormat = '';
    if (this._filingDate.value !== '') {
      filingDateFormat = `${filingDateStandard.getMonth() + 1}/${filingDateStandard.getDate()}/${filingDateStandard.getFullYear()}`;
    }
    // le.log('filingDateFormat file update:', filingDateFormat);
    switch (this._reportType.value) {
      case '' :
        this.reportType = '';
        break;
      case 'amendment':
        if (this.file.reportType !== null) {
          if (this.file.reportType.includes('Amendment')) {
            this.reportType = this.file.reportType;
          } else {
            this.reportType = 'Periodic Transaction Report (Amendment)';
          }
        } else {
          this.reportType = 'Periodic Transaction Report (Amendment)';
        }
        break;
      case 'new':
        this.reportType = `Periodic Transaction Report for ${filingDateFormat}`;
        break;
    }

    const isApprove = this.isApprovedFile();

    const body: SenatorFiling = {
      filingId: this.file.filingId,
      firstName: this._firstName.value || '',
      lastName: this._lastName.value || '',
      officeName: this._officeName.value || '',
      refBiographyId: this._refBiographyId.value,
      filerType: this.file.filerType || '',
      filingDate: filingDateFormat || '',
      sourceUrl: this.file.sourceUrl,
      isAmended: this.file.isAmended,
      remarks: this._analystRemarks.value,
      reportType: this.reportType,
      isGrabbedLate: this._isGrabbedLate.value,
      amendmentFilingId: this.file.amendmentFilingId,
      congressType: this.file.congressType,
      isApproved: isApprove
    };
    const params = {
      senatorFiling: body,
      congressType: this.paramCongressType
    };

    this._senatorService.saveSenatorFiling(params).subscribe((res: SenatorFiling) => {
      this.file = res;
      this.maxTransactionDate = new Date(this.file.filingDate);

    }, (err) => {
      this.enableFileForm();
      this.openSnackBar(err.error.message, 'Close');
    }, () => {
      this.enableFileForm();

      this.snackBar.open('File details has been updated successfully!', 'Close', {
        duration: 2000,
        panelClass: ['green-snackbar']
      });

      this.initFileForm();
    });
  }

  getFileDetail() {
    const filters: SenatorTradeFilter = {
      congressType: this.paramCongressType,
      filingId: this.paramFilingId,
    };
    this._senatorService.getSenatorFilings(filters).subscribe((res: SenatorFiling[]) => {
      this.file = res[0];
    }, (err) => {
      console.log('err', err);
    }, () => {
      this.initVariables();
      this.initFileForm();
      this.getPoliticians();
      // this.getTradesByFilingId();
    });
  }

  getTradesByFilingId() {
    // this.tradesLoading = true;
    const params: GetSenatorTradesByFilingIdParams = {
      filingId: this.file.filingId,
      congressType: this.paramCongressType
    };

    let trades = [] as SenatorTrades[];
    this._senatorService.getSenatorTradesByFilingId(params).subscribe((res: SenatorTrades[]) => {
      trades = res;
    }, (err) => {
      console.log(err);
      this.openSnackBar(err.error.message, 'Close');
      // this.tradesLoading = false;
    }, () => {
      // this.tradesLoading = false;
      this.trades = trades;
      this.updateFileStatus();
    });
  }

  getListOfPoliticians(): Observable<Array<Array<Politician>>> {
    const result = [CongressType.SENATOR, CongressType.REPRESENTATIVE]
      .map(param => {
        return this._senatorService.getSenatorBiographyNames(param);
      });
    return Observable.forkJoin(result);
  }

  onCloseFileClicked() {
    // navigate the home page.
    if (this.file.isProcessed) {
      this.router.navigate(['/files']);
      return;
    }
    this.getTradesByFilingId();
  }

  updateFileStatus() {

    const filingDateStandard = new Date(this._filingDate.value);
    const isApprove = this.isApprovedFile();

    const body = {
      filingId: this.file.filingId,
      firstName: this._firstName.value || '',
      lastName: this._lastName.value || '',
      officeName: this._officeName.value || '',
      refBiographyId: this._refBiographyId.value,
      filerType: this.file.filerType || '',
      filingDate: this.datePipe.transform(filingDateStandard, this.DATE_FORMAT),
      sourceUrl: this.file.sourceUrl,
      isAmended: this.file.isAmended,
      reportType: this.reportType,
      amendmentFilingId: this.file.amendmentFilingId,
      isApproved: isApprove,
      isGrabbedLate: this._isGrabbedLate.value,
      remarks: this.file.remarks
    } as SenatorFiling;

    const params = {
      senatorFiling: body,
      congressType: this.paramCongressType
    } as SaveSenatorFilingParams;
    this.tradesLoading = true;
    this._senatorService.saveSenatorFiling(params).subscribe((res: SenatorFiling) => {
    }, (err: Error) => {
      this.tradesLoading = false;
    }, () => {
      this.tradesLoading = false;
      this.router.navigate(['/files']);
    });
  }

  isApprovedFile() {
    return this.trades.length > 0
      && this.trades.filter(t => !(t.isApproved) && !(t.isAmended)).length < 1
      && this._refBiographyId.value !== null;
  }

  enableFileForm() {
    this.isFileUpdating = false;
    this.fileForm.enable();
  }

  disableFileForm() {
    this.isFileUpdating = true;
    this.fileForm.disable();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  unlockFile() {
    const params: UnlockFilingByIdParams = {
      congressType: this.file.congressType,
      filingId: this.file.filingId
    };

    this._senatorService.unlockFilingById(params).subscribe((res) => {
    }, (err) => {
      console.log('err', err);
    }, () => {
    });

  }

  expandTradesPanel(expandFlag: boolean) {
    this.expandTradePanel = expandFlag;
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.politiciansNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  validFullName(): boolean {
    return this._refBiographyId.value === null;
  }

  changePoliticianName(event: Politician) {
    if (event == null) {
      this._refBiographyId.setValue(null);
      return;
    }
    this._refBiographyId.setValue(event.id);
  }

  private getPoliticians() {
    let res = [];
    this.getListOfPoliticians().subscribe(value => {
      res = value;
    }, error => {

    }, () => {
      res = res.reduce((accumulator, value) => accumulator.concat(value), []);
      this.allPoliticians = this.allPoliticians.concat(res);
      this.selectedPolitician = this.allPoliticians.find(p => p.id == this.file.refBiographyId);
    });
  }

  get _firstName() {
    return this.fileForm.get('firstName');
  }

  get _lastName() {
    return this.fileForm.get('lastName');
  }

  get _officeName(): AbstractControl {
    return this.fileForm.get('officeName');
  }

  get _isGrabbedLate(): AbstractControl {
    return this.fileForm.get('isGrabbedLate');
  }


  get _refBiographyId(): AbstractControl {
    return this.fileForm.get('refBiographyId');
  }

  get _filingDate(): AbstractControl {
    return this.fileForm.get('filingDate');
  }

  get _reportType() {
    return this.fileForm.get('reportType');
  }

  get _analystRemarks() {
    return this.fileForm.get('remarks');
  }

}

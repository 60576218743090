/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SuspiciousTrade } from '../models/suspicious-trade';
import { SuspiciousViewFilter } from '../models/suspicious-view-filter';
@Injectable({
  providedIn: 'root',
})
class SuspiciousViewService extends __BaseService {
  static readonly getSuspiciousViewTradesPath = '/suspicious/view';
  static readonly addSuspiciousViewNotePath = '/suspicious/view/add/note';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param SuspiciousViewFilter Contains filter to select announcements that need a review.
   * @return List of Announcement with their buyback details.
   */
  getSuspiciousViewTradesResponse(SuspiciousViewFilter: SuspiciousViewFilter): __Observable<__StrictHttpResponse<Array<SuspiciousTrade>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = SuspiciousViewFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/suspicious/view`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SuspiciousTrade>>;
      })
    );
  }
  /**
   * @param SuspiciousViewFilter Contains filter to select announcements that need a review.
   * @return List of Announcement with their buyback details.
   */
  getSuspiciousViewTrades(SuspiciousViewFilter: SuspiciousViewFilter): __Observable<Array<SuspiciousTrade>> {
    return this.getSuspiciousViewTradesResponse(SuspiciousViewFilter).pipe(
      __map(_r => _r.body as Array<SuspiciousTrade>)
    );
  }

  /**
   * @param suspiciousTrade Contains updated suspicious Trade with its note.
   * @return boolean value to show if the trade was updated or not.
   */
  addSuspiciousViewNoteResponse(suspiciousTrade: SuspiciousTrade): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = suspiciousTrade;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/suspicious/view/add/note`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param suspiciousTrade Contains updated suspicious Trade with its note.
   * @return boolean value to show if the trade was updated or not.
   */
  addSuspiciousViewNote(suspiciousTrade: SuspiciousTrade): __Observable<boolean> {
    return this.addSuspiciousViewNoteResponse(suspiciousTrade).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module SuspiciousViewService {
}

export { SuspiciousViewService }

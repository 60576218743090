/* tslint:disable */

/**
 * A enum contains financial instruments
 */
type FinancialInstruments =
  'Admin Class' |
  'Administrative Class' |
  'Admiral Class' |
  'ADR' |
  'ADR A' |
  'ADR B' |
  'PFD ADR' |
  'ADS' |
  'ADS B' |
  'Advisor Class' |
  'C-2 Shares' |
  'Call' |
  'Class 2' |
  'Class A' |
  'Class A1' |
  'Class B' |
  'Class B1' |
  'Class C' |
  'Class D' |
  'Class E' |
  'Class F' |
  'Class F1' |
  'Class F2' |
  'Class HH' |
  'Class I' |
  'Class I2' |
  'Class J' |
  'Class K' |
  'Class L' |
  'Class N' |
  'Class P' |
  'Class S' |
  'Class V' |
  'Class Y' |
  'Class Z' |
  'Consultant Class' |
  'CRA Class' |
  'Daily Money Class' |
  'Initial Class' |
  'Institutional Class' |
  'Institutional Class I' |
  'Investor' |
  'Investor A' |
  'Paired Shares' |
  'PFD' |
  'PFD A' |
  'PFD B' |
  'PFD C' |
  'PFD D' |
  'Put' |
  'Retail Class' |
  'Retirement Class' |
  'Ser B Note' |
  'Series A Caju' |
  'Series Alice' |
  'Service Class' |
  'Service Class I' |
  'Unpson. ADR' |
  'Unpson. ADS';
module FinancialInstruments {
  export const ADMIN_CLASS: FinancialInstruments = 'Admin Class';
  export const ADMINISTRATIVE_CLASS: FinancialInstruments = 'Administrative Class';
  export const ADMIRAL_CLASS: FinancialInstruments = 'Admiral Class';
  export const ADR: FinancialInstruments = 'ADR';
  export const ADR_A: FinancialInstruments = 'ADR A';
  export const ADR_B: FinancialInstruments = 'ADR B';
  export const PFD_ADR: FinancialInstruments = 'PFD ADR';
  export const ADS: FinancialInstruments = 'ADS';
  export const ADS_B: FinancialInstruments = 'ADS B';
  export const ADVISOR_CLASS: FinancialInstruments = 'Advisor Class';
  export const C_2_SHARES: FinancialInstruments = 'C-2 Shares';
  export const CALL: FinancialInstruments = 'Call';
  export const CLASS_2: FinancialInstruments = 'Class 2';
  export const CLASS_A: FinancialInstruments = 'Class A';
  export const CLASS_A1: FinancialInstruments = 'Class A1';
  export const CLASS_B: FinancialInstruments = 'Class B';
  export const CLASS_B1: FinancialInstruments = 'Class B1';
  export const CLASS_C: FinancialInstruments = 'Class C';
  export const CLASS_D: FinancialInstruments = 'Class D';
  export const CLASS_E: FinancialInstruments = 'Class E';
  export const CLASS_F: FinancialInstruments = 'Class F';
  export const CLASS_F1: FinancialInstruments = 'Class F1';
  export const CLASS_F2: FinancialInstruments = 'Class F2';
  export const CLASS_HH: FinancialInstruments = 'Class HH';
  export const CLASS_I: FinancialInstruments = 'Class I';
  export const CLASS_I2: FinancialInstruments = 'Class I2';
  export const CLASS_J: FinancialInstruments = 'Class J';
  export const CLASS_K: FinancialInstruments = 'Class K';
  export const CLASS_L: FinancialInstruments = 'Class L';
  export const CLASS_N: FinancialInstruments = 'Class N';
  export const CLASS_P: FinancialInstruments = 'Class P';
  export const CLASS_S: FinancialInstruments = 'Class S';
  export const CLASS_V: FinancialInstruments = 'Class V';
  export const CLASS_Y: FinancialInstruments = 'Class Y';
  export const CLASS_Z: FinancialInstruments = 'Class Z';
  export const CONSULTANT_CLASS: FinancialInstruments = 'Consultant Class';
  export const CRA_CLASS: FinancialInstruments = 'CRA Class';
  export const DAILY_MONEY_CLASS: FinancialInstruments = 'Daily Money Class';
  export const INITIAL_CLASS: FinancialInstruments = 'Initial Class';
  export const INSTITUTIONAL_CLASS: FinancialInstruments = 'Institutional Class';
  export const INSTITUTIONAL_CLASS_I: FinancialInstruments = 'Institutional Class I';
  export const INVESTOR: FinancialInstruments = 'Investor';
  export const INVESTOR_A: FinancialInstruments = 'Investor A';
  export const PAIRED_SHARES: FinancialInstruments = 'Paired Shares';
  export const PFD: FinancialInstruments = 'PFD';
  export const PFD_A: FinancialInstruments = 'PFD A';
  export const PFD_B: FinancialInstruments = 'PFD B';
  export const PFD_C: FinancialInstruments = 'PFD C';
  export const PFD_D: FinancialInstruments = 'PFD D';
  export const PUT: FinancialInstruments = 'Put';
  export const RETAIL_CLASS: FinancialInstruments = 'Retail Class';
  export const RETIREMENT_CLASS: FinancialInstruments = 'Retirement Class';
  export const SER_B_NOTE: FinancialInstruments = 'Ser B Note';
  export const SERIES_A_CAJU: FinancialInstruments = 'Series A Caju';
  export const SERIES_ALICE: FinancialInstruments = 'Series Alice';
  export const SERVICE_CLASS: FinancialInstruments = 'Service Class';
  export const SERVICE_CLASS_I: FinancialInstruments = 'Service Class I';
  export const UNPSON_ADR: FinancialInstruments = 'Unpson. ADR';
  export const UNPSON_ADS: FinancialInstruments = 'Unpson. ADS';
  export function values(): FinancialInstruments[] {
    return [
      ADMIN_CLASS,
      ADMINISTRATIVE_CLASS,
      ADMIRAL_CLASS,
      ADR,
      ADR_A,
      ADR_B,
      PFD_ADR,
      ADS,
      ADS_B,
      ADVISOR_CLASS,
      C_2_SHARES,
      CALL,
      CLASS_2,
      CLASS_A,
      CLASS_A1,
      CLASS_B,
      CLASS_B1,
      CLASS_C,
      CLASS_D,
      CLASS_E,
      CLASS_F,
      CLASS_F1,
      CLASS_F2,
      CLASS_HH,
      CLASS_I,
      CLASS_I2,
      CLASS_J,
      CLASS_K,
      CLASS_L,
      CLASS_N,
      CLASS_P,
      CLASS_S,
      CLASS_V,
      CLASS_Y,
      CLASS_Z,
      CONSULTANT_CLASS,
      CRA_CLASS,
      DAILY_MONEY_CLASS,
      INITIAL_CLASS,
      INSTITUTIONAL_CLASS,
      INSTITUTIONAL_CLASS_I,
      INVESTOR,
      INVESTOR_A,
      PAIRED_SHARES,
      PFD,
      PFD_A,
      PFD_B,
      PFD_C,
      PFD_D,
      PUT,
      RETAIL_CLASS,
      RETIREMENT_CLASS,
      SER_B_NOTE,
      SERIES_A_CAJU,
      SERIES_ALICE,
      SERVICE_CLASS,
      SERVICE_CLASS_I,
      UNPSON_ADR,
      UNPSON_ADS
    ];
  }
}

export { FinancialInstruments }
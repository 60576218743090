/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorCommittee } from '../models/senator-committee';
@Injectable({
  providedIn: 'root',
})
class SenatorCommitteeService extends __BaseService {
  static readonly getCommitteeListPath = '/senatorCommittee';
  static readonly saveCommitteePath = '/senatorCommittee';
  static readonly getCommitteeDetailPath = '/senatorCommittee/{committeeId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SenatorCommitteeService.GetCommitteeListParams` containing the following parameters:
   *
   * - `committeeType`: it will be one of the value from enum type SenatorCommitteeType
   *
   * - `isApproved`:
   *
   * @return a list of string to fill the dropdown.
   */
  getCommitteeListResponse(params: SenatorCommitteeService.GetCommitteeListParams): __Observable<__StrictHttpResponse<Array<SenatorCommittee>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.committeeType != null) __params = __params.set('committeeType', params.committeeType.toString());
    if (params.isApproved != null) __params = __params.set('isApproved', params.isApproved.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorCommittee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorCommittee>>;
      })
    );
  }
  /**
   * @param params The `SenatorCommitteeService.GetCommitteeListParams` containing the following parameters:
   *
   * - `committeeType`: it will be one of the value from enum type SenatorCommitteeType
   *
   * - `isApproved`:
   *
   * @return a list of string to fill the dropdown.
   */
  getCommitteeList(params: SenatorCommitteeService.GetCommitteeListParams): __Observable<Array<SenatorCommittee>> {
    return this.getCommitteeListResponse(params).pipe(
      __map(_r => _r.body as Array<SenatorCommittee>)
    );
  }

  /**
   * @param senatorCommittee undefined
   * @return the same model which is updated.
   */
  saveCommitteeResponse(senatorCommittee: SenatorCommittee): __Observable<__StrictHttpResponse<SenatorCommittee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorCommittee;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorCommittee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCommittee>;
      })
    );
  }
  /**
   * @param senatorCommittee undefined
   * @return the same model which is updated.
   */
  saveCommittee(senatorCommittee: SenatorCommittee): __Observable<SenatorCommittee> {
    return this.saveCommitteeResponse(senatorCommittee).pipe(
      __map(_r => _r.body as SenatorCommittee)
    );
  }

  /**
   * @param committeeId undefined
   * @return Senator Committee with detail.
   */
  getCommitteeDetailResponse(committeeId: number): __Observable<__StrictHttpResponse<SenatorCommittee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorCommittee/${encodeURIComponent(String(committeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCommittee>;
      })
    );
  }
  /**
   * @param committeeId undefined
   * @return Senator Committee with detail.
   */
  getCommitteeDetail(committeeId: number): __Observable<SenatorCommittee> {
    return this.getCommitteeDetailResponse(committeeId).pipe(
      __map(_r => _r.body as SenatorCommittee)
    );
  }
}

module SenatorCommitteeService {

  /**
   * Parameters for getCommitteeList
   */
  export interface GetCommitteeListParams {

    /**
     * it will be one of the value from enum type SenatorCommitteeType
     */
    committeeType: string;
    isApproved?: boolean;
  }
}

export { SenatorCommitteeService }

/* tslint:disable */
type ActOnTickerType =
  'BUYING' |
  'SELLING' |
  'EXERCISE';
module ActOnTickerType {
  export const BUYING: ActOnTickerType = 'BUYING';
  export const SELLING: ActOnTickerType = 'SELLING';
  export const EXERCISE: ActOnTickerType = 'EXERCISE';
  export function values(): ActOnTickerType[] {
    return [
      BUYING,
      SELLING,
      EXERCISE
    ];
  }
}

export { ActOnTickerType }
/* tslint:disable */
type SenatorTransactionTypes =
  'Exchange' |
  'Received' |
  'Purchase' |
  'Sale' |
  'Sale (Partial)' |
  'Sale (Full)';
module SenatorTransactionTypes {
  export const EXCHANGE: SenatorTransactionTypes = 'Exchange';
  export const RECEIVED: SenatorTransactionTypes = 'Received';
  export const PURCHASE: SenatorTransactionTypes = 'Purchase';
  export const SALE: SenatorTransactionTypes = 'Sale';
  export const SALE_PARTIAL_: SenatorTransactionTypes = 'Sale (Partial)';
  export const SALE_FULL_: SenatorTransactionTypes = 'Sale (Full)';
  export function values(): SenatorTransactionTypes[] {
    return [
      EXCHANGE,
      RECEIVED,
      PURCHASE,
      SALE,
      SALE_PARTIAL_,
      SALE_FULL_
    ];
  }
}

export { SenatorTransactionTypes }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorCompanyMerger } from '../models/senator-company-merger';
import { SenatorCompanyAssetClass } from '../models/senator-company-asset-class';
import { SenatorCompany } from '../models/senator-company';
@Injectable({
  providedIn: 'root',
})
class SenatorCompanyService extends __BaseService {
  static readonly mergeSenatorCompanyPath = '/senatorCompany/merger';
  static readonly deMergerSenatorCompanyPath = '/senatorCompany/demerger';
  static readonly getSenatorCompanyByIdPath = '/senatorCompany';
  static readonly addUpdateSenatorCompanyPath = '/senatorCompany';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param senatorCompanyMerger undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  mergeSenatorCompanyResponse(senatorCompanyMerger: SenatorCompanyMerger): __Observable<__StrictHttpResponse<SenatorCompanyMerger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorCompanyMerger;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorCompany/merger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompanyMerger>;
      })
    );
  }
  /**
   * @param senatorCompanyMerger undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  mergeSenatorCompany(senatorCompanyMerger: SenatorCompanyMerger): __Observable<SenatorCompanyMerger> {
    return this.mergeSenatorCompanyResponse(senatorCompanyMerger).pipe(
      __map(_r => _r.body as SenatorCompanyMerger)
    );
  }

  /**
   * @param senatorCompanyDeMerger undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  deMergerSenatorCompanyResponse(senatorCompanyDeMerger: SenatorCompanyAssetClass): __Observable<__StrictHttpResponse<SenatorCompanyAssetClass>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorCompanyDeMerger;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorCompany/demerger`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompanyAssetClass>;
      })
    );
  }
  /**
   * @param senatorCompanyDeMerger undefined
   * @return Returns boolean rather deleted successfully or not.
   */
  deMergerSenatorCompany(senatorCompanyDeMerger: SenatorCompanyAssetClass): __Observable<SenatorCompanyAssetClass> {
    return this.deMergerSenatorCompanyResponse(senatorCompanyDeMerger).pipe(
      __map(_r => _r.body as SenatorCompanyAssetClass)
    );
  }

  /**
   * @param ID undefined
   * @return it will return the senator company
   */
  getSenatorCompanyByIdResponse(ID: number): __Observable<__StrictHttpResponse<SenatorCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ID != null) __params = __params.set('ID', ID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/senatorCompany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompany>;
      })
    );
  }
  /**
   * @param ID undefined
   * @return it will return the senator company
   */
  getSenatorCompanyById(ID: number): __Observable<SenatorCompany> {
    return this.getSenatorCompanyByIdResponse(ID).pipe(
      __map(_r => _r.body as SenatorCompany)
    );
  }

  /**
   * @param company undefined
   * @return it will return the saved or updated company, or null object if not successful
   */
  addUpdateSenatorCompanyResponse(company: SenatorCompany): __Observable<__StrictHttpResponse<SenatorCompany>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = company;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorCompany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorCompany>;
      })
    );
  }
  /**
   * @param company undefined
   * @return it will return the saved or updated company, or null object if not successful
   */
  addUpdateSenatorCompany(company: SenatorCompany): __Observable<SenatorCompany> {
    return this.addUpdateSenatorCompanyResponse(company).pipe(
      __map(_r => _r.body as SenatorCompany)
    );
  }
}

module SenatorCompanyService {
}

export { SenatorCompanyService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListedOptionsQualityGate } from '../models/listed-options-quality-gate';
import { ListedOptionFilter } from '../models/listed-option-filter';
@Injectable({
  providedIn: 'root',
})
class ListedOptionsQualityGateService extends __BaseService {
  static readonly getListedOptionsQualityGatePath = '/listedOptions/qualityGate';
  static readonly addProcessedListingOptionPath = '/listedOptions/qualityGate/processed';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param listedOptionFilter undefined
   * @return A list of Listed Options Quality Gate.
   */
  getListedOptionsQualityGateResponse(listedOptionFilter: ListedOptionFilter): __Observable<__StrictHttpResponse<Array<ListedOptionsQualityGate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = listedOptionFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/listedOptions/qualityGate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ListedOptionsQualityGate>>;
      })
    );
  }
  /**
   * @param listedOptionFilter undefined
   * @return A list of Listed Options Quality Gate.
   */
  getListedOptionsQualityGate(listedOptionFilter: ListedOptionFilter): __Observable<Array<ListedOptionsQualityGate>> {
    return this.getListedOptionsQualityGateResponse(listedOptionFilter).pipe(
      __map(_r => _r.body as Array<ListedOptionsQualityGate>)
    );
  }

  /**
   * @param listedOptionQualityGate undefined
   * @return A Model will be returned after adding entry
   */
  addProcessedListingOptionResponse(listedOptionQualityGate: ListedOptionsQualityGate): __Observable<__StrictHttpResponse<ListedOptionsQualityGate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = listedOptionQualityGate;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/listedOptions/qualityGate/processed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListedOptionsQualityGate>;
      })
    );
  }
  /**
   * @param listedOptionQualityGate undefined
   * @return A Model will be returned after adding entry
   */
  addProcessedListingOption(listedOptionQualityGate: ListedOptionsQualityGate): __Observable<ListedOptionsQualityGate> {
    return this.addProcessedListingOptionResponse(listedOptionQualityGate).pipe(
      __map(_r => _r.body as ListedOptionsQualityGate)
    );
  }
}

module ListedOptionsQualityGateService {
}

export { ListedOptionsQualityGateService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SmallCapCompanies } from '../models/small-cap-companies';
import { SmallCapCompaniesFilter } from '../models/small-cap-companies-filter';
@Injectable({
  providedIn: 'root',
})
class SmallCapCompaniesService extends __BaseService {
  static readonly getSmallCapCompanyListPath = '/SmallCapCompanies';
  static readonly excludeCompanyfromSmallCapCompanyListPath = '/SmallCapCompanies/exclude';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param SmallCapCompanyFilter filters for Small Cap Companies
   * @return List of companies in a given country
   */
  getSmallCapCompanyListResponse(SmallCapCompanyFilter: SmallCapCompaniesFilter): __Observable<__StrictHttpResponse<Array<SmallCapCompanies>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = SmallCapCompanyFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/SmallCapCompanies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SmallCapCompanies>>;
      })
    );
  }
  /**
   * @param SmallCapCompanyFilter filters for Small Cap Companies
   * @return List of companies in a given country
   */
  getSmallCapCompanyList(SmallCapCompanyFilter: SmallCapCompaniesFilter): __Observable<Array<SmallCapCompanies>> {
    return this.getSmallCapCompanyListResponse(SmallCapCompanyFilter).pipe(
      __map(_r => _r.body as Array<SmallCapCompanies>)
    );
  }

  /**
   * @param SmallCapCompany ID of the country to map the company
   * @return Return boolean if updated.
   */
  excludeCompanyfromSmallCapCompanyListResponse(SmallCapCompany: SmallCapCompanies): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = SmallCapCompany;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/SmallCapCompanies/exclude`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param SmallCapCompany ID of the country to map the company
   * @return Return boolean if updated.
   */
  excludeCompanyfromSmallCapCompanyList(SmallCapCompany: SmallCapCompanies): __Observable<boolean> {
    return this.excludeCompanyfromSmallCapCompanyListResponse(SmallCapCompany).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module SmallCapCompaniesService {
}

export { SmallCapCompaniesService }

/* tslint:disable */
type SenatorCommitteeType =
  'Representative' |
  'Senator' |
  'Joint';
module SenatorCommitteeType {
  export const REPRESENTATIVE: SenatorCommitteeType = 'Representative';
  export const SENATOR: SenatorCommitteeType = 'Senator';
  export const JOINT: SenatorCommitteeType = 'Joint';
  export function values(): SenatorCommitteeType[] {
    return [
      REPRESENTATIVE,
      SENATOR,
      JOINT
    ];
  }
}

export { SenatorCommitteeType }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SenatorNoGvKeyConfirmation } from '../models/senator-no-gv-key-confirmation';
import { SenatorQualityGateFilterModel } from '../models/senator-quality-gate-filter-model';
@Injectable({
  providedIn: 'root',
})
class SenatorNoGvKeyConfirmationService extends __BaseService {
  static readonly getSenatorNoGvKeyConfirmationPath = '/senatorNoGvKeyConfirmation';
  static readonly updateSenatorNoGvKeyConfirmationPath = '/senatorNoGvKeyConfirmation';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param senatorQualityGateFilterModel undefined
   * @return get list of SenatorNoGvKeyConfirmation
   */
  getSenatorNoGvKeyConfirmationResponse(senatorQualityGateFilterModel: SenatorQualityGateFilterModel): __Observable<__StrictHttpResponse<Array<SenatorNoGvKeyConfirmation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorQualityGateFilterModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/senatorNoGvKeyConfirmation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SenatorNoGvKeyConfirmation>>;
      })
    );
  }
  /**
   * @param senatorQualityGateFilterModel undefined
   * @return get list of SenatorNoGvKeyConfirmation
   */
  getSenatorNoGvKeyConfirmation(senatorQualityGateFilterModel: SenatorQualityGateFilterModel): __Observable<Array<SenatorNoGvKeyConfirmation>> {
    return this.getSenatorNoGvKeyConfirmationResponse(senatorQualityGateFilterModel).pipe(
      __map(_r => _r.body as Array<SenatorNoGvKeyConfirmation>)
    );
  }

  /**
   * @param senatorNoGvKeyConfirmation undefined
   * @return saved confirmed value.
   */
  updateSenatorNoGvKeyConfirmationResponse(senatorNoGvKeyConfirmation: SenatorNoGvKeyConfirmation): __Observable<__StrictHttpResponse<SenatorNoGvKeyConfirmation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = senatorNoGvKeyConfirmation;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/senatorNoGvKeyConfirmation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SenatorNoGvKeyConfirmation>;
      })
    );
  }
  /**
   * @param senatorNoGvKeyConfirmation undefined
   * @return saved confirmed value.
   */
  updateSenatorNoGvKeyConfirmation(senatorNoGvKeyConfirmation: SenatorNoGvKeyConfirmation): __Observable<SenatorNoGvKeyConfirmation> {
    return this.updateSenatorNoGvKeyConfirmationResponse(senatorNoGvKeyConfirmation).pipe(
      __map(_r => _r.body as SenatorNoGvKeyConfirmation)
    );
  }
}

module SenatorNoGvKeyConfirmationService {
}

export { SenatorNoGvKeyConfirmationService }

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SenatorMarketTradedSecurityConfirmation} from '../../../../api/models/senator-market-traded-security-confirmation';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FormBuilder} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ListedOptionsQualityGateService} from '../../../../api/services/listed-options-quality-gate.service';
import {ListedOptionFilter} from '../../../../api/models/listed-option-filter';
import {ListedOptionsQualityGate} from '../../../../api/models/listed-options-quality-gate';
import {ListedOptionQualityGateCheck} from '../../../../api/models/listed-option-quality-gate-check';

@Component({
  selector: 'app-quality-gate-listed-options',
  templateUrl: './quality-gate-listed-options.component.html',
  styleUrls: ['./quality-gate-listed-options.component.css']
})
export class QualityGateListedOptionsComponent implements OnInit, AfterViewInit {

  // mat table vars
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  nonGvkeyConfirmationColumns: string[] = ['filingId', 'tradeIndex', 'totalCount', 'botGrabbed', 'transactionType',
    'transactionDate', 'rowCreation', 'comments', 'source', 'actions'];
  displayedColumns: string[] = this.nonGvkeyConfirmationColumns;
  tableDataSource: MatTableDataSource<ListedOptionsQualityGate>;


  pageSize: number;
  pageNumber: number;
  pageSizeOptions: number[] = [10, 20, 50, 100, 200];
  pageEvent: PageEvent;

  isSearchingIssuerName: boolean;
  searchString: string;
  isLastActionSearch: boolean;
  lastPageNumber: number;
  lastSearchPageNumber: number;
  searchPageNumber: number;
  lastSearchString: string;
  loadingData = false;
  assetNamesLoading: boolean;
  filters: ListedOptionFilter;
  processTypes = ['UNEXCLUDED', 'EXCLUDED'];
  sortedByList = ['TRANSACTION_DATE', 'ROW_CREATION_DATE'];
  subProcessTypes = [{value: 'populated', viewValue: 'populated'},
    {value: 'unpopulated', viewValue: 'unpopulated'}];
  checks: ListedOptionQualityGateCheck[] = ListedOptionQualityGateCheck.values();
  selectedSubProcessTypes = [];
  descriptions = [];

  constructor(
    public snackBar: MatSnackBar, private _fb: FormBuilder,
    private dialog: MatDialog,
    private listedOptionsQualityGateService: ListedOptionsQualityGateService) {
  }


  ngOnInit() {
    this.initVars();
    this.getRecords();
  }

  ngAfterViewInit() {
    if (this.tableDataSource != null) {
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    }
  }

  initVars() {
    this.pageSize = 10;
    this.pageNumber = 1;
    this.searchPageNumber = 1;
    this.lastPageNumber = 1;
    this.lastSearchPageNumber = 1;
    this.assetNamesLoading = false;
    this.isSearchingIssuerName = false;
    this.searchString = '';
    this.lastSearchString = '';
    this.isLastActionSearch = false;

    this.filters = {
      checks: ['OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED'],
      filingId: null,
      issureName: null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      processtype: null,
      populated: false,
      unpopulated: false,
      sortedBy: 'ROW_CREATION_DATE'
    };

  }

  getRecords(value?: string) {
    this.descriptions = this.filters.checks.map(t => this.getDescription(t));
    if (this.isSearchingIssuerName)
      value = this.searchString;
    this.loadingData = true;

    const body: ListedOptionFilter = {
      checks: this.filters.checks,
      filingId: this.filters.filingId,
      issureName: value,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      processtype: this.filters.processtype,
      sortedBy: this.filters.sortedBy,
      populated: this.selectedSubProcessTypes.some(el => el === 'populated'),
      unpopulated: this.selectedSubProcessTypes.some(el => el === 'unpopulated')
    };

    this.listedOptionsQualityGateService.getListedOptionsQualityGate(body).subscribe(
      (res: ListedOptionsQualityGate[]) => {
        this.tableDataSource = new MatTableDataSource<ListedOptionsQualityGate>(res);

      },
      error => {
        this.loadingData = false;
      },
      () => {
        this.loadingData = false;
        this.paginator.pageIndex = this.pageNumber - 1;
        this.paginator.pageSize = this.pageSize;
      }
    );
  }

  onProcessClick(row: ListedOptionsQualityGate) {
    const params: SenatorMarketTradedSecurityConfirmation = row;
    this.listedOptionsQualityGateService.addProcessedListingOption(params).subscribe((res: ListedOptionsQualityGate) => {
      },
      err => {
        this.loadingData = false;
        this.openSnackBar(err.error.message, 'Close');
      },
      () => {
        this.loadingData = false;
        this.paginator.pageIndex = this.pageNumber - 1;
        this.paginator.pageSize = this.pageSize;
        this.getRecords();
        this.openSnackBar(`Issuer has been marked as processed`, 'Close');
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'left',
      verticalPosition: 'bottom'
    });
  }


  applySearch(value: string) {
    //this.selection.clear();
    if (value.length === 0) {
      //if search removed
      this.isSearchingIssuerName = false;

      if (this.isLastActionSearch) {
        this.lastSearchPageNumber = this.pageNumber;
        this.pageNumber = this.lastPageNumber;
      }
      this.getRecords();
      this.isLastActionSearch = false;
    } else {
      //if searched something
      this.isSearchingIssuerName = true;
      if (!this.isLastActionSearch) {
        this.lastPageNumber = this.pageNumber;
      }
      if (value != this.searchString) {
        //if in case searched something that is new than previous search
        this.lastSearchString = this.searchString;
        this.lastSearchPageNumber = 1;
        this.searchString = value;

      } else {
        //if in case searched something that is the same as pervious one
        this.pageNumber = this.lastSearchPageNumber;
      }
      this.pageNumber = this.lastSearchPageNumber;

      this.getRecords(value);
      this.isLastActionSearch = true;
    }
  }

  onPaginateChange(event: PageEvent) {

    if (event.pageSize != this.pageSize) {
      // this.selection.clear();
    }

    if (!this.isSearchingIssuerName) {
      //if search removed and paginating
      this.pageNumber = event.pageIndex + 1;
      this.lastPageNumber = this.pageNumber;
      this.pageSize = event.pageSize;
      this.getRecords();

    } else {
      //if searched something and paginating
      this.pageNumber = event.pageIndex + 1;
      this.lastSearchPageNumber = this.pageNumber;
      this.pageSize = event.pageSize;
      this.getRecords(this.searchString);

    }
  }

  private getDescription(listedItem: ListedOptionQualityGateCheck) {
    let value = '';
    if (listedItem == 'OPR17_OCC21_FIGI_ALL_NULL_OR_POPULATED') {
      value = 'OPR17, OCC21 and FIGI. Either all should be NULL or all should be populated';
    } else if (listedItem == 'TICKER_NULL_THEN_STYLE_OPR17_OCC21_FIGI_NULL') {
      value = 'If ticker is NULL, then Style, OPR17, OCC21 and FIGI should be NULL.';
    } else if (listedItem == 'EXPIRATION_DATE_LESS_THAN_TRANSACTION_DATE') {
      value = 'Expiration date less than Transaction Date';
    } else if (listedItem == 'EXPIRATION_DATE_DIFFERENT_DATE_IN_TICKER') {
      value = 'Where expiration date is different than the date in ticker';
    } else if (listedItem == 'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_IN_COMMENTS_BOT_NAME') {
      value = 'Where Call/Put/Other is taken in Financial Instrument, but not in comments / bot name.';
    } else if (listedItem == 'CALL_PUT_TAKEN_FINANCIAL_INSTRUMENT_NOT_MATCH_TYPE_OF_LISTED_OPTION') {
      value = 'Where Call/Put/Other in Fin Instrument doesn\'t match with Type of Listed Option';
    } else if (listedItem == 'UNDERLYING_ASSET_AND_STYLE_PAIR_CHECK') {
      value = 'Underlying Asset and Style is not matched';
    } else if (listedItem == 'UNDERLYING_ASSET_AND_CORRESPONDING_ASSET_TYPE_PAIR_CHECK') {
      value = 'UnderlyingAssetType and Corresponding AssetType of UnderlyingAssetID must form these pairs.';
    } else if (listedItem == 'UNDERLYING_ASSET_TYPE_IS_STOCK_EQUITIES_THEN_UNDERLYING_ASSET_TYPE_DECLARED') {
      value = 'If Underlying AssetType is Stock Equities, then Underlying Asset Type must be declared';
    } else if (listedItem == 'OPTION_DETAIL_IS_NOT_POPULATE') {
      value = 'Option detail isn\'t populated';
    }
    return {id: listedItem, value: value};
  }

}

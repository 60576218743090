/* tslint:disable */
type SenatorAssetType =
  '401K' |
  '529 C' |
  '529 P' |
  'ABS' |
  'Cash' |
  'Collectibles' |
  'Corporate Bond' |
  'Crypto' |
  'Defined Benefit Pension' |
  'Debts Owed' |
  'Delaware Statutory Trust' |
  'ETF' |
  'Blind Trust' |
  'ETN' |
  'Farms' |
  'Foreign Currency' |
  'Fixed Annuity' |
  'Futures' |
  'Municipal Security' |
  'Hedge / Pvt Eq Funds (EIF)' |
  'Hedge / Pvt Eq Funds (non-EIF)' |
  'Investment Club' |
  'IRA' |
  'Intellectual Property' |
  'Mutual Fund' |
  'Ownership Interest' |
  'Stock Options' |
  'Other Securities' |
  'Pensions' |
  'Non-Public Stock' |
  'REIT' |
  'Property' |
  'Stock Appreciation Right' |
  'Stock' |
  'Trust' |
  'Variable Annuity' |
  'Variable Insurance' |
  'Insurance' |
  'Bank Deposit' |
  'Annuity Fixed' |
  'Real Estate (Although we have a category of Property in the list)' |
  'Retirement Plan - IRA' |
  'Brokerage/Managed Account' |
  'Not Disclosed';
module SenatorAssetType {
  export const _401K: SenatorAssetType = '401K';
  export const _529_C: SenatorAssetType = '529 C';
  export const _529_P: SenatorAssetType = '529 P';
  export const ABS: SenatorAssetType = 'ABS';
  export const CASH: SenatorAssetType = 'Cash';
  export const COLLECTIBLES: SenatorAssetType = 'Collectibles';
  export const CORPORATE_BOND: SenatorAssetType = 'Corporate Bond';
  export const CRYPTO: SenatorAssetType = 'Crypto';
  export const DEFINED_BENEFIT_PENSION: SenatorAssetType = 'Defined Benefit Pension';
  export const DEBTS_OWED: SenatorAssetType = 'Debts Owed';
  export const DELAWARE_STATUTORY_TRUST: SenatorAssetType = 'Delaware Statutory Trust';
  export const ETF: SenatorAssetType = 'ETF';
  export const BLIND_TRUST: SenatorAssetType = 'Blind Trust';
  export const ETN: SenatorAssetType = 'ETN';
  export const FARMS: SenatorAssetType = 'Farms';
  export const FOREIGN_CURRENCY: SenatorAssetType = 'Foreign Currency';
  export const FIXED_ANNUITY: SenatorAssetType = 'Fixed Annuity';
  export const FUTURES: SenatorAssetType = 'Futures';
  export const MUNICIPAL_SECURITY: SenatorAssetType = 'Municipal Security';
  export const HEDGE_PVT_EQ_FUNDS_EIF_: SenatorAssetType = 'Hedge / Pvt Eq Funds (EIF)';
  export const HEDGE_PVT_EQ_FUNDS_NON_EIF_: SenatorAssetType = 'Hedge / Pvt Eq Funds (non-EIF)';
  export const INVESTMENT_CLUB: SenatorAssetType = 'Investment Club';
  export const IRA: SenatorAssetType = 'IRA';
  export const INTELLECTUAL_PROPERTY: SenatorAssetType = 'Intellectual Property';
  export const MUTUAL_FUND: SenatorAssetType = 'Mutual Fund';
  export const OWNERSHIP_INTEREST: SenatorAssetType = 'Ownership Interest';
  export const STOCK_OPTIONS: SenatorAssetType = 'Stock Options';
  export const OTHER_SECURITIES: SenatorAssetType = 'Other Securities';
  export const PENSIONS: SenatorAssetType = 'Pensions';
  export const NON_PUBLIC_STOCK: SenatorAssetType = 'Non-Public Stock';
  export const REIT: SenatorAssetType = 'REIT';
  export const PROPERTY: SenatorAssetType = 'Property';
  export const STOCK_APPRECIATION_RIGHT: SenatorAssetType = 'Stock Appreciation Right';
  export const STOCK: SenatorAssetType = 'Stock';
  export const TRUST: SenatorAssetType = 'Trust';
  export const VARIABLE_ANNUITY: SenatorAssetType = 'Variable Annuity';
  export const VARIABLE_INSURANCE: SenatorAssetType = 'Variable Insurance';
  export const INSURANCE: SenatorAssetType = 'Insurance';
  export const BANK_DEPOSIT: SenatorAssetType = 'Bank Deposit';
  export const ANNUITY_FIXED: SenatorAssetType = 'Annuity Fixed';
  export const REAL_ESTATE_ALTHOUGH_WE_HAVE_A_CATEGORY_OF_PROPERTY_IN_THE_LIST_: SenatorAssetType = 'Real Estate (Although we have a category of Property in the list)';
  export const RETIREMENT_PLAN_IRA: SenatorAssetType = 'Retirement Plan - IRA';
  export const BROKERAGE_MANAGED_ACCOUNT: SenatorAssetType = 'Brokerage/Managed Account';
  export const NOT_DISCLOSED: SenatorAssetType = 'Not Disclosed';
  export function values(): SenatorAssetType[] {
    return [
      _401K,
      _529_C,
      _529_P,
      ABS,
      CASH,
      COLLECTIBLES,
      CORPORATE_BOND,
      CRYPTO,
      DEFINED_BENEFIT_PENSION,
      DEBTS_OWED,
      DELAWARE_STATUTORY_TRUST,
      ETF,
      BLIND_TRUST,
      ETN,
      FARMS,
      FOREIGN_CURRENCY,
      FIXED_ANNUITY,
      FUTURES,
      MUNICIPAL_SECURITY,
      HEDGE_PVT_EQ_FUNDS_EIF_,
      HEDGE_PVT_EQ_FUNDS_NON_EIF_,
      INVESTMENT_CLUB,
      IRA,
      INTELLECTUAL_PROPERTY,
      MUTUAL_FUND,
      OWNERSHIP_INTEREST,
      STOCK_OPTIONS,
      OTHER_SECURITIES,
      PENSIONS,
      NON_PUBLIC_STOCK,
      REIT,
      PROPERTY,
      STOCK_APPRECIATION_RIGHT,
      STOCK,
      TRUST,
      VARIABLE_ANNUITY,
      VARIABLE_INSURANCE,
      INSURANCE,
      BANK_DEPOSIT,
      ANNUITY_FIXED,
      REAL_ESTATE_ALTHOUGH_WE_HAVE_A_CATEGORY_OF_PROPERTY_IN_THE_LIST_,
      RETIREMENT_PLAN_IRA,
      BROKERAGE_MANAGED_ACCOUNT,
      NOT_DISCLOSED
    ];
  }
}

export { SenatorAssetType }
/* tslint:disable */

/**
 * A enum contains underlying asset type
 */
type UnderlyingAssetType =
  'Stock-Equities' |
  'Debt Instruments' |
  'Currencies' |
  'Commodities' |
  'Futures' |
  'Swaps' |
  'Interest Rates' |
  'Others' |
  'Indices' |
  'Baskets' |
  'Options';
module UnderlyingAssetType {
  export const STOCK_EQUITIES: UnderlyingAssetType = 'Stock-Equities';
  export const DEBT_INSTRUMENTS: UnderlyingAssetType = 'Debt Instruments';
  export const CURRENCIES: UnderlyingAssetType = 'Currencies';
  export const COMMODITIES: UnderlyingAssetType = 'Commodities';
  export const FUTURES: UnderlyingAssetType = 'Futures';
  export const SWAPS: UnderlyingAssetType = 'Swaps';
  export const INTEREST_RATES: UnderlyingAssetType = 'Interest Rates';
  export const OTHERS: UnderlyingAssetType = 'Others';
  export const INDICES: UnderlyingAssetType = 'Indices';
  export const BASKETS: UnderlyingAssetType = 'Baskets';
  export const OPTIONS: UnderlyingAssetType = 'Options';
  export function values(): UnderlyingAssetType[] {
    return [
      STOCK_EQUITIES,
      DEBT_INSTRUMENTS,
      CURRENCIES,
      COMMODITIES,
      FUTURES,
      SWAPS,
      INTEREST_RATES,
      OTHERS,
      INDICES,
      BASKETS,
      OPTIONS
    ];
  }
}

export { UnderlyingAssetType }
/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChineseTranslation } from '../models/chinese-translation';
@Injectable({
  providedIn: 'root',
})
class IssuerMappingService extends __BaseService {
  static readonly getAllRemainingTranslationsPath = '/chineseIssuerTranslation';
  static readonly updateChineseTranslationsPath = '/chineseIssuerTranslation';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `IssuerMappingService.GetAllRemainingTranslationsParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * @return sdsDataForManagerChart
   */
  getAllRemainingTranslationsResponse(params: IssuerMappingService.GetAllRemainingTranslationsParams): __Observable<__StrictHttpResponse<Array<ChineseTranslation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNo != null) __params = __params.set('pageNo', params.pageNo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/chineseIssuerTranslation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ChineseTranslation>>;
      })
    );
  }
  /**
   * @param params The `IssuerMappingService.GetAllRemainingTranslationsParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNo`:
   *
   * @return sdsDataForManagerChart
   */
  getAllRemainingTranslations(params: IssuerMappingService.GetAllRemainingTranslationsParams): __Observable<Array<ChineseTranslation>> {
    return this.getAllRemainingTranslationsResponse(params).pipe(
      __map(_r => _r.body as Array<ChineseTranslation>)
    );
  }

  /**
   * @param translatedIssuer Updating Issuer name with translated data
   * @return Return boolean if updated.
   */
  updateChineseTranslationsResponse(translatedIssuer: ChineseTranslation): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = translatedIssuer;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/chineseIssuerTranslation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param translatedIssuer Updating Issuer name with translated data
   * @return Return boolean if updated.
   */
  updateChineseTranslations(translatedIssuer: ChineseTranslation): __Observable<boolean> {
    return this.updateChineseTranslationsResponse(translatedIssuer).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module IssuerMappingService {

  /**
   * Parameters for getAllRemainingTranslations
   */
  export interface GetAllRemainingTranslationsParams {
    pageSize: number;
    pageNo: number;
  }
}

export { IssuerMappingService }

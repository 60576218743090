/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ShortSelling } from '../models/short-selling';
import { Company } from '../models/company';
@Injectable({
  providedIn: 'root',
})
class ShortSellingDataService extends __BaseService {
  static readonly GetShortSellingsDataByIdPath = '/getAllShortSellings';
  static readonly getIssuersByManagerPath = '/issuer/{manager}/{activeOnly}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShortSellingDataService.GetShortSellingsDataByIdParams` containing the following parameters:
   *
   * - `companyName`: companyName to fetch data
   *
   * - `managerName`: managerName to fetch data
   *
   * @return Returns Array of objects of ShortSelling modal.
   */
  GetShortSellingsDataByIdResponse(params: ShortSellingDataService.GetShortSellingsDataByIdParams): __Observable<__StrictHttpResponse<Array<ShortSelling>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.companyName != null) __params = __params.set('companyName', params.companyName.toString());
    if (params.managerName != null) __params = __params.set('managerName', params.managerName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getAllShortSellings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShortSelling>>;
      })
    );
  }
  /**
   * @param params The `ShortSellingDataService.GetShortSellingsDataByIdParams` containing the following parameters:
   *
   * - `companyName`: companyName to fetch data
   *
   * - `managerName`: managerName to fetch data
   *
   * @return Returns Array of objects of ShortSelling modal.
   */
  GetShortSellingsDataById(params: ShortSellingDataService.GetShortSellingsDataByIdParams): __Observable<Array<ShortSelling>> {
    return this.GetShortSellingsDataByIdResponse(params).pipe(
      __map(_r => _r.body as Array<ShortSelling>)
    );
  }

  /**
   * @param params The `ShortSellingDataService.GetIssuersByManagerParams` containing the following parameters:
   *
   * - `manager`: Search Value By Manager
   *
   * - `activeOnly`: Active Only Issuers
   *
   * @return List of Issuers matching the manager.
   */
  getIssuersByManagerResponse(params: ShortSellingDataService.GetIssuersByManagerParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/issuer/${encodeURIComponent(String(params.manager))}/${encodeURIComponent(String(params.activeOnly))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `ShortSellingDataService.GetIssuersByManagerParams` containing the following parameters:
   *
   * - `manager`: Search Value By Manager
   *
   * - `activeOnly`: Active Only Issuers
   *
   * @return List of Issuers matching the manager.
   */
  getIssuersByManager(params: ShortSellingDataService.GetIssuersByManagerParams): __Observable<Array<Company>> {
    return this.getIssuersByManagerResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }
}

module ShortSellingDataService {

  /**
   * Parameters for GetShortSellingsDataById
   */
  export interface GetShortSellingsDataByIdParams {

    /**
     * companyName to fetch data
     */
    companyName: string;

    /**
     * managerName to fetch data
     */
    managerName?: string;
  }

  /**
   * Parameters for getIssuersByManager
   */
  export interface GetIssuersByManagerParams {

    /**
     * Search Value By Manager
     */
    manager: string;

    /**
     * Active Only Issuers
     */
    activeOnly: boolean;
  }
}

export { ShortSellingDataService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Sector } from '../models/sector';
@Injectable({
  providedIn: 'root',
})
class SectorService extends __BaseService {
  static readonly getSectorValuePath = '/sector/value';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SectorService.GetSectorValueParams` containing the following parameters:
   *
   * - `positionHolder`: Manager Name
   *
   * - `positionDate`: position Date
   *
   * - `countryID`: country Id
   *
   * @return sdsDataForManagerChart
   */
  getSectorValueResponse(params: SectorService.GetSectorValueParams): __Observable<__StrictHttpResponse<Array<Sector>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.positionHolder != null) __params = __params.set('positionHolder', params.positionHolder.toString());
    if (params.positionDate != null) __params = __params.set('positionDate', params.positionDate.toString());
    if (params.countryID != null) __params = __params.set('countryID', params.countryID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sector/value`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Sector>>;
      })
    );
  }
  /**
   * @param params The `SectorService.GetSectorValueParams` containing the following parameters:
   *
   * - `positionHolder`: Manager Name
   *
   * - `positionDate`: position Date
   *
   * - `countryID`: country Id
   *
   * @return sdsDataForManagerChart
   */
  getSectorValue(params: SectorService.GetSectorValueParams): __Observable<Array<Sector>> {
    return this.getSectorValueResponse(params).pipe(
      __map(_r => _r.body as Array<Sector>)
    );
  }
}

module SectorService {

  /**
   * Parameters for getSectorValue
   */
  export interface GetSectorValueParams {

    /**
     * Manager Name
     */
    positionHolder?: string;

    /**
     * position Date
     */
    positionDate?: string;

    /**
     * country Id
     */
    countryID?: string;
  }
}

export { SectorService }

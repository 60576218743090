import {forwardRef, InjectionToken, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {
  AddNewFileComponent,
  FileAmendmentDialogComponent,
  FileCombineDialogComponent,
  FileDeleteDialogComponent,
  FilesComponent,
  FileSplitDialogComponent,
  FileUnlockDialogComponent
} from './components/files/files.component';
import {AppRoutingModule} from "./app-routing.module";
import {MaterialModule} from "./material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AuthenticationService} from "./services/authentication.service";
import {OAuthModule, OAuthService, UrlHelperService} from "angular-oauth2-oidc";
import {environment} from "../environments/environment";
import {ApiModule} from "../api/api.module";
import {ApiInterceptor} from "./interceptors/api.interceptor";
import {ApiRequestConfiguration} from "./interceptors/api.request.configuration";
import {FileProcessingComponent} from "./components/file-processing/file-procesing.component";
import {AnnualReportsComponent} from "./components/annual-reports/annual-reports.component";
import {
  AnnualReportProcessingComponent
} from "./components/annual-report-processing/annual-report-processing.component";
import {ListedOptionTradesComponent} from "./components/listed-option-trades/listed-option-trades.component";
import {
  IssuerTradesProcessingComponent
} from "./components/issuer-trades-processing/issuer-trades-processing.component";
import {
  FocusedFileIssuerMappingComponent
} from "./components/focused-file-issuer-mapping/focused-file-issuer-mapping.component";
import {BiographyComponent} from "./components/biography/biography.component";
import {CommitteesComponent} from "./components/committes/committees.component";
import {QualityGatesComponent} from "./components/quality-gates/quality-gates.component";
import {StatsComponent} from "./components/stats/stats.component";
import {AllTradesComponent} from "./components/all-trades/all-trades.component";
import {
  MergeIssuerDialog1Component, SnackBar1Component,
  TradeAmendmentDialog1Component, TradeDeleteDialog1Component, TradesComponent,
  TradeUnAmendmentDialog1Component
} from "./components/trades/trades.component";
import {
  AddEditListedOptionsComponent
} from "./components/quality-gates/issuer-mapping/all-companies/edit-company/listed-options/add-edit-listed-options/add-edit-listed-options.component";
import {
  DatePickerDialogComponent
} from "./components/biography/edit-and-create-biography-dialog/date-picker-dialog/date-picker-dialog.component";
import {AllCompaniesComponent} from "./components/quality-gates/issuer-mapping/all-companies/all-companies.component";
import {
  QualityGateListedOptionsComponent
} from "./components/quality-gates/quality-gate-listed-options/quality-gate-listed-options.component";
import {DataService} from "./components/services/data.service";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {DropdownModule} from "primeng/dropdown";


export const OAUTH_PROVIDER_URL = new InjectionToken<string>('oauthProviderUrl');

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    FilesComponent,
    FileDeleteDialogComponent,
    FileCombineDialogComponent,
    FileSplitDialogComponent,
    FileAmendmentDialogComponent,
    FileUnlockDialogComponent,
    AddNewFileComponent,
    FileProcessingComponent,
    AnnualReportsComponent,
    AnnualReportProcessingComponent,
    ListedOptionTradesComponent,
    IssuerTradesProcessingComponent,
    FocusedFileIssuerMappingComponent,
    BiographyComponent,
    CommitteesComponent,
    QualityGatesComponent,
    StatsComponent,
    AllTradesComponent,
    TradeUnAmendmentDialog1Component,
    TradeAmendmentDialog1Component,
    MergeIssuerDialog1Component,
    TradeDeleteDialog1Component,
    SnackBar1Component,
    TradesComponent,
    AddEditListedOptionsComponent,
    DatePickerDialogComponent,
    AllCompaniesComponent,
    QualityGateListedOptionsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ToastModule,
    DropdownModule,
    ApiModule.forRoot({rootUrl: environment.apiUrl}),
    OAuthModule.forRoot()
  ],
  providers: [
    DatePipe,
    DataService,
    MessageService,
    AuthenticationService,
    ApiInterceptor,
    OAuthService,
    UrlHelperService,
    API_INTERCEPTOR_PROVIDER,
    ApiRequestConfiguration,
    {provide: OAUTH_PROVIDER_URL, useValue: environment.oauthProviderUrl},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Announcement } from '../models/announcement';
import { AnnouncementFilter } from '../models/announcement-filter';
import { AnnouncementCategory } from '../models/announcement-category';
import { AnnouncementChecker } from '../models/announcement-checker';
import { AnnouncementCountry } from '../models/announcement-country';
import { AnnouncementDetail } from '../models/announcement-detail';
import { FakeAnnouncement } from '../models/fake-announcement';
import { UniqueValue } from '../models/unique-value';
import { AnnouncementStatus } from '../models/announcement-status';
import { LockStatus } from '../models/lock-status';
import { MarkProcessed } from '../models/mark-processed';
import { GrabberDetail } from '../models/grabber-detail';
@Injectable({
  providedIn: 'root',
})
class AnnouncementService extends __BaseService {
  static readonly getAnnouncementPath = '/announcement';
  static readonly deleteAnnouncementPath = '/announcement/{announcementID}';
  static readonly getAnnouncementCategoriesPath = '/announcement/category';
  static readonly getAnnouncementCheckerDataPath = '/announcement/checker';
  static readonly getAnnouncementCountryPath = '/announcement/country/{announcementID}';
  static readonly getAnnouncementDetailPath = '/announcement/detail/{announcementID}';
  static readonly displayAnnouncementPath = '/announcement/display/{announcementID}';
  static readonly createFakeAnnouncementPath = '/announcement/fake';
  static readonly getAnnouncementIDForRnsIDPath = '/announcement/IDForRnsID';
  static readonly getAnnouncementsLinkedToTradePath = '/announcement/linkedToTrade/{tradeType}/{tradeID}';
  static readonly markAnnouncementPath = '/announcement/mark/{announcementID}/{action}';
  static readonly getAnnouncementStatusPath = '/announcement/status';
  static readonly assignAssignmentToBuybackPath = '/announcement/toBuyback/{announcementID}/{buybackID}/{processDate}/{countryCode}/{countryID}/{reportPeriodID}';
  static readonly assignAnnouncementToIntentionPath = '/announcement/toIntention/{announcementID}/{intentionID}/{processDate}/{countryCode}/{countryID}/{reportPeriodID}';
  static readonly unAssignAnnouncementFromBuybackPath = '/announcement/unAssign/fromBuyback/{announcementID}/{buybackID}';
  static readonly unAssignAnnouncementFromIntentionPath = '/announcement/unAssign/fromIntention/{announcementID}/{intentionID}';
  static readonly lockingAnnouncementPath = '/announcement/locking/{status}/{announcementID}';
  static readonly unlockingAnnouncementPath = '/announcement/unlocking/{announcementID}';
  static readonly isLockedAnnouncementPath = '/announcement/isLock/{announcementID}';
  static readonly markAsProcessedPath = '/markAsProcessed/{announcementID}';
  static readonly markAsCheckedPath = '/markAsChecked/{announcementID}';
  static readonly getGrabberDetailPath = '/grabberDetail/{countryID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param AnnouncementFilters Get all Announcements.
   * @return List of Announcements.
   */
  getAnnouncementResponse(AnnouncementFilters: AnnouncementFilter): __Observable<__StrictHttpResponse<Array<Announcement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = AnnouncementFilters;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/announcement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Announcement>>;
      })
    );
  }
  /**
   * @param AnnouncementFilters Get all Announcements.
   * @return List of Announcements.
   */
  getAnnouncement(AnnouncementFilters: AnnouncementFilter): __Observable<Array<Announcement>> {
    return this.getAnnouncementResponse(AnnouncementFilters).pipe(
      __map(_r => _r.body as Array<Announcement>)
    );
  }

  /**
   * @param announcementID This is the announcementID.
   * @return Return where announcement is deleted
   */
  deleteAnnouncementResponse(announcementID: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/announcement/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param announcementID This is the announcementID.
   * @return Return where announcement is deleted
   */
  deleteAnnouncement(announcementID: number): __Observable<boolean> {
    return this.deleteAnnouncementResponse(announcementID).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return List of AnnouncementCategories.
   */
  getAnnouncementCategoriesResponse(): __Observable<__StrictHttpResponse<Array<AnnouncementCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/category`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnnouncementCategory>>;
      })
    );
  }
  /**
   * @return List of AnnouncementCategories.
   */
  getAnnouncementCategories(): __Observable<Array<AnnouncementCategory>> {
    return this.getAnnouncementCategoriesResponse().pipe(
      __map(_r => _r.body as Array<AnnouncementCategory>)
    );
  }

  /**
   * @return Get last and current week announcements for countries
   */
  getAnnouncementCheckerDataResponse(): __Observable<__StrictHttpResponse<Array<AnnouncementChecker>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/checker`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnnouncementChecker>>;
      })
    );
  }
  /**
   * @return Get last and current week announcements for countries
   */
  getAnnouncementCheckerData(): __Observable<Array<AnnouncementChecker>> {
    return this.getAnnouncementCheckerDataResponse().pipe(
      __map(_r => _r.body as Array<AnnouncementChecker>)
    );
  }

  /**
   * @param announcementID Announcement ID
   * @return Get Country of a specific announcement
   */
  getAnnouncementCountryResponse(announcementID: string): __Observable<__StrictHttpResponse<AnnouncementCountry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/country/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnouncementCountry>;
      })
    );
  }
  /**
   * @param announcementID Announcement ID
   * @return Get Country of a specific announcement
   */
  getAnnouncementCountry(announcementID: string): __Observable<AnnouncementCountry> {
    return this.getAnnouncementCountryResponse(announcementID).pipe(
      __map(_r => _r.body as AnnouncementCountry)
    );
  }

  /**
   * @param announcementID Announcement ID
   * @return Announcement detail.
   */
  getAnnouncementDetailResponse(announcementID: number): __Observable<__StrictHttpResponse<AnnouncementDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/detail/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnouncementDetail>;
      })
    );
  }
  /**
   * @param announcementID Announcement ID
   * @return Announcement detail.
   */
  getAnnouncementDetail(announcementID: number): __Observable<AnnouncementDetail> {
    return this.getAnnouncementDetailResponse(announcementID).pipe(
      __map(_r => _r.body as AnnouncementDetail)
    );
  }

  /**
   * @param announcementID Announcement ID.
   * @return Announcement Detail.
   */
  displayAnnouncementResponse(announcementID: number): __Observable<__StrictHttpResponse<Array<Announcement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/display/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Announcement>>;
      })
    );
  }
  /**
   * @param announcementID Announcement ID.
   * @return Announcement Detail.
   */
  displayAnnouncement(announcementID: number): __Observable<Array<Announcement>> {
    return this.displayAnnouncementResponse(announcementID).pipe(
      __map(_r => _r.body as Array<Announcement>)
    );
  }

  /**
   * @param FakeAnnouncement Post fake announcement detail.
   * @return Return ID of the the newly created fake announcement
   */
  createFakeAnnouncementResponse(FakeAnnouncement: FakeAnnouncement): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = FakeAnnouncement;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/announcement/fake`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param FakeAnnouncement Post fake announcement detail.
   * @return Return ID of the the newly created fake announcement
   */
  createFakeAnnouncement(FakeAnnouncement: FakeAnnouncement): __Observable<number> {
    return this.createFakeAnnouncementResponse(FakeAnnouncement).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param UniqueValue Post UniqueValue model.
   * @return Return announcement ID for RNSID or SOURCEURL.
   */
  getAnnouncementIDForRnsIDResponse(UniqueValue: UniqueValue): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = UniqueValue;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/announcement/IDForRnsID`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param UniqueValue Post UniqueValue model.
   * @return Return announcement ID for RNSID or SOURCEURL.
   */
  getAnnouncementIDForRnsID(UniqueValue: UniqueValue): __Observable<number> {
    return this.getAnnouncementIDForRnsIDResponse(UniqueValue).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `AnnouncementService.GetAnnouncementsLinkedToTradeParams` containing the following parameters:
   *
   * - `tradeType`: tradeType defines what kind of trade assigned with announcement, either Buyback or Intention.
   *
   * - `tradeID`: tradeId is the Id of saved transaction. It should be buybackId or intentionId depending upon parameter tradeType
   *
   * @return Announcements that are assigned to the buyback or intention
   */
  getAnnouncementsLinkedToTradeResponse(params: AnnouncementService.GetAnnouncementsLinkedToTradeParams): __Observable<__StrictHttpResponse<Array<Announcement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/linkedToTrade/${encodeURIComponent(String(params.tradeType))}/${encodeURIComponent(String(params.tradeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Announcement>>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.GetAnnouncementsLinkedToTradeParams` containing the following parameters:
   *
   * - `tradeType`: tradeType defines what kind of trade assigned with announcement, either Buyback or Intention.
   *
   * - `tradeID`: tradeId is the Id of saved transaction. It should be buybackId or intentionId depending upon parameter tradeType
   *
   * @return Announcements that are assigned to the buyback or intention
   */
  getAnnouncementsLinkedToTrade(params: AnnouncementService.GetAnnouncementsLinkedToTradeParams): __Observable<Array<Announcement>> {
    return this.getAnnouncementsLinkedToTradeResponse(params).pipe(
      __map(_r => _r.body as Array<Announcement>)
    );
  }

  /**
   * @param params The `AnnouncementService.MarkAnnouncementParams` containing the following parameters:
   *
   * - `announcementID`: Announcement ID
   *
   * - `action`: Action defines which type of operation need to perform on backend, either Unmark or Mark announcement as edited.
   *
   * @return Returns boolean value.
   */
  markAnnouncementResponse(params: AnnouncementService.MarkAnnouncementParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/mark/${encodeURIComponent(String(params.announcementID))}/${encodeURIComponent(String(params.action))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `AnnouncementService.MarkAnnouncementParams` containing the following parameters:
   *
   * - `announcementID`: Announcement ID
   *
   * - `action`: Action defines which type of operation need to perform on backend, either Unmark or Mark announcement as edited.
   *
   * @return Returns boolean value.
   */
  markAnnouncement(params: AnnouncementService.MarkAnnouncementParams): __Observable<boolean> {
    return this.markAnnouncementResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return Get status of announcement.
   */
  getAnnouncementStatusResponse(): __Observable<__StrictHttpResponse<Array<AnnouncementStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AnnouncementStatus>>;
      })
    );
  }
  /**
   * @return Get status of announcement.
   */
  getAnnouncementStatus(): __Observable<Array<AnnouncementStatus>> {
    return this.getAnnouncementStatusResponse().pipe(
      __map(_r => _r.body as Array<AnnouncementStatus>)
    );
  }

  /**
   * @param params The `AnnouncementService.AssignAssignmentToBuybackParams` containing the following parameters:
   *
   * - `reportPeriodID`: Report period Id
   *
   * - `processDate`: Process Date
   *
   * - `countryID`: Country Id
   *
   * - `countryCode`: Forced Country Code
   *
   * - `buybackID`: Buyback Id
   *
   * - `announcementID`: Announcement Id
   *
   * @return Return "Not assigned" when not assigned and complete message when assigned
   */
  assignAssignmentToBuybackResponse(params: AnnouncementService.AssignAssignmentToBuybackParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/toBuyback/${encodeURIComponent(String(params.announcementID))}/${encodeURIComponent(String(params.buybackID))}/${encodeURIComponent(String(params.processDate))}/${encodeURIComponent(String(params.countryCode))}/${encodeURIComponent(String(params.countryID))}/${encodeURIComponent(String(params.reportPeriodID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.AssignAssignmentToBuybackParams` containing the following parameters:
   *
   * - `reportPeriodID`: Report period Id
   *
   * - `processDate`: Process Date
   *
   * - `countryID`: Country Id
   *
   * - `countryCode`: Forced Country Code
   *
   * - `buybackID`: Buyback Id
   *
   * - `announcementID`: Announcement Id
   *
   * @return Return "Not assigned" when not assigned and complete message when assigned
   */
  assignAssignmentToBuyback(params: AnnouncementService.AssignAssignmentToBuybackParams): __Observable<string> {
    return this.assignAssignmentToBuybackResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AnnouncementService.AssignAnnouncementToIntentionParams` containing the following parameters:
   *
   * - `reportPeriodID`: Report period Id
   *
   * - `processDate`: Process Date
   *
   * - `intentionID`: Intention Id
   *
   * - `countryID`: Country Id
   *
   * - `countryCode`: Forced Country Code
   *
   * - `announcementID`: Announcement Id
   *
   * - `buybackIntentionGroupByID`: buybackIntentionGroupByID
   *
   * @return Return "Not assigned" when not assigned and complete message when assigned
   */
  assignAnnouncementToIntentionResponse(params: AnnouncementService.AssignAnnouncementToIntentionParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    if (params.buybackIntentionGroupByID != null) __params = __params.set('buybackIntentionGroupByID', params.buybackIntentionGroupByID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/toIntention/${encodeURIComponent(String(params.announcementID))}/${encodeURIComponent(String(params.intentionID))}/${encodeURIComponent(String(params.processDate))}/${encodeURIComponent(String(params.countryCode))}/${encodeURIComponent(String(params.countryID))}/${encodeURIComponent(String(params.reportPeriodID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.AssignAnnouncementToIntentionParams` containing the following parameters:
   *
   * - `reportPeriodID`: Report period Id
   *
   * - `processDate`: Process Date
   *
   * - `intentionID`: Intention Id
   *
   * - `countryID`: Country Id
   *
   * - `countryCode`: Forced Country Code
   *
   * - `announcementID`: Announcement Id
   *
   * - `buybackIntentionGroupByID`: buybackIntentionGroupByID
   *
   * @return Return "Not assigned" when not assigned and complete message when assigned
   */
  assignAnnouncementToIntention(params: AnnouncementService.AssignAnnouncementToIntentionParams): __Observable<string> {
    return this.assignAnnouncementToIntentionResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AnnouncementService.UnAssignAnnouncementFromBuybackParams` containing the following parameters:
   *
   * - `buybackID`: Buyback Id
   *
   * - `announcementID`: Announcement Id
   *
   * @return Return "Not Unassigned" when not Unassigned and complete message when unassigned
   */
  unAssignAnnouncementFromBuybackResponse(params: AnnouncementService.UnAssignAnnouncementFromBuybackParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/unAssign/fromBuyback/${encodeURIComponent(String(params.announcementID))}/${encodeURIComponent(String(params.buybackID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.UnAssignAnnouncementFromBuybackParams` containing the following parameters:
   *
   * - `buybackID`: Buyback Id
   *
   * - `announcementID`: Announcement Id
   *
   * @return Return "Not Unassigned" when not Unassigned and complete message when unassigned
   */
  unAssignAnnouncementFromBuyback(params: AnnouncementService.UnAssignAnnouncementFromBuybackParams): __Observable<string> {
    return this.unAssignAnnouncementFromBuybackResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AnnouncementService.UnAssignAnnouncementFromIntentionParams` containing the following parameters:
   *
   * - `intentionID`: Itention Id
   *
   * - `announcementID`: Announcement Id
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Return "Not Unassigned" when not Unassigned and complete message when unassigned
   */
  unAssignAnnouncementFromIntentionResponse(params: AnnouncementService.UnAssignAnnouncementFromIntentionParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.buybackIntentionGroupByID != null) __params = __params.set('buybackIntentionGroupByID', params.buybackIntentionGroupByID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/unAssign/fromIntention/${encodeURIComponent(String(params.announcementID))}/${encodeURIComponent(String(params.intentionID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AnnouncementService.UnAssignAnnouncementFromIntentionParams` containing the following parameters:
   *
   * - `intentionID`: Itention Id
   *
   * - `announcementID`: Announcement Id
   *
   * - `buybackIntentionGroupByID`: Buyback Intention Group Id
   *
   * @return Return "Not Unassigned" when not Unassigned and complete message when unassigned
   */
  unAssignAnnouncementFromIntention(params: AnnouncementService.UnAssignAnnouncementFromIntentionParams): __Observable<string> {
    return this.unAssignAnnouncementFromIntentionResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `AnnouncementService.LockingAnnouncementParams` containing the following parameters:
   *
   * - `status`: status will true for lock and false for unlock.
   *
   * - `announcementID`: announcementID Id
   *
   * @return Return whether operation is successful or not.
   */
  lockingAnnouncementResponse(params: AnnouncementService.LockingAnnouncementParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/locking/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `AnnouncementService.LockingAnnouncementParams` containing the following parameters:
   *
   * - `status`: status will true for lock and false for unlock.
   *
   * - `announcementID`: announcementID Id
   *
   * @return Return whether operation is successful or not.
   */
  lockingAnnouncement(params: AnnouncementService.LockingAnnouncementParams): __Observable<boolean> {
    return this.lockingAnnouncementResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param announcementID announcementID Id
   * @return Return whether operation is successful or not.
   */
  unlockingAnnouncementResponse(announcementID: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/unlocking/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param announcementID announcementID Id
   * @return Return whether operation is successful or not.
   */
  unlockingAnnouncement(announcementID: number): __Observable<boolean> {
    return this.unlockingAnnouncementResponse(announcementID).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param announcementID announcementID Id
   * @return Return whether it is lock or not.
   */
  isLockedAnnouncementResponse(announcementID: number): __Observable<__StrictHttpResponse<LockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/announcement/isLock/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LockStatus>;
      })
    );
  }
  /**
   * @param announcementID announcementID Id
   * @return Return whether it is lock or not.
   */
  isLockedAnnouncement(announcementID: number): __Observable<LockStatus> {
    return this.isLockedAnnouncementResponse(announcementID).pipe(
      __map(_r => _r.body as LockStatus)
    );
  }

  /**
   * @param params The `AnnouncementService.MarkAsProcessedParams` containing the following parameters:
   *
   * - `announcementID`: Announcement Id that needs to be deleted.
   *
   * - `MarkProcessed`: Post MarkProcessed model.
   *
   * @return Response for Mark announcement as processed
   */
  markAsProcessedResponse(params: AnnouncementService.MarkAsProcessedParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.MarkProcessed;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/markAsProcessed/${encodeURIComponent(String(params.announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `AnnouncementService.MarkAsProcessedParams` containing the following parameters:
   *
   * - `announcementID`: Announcement Id that needs to be deleted.
   *
   * - `MarkProcessed`: Post MarkProcessed model.
   *
   * @return Response for Mark announcement as processed
   */
  markAsProcessed(params: AnnouncementService.MarkAsProcessedParams): __Observable<boolean> {
    return this.markAsProcessedResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param announcementID Announcement Id.
   * @return Return boolean response for markAsChecked.
   */
  markAsCheckedResponse(announcementID: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/markAsChecked/${encodeURIComponent(String(announcementID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param announcementID Announcement Id.
   * @return Return boolean response for markAsChecked.
   */
  markAsChecked(announcementID: number): __Observable<boolean> {
    return this.markAsCheckedResponse(announcementID).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param countryID Country Id.
   * @return Return GrabberDetail model.
   */
  getGrabberDetailResponse(countryID: number): __Observable<__StrictHttpResponse<GrabberDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/grabberDetail/${encodeURIComponent(String(countryID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GrabberDetail>;
      })
    );
  }
  /**
   * @param countryID Country Id.
   * @return Return GrabberDetail model.
   */
  getGrabberDetail(countryID: number): __Observable<GrabberDetail> {
    return this.getGrabberDetailResponse(countryID).pipe(
      __map(_r => _r.body as GrabberDetail)
    );
  }
}

module AnnouncementService {

  /**
   * Parameters for getAnnouncementsLinkedToTrade
   */
  export interface GetAnnouncementsLinkedToTradeParams {

    /**
     * tradeType defines what kind of trade assigned with announcement, either Buyback or Intention.
     */
    tradeType: string;

    /**
     * tradeId is the Id of saved transaction. It should be buybackId or intentionId depending upon parameter tradeType
     */
    tradeID: number;
  }

  /**
   * Parameters for markAnnouncement
   */
  export interface MarkAnnouncementParams {

    /**
     * Announcement ID
     */
    announcementID: number;

    /**
     * Action defines which type of operation need to perform on backend, either Unmark or Mark announcement as edited.
     */
    action: string;
  }

  /**
   * Parameters for assignAssignmentToBuyback
   */
  export interface AssignAssignmentToBuybackParams {

    /**
     * Report period Id
     */
    reportPeriodID: number;

    /**
     * Process Date
     */
    processDate: string;

    /**
     * Country Id
     */
    countryID: number;

    /**
     * Forced Country Code
     */
    countryCode: string;

    /**
     * Buyback Id
     */
    buybackID: number;

    /**
     * Announcement Id
     */
    announcementID: number;
  }

  /**
   * Parameters for assignAnnouncementToIntention
   */
  export interface AssignAnnouncementToIntentionParams {

    /**
     * Report period Id
     */
    reportPeriodID: number;

    /**
     * Process Date
     */
    processDate: string;

    /**
     * Intention Id
     */
    intentionID: number;

    /**
     * Country Id
     */
    countryID: number;

    /**
     * Forced Country Code
     */
    countryCode: string;

    /**
     * Announcement Id
     */
    announcementID: number;

    /**
     * buybackIntentionGroupByID
     */
    buybackIntentionGroupByID?: number;
  }

  /**
   * Parameters for unAssignAnnouncementFromBuyback
   */
  export interface UnAssignAnnouncementFromBuybackParams {

    /**
     * Buyback Id
     */
    buybackID: number;

    /**
     * Announcement Id
     */
    announcementID: number;
  }

  /**
   * Parameters for unAssignAnnouncementFromIntention
   */
  export interface UnAssignAnnouncementFromIntentionParams {

    /**
     * Itention Id
     */
    intentionID: number;

    /**
     * Announcement Id
     */
    announcementID: number;

    /**
     * Buyback Intention Group Id
     */
    buybackIntentionGroupByID?: number;
  }

  /**
   * Parameters for lockingAnnouncement
   */
  export interface LockingAnnouncementParams {

    /**
     * status will true for lock and false for unlock.
     */
    status: boolean;

    /**
     * announcementID Id
     */
    announcementID: number;
  }

  /**
   * Parameters for markAsProcessed
   */
  export interface MarkAsProcessedParams {

    /**
     * Announcement Id that needs to be deleted.
     */
    announcementID: number;

    /**
     * Post MarkProcessed model.
     */
    MarkProcessed: MarkProcessed;
  }
}

export { AnnouncementService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RepresentativeDistrictModel } from '../models/representative-district-model';
@Injectable({
  providedIn: 'root',
})
class RepresentativeDistrictService extends __BaseService {
  static readonly getRepresentativeDistrictPath = '/representative/district';
  static readonly createRepresentativeDistrictPath = '/representative/district';
  static readonly exportRepresentativeDistrictPath = '/representative/district/import';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return All Added Representative Districts will be returned.
   */
  getRepresentativeDistrictResponse(): __Observable<__StrictHttpResponse<Array<RepresentativeDistrictModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/representative/district`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RepresentativeDistrictModel>>;
      })
    );
  }
  /**
   * @return All Added Representative Districts will be returned.
   */
  getRepresentativeDistrict(): __Observable<Array<RepresentativeDistrictModel>> {
    return this.getRepresentativeDistrictResponse().pipe(
      __map(_r => _r.body as Array<RepresentativeDistrictModel>)
    );
  }

  /**
   * @param representativeDistrictModel undefined
   * @return New Added Transaction Representative District will be returned.
   */
  createRepresentativeDistrictResponse(representativeDistrictModel: RepresentativeDistrictModel): __Observable<__StrictHttpResponse<RepresentativeDistrictModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = representativeDistrictModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/representative/district`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RepresentativeDistrictModel>;
      })
    );
  }
  /**
   * @param representativeDistrictModel undefined
   * @return New Added Transaction Representative District will be returned.
   */
  createRepresentativeDistrict(representativeDistrictModel: RepresentativeDistrictModel): __Observable<RepresentativeDistrictModel> {
    return this.createRepresentativeDistrictResponse(representativeDistrictModel).pipe(
      __map(_r => _r.body as RepresentativeDistrictModel)
    );
  }

  /**
   * @return A message will be shown after successfull Export.
   */
  exportRepresentativeDistrictResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/representative/district/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return A message will be shown after successfull Export.
   */
  exportRepresentativeDistrict(): __Observable<string> {
    return this.exportRepresentativeDistrictResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module RepresentativeDistrictService {
}

export { RepresentativeDistrictService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SdsQualityGateModel } from '../models/sds-quality-gate-model';
import { SdsQualityGateFilter } from '../models/sds-quality-gate-filter';
import { SdsQgMarkAttendedRequest } from '../models/sds-qg-mark-attended-request';
import { SdsQgEditRequest } from '../models/sds-qg-edit-request';
import { SdsQgRevertRequest } from '../models/sds-qg-revert-request';
@Injectable({
  providedIn: 'root',
})
class ShortDisclosuresQualityGateService extends __BaseService {
  static readonly getShortDisclosuresForQualityGatePath = '/sdsQualityGate';
  static readonly markAttendedPath = '/sdsQualityGate/mark_attended';
  static readonly editDisclosurePath = '/sdsQualityGate/edit';
  static readonly revertPath = '/sdsQualityGate/revert';
  static readonly unDeleteDisclosurePath = '/sdsQualityGate/unDelete/{disclosureId}';
  static readonly markDisclosureDeletedPath = '/sdsQualityGate/revert/unDelete/{disclosureId}';
  static readonly markDisclosureInactivePath = '/sdsQualityGate/inactive/{disclosureId}';
  static readonly revertInactiveDisclosurePath = '/sdsQualityGate/revertInactive/{disclosureId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param qualityGateFilter undefined
   * @return list of values need to be in order to make it correctable
   */
  getShortDisclosuresForQualityGateResponse(qualityGateFilter: SdsQualityGateFilter): __Observable<__StrictHttpResponse<SdsQualityGateModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = qualityGateFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sdsQualityGate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SdsQualityGateModel>;
      })
    );
  }
  /**
   * @param qualityGateFilter undefined
   * @return list of values need to be in order to make it correctable
   */
  getShortDisclosuresForQualityGate(qualityGateFilter: SdsQualityGateFilter): __Observable<SdsQualityGateModel> {
    return this.getShortDisclosuresForQualityGateResponse(qualityGateFilter).pipe(
      __map(_r => _r.body as SdsQualityGateModel)
    );
  }

  /**
   * @param markAttendedRequest undefined
   * @return returs boolean status
   */
  markAttendedResponse(markAttendedRequest: SdsQgMarkAttendedRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = markAttendedRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sdsQualityGate/mark_attended`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param markAttendedRequest undefined
   * @return returs boolean status
   */
  markAttended(markAttendedRequest: SdsQgMarkAttendedRequest): __Observable<boolean> {
    return this.markAttendedResponse(markAttendedRequest).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param editRequest undefined
   * @return returs boolean status
   */
  editDisclosureResponse(editRequest: SdsQgEditRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = editRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sdsQualityGate/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param editRequest undefined
   * @return returs boolean status
   */
  editDisclosure(editRequest: SdsQgEditRequest): __Observable<boolean> {
    return this.editDisclosureResponse(editRequest).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param revertRequest undefined
   * @return returs boolean status
   */
  revertResponse(revertRequest: SdsQgRevertRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = revertRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sdsQualityGate/revert`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param revertRequest undefined
   * @return returs boolean status
   */
  revert(revertRequest: SdsQgRevertRequest): __Observable<boolean> {
    return this.revertResponse(revertRequest).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * This reverts back the deleted disclosures.
   * @param params The `ShortDisclosuresQualityGateService.UnDeleteDisclosureParams` containing the following parameters:
   *
   * - `disclosureId`:
   *
   * - `qualityGateType`:
   *
   * @return Disclosure has been reverted successfully.
   */
  unDeleteDisclosureResponse(params: ShortDisclosuresQualityGateService.UnDeleteDisclosureParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.qualityGateType != null) __params = __params.set('qualityGateType', params.qualityGateType.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sdsQualityGate/unDelete/${encodeURIComponent(String(params.disclosureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * This reverts back the deleted disclosures.
   * @param params The `ShortDisclosuresQualityGateService.UnDeleteDisclosureParams` containing the following parameters:
   *
   * - `disclosureId`:
   *
   * - `qualityGateType`:
   *
   * @return Disclosure has been reverted successfully.
   */
  unDeleteDisclosure(params: ShortDisclosuresQualityGateService.UnDeleteDisclosureParams): __Observable<boolean> {
    return this.unDeleteDisclosureResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * This reverts back the un_deleted disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  markDisclosureDeletedResponse(disclosureId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sdsQualityGate/revert/unDelete/${encodeURIComponent(String(disclosureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * This reverts back the un_deleted disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  markDisclosureDeleted(disclosureId: number): __Observable<boolean> {
    return this.markDisclosureDeletedResponse(disclosureId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * This reverts back the inactive disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  markDisclosureInactiveResponse(disclosureId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sdsQualityGate/inactive/${encodeURIComponent(String(disclosureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * This reverts back the inactive disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  markDisclosureInactive(disclosureId: number): __Observable<boolean> {
    return this.markDisclosureInactiveResponse(disclosureId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * This reverts back the inactive disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  revertInactiveDisclosureResponse(disclosureId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sdsQualityGate/revertInactive/${encodeURIComponent(String(disclosureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * This reverts back the inactive disclosures.
   * @param disclosureId undefined
   * @return Disclosure has been reverted successfully.
   */
  revertInactiveDisclosure(disclosureId: number): __Observable<boolean> {
    return this.revertInactiveDisclosureResponse(disclosureId).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module ShortDisclosuresQualityGateService {

  /**
   * Parameters for unDeleteDisclosure
   */
  export interface UnDeleteDisclosureParams {
    disclosureId: number;
    qualityGateType?: string;
  }
}

export { ShortDisclosuresQualityGateService }

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EUDisclosure } from '../models/eudisclosure';
import { ViewDisclosureFilter } from '../models/view-disclosure-filter';
import { AsianDisclosure } from '../models/asian-disclosure';
import { AmericanDisclosure } from '../models/american-disclosure';
import { OceanianDisclosure } from '../models/oceanian-disclosure';
@Injectable({
  providedIn: 'root',
})
class ShortDisclosuresService extends __BaseService {
  static readonly getEUDisclosuresPath = '/shortDisclosures/european/view/';
  static readonly getEUDisclosuresExportPath = '/shortDisclosures/european/export/';
  static readonly UpdateEUDisclosuresPath = '/shortDisclosures/european/update/';
  static readonly getAsianDisclosuresPath = '/shortDisclosures/asian/view/';
  static readonly getAsianDisclosuresExportPath = '/shortDisclosures/asian/export/';
  static readonly getAmericanDisclosuresPath = '/shortDisclosures/american/view/';
  static readonly getAmericanDisclosuresExportPath = '/shortDisclosures/american/export/';
  static readonly getOceanianDisclosuresPath = '/shortDisclosures/oceanian/view/';
  static readonly getOceanianDisclosuresExportPath = '/shortDisclosures/oceanian/export/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getEUDisclosuresResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Array<EUDisclosure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/european/view/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EUDisclosure>>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getEUDisclosures(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Array<EUDisclosure>> {
    return this.getEUDisclosuresResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Array<EUDisclosure>)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data export according to paramters
   */
  getEUDisclosuresExportResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/european/export/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data export according to paramters
   */
  getEUDisclosuresExport(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Blob> {
    return this.getEUDisclosuresExportResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Returns the item updated
   */
  UpdateEUDisclosuresResponse(viewDisclosureFilter: EUDisclosure): __Observable<__StrictHttpResponse<EUDisclosure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/european/update/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EUDisclosure>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Returns the item updated
   */
  UpdateEUDisclosures(viewDisclosureFilter: EUDisclosure): __Observable<EUDisclosure> {
    return this.UpdateEUDisclosuresResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as EUDisclosure)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getAsianDisclosuresResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Array<AsianDisclosure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/asian/view/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AsianDisclosure>>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getAsianDisclosures(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Array<AsianDisclosure>> {
    return this.getAsianDisclosuresResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Array<AsianDisclosure>)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getAsianDisclosuresExportResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/asian/export/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getAsianDisclosuresExport(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Blob> {
    return this.getAsianDisclosuresExportResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getAmericanDisclosuresResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Array<AmericanDisclosure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/american/view/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AmericanDisclosure>>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getAmericanDisclosures(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Array<AmericanDisclosure>> {
    return this.getAmericanDisclosuresResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Array<AmericanDisclosure>)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getAmericanDisclosuresExportResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/american/export/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getAmericanDisclosuresExport(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Blob> {
    return this.getAmericanDisclosuresExportResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getOceanianDisclosuresResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Array<OceanianDisclosure>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/oceanian/view/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OceanianDisclosure>>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return Sds data according to paramters
   */
  getOceanianDisclosures(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Array<OceanianDisclosure>> {
    return this.getOceanianDisclosuresResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Array<OceanianDisclosure>)
    );
  }

  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getOceanianDisclosuresExportResponse(viewDisclosureFilter: ViewDisclosureFilter): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = viewDisclosureFilter;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shortDisclosures/oceanian/export/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param viewDisclosureFilter undefined
   * @return Return data export according to paramters
   */
  getOceanianDisclosuresExport(viewDisclosureFilter: ViewDisclosureFilter): __Observable<Blob> {
    return this.getOceanianDisclosuresExportResponse(viewDisclosureFilter).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ShortDisclosuresService {
}

export { ShortDisclosuresService }
